import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { RichText } from 'prismic-reactjs'

import Layout from '../../components/Layout'
import OrganizationMemberDetail from '../../components/OrganizationMemberDetail'
import SliceZone from '../../components/SliceZone'
import Seo from '../../components/Seo'

const OrganizationMemberTemplate = ({ data }) => {
  if (!data) return null
  const doc = data.prismicOrganizationMember.data

  const {
    name,
    description,
  } = doc

  return (
    <Layout useDarkHeader={true} useDarkFooter={true}>
      <Seo title={RichText.asText(name.richText)} description={RichText.asText(description.richText)} />
      
      <div className="pt-12 md:pt-6">
        <OrganizationMemberDetail {...doc} id={data.prismicOrganizationMember.id} />
        
        {doc.body ? <SliceZone sliceZone={doc.body} /> : null}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query OrganizationMemberQuery($id: String) {
    prismicOrganizationMember(id: { eq: $id }) {
      ...PrismicOrganizationMemberFragment

      data {
        body {
          ...on PrismicSliceType {
            slice_type
          }
          ...OrganizationMemberDataBodyFeaturedArticles
        }
      }
    }
  }
`

export default withPrismicPreview(OrganizationMemberTemplate)
