import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import aeembedcalendar from '../utils/EmbedCalendar'

const FullSizeEmbed = ({ slice }) => {
  const { primary } = slice

  useEffect(() => {
    const calendar = new aeembedcalendar()
    calendar.initialize()
  });

  if(!slice || !slice.primary) return null

  return (
    <section className={`container mt-4 sm:mt-8`}>
      {primary.title ? 
        <h2 className="text-center">
          {RichText.asText(primary.title.richText)}
        </h2> : null}
      
      {primary.embed_code ? 
        <div dangerouslySetInnerHTML={{ __html: primary.embed_code }}></div> 
        : null}
    </section>
  )
}

export const query = graphql`
  fragment SteeringGroupDataBodyFullSizeEmbed on PrismicSteeringGroupDataBodyFullSizeEmbed {
    primary {
      title {
        richText
      }
      embed_code
    }
  }

  fragment PageDataBodyFullSizeEmbed on PrismicPageDataBodyFullSizeEmbed {
    primary {
      title {
        richText
      }
      embed_code
    }
  }
`

export default FullSizeEmbed
