import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

import LinkButton from '../components/LinkButton.js'
import TeamMemberPhoto from '../components/TeamMemberPhoto.js'

const AllAdvisors = ({ slice }) => {
  const { primary } = slice
  
  // get all advisors
  const queryData = useStaticQuery(graphql`
    {
      allPrismicAdvisor(sort: {data: {full_name: {text: ASC}}}) {
        edges {
          node {
            _previewable
            data {
              linkedin_profile {
                url
                uid
                type
                target
                link_type
              }
              full_name {
                richText
              }
              photo {
                gatsbyImageData(
                  width: 180, layout: FULL_WIDTH
                )
              }
              title {
                richText
              }
            }
          }
        }
      }
    }
  `)
  const { data } = useMergePrismicPreviewData(queryData)
  const doc = data.allPrismicAdvisor
  const advisors = doc.edges

  return (
    <section className="mt-16 sm:mt-20">
      <div className="container">
        {!!primary.title && (
          <h2 className="text-center">
            {RichText.asText(primary.title.richText)}
          </h2>
        )}

        {!!primary.subtitle && (
          <p className="text-xl font-light font-display text-gray-500 mt-3 text-center">
            {RichText.asText(primary.subtitle.richText)}
          </p>
        )}

        {Array.isArray(advisors) && advisors.length > 0 && (
          <div className="grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-5 gap-4 mt-12 mb-0">
            {advisors.map((member, index) => member?.node?.data ?
              (
                <TeamMemberPhoto
                  {...member.node.data}
                  key={`advisor-photo-${index}`}
                />
              ) :
              null
            )}
          </div>
        )}
        
        {!!primary.cta && !!primary.link.url && (
          <div className="mt-12">
            <LinkButton
              label={primary.cta}
              link={primary.link}
            />
          </div>
        )}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyAllAdvisors on PrismicPageDataBodyAllAdvisors {
    primary {
      cta: call_to_action
      link {
        url
        type
        target
        uid
        link_type
      }
      subtitle {
        richText
      }
      title {
        richText
      }
    }
  }

  fragment LandingPageDataBodyAllAdvisors on PrismicLandingPageDataBodyAllAdvisors {
    primary {
      cta: call_to_action
      link {
        url
        type
        target
        uid
        link_type
      }
      subtitle {
        richText
      }
      title {
        richText
      }
    }
  }
`

export default AllAdvisors
