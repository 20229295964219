import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { linkResolver } from '/src/utils/LinkResolver.js';

import { RichText } from 'prismic-reactjs';

import ChevronDown from '/src/images/icons/chevron-down.svg';
import ChevronUp from '/src/images/icons/chevron-up.svg';


const TwoTeamMembersWithText = ({ slice }) => {
  const [showRevealableText, setShowRevealableText] = useState(false);
  const {
    background_color,
    revealable_text,
    team_member_1,
    team_member_2,
    title,
    visible_text
  } = slice.primary;

  const handleReadMoreButtonClick = () => {
    setShowRevealableText(prevState => !prevState);
  };

  const teamMember1 = team_member_1?.document?.data;
  const teamMember1Image = getImage(teamMember1?.photo);
  const teamMember2 = team_member_2?.document?.data;
  const teamMember2Image = getImage(teamMember2?.photo);

  return (
    <section
      className="pb-[60px] sm:pb-[120px]"
      style={{ backgroundColor: background_color || '#ffffff' }}
    >
      <div className="container max-w-[976px]">
        <div className="bg-white rounded-md p-5 sm:p-12 z-10">
          {!!title.text && (
            <h4 className="font-bold text-black text-[24px] sm:text-[32px]">
              {title.text}
            </h4>
          )}
          <div className="relative md:flex items-start justify-between mt-3">
            <div>
              {(Array.isArray(visible_text.richText) || visible_text.richText[0]?.text?.length > 0) && (
                <div className="text-gray-700 leading-6">
                  <RichText
                    linkResolver={linkResolver}
                    render={visible_text.richText}
                  />
                </div>
              )}
              {showRevealableText && (Array.isArray(revealable_text.richText) || revealable_text.richText[0]?.text?.length > 0) && (
                <div className="prose font-sans text-gray-700 leading-6 mt-3">
                  <RichText
                    linkResolver={linkResolver}
                    render={revealable_text.richText}
                  />
                </div>
              )}
              {(Array.isArray(revealable_text.richText) || revealable_text.richText[0]?.text?.length > 0) && (
                <div className="mt-6">
                  <button
                    aria-expanded={showRevealableText}
                    className="text-secondary-900"
                    type="button"
                    onClick={handleReadMoreButtonClick}
                  >
                    <div className="flex flex-row items-center">
                      <span className="font-semibold">
                        Read
                        {' '}
                        {showRevealableText ?
                        (
                          <span>
                            less
                          </span>
                        ) :
                        (
                          <span>
                            more
                          </span>
                        )}
                      </span>
                      {showRevealableText ?
                      (
                        <ChevronUp className="ml-1" />
                      ) :
                      (
                        <ChevronDown className="ml-1" />
                      )}
                    </div>
                  </button>
                </div>
              )}
            </div>
            {(!!teamMember1 || !!teamMember2) && (
              <div className="bg-[#f2f2f5] rounded-md p-3 mt-7 md:mt-0 md:ml-7">
                {!!teamMember1 && (
                  <div className="flex items-center">
                    {!!teamMember1Image && (
                      <GatsbyImage
                        alt={teamMember1Image.alt || ''}
                        className="rounded-md shrink-0"
                        image={teamMember1Image}
                        objectFit="cover"
                        objectPosition="center top"
                        layout="fixed"
                        width={90}
                      />
                    )}
                    {!!teamMember1.full_name.text && !!teamMember1.title.text && (
                      <div className="min-w-[150px]">
                        <div className="ml-2 mb-1">
                          {teamMember1.full_name.text}
                        </div>
                        <div className="text-xs text-gray-600 ml-2">
                          {teamMember1.title.text}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {!!teamMember2 && (
                  <div className="flex items-center mt-3">
                    {!!teamMember2Image && (
                      <GatsbyImage
                        alt={teamMember2Image.alt || ''}
                        className="rounded-md shrink-0"
                        objectFit="cover"
                        objectPosition="center top"
                        image={teamMember2Image}
                        layout="fixed"
                        width={90}
                      />
                    )}
                    {!!teamMember2.full_name.text && !!teamMember2.title.text && (
                      <div className="min-w-[150px]">
                        <div className="ml-2 mb-1">
                          {teamMember2.full_name.text}
                        </div>
                        <div className="text-xs text-gray-600 ml-2">
                          {teamMember2.title.text}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment AnnualReportDataBodyTwoTeamMembersWithText on PrismicAnnualReportDataBodyTwoTeamMembersWithText {
    primary {
      background_color
      team_member_1 {
        document {
          ... on PrismicTeamMember {
            data {
              full_name {
                text
              }
              photo {
                gatsbyImageData(aspectRatio: 1, width: 90, layout: FIXED)
                alt
              }
              title {
                text
              }
            }
          }
        }
      }
      team_member_2 {
        document {
          ... on PrismicTeamMember {
            data {
              photo {
                gatsbyImageData(aspectRatio: 1, width: 90, layout: FIXED)
                alt
              }
              title {
                text
              }
              full_name {
                text
              }
            }
          }
        }
      }
      revealable_text {
        richText
        html
      }
      title {
        text
      }
      visible_text {
        richText
      }
    }
  }
`;

export default TwoTeamMembersWithText;
