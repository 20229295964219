import * as React from 'react'
import { RichText } from 'prismic-reactjs'
import {
  isFuture,
  isPast,
} from 'date-fns'
import { uniq } from 'lodash'

import EventsByMonth from './EventsByMonth'
import LinkButton from './LinkButton'

class ListEvents extends React.Component {

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      activeType: null
    }
  }

  handleChange(event) {
    const newState = { activeType: event.target.value }
    this.setState(newState)
  };

  isVisible(evnt) {
    if (this.state.activeType === null)
      return true

    if (evnt.type && evnt.type === this.state.activeType)
      return true

    return false
  }

  render() {
    const {
      allEvents,
      showUpcoming,
      showPast,
      headline,
      description,
      call_to_action,
      call_to_action_label
    } = this.props

    let events = []

    if(showUpcoming) {
      events = allEvents.filter(event => isFuture(event.start_date_obj) || isFuture(event.end_date_obj))
    } else if (showPast) {
      events = allEvents.filter(event => isPast(event.start_date_obj) && isPast(event.end_date_obj))
    } else {
      events = allEvents
    }

    const allTypes = uniq(events.map(evnt => evnt.type))
    allTypes.unshift(null)
    events = events.filter((evnt) => this.isVisible(evnt))

    return (
      <section className={`mt-12 sm:mt-20 lg:pb-8 container-medium`}>
        <h2 className="text-center">
          {RichText.asText(headline.richText)}
        </h2>

        <p className="text-xl font-light font-display text-gray-500 mt-3 text-center">
          {RichText.asText(description.richText)}
        </p>

        <div className="mt-8 sm:mt-12 c-tabs">
          <strong className="inline-block mr-3">Filter:</strong>

          <div className="inline-block relative w-64">
            <select
              onChange={this.handleChange}
              defaultValue={''}
              className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            >
              {allTypes.map((type, index) => {
                return (
                  <option
                    key={`events-filter-${index}`}
                  >
                    {type === null ? 'All' : type}
                  </option>
                )
              })}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
            </div>
          </div>
        </div>

        <div className={`mt-12`}>
          <EventsByMonth events={events} />
        </div>

        {call_to_action && call_to_action_label ?
          <div className="mt-12 text-center">
            <LinkButton
              link={call_to_action}
              label={call_to_action_label}
              isWarning
            />
          </div> : null}
      </section>
    )
  }
}

export default ListEvents
