import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { linkResolver } from '../utils/LinkResolver.js';
import { RichText } from 'prismic-reactjs';

// Import Swiper
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';


const PositionFeaturedResources = ({ slice }) => {
  const { description, title } = slice?.primary || {};
  const { items } = slice;

  if(!slice || (!Array.isArray(slice.items) || !slice.items.length)) return null;

  const featuredResoureElement = (item, index) => {
    if (!item) return null;

    const { resource_type } = item;
    let resourceTypeTextColor = null;
    let resourceTypeBackgroundColor = null;

    switch (resource_type) {
      case 'External Link':
      case 'Internal Link':
        resourceTypeTextColor = '#F48D2B'
        resourceTypeBackgroundColor = '#E696351F';
        break;
      case 'Resource':
        default:
        resourceTypeTextColor = '##1D4732';
        resourceTypeBackgroundColor = '#38604B1F';
        break;
    }

    const resourceData = item.resource?.document?.data;
    const image = getImage(resourceData.preview_image || item.image);
    const link = resourceData?.resource?.url || item.link?.url;
    const linkTarget = resourceData?.resource?.target || item.link?.target;

    return (
      <Link
        key={index}
        className="flex flex-col gap-[20px] md:flex-row mt-[32px] md:mt-[60px]"
        to={link}
        target={linkTarget}
      >
        <div className="rounded-md bg-white shadow-md p-[8px] md:p-[12px] h-[200px] md:h-[275px] w-[150px] md:min-w-[160px] lg:min-w-[200px]">
          <GatsbyImage
            alt={RichText.asText(item.image.alt) || ""}
            className="block rounded-md h-full w-full"
            objectFit="cover"
            image={image}
          />
        </div>
        <div>
          {!!item.resource_type && (
            <div
              className="inline-block rounded-[30px] mb-[12px] py-[4px] px-[8px] uppercase text-[12px] md:text-[14px] leading-[14px] md:leading-[16px]"
              style={{
                backgroundColor: resourceTypeBackgroundColor,
                color: resourceTypeTextColor
              }}
            >
              {item.resource_type}
            </div>
          )}
          {(!!item.title?.text || !!resourceData.name.text) && (
            <h5 className="text-[18px] leading-[25px] md:text-[20px] md:leading-[27px]font-semibold font-body line-clamp-3">
              {item.title?.text || resourceData.name.text}
            </h5>
          )}
          {Array.isArray(resourceData.description?.richText) && resourceData.description.richText[0]?.text && (
            <div className="text-[14px] leading-[23px] md:text-[16px] md:leading-[26px] mt-[4px] md:mt-[12px] line-clamp-6">
              <RichText render={resourceData.description.richText} />
            </div>
          )}
        </div>
      </Link>
    );
  };

  return (
    <section className="bg-[#F2F2F5] py-[60px] md:py-[120px] my-[60px] md:my-[140px]">
      <div className="container">
        <h2 className="font-display font-bold text-[24px] md:text-[40px] text-[#1B202B] leading-[25px] md:leading-[49px]">
          {title.text}
        </h2>
        {Array.isArray(description?.richText) && description.richText[0]?.text && (
          <div className="text-[18px] md:text-[20px] leading-[24px] md:leading-[29px] text-[#1B202B] mx-auto mt-[20px]">
            <RichText
              linkResolver={linkResolver}
              render={description.richText}
            />
          </div>
        )}
        <div className='md:hidden'>
          <Swiper
            className="overflow-visible"
            breakpoints={{
              576: {
                slidesPerView: 2.1
              }
            }}
            spaceBetween={24}
            slidesPerView={1.1}
          >
            {items.map((item, index) => (
              <SwiperSlide key={index}>
                {featuredResoureElement(item, index)}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="hidden md:grid grid-cols-2 gap-x-[56px] gap-y-[30px]">
          {items.map((item, index) => featuredResoureElement(item, index))}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PositionDataBodyPositionFeaturedResources on PrismicPositionDataBodyPositionFeaturedResources {
    items {
      resource_type
      resource {
        document {
          ...PrismicResourceFragment
        }
      }
      image {
        gatsbyImageData(width: 180)
      }
      link {
        url
        uid
        type
        target
        link_type
      }
      title {
        text
      }
    }
    primary {
      title {
        text
      }
      description {
        richText
      }
    }
  }
`;

export default PositionFeaturedResources;
