import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import { linkResolver } from '../utils/LinkResolver.js'

const FeaturesWithIconsGrid = ({ slice }) => {
  return (
    <section className={`mx-auto mt-24 max-w-5xl px-4 md:px-8`}>
      <div class="grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-8 md:gap-12 place-items-stretch">
        {slice.items.map((item, index) => {
          const { 
            streamline_icon_svg,
            feature_title,
            feature_description,
          } = item

          return (
            <div class="text-center" key={`feat-with-grid-icons-${index}`}>
              <img
                alt=""
                className="text-secondary-900 fill-current w-12 h-12 mx-auto"
                src={streamline_icon_svg.url}
              />
              <h4 class="mt-4">{RichText.asText(feature_title.richText)}</h4>
              <div class="mt-4 prose sm:text-lg text-gray-600">
                <RichText render={feature_description.richText} linkResolver={linkResolver} />
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyFeaturesWithIconsGrid on PrismicHomepageDataBodyFeaturesWithIconsGrid {
    items {
      feature_title {
        richText
      }
      feature_description {
        richText
      }
      streamline_icon_svg {
        url
        localFile {
          relativePath
          absolutePath
        }
      }
    }
  }

  fragment LandingPageDataBodyFeaturesWithIconsGrid on PrismicLandingPageDataBodyFeaturesWithIconsGrid {
    items {
      feature_title {
        richText
      }
      feature_description {
        richText
      }
      streamline_icon_svg {
        url
        localFile {
          relativePath
          absolutePath
        }
      }
    }
  }
`

export default FeaturesWithIconsGrid
