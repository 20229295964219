import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import { linkResolver } from '../utils/LinkResolver.js'

const FullQuote = ({ slice }) => {
  if(!slice || !slice.primary) return null

  const { primary } = slice

  return (
    <section className={`container-medium mt-12 sm:mt-24`}>
      {primary.title ? 
        <h2 className="text-center">
          {RichText.asText(primary.title.richText)}
        </h2> : null}

      {primary.subtitle ?
        <p className="text-xl font-light font-display text-gray-500 mt-3 text-center">
          {RichText.asText(primary.subtitle.richText)}
        </p> : null}

      <blockquote className="text-center text-2xl italic mt-12 md:mt-16 max-w-2xl mx-auto">
        {primary.quote ? 
          <RichText
            render={primary.quote.richText}
            linkResolver={linkResolver}
          /> : null}

        {primary.attribution ?
          <cite className="not-italic text-center text-lg mt-4 text-gray-700 block">
            {RichText.asText(primary.attribution.richText)}
          </cite> : null}
      </blockquote>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyFullQuote on PrismicHomepageDataBodyFullQuote {
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
      quote {
        richText
      }
      attribution {
        richText
      }
    }
  }

  fragment DictionaryEntryDataBodyFullQuote on PrismicDictionaryEntryDataBodyFullQuote {
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
      quote {
        richText
      }
      attribution {
        richText
      }
    }
  }

  fragment ResourceDataBodyFullQuote on PrismicResourceDataBodyFullQuote {
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
      quote {
        richText
      }
      attribution {
        richText
      }
    }
  }

  fragment PageDataBodyFullQuote on PrismicPageDataBodyFullQuote {
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
      quote {
        richText
      }
      attribution {
        richText
      }
    }
  }

  fragment LandingPageDataBodyFullQuote on PrismicLandingPageDataBodyFullQuote {
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
      quote {
        richText
      }
      attribution {
        richText
      }
    }
  }

  fragment MembershipDataBodyFullQuote on PrismicMembershipDataBodyFullQuote {
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
      quote {
        richText
      }
      attribution {
        richText
      }
    }
  }

  fragment OrganizationMemberDataBodyFullQuote on PrismicOrganizationMemberDataBodyFullQuote {
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
      quote {
        richText
      }
      attribution {
        richText
      }
    }
  }

  fragment SteeringGroupDataBodyFullQuote on PrismicSteeringGroupDataBodyFullQuote {
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
      quote {
        richText
      }
      attribution {
        richText
      }
    }
  }
`

export default FullQuote
