import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { linkResolver } from '/src/utils/LinkResolver.js';

const ArticleImage = ({ slice }) => {
  const data = slice.primary;
  const image = getImage(data.image);

  if (!image) return null;

  return (
    <section className="my-[40px] w-full">
      <GatsbyImage
        alt={image.alt || ''}
        className="h-[404px] rounded-[8px]"
        objectFit="contain"
        image={image}
      />
      {Array.isArray(data.description?.richText) && data.description.richText[0]?.text?.length > 0 && (
        <div className="text-[18px] leading-[25px] text-[#777785] text-center italic mt-[12px]">
          <RichText
            linkResolver={linkResolver}
            render={data.description.richText}
          />
        </div>
      )}
    </section>
  );
};

export const query = graphql`
  fragment ArticleDataBodyArticleImage on PrismicArticleDataBodyArticleImage {
    primary {
      description {
        richText
      }
      image {
        alt
        gatsbyImageData
      }
    }
  }
`;

export default ArticleImage;
