import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { linkResolver } from '../utils/LinkResolver.js';
import { RichText } from 'prismic-reactjs';


const FullWidthVideoBlock = ({ slice }) => {
  const data = slice.primary;

  const {
    anchor_id,
    content_alignment: contentAlignment,
    description,
    tagline,
    title,
    video_url: videoData,
    section_margin
  } = data || {};

  if (!videoData?.url ) return null;

  const {
    link_type: linkType,
    url,
  } = videoData;

  let youtubeVideoId = null;

  // Check if video is from YouTube
  // If it is, extract the video ID and use it to embed the video
  if (linkType === 'Web' && url.includes('youtube')) {
    youtubeVideoId = url.split('v=')[1];
    const ampersandPosition = youtubeVideoId.indexOf('&');

    if (ampersandPosition !== -1) {
      youtubeVideoId = youtubeVideoId.substring(0, ampersandPosition);
    }
  }

  return (
    <section
      className={`bg-[#F2F2F7] py-[60px] md:py-[120px] ${section_margin === 'small' ? 'my-[40px] md:my-[60px]' : 'my-[80px] md:my-[140px]'}`}
      id={anchor_id ?? null}
    >
      <div className="container">
        <div className={`max-w-[640px] mx-auto${contentAlignment === 'left' ? ' text-left' : ' text-center'}`}>
          {!!tagline?.text && (
            <div className="font-semibold uppercase text-[18px] md:text-[24px] leading-[22px] md:leading-[32px] tracking-[2.4px] text-[#777785] mb-[20px]">
              {tagline.text}
            </div>
          )}
          {!!title?.text && (
            <h2 className="font-bold font-body text-[32px] md:text-[40px] leading-[37px] md:leading-[44px]">
              {title.text}
            </h2>
          )}
          {Array.isArray(description?.richText) && description.richText[0]?.text?.length > 0 && (
            <div className="font-body text-[18px] md:text-[20px] leading-[24px] md:leading-[28px] mt-[20px]">
              <RichText
                linkResolver={linkResolver}
                render={description.richText}
              />
            </div>
          )}
        </div>
      </div>
      <div className="container mx-auto mt-[40px] md:mt-[60px]">
      {youtubeVideoId ?
          (
            <iframe
              className="relative rounded-md bg-white aspect-video"
              height="auto"
              src={`https://www.youtube.com/embed/${youtubeVideoId}`}
              title="YouTube video player"
              width="100%"
            />
          ) :
          (
            <video
              className="relative rounded-md bg-white"
              controls
              height="auto"
              preload="metadata"
              src={url}
              type="video/mp4"
              width="100%"
            >
              <track
                default
                kind="captions"
              />
            </video>
          )
        }
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ConferenceDataBodyFullWidthVideoBlock on PrismicDigitalSustainabilityConferenceDataBodyFullWidthVideoBlock {
    primary {
      tagline {
        text
      }
      title {
        text
      }
      description {
        richText
      }
      content_alignment
      section_margin
      video_url {
        url
        type
        target
        link_type
      }
    }
  }
`;

export default FullWidthVideoBlock;
