import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';

import CloseIcon from '/src/images/icons/close.svg';

const VideoModal = ({ onClose, showModal, videoUrl }) => {
  if (!videoUrl) return null;

  let youtubeVideoId = null;

  // Check if video is from YouTube
  // If it is, extract the video ID and use it to embed the video
  if (videoUrl.includes('youtube')) {
    youtubeVideoId = videoUrl.split('v=')[1];
    const ampersandPosition = youtubeVideoId.indexOf('&');

    if (ampersandPosition !== -1) {
      youtubeVideoId = youtubeVideoId.substring(0, ampersandPosition);
    }
  }

  return (
    <Modal
      centered
      contentClassName="relative bg-transparent rounded-[12px] border-0"
      dialogClassName="container max-w-[900px]"
      scrollable
      show={showModal}
      onHide={onClose}
    >
      <Modal.Header className="absolute w-full z-10 flex justify-end border-0 p-[10px]">
        <div
          className="bg-white rounded-md p-[8px]"
          role="button"
          tabIndex={0}
          onKeyDown={onClose}
          onClick={onClose}
        >
          <CloseIcon className="flex text-[#1D4732]" />
        </div>
      </Modal.Header>
      <Modal.Body className="p-0">
        {youtubeVideoId ?
          (
            <iframe
              className="relative rounded-md bg-white aspect-video"
              height="auto"
              src={`https://www.youtube.com/embed/${youtubeVideoId}`}
              title="YouTube video player"
              width="100%"
            />
          ) :
          (
            <video
              className="relative rounded-md bg-white"
              controls
              height="auto"
              preload="metadata"
              src={videoUrl}
              type="video/mp4"
              width="100%"
            >
              <track
                default
                kind="captions"
              />
            </video>
          )
        }
      </Modal.Body>
    </Modal>
  )
};

VideoModal.propTypes = {
  onClose: PropTypes.func,
  showModal: PropTypes.bool,
  video: PropTypes.string
};

VideoModal.defaultProps = {
  onClose: () => {},
  showModal: false,
  video: null
}

export default VideoModal;
