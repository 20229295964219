import React from 'react';
import PropTypes from 'prop-types';

import LoaderIcon from '../images/icons/loader.svg';

const Loader = ({ className }) => (
  <LoaderIcon className={`animate-spin${className ? ` ${className}` : ''}`} />
);

Loader.propTypes = {
  className: PropTypes.string
};

Loader.defaultProps = {
  className: null
};

export default Loader;
