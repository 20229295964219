import React, { useEffect } from 'react'
import { format } from 'date-fns'
import { useLocation } from "@reach/router"
import useSiteMetadata from '../hooks/use-site-metadata'

const AddToCalendar = ({ 
  children, className, start_date, end_date, title, location, description,
  main_link, main_link_label, timezone, stream_link
}) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      try {
        window.addeventatc.refresh()
      } catch (e) {
        console.error('Add to Calendar is not loaded.', e)
      }
    }
  });

  // default to Europe/Amsterdam
  const actualTimeZone = timezone || 'Europe/Amsterdam'
  const { pathname } = useLocation()
  const { siteUrl } = useSiteMetadata()
  const eventUrl = `${siteUrl}${pathname}`
  let actual_main_link, actual_stream_link
  if(main_link && main_link.url !== null) {
    actual_main_link = main_link.url && main_link.type === 'Web' ? main_link.url : `${siteUrl}${main_link.url}`
  }
  if(stream_link && stream_link.url !== null) {
    actual_stream_link = stream_link.url && stream_link.type === 'Web' ? stream_link.url : `${siteUrl}${stream_link.url}`
  }
  
  const actualDescription = `
    Event page: ${eventUrl}<br>
    ${actual_stream_link ? `Link to Live Stream: ${actual_stream_link}<br>` : ''}
    ${!!actual_stream_link && actual_main_link && main_link_label ? `${main_link_label}: ${actual_main_link}<br>` : ''}
    ${description}
  `

  return (
    <div title="Add to Calendar" className="addeventatc" data-dropdown-y="up">
      Add to Calendar
      <span className="start">{format(start_date, 'dd-MM-yyyy HH:mm')}</span>
      <span className="end">{format(end_date, 'dd-MM-yyyy HH:mm')}</span>
      <span className="timezone">{actualTimeZone}</span>
      <span className="title">{title}</span>
      <span className="description">{actualDescription}</span>
      <span className="location">{location}</span>
      <span className="alarm_reminder">15</span>
      <span className="date_format">DD/MM/YYYY</span>
    </div>
  )
}

export default AddToCalendar
