import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/LinkResolver'

export const stateStyling = {
  'Archived': "bg-gray-300 text-gray-800",
  'Upcoming': "bg-blue-100 text-blue-800",
  'Live': "bg-green-100 text-green-800",
}

const Podcast = ({ 
  cover_image,
  state,
  podcast_url,
  description,
  title,
}) => {
  return (
    <div className="max-w-sm bg-primary-100 rounded-lg">
      <a href={podcast_url.url} target={podcast_url.target} className="relative">
        <GatsbyImage
          image={cover_image}
          className="rounded-t-lg"
          alt={RichText.asText(title.richText)}
        />

        {state ? 
          <span 
            className={`
              absolute top-2 left-2 inline text-base font-medium mr-2 px-2.5 py-0.5 rounded 
              ${stateStyling[state]}
            `}
          >
            {state}
          </span>
        : null}
      </a>

      <div className="p-5">
        {/* <a href="#">
          <h5 class="mb-2 text-2xl">
            {RichText.asText(title.richText)}
          </h5>
        </a> */}

        <p className="mb-3 font-normal prose">
          <RichText render={description.richText} linkResolver={linkResolver} />
        </p>
        
        <a 
          href={podcast_url.url} 
          target={podcast_url.target} 
          className="inline-flex items-center c-button c-button--warning"
        >
          Subscribe      
          <svg class="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
        </a>
      </div>
    </div>
  )
}

export default Podcast

export const podcastFragment = graphql`
  fragment PrismicPodcastFragment on PrismicPodcast {
    _previewable
    uid
    url

    data {
      description {
        richText
      }
      podcast_url {
        url
        type
        target
        link_type
      }
      title {
        richText
      }
      state
      cover_image {
        gatsbyImageData(width: 400)
      }
    }
  }
`