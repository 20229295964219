import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';

import CloseIcon from '/src/images/icons/close.svg';

const ModalBootstrap = ({ children, onClose, showModal, title }) => {
  if (!children) return null;

  return (
    <Modal
      centered
      contentClassName="relative bg-white rounded-[12px] border-0 p-6"
      dialogClassName="container max-w-[900px]"
      scrollable
      show={showModal}
      onHide={onClose}
    >
      <Modal.Header className="w-full flex items-start justify-between border-0 p-0 mb-3">
        {!!title && (
          <h5>
            {title}
          </h5>
        )}
        <div
          className="rounded-md p-[8px]"
          role="button"
          tabIndex={0}
          onKeyDown={onClose}
          onClick={onClose}
        >
          <CloseIcon className="flex text-[#1D4732]" />
        </div>
      </Modal.Header>
      <Modal.Body className="rounded-md bg-white w-full p-0">
        {children}
      </Modal.Body>
    </Modal>
  )
};

ModalBootstrap.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onClose: PropTypes.func,
  showModal: PropTypes.bool,
  title: PropTypes.string
};

ModalBootstrap.defaultProps = {
  children: null,
  onClose: () => {},
  showModal: false,
  title: null
}

export default ModalBootstrap;
