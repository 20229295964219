import * as React from 'react';
import { graphql } from 'gatsby';

import FeaturedArticlesSection from '../components/FeaturedArticlesSection';

const FeaturedArticles = ({ slice }) => {
  if (!Array.isArray(slice?.items) || !slice.items.length) return null;

  const { items, primary } = slice;

  const articles = items.map(article => article.featured_article);

  return (
    <div className="container">
      <FeaturedArticlesSection
        items={articles}
        title={primary?.title?.richText}
        subtitle={primary?.subtitle?.richText}
      />
    </div>
  );
};

export const query = graphql`
  fragment HomepageDataBodyFeaturedArticles on PrismicHomepageDataBodyFeaturedArticles {
    items {
      featured_article {
        document {
          ... on PrismicArticle {
            ... PrismicArticleFragment
          }
        }
        slug
      }
    }
    primary {
      title {
        text
        richText
      }
      subtitle {
        text
        html
        richText
      }
    }
  }

  fragment LandingPageDataBodyFeaturedArticles on PrismicLandingPageDataBodyFeaturedArticles {
    items {
      featured_article {
        document {
          ... on PrismicArticle {
            ... PrismicArticleFragment
          }
        }
        slug
      }
    }
    primary {
      title {
        text
        richText
      }
      subtitle {
        text
        html
        richText
      }
    }
  }

  fragment PageDataBodyFeaturedArticles on PrismicPageDataBodyFeaturedArticles {
    items {
      featured_article {
        document {
          ... on PrismicArticle {
            ... PrismicArticleFragment
          }
        }
        slug
      }
    }
    primary {
      title {
        text
        richText
      }
      subtitle {
        text
        html
        richText
      }
    }
  }

  fragment EventDataBody1FeaturedArticles on PrismicEventDataBody1FeaturedArticles {
    items {
      featured_article {
        document {
          ... on PrismicArticle {
            ... PrismicArticleFragment
          }
        }
        slug
      }
    }
    primary {
      title {
        text
        richText
      }
      subtitle {
        text
        html
        richText
      }
    }
  }

  fragment SteeringGroupDataBodyFeaturedArticles on PrismicSteeringGroupDataBodyFeaturedArticles {
    items {
      featured_article {
        document {
          ... on PrismicArticle {
            ... PrismicArticleFragment
          }
        }
        slug
      }
    }
    primary {
      title {
        text
        richText
      }
      subtitle {
        text
        html
        richText
      }
    }
  }

  fragment OrganizationMemberDataBodyFeaturedArticles on PrismicOrganizationMemberDataBodyFeaturedArticles {
    items {
      featured_article {
        document {
          ... on PrismicArticle {
            ... PrismicArticleFragment
          }
        }
        slug
      }
    }
    primary {
      title {
        text
        richText
      }
      subtitle {
        text
        html
        richText
      }
    }
  }
`

export default FeaturedArticles;
