import * as React from 'react'

import * as Slices from '../slices'
import { camelCase, upperFirst } from 'lodash'

const SliceZone = ({ className, location, sliceZone, timezone }) => {
  const sliceZoneContent = sliceZone.map((slice, index) => {
    let slice_name = upperFirst(camelCase(slice.slice_type))
    
    const SliceComponent = Slices[slice_name]

    if (SliceComponent) {
      return (
        <SliceComponent
          key={`slice-${index}`}
          location={location}
          slice={slice}
          timezone={timezone}
        />
      )
    }

    return null
  })

  return <main className={className ?? null}>{sliceZoneContent}</main>
}

export default SliceZone
