import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import { linkResolver } from '../utils/LinkResolver.js'

const TextHero = ({ slice }) => {
  const { hero_title, hero_text } = slice.primary

  return (
    <section className={`container-medium mt-20 sm:mt-32`}>
      <h2 className="text-2xl md:text-4xl">{RichText.asText(hero_title.richText)}</h2>
      <div className="c-divider c-divider--black mt-3" />
      <div className="prose mt-5 md:prose-xl max-w-none font-display font-light leading-relaxed">
        <RichText
          render={hero_text.richText}
          linkResolver={linkResolver}
        />
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyTextHero on PrismicPageDataBodyTextHero {
    primary {
      hero_text {
        richText
      }
      hero_title {
        richText
      }
    }
    items {
      hero_link {
        url
        uid
        type
        target
        link_type
      }
      hero_link_label
    }
  }

  fragment LandingPageDataBodyTextHero on PrismicLandingPageDataBodyTextHero {
    primary {
      hero_text {
        richText
      }
      hero_title {
        richText
      }
    }
    items {
      hero_link {
        url
        uid
        type
        target
        link_type
      }
      hero_link_label
    }
  }

  fragment MembershipDataBodyTextHero on PrismicMembershipDataBodyTextHero {
    primary {
      hero_text {
        richText
      }
      hero_title {
        richText
      }
    }
    items {
      hero_link {
        url
        uid
        type
        target
        link_type
      }
      hero_link_label
    }
  }
`

export default TextHero
