import * as React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion';

import { linkResolver } from '../utils/LinkResolver.js';

import MinusIcon from '/src/images/icons/minus.svg';
import PlusIcon from '/src/images/icons/plus.svg';

const ToggleList = ({ slice }) => {
  const { anchor_id, title } = slice.primary;
  const { items } = slice;

  if (!Array.isArray(items) || !items.length) return null;

  return (
    <section
      className="container-medium my-[70px] md:my-[140px] scroll-mt-[80px]"
      id={anchor_id ?? null}
    >
      <h2 className="font-body font-semibold text-center text-[#527563]">
        {RichText.asText(title.richText)}
      </h2>

      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        className="flex flex-col gap-[20px] mt-[60px]"
      >
        {items.map((item, index) => (
          <AccordionItem
            key={`toggle-list-${index}`}
            expanded="true"
          >
            <AccordionItemHeading>
              <AccordionItemButton className="flex flex-row items-center justify-between accordion__button rounded-[8px] bg-white border shadow-sm border-[#F2F2F5] py-[18px] px-[27px]">
                <h4 className="font-body font-normal text-[20px] leading-[36px] text-[#161633]">
                  {RichText.asText(item.toggle_title.richText)}
                </h4>
                <AccordionItemState>
                  {(state) => (
                    <div className="flex h-[25px] w-[25px] items-center justify-center">
                      {state.expanded ? <MinusIcon className="h-[16px] w-[16px] outline-0" /> : <PlusIcon className="outline-0" />}
                    </div>
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="rounded-[8px] bg-slate-100 mt-[12px] py-[18px] px-[27px]">
              <div className="prose">
                <RichText
                  render={item.toggle_text.richText}
                  linkResolver={linkResolver}
                />
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </section>
  );
};

export const query = graphql`
  fragment HomepageDataBodyToggleList on PrismicHomepageDataBodyToggleList {
    primary {
      title {
        richText
      }
    }
    items {
      toggle_text {
        richText
      }
      toggle_title {
        richText
      }
    }
  }

  fragment DictionaryEntryDataBodyToggleList on PrismicDictionaryEntryDataBodyToggleList {
    primary {
      title {
        richText
      }
    }
    items {
      toggle_text {
        richText
      }
      toggle_title {
        richText
      }
    }
  }

  fragment PageDataBodyToggleList on PrismicPageDataBodyToggleList {
    primary {
      title {
        richText
      }
    }
    items {
      toggle_text {
        richText
      }
      toggle_title {
        richText
      }
    }
  }

  fragment SteeringGroupDataBodyToggleList on PrismicSteeringGroupDataBodyToggleList {
    primary {
      title {
        richText
      }
    }
    items {
      toggle_text {
        richText
      }
      toggle_title {
        richText
      }
    }
  }

  fragment LandingPageDataBodyToggleList on PrismicLandingPageDataBodyToggleList {
    primary {
      title {
        richText
      }
    }
    items {
      toggle_text {
        richText
      }
      toggle_title {
        richText
      }
    }
  }

  fragment MembershipDataBodyToggleList on PrismicMembershipDataBodyToggleList {
    primary {
      title {
        richText
      }
    }
    items {
      toggle_text {
        richText
      }
      toggle_title {
        richText
      }
    }
  }

  fragment ResourceDataBodyToggleList on PrismicResourceDataBodyToggleList {
    primary {
      title {
        richText
      }
    }
    items {
      toggle_text {
        richText
      }
      toggle_title {
        richText
      }
    }
  }

  fragment ConferenceDataBodyToggleList on PrismicDigitalSustainabilityConferenceDataBodyToggleList {
    primary {
      title {
        richText
      }
    }
    items {
      toggle_text {
        richText
      }
      toggle_title {
        richText
      }
    }
  }
`

export default ToggleList;
