import * as React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link, navigate } from 'gatsby';
import { linkResolver } from '/src/utils/LinkResolver.js';
import { RichText } from 'prismic-reactjs';

import ArrowSquareOut from '/src/images/icons/arrow-square-out.svg';
import FileArrowDown from '/src/images/icons/file-arrow-down.svg';

const PresentationSlideCard = ({
  authors,
  image,
  numberOfSlides,
  slidesLink,
  title
}) => {
  const imageGatsby = getImage(image);

  const handleDownloadButtonClick = (event) => {
    event.preventDefault();

    if (slidesLink?.url) window.open(slidesLink.url, '_blank');
  }

  const cardElement = (
    <div className="flex h-full flex-col border border-[#DADAE7] rounded-lg bg-white p-[20px]">
      {imageGatsby ?
        (
          <GatsbyImage
            alt={imageGatsby.alt || title}
            className="rounded-lg h-[200px] w-full"
            image={imageGatsby}
            objectFit="cover"
          />
        ) :
        (
          <div className="flex items-center justify-center rounded-md bg-[#F2F2F7] h-[200px] w-full">
            <FileArrowDown className="h-[80px] w-[80px] text-[#3E6552]" />
          </div>
        )
      }
      <div className="flex flex-col grow">
        {!!title && (
          <h5 className="text-[18px] leading-[22px] md:text-[18px] md:leading-[24px] font-semibold font-body line-clamp-2 mt-[20px]">
            {title}
          </h5>
        )}
        {!!slidesLink?.url && (
          <div
            className="text-[14px] leading-[18px] text-[#E79523] font-semibold mt-[8px] hover:text-[#DC851B]"
            onClick={handleDownloadButtonClick}
          >
            <div className="flex items-center gap-[8px]">
              <ArrowSquareOut className="h-[20px] w-[20px] text-[#E79523]" />
              <span>
                Download
                {' '}
                {!!numberOfSlides ?
                  (
                    <span>
                      {numberOfSlides}
                      {' '}
                      {numberOfSlides === 1 ? 'Slide' : 'Slides'}
                    </span>
                  ) :
                  (
                    <span>
                      Slides
                    </span>
                  )}
              </span>
            </div>
          </div>
        )}
        {Array.isArray(authors) && !!authors.length && (
          <div className="mt-auto pt-[32px]">
            {authors.map((author, index) => Array.isArray(author.name?.richText) && author.name.richText[0]?.text?.length && (
              <div
                key={index}
                className="flex items-center mt-[8px] self-end"
              >
                {!!author.photo && (
                  <div className="rounded-full h-[32px] w-[32px] mr-[8px]">
                    <GatsbyImage
                      alt={author.photo.alt || ''}
                      className="rounded-full h-full w-full"
                      image={author.photo}
                      objectFit="cover"
                      objectPosition="top"
                    />
                  </div>
                )}
                {((Array.isArray(author.name?.richText) && author.name.richText[0]?.text?.length > 0) ||
                (Array.isArray(author.company?.richText) && author.company.richText[0]?.text?.length > 0)) && (
                  <>
                    {Array.isArray(author.name?.richText) && author.name.richText[0]?.text?.length > 0 && (
                      <span className='text-[14px] leading-[20px] text-[#101019]'>
                        {author.name.richText[0]?.text}
                        {Array.isArray(author.company?.richText) && author.company.richText[0]?.text?.length > 0 && (
                          <span>
                            ,
                            {' '}
                            <span className="text-[#717184]">
                              {author.company.richText[0].text}
                            </span>
                          </span>
                        )}
                      </span>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="h-full">
      {slidesLink?.url ?
        (
          <a
            href={slidesLink.url}
            target="_blank"
          >
            {cardElement}
          </a>
        ) :
        cardElement}
    </div>
  )
};

PresentationSlideCard.propTypes = {
  authors: PropTypes.arrayOf(PropTypes.shape({
    company: PropTypes.shape({}),
    name: PropTypes.shape({}),
    photo: PropTypes.shape({
      alt: PropTypes.string
    })
  })),
  image: PropTypes.shape({
    alt: PropTypes.string
  }),
  numberOfSlides: PropTypes.number,
  slidesLink: PropTypes.shape({}),
  title: PropTypes.string
};

PresentationSlideCard.defaultProps = {
  authors: null,
  image: null,
  numberOfSlides: null,
  slidesLink: null,
  title: null
};

export default PresentationSlideCard;
