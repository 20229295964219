import { useEffect } from 'react';
import getEventbriteEventId from '../utils/GetEventbriteEventId';

const windowIsDefined = typeof window !== 'undefined';

const useEventbriteWidget = (buttonId, eventUrl) => {
  const isUrlValid = typeof eventUrl === 'string' && eventUrl.includes('eventbrite');

  useEffect(() => {
    if (isUrlValid) {
      const eventId = getEventbriteEventId(eventUrl);

      const eventbriteWidget = () => {
        var options = {
          widgetType: 'checkout',
          eventId,
          modal: true,
          modalTriggerElementId: buttonId
        }

        if (windowIsDefined) window.EBWidgets.createWidget(options);
      }

      const eventbriteSrc = 'https://www.eventbrite.de/static/widgets/eb_widgets.js';

      const script = document.createElement('script');

      script.src = eventbriteSrc;
      script.onload = eventbriteWidget;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      }
    }
   }, [isUrlValid]);
};

export default useEventbriteWidget;
