import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import { linkResolver } from '../utils/LinkResolver'
import TeamMemberPhoto from '../components/TeamMemberPhoto'
import LinkButton from '../components/LinkButton'

const AboutSummaryBlock = ({ slice }) => {
  const {
    items,
    primary,
  } = slice

  return (
    <section className={`mx-auto mt-12 px-4 sm:px-8 max-w-3xl`}>
      {!!primary.headline && (
        <h2 class="text-center">
          {RichText.asText(primary.headline.richText)}
        </h2>
      )}

      {!!primary.description && (
        <div class="prose-lg text-center text-gray-600 mt-4">
          <RichText
            linkResolver={linkResolver}
            render={primary.description.richText}
          />
        </div>
      )}

      <div class="flex flex-col sm:flex-row mt-12">
        {!!primary.team_member?.document?.data && (
          <div class="sm:w-28 md:w-40 order-2 sm:order-none mt-12 sm:mt-0">
            <TeamMemberPhoto
              {...primary.team_member.document.data}
              className="w-28 md:w-40"
            />
          </div>
        )}

        {!!primary.about && (
          <div class="sm:flex-1 sm:ml-12 prose leading-relaxed">
            <RichText
              linkResolver={linkResolver}
              render={primary.about.richText}
            />
          </div>
        )}
      </div>

      {Array.isArray(items) && items.length > 0 && (
        <div class="flex mt-8">
          {items.map((item) => (
            <LinkButton
              className="inline-block mr-4"
              label={item.call_to_action_label}
              link={item.call_to_action}
            />
          ))}
        </div>
      )}
    </section>
  )
}

export const query = graphql`
  fragment LandingPageDataBodyAboutSummaryBlock on PrismicLandingPageDataBodyAboutSummaryBlock {
    id
    items {
      call_to_action {
        url
        type
        target
        link_type
      }
      call_to_action_label
    }
    primary {
      about {
        richText
      }
      description {
        richText
      }
      headline {
        richText
      }
      team_member {
        document {
          ... on PrismicTeamMember {
            id
            data {
              full_name {
                richText
              }
              linkedin_profile {
                url
                type
                target
                link_type
              }
              photo {
                gatsbyImageData(width: 180)
              }
              title {
                richText
              }
            }
          }
        }
      }
    }
  }

  fragment ResourceDataBodyAboutSummaryBlock on PrismicResourceDataBodyAboutSummaryBlock {
    id
    items {
      call_to_action {
        url
        type
        target
        link_type
      }
      call_to_action_label
    }
    primary {
      about {
        richText
      }
      description {
        richText
      }
      headline {
        richText
      }
      team_member {
        document {
          ... on PrismicTeamMember {
            id
            data {
              full_name {
                richText
              }
              linkedin_profile {
                url
                type
                target
                link_type
              }
              photo {
                gatsbyImageData(width: 180)
              }
              title {
                richText
              }
            }
          }
        }
      }
    }
  }
`

export default AboutSummaryBlock
