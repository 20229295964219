import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

import ListActivities from '../components/roadmap/ListActivities'

const AllRoadmapActivites = ({ slice }) => {
  const { primary } = slice

  // get all upcoming events
  const queryData = useStaticQuery(graphql`
    {
      allPrismicRoadmapMetric(sort: {data: {order_priority: ASC}}) {
        edges {
          node {
            ...PrismicRoadmapMetricFragment
          }
        }
      }
    }
  `)

  const { data } = useMergePrismicPreviewData(queryData)
  const doc = data.allPrismicRoadmapMetric
  const allMetrics = doc.edges.map(edge => (
    { ...edge.node, uid: edge.node.uid, url: edge.node.url } )
  )
  
  return (
    <section className={`mt-12 sm:mt-18 lg:pb-8 container-medium`}>
        <ListActivities {...primary} metrics={allMetrics} />
    </section>
  )
}



export const query = graphql`
  fragment PageDataBodyAllRoadmapActivities on PrismicPageDataBodyAllRoadmapActivities {
    primary {
      title {
        richText
      }
    }
  }
`

export default AllRoadmapActivites
