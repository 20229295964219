import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import TeamMemberPhoto from '../components/TeamMemberPhoto.js'

const ContactTeamMembers = ({ slice }) => (
  <section className="mt-12 sm:mt-20">
    <div className="container-medium text-center">
      <h3>
        {RichText.asText(slice.primary.title.richText)}
      </h3>

      <div className="grid grid-cols-3 sm:grid-cols-4 gap-4 my-8">
        {slice.items.map((item, index) => item?.team_member?.document?.data ?
          (
            <TeamMemberPhoto 
              useEmailInsteadOfLinkedIn
              {...item.team_member.document.data} 
              key={`contact-team-photo-${index}`} 
            />
          ) :
          null
        )}
      </div>
    </div>
  </section>
)

export const query = graphql`
  fragment PageDataBodyContactTeamMembers on PrismicPageDataBodyContactTeamMembers {
    items {
      team_member {
        document {
          ... on PrismicTeamMember {
            id
            _previewable
            data {
              full_name {
                richText
              }
              public_email
              photo {
                gatsbyImageData(width: 180, layout: FULL_WIDTH)
              }
              title {
                richText
              }
            }
          }
        }
      }
    }
    primary {
      title {
        richText
      }
    }
  }

  fragment DictionaryEntryDataBodyContactTeamMembers on PrismicDictionaryEntryDataBodyContactTeamMembers {
    items {
      team_member {
        document {
          ... on PrismicTeamMember {
            id
            _previewable
            data {
              full_name {
                richText
              }
              public_email
              photo {
                gatsbyImageData(width: 180, layout: FULL_WIDTH)
              }
              title {
                richText
              }
            }
          }
        }
      }
    }
    primary {
      title {
        richText
      }
    }
  }

  fragment ResourceDataBodyContactTeamMembers on PrismicResourceDataBodyContactTeamMembers {
    items {
      team_member {
        document {
          ... on PrismicTeamMember {
            id
            _previewable
            data {
              full_name {
                richText
              }
              public_email
              photo {
                gatsbyImageData(width: 180, layout: FULL_WIDTH)
              }
              title {
                richText
              }
            }
          }
        }
      }
    }
    primary {
      title {
        richText
      }
    }
  }

  fragment LandingPageDataBodyContactTeamMembers on PrismicLandingPageDataBodyContactTeamMembers {
    items {
      team_member {
        document {
          ... on PrismicTeamMember {
            id
            _previewable
            data {
              full_name {
                richText
              }
              public_email
              photo {
                gatsbyImageData(width: 180, layout: FULL_WIDTH)
              }
              title {
                richText
              }
            }
          }
        }
      }
    }
    primary {
      title {
        richText
      }
    }
  }

  fragment MembershipDataBodyContactTeamMembers on PrismicMembershipDataBodyContactTeamMembers {
    items {
      team_member {
        document {
          ... on PrismicTeamMember {
            id
            _previewable
            data {
              full_name {
                richText
              }
              public_email
              photo {
                gatsbyImageData(width: 180, layout: FULL_WIDTH)
              }
              title {
                richText
              }
            }
          }
        }
      }
    }
    primary {
      title {
        richText
      }
    }
  }
`

export default ContactTeamMembers
