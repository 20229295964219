import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import CurrencyFormat from 'react-currency-format'

import LinkButton from '../components/LinkButton.js'

const FeaturedMemberships = ({ slice }) => {
  const maxCols = slice.items.length <= 4 ? slice.items.length : 4
  
  return (
    <section className={`container mt-12 sm:mt-16 grid grid-cols-1 sm:grid-cols-2 gap-3 lg:grid-cols-${maxCols}`}>
      {slice.items.map((item, index) => {
        const { 
          color, call_to_action, 
          call_to_action_label, features, 
          name, tagline, price, currency
        } = item.featured_membership.document.data

        return (
          <div 
            className="rounded bg-gray-900 py-4 px-6 text-white sm:flex sm:flex-wrap sm:flex-col sm:justify-center sm:content-start" 
            key={`feat-membership-${index}`}
          >
            <h4 
              className="text-xl lg:text-2xl leading-tight lg:leading-tight border-b-2 pb-2 w-full" 
              style={{'borderColor': color}}
            >
              {RichText.asText(name.richText)}
              {price !== null ? 
                <span className='text-sm block'>
                  
                  {parseInt(price) === 0 ? 
                    'Free' : 
                    <>
                      Starting at&nbsp;
                      <CurrencyFormat 
                        value={price} 
                        displayType={'text'} 
                        thousandSeparator={'.'} 
                        decimalSeparator={','}
                        prefix={currency === 'EUR' ? '€' : '$'} 
                      /> per month
                    </>
                  }
                </span>
              : null}
              
            </h4>
            <p className="text-base mt-3 font-bold">{RichText.asText(tagline.richText)}</p>

            <ul className="list-disc ml-4 mb-5">
              {features.map((feature, i) => (
                <li key={`feat-member-${index}-feat-${i}`} className="text-gray-600 mt-3">
                  {RichText.asText(feature.text.richText)}
                </li>
              ))}
            </ul>

            <LinkButton link={call_to_action} label={call_to_action_label} isWarning className="sm:mt-auto" />
          </div>
        )
      })}
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyFeaturedMemberships on PrismicPageDataBodyFeaturedMemberships {
    items {
      featured_membership {
        document {
          ...PrismicMembershipFragment
        }
      }
    }
  }

  fragment LandingPageDataBodyFeaturedMemberships on PrismicLandingPageDataBodyFeaturedMemberships {
    items {
      featured_membership {
        document {
          ...PrismicMembershipFragment
        }
      }
    }
  }
`

export default FeaturedMemberships
