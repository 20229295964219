import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

// Utils
import GetHorizontalMasonryRows from '../utils/GetHorizontalMasonryRows.js';

// Icons
import ChevronLeft from '/src/images/icons/chevron-left.svg';
import ChevronRight from '/src/images/icons/chevron-right.svg';

// Constants
const SCROLL_AMOUNT = 500;

const HorizontalMasonry = ({ items }) => {
  const horizontalMasonryLayoutGrid = useRef(null);

  const [initialMousePosition, setInitialMousePosition] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [gridScrollLeft, setGridScrollLeft] = useState(0);

  if (!Array.isArray(items) || !items.length) return null;

  const gridItems = items.length <= 10 ? [items] : GetHorizontalMasonryRows(items);

  // Methods
  const handleGridMouseDown = (event) => {
    if (horizontalMasonryLayoutGrid.current) {
      setIsDragging(true);

      // Set cursor to grabbing
      horizontalMasonryLayoutGrid.current.style.cursor = 'grabbing';

      // Set the initial mouse position
      setInitialMousePosition(event.pageX);

      // Set the initial scroll left position
      setGridScrollLeft(horizontalMasonryLayoutGrid.current.scrollLeft);
    }
  }

  const handleGridMouseEnter = () => {
    if (horizontalMasonryLayoutGrid.current) {
      // Set cursor to grab
      horizontalMasonryLayoutGrid.current.style.cursor = 'grab';
    }
  }

  const handleGridMouseLeave = () => {
    if (horizontalMasonryLayoutGrid.current) {
      setIsDragging(false);

      // Set cursor to default
      horizontalMasonryLayoutGrid.current.style.cursor = 'default';
    }
  }

  const handleGridMouseUp = (event) => {
    if (horizontalMasonryLayoutGrid.current) {
      setIsDragging(false);

      // Set cursor to grab
      horizontalMasonryLayoutGrid.current.style.cursor = 'grab';
    }
  }

  const handleGridMouseMove = (event) => {
    event.preventDefault();

    if (isDragging && horizontalMasonryLayoutGrid.current) {
      // Scroll the grid left or right based on the difference between the initial mouse position and the current mouse position
      horizontalMasonryLayoutGrid.current.scrollTo({
        // The scroll amount is multiplied by 3 to make the scrolling feel more responsive
        left: gridScrollLeft - ((event.pageX - initialMousePosition) * 3)
      });
    }
  }

  const handlePreviousButtonClick = () => {
    if (horizontalMasonryLayoutGrid.current) {
      // Scroll smoothly to the left
      horizontalMasonryLayoutGrid.current.scrollTo({
        behavior: 'smooth',
        left: horizontalMasonryLayoutGrid.current.scrollLeft - SCROLL_AMOUNT
      });
    }
  }

  const handleNextButtonClick = () => {
    if (horizontalMasonryLayoutGrid.current) {
      // Scroll smoothly to the right
      horizontalMasonryLayoutGrid.current.scrollTo({
        behavior: 'smooth',
        left: horizontalMasonryLayoutGrid.current.scrollLeft + SCROLL_AMOUNT
      });
    }
  }

  return (
    <div className="horizontal-masonry-layout">
      <div
        ref={horizontalMasonryLayoutGrid}
        className="horizontal-masonry-layout__grid"
        onMouseDown={handleGridMouseDown}
        onMouseEnter={handleGridMouseEnter}
        onMouseLeave={handleGridMouseLeave}
        onMouseMove={handleGridMouseMove}
        onMouseUp={handleGridMouseUp}
      >
        {gridItems.map((row, rowIndex) => (
          <div
            key={rowIndex}
            className="horizontal-masonry-layout__grid-row"
          >
            {row.map((item, itemIndex) => (
              <img
                key={itemIndex}
                className="horizontal-masonry-layout__grid-row-item"
                draggable={false}
                src={item.imageSrc}
              />
            ))}
          </div>
        ))}
      </div>
      <div
        className='horizontal-masonry-layout__navigation-button horizontal-masonry-layout__navigation-button-previous'
        onClick={handlePreviousButtonClick}
      >
        <ChevronLeft className="horizontal-masonry-layout__navigation-button-icon" />
      </div>
      <div
        className='horizontal-masonry-layout__navigation-button horizontal-masonry-layout__navigation-button-next'
        onClick={handleNextButtonClick}
      >
        <ChevronRight className="horizontal-masonry-layout__navigation-button-icon" />
      </div>
    </div>
  );
}

HorizontalMasonry.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    imageHeight: PropTypes.number,
    imageSrc: PropTypes.string,
    imageWidth: PropTypes.number
  }))
}

HorizontalMasonry.defaultProps = {
  items: null
}

export default HorizontalMasonry;
