import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { linkResolver } from '../utils/LinkResolver'

const HeroWithTeamMember = ({ slice }) => {
  const { hero_title, hero_text, team_member } = slice.primary
  const { photo, title, full_name } = team_member?.document?.data || {}
  const image = getImage(photo)

  return (
    <section className={`container mt-20 sm:mt-32 mb-24 flex flex-1 flex-col content-center sm:flex-row sm:justify-items-stretch sm:items-start`}>
      <div className="sm:w-10/12 w-full sm:pr-12">
        <h2 className="">{RichText.asText(hero_title.richText)}</h2>
        <div className="c-divider c-divider--black mt-3" />
        <div className="prose mt-5 prose-xl max-w-none font-display font-light leading-relaxed">
          <RichText
            render={hero_text.richText}
            linkResolver={linkResolver}
          />
        </div>
      </div>
      <div className="mt-6 sm:mt-0 sm:w-2/12 w-full sm:flex-shrink-0 text-center">
        <GatsbyImage alt={RichText.asText(full_name.richText)} image={image} className="rounded shadow" />
        <h5 className="mt-5">{RichText.asText(full_name.richText)}</h5>
        <h6 className="text-gray-500 font-normal mt-1">{RichText.asText(title.richText)}</h6>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyHeroWithTeamMember on PrismicPageDataBodyHeroWithTeamMember {
    primary {
      hero_text {
        richText
      }
      hero_title {
        richText
      }
      team_member {
        document {
          ... on PrismicTeamMember {
            id
            _previewable
            data {
              full_name {
                richText
              }
              photo {
                gatsbyImageData(width: 170)
              }
              title {
                richText
              }
            }
          }
        }
      }
    }
    items {
      hero_link {
        url
        uid
        type
        target
        link_type
      }
      hero_link_label
    }
  }

  fragment LandingPageDataBodyHeroWithTeamMember on PrismicLandingPageDataBodyHeroWithTeamMember {
    primary {
      hero_text {
        richText
      }
      hero_title {
        richText
      }
      team_member {
        document {
          ... on PrismicTeamMember {
            id
            _previewable
            data {
              full_name {
                richText
              }
              photo {
                gatsbyImageData(width: 170)
              }
              title {
                richText
              }
            }
          }
        }
      }
    }
    items {
      hero_link {
        url
        uid
        type
        target
        link_type
      }
      hero_link_label
    }
  }
`

export default HeroWithTeamMember
