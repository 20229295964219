import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { compact, sortBy } from 'lodash'

import FeaturedEvent from '../components/FeaturedEvent'
import { enrichEventsDates } from '../utils/EnrichEventDates'

const FeaturedEvents = ({ slice }) => {
  const { primary, items } = slice
  const { headline } = primary
  const events = enrichEventsDates(
    compact(
      items.map(item => { 
        if (item && item.featured_event && item.featured_event.document && item.featured_event.document.data)
          return { ...item.featured_event.document.data, uid: item.featured_event.document.uid, url: item.featured_event.document.url } 
        return null
      })
    )
  )
  const sortFunc = event => event.start_date
  const sortedEvents = sortBy(events, sortFunc)

  return (
    <section className="container-medium mt-12 sm:mt-16">
      <h3 className="text-center">
        {RichText.asText(headline.richText)}
      </h3>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mt-6">
        {sortedEvents.map((event, index) => (
          <FeaturedEvent key={`feat-event-${index}`} {...event} />
        ))}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyFeaturedEvents on PrismicHomepageDataBodyFeaturedEvents {
    items {
      featured_event {
        document {
          ...PrismicEventFragment
        }
      }
    }
    primary {
      headline {
        richText
      }
    }
  }

  fragment RoadmapChallengeDataBodyFeaturedEvents on PrismicRoadmapChallengeDataBodyFeaturedEvents {
    items {
      featured_event {
        document {
          ...PrismicEventFragment
        }
      }
    }
    primary {
      headline {
        richText
      }
    }
  }

  fragment DictionaryEntryDataBodyFeaturedEvents on PrismicDictionaryEntryDataBodyFeaturedEvents {
    items {
      featured_event {
        document {
          ...PrismicEventFragment
        }
      }
    }
    primary {
      headline {
        richText
      }
    }
  }

  fragment PageDataBodyFeaturedEvents on PrismicPageDataBodyFeaturedEvents {
    items {
      featured_event {
        document {
          ...PrismicEventFragment
        }
      }
    }
    primary {
      headline {
        richText
      }
    }
  }

  fragment LandingPageDataBodyFeaturedEvents on PrismicLandingPageDataBodyFeaturedEvents {
    items {
      featured_event {
        document {
          ...PrismicEventFragment
        }
      }
    }
    primary {
      headline {
        richText
      }
    }
  }

  fragment SteeringGroupDataBodyFeaturedEvents on PrismicSteeringGroupDataBodyFeaturedEvents {
    items {
      featured_event {
        document {
          ...PrismicEventFragment
        }
      }
    }
    primary {
      headline {
        richText
      }
    }
  }

  fragment ResourceDataBodyFeaturedEvents on PrismicResourceDataBodyFeaturedEvents {
    items {
      featured_event {
        document {
          ...PrismicEventFragment
        }
      }
    }
    primary {
      headline {
        richText
      }
    }
  }

  fragment EventDataBody1FeaturedEvents on PrismicEventDataBody1FeaturedEvents {
    primary {
      headline {
        richText
      }
    }
    items {
      featured_event {
        document {
          ... on PrismicEvent {
            id
            _previewable
            data {
              location {
                richText
              }
              end_date
              start_date
              title {
                richText
              }
            }
            url
          }
        }
      }
    }
  }
`

export default FeaturedEvents
