import * as React from 'react'

import Activity from './Activity'

const ListActivities = ({ metrics }) => {
  return (
    <section className={`mt-12 sm:mt-18 lg:pb-8 first-of-type:mt-0`}>
      {metrics.map((metric) => (
        <div key={`list-metric-${metric.id}`} className="mb-4">
          <h4 className="text-xl lg:text-2xl">
            <span className='text-gray-600'>Impact Category:&nbsp;</span> 
            {metric.data.name}
          </h4>
          <ul className="my-3">
            {metric.data.activities.map((item, index) => 
              <Activity 
                key={`list-activity-${index}`} 
                url={item.activity.document.url}
                {...item.activity.document.data} 
              />
            )}
          </ul>
        </div>
      ))}
    </section>
  )
}

export default ListActivities
