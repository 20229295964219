import * as React from 'react'
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/LinkResolver'
import { format, parseISO } from 'date-fns'
import TeamMemberPhoto from './TeamMemberPhoto'
import { remove } from 'lodash'

const EventSessions = ({
  sessions,
  className,
}) => {
  remove(sessions, s => {
    return !s.session_title || s.session_title.richText.length === 0
  })

  if(!Array.isArray(sessions) || sessions.length === 0) return null

  return (
    <div className={`${className}`}>
      <h4>
        Sessions
      </h4>

      {sessions.map((session, index) => {
        return (
          <div
            className="bg-gray-100 rounded p-5 mt-3 md:flex md:flex-row md:items-start"
            key={`event-sess-${index}`}
          >
            <div className="md:pr-10">
              {!!session.timestamp && (
                <p className="text-gray-500 text-sm">
                  {format(parseISO(session.timestamp), 'do MMMM yyyy HH:mm')}
                </p>
              )}

              <h5 className="">
                {RichText.asText(session.session_title.richText)}
              </h5>

              {!!session.hosted_by && (
                <p className="my-1 text-gray-700 italic">
                  Hosted by {RichText.asText(session.hosted_by.richText)}
                </p>
              )}

              <div className="prose leading-relaxed mt-2">
                <RichText
                  linkResolver={linkResolver}
                  render={session.session_description.richText}
                />
              </div>

              <Link
                to={session.session_link.url} 
                target={session.session_link.target} 
                className="text-secondary-900 border-b-2 border-current font-bold mt-3 inline-block hover:border-transparent hover:text-secondary-500"
              >
                {session.session_link_label}
              </Link>
            </div>

            <div className="w-44 mt-4 md:flex-shrink-0 md:w-36 lg:w-44">
              {!!session.team_member?.document?.data && (
                <TeamMemberPhoto
                  {...session.team_member.document.data}
                  className="mt-2"
                />
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default EventSessions
