import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import { linkResolver } from '/src/utils/LinkResolver.js';
import getCorrectTextColor from '/src/utils/SetTextColorDependingOnBackground';

import LinkButton from '/src/components/LinkButton.js';

const QuoteWithCallToAction = ({ slice }) => {
  if(!slice || !slice.primary) return null;

  const { primary } = slice;
  const backgroundColor = primary.background_color || '#ffffff';
  const backgroundColorFilling = primary.background_color_filling || '#f2f2f5';
  const textColor = getCorrectTextColor(backgroundColorFilling);

  return (
    <section
      className="mb-[60px] md:mb-[120px]"
      style={{ backgroundColor: backgroundColor }}
    >
      <div className="container">
        <div
          className="rounded-[12px] px-[32px] py-[60px] w-full"
          style={{ backgroundColor: backgroundColorFilling }}
        >
          {!!primary.title && (
            <h2
              className="text-center"
              style={{ color: textColor }}
            >
              {primary.title.text}
            </h2>
          )}
          
          {Array.isArray(primary.description?.richText) && primary.description.richText[0]?.text.length > 0 && (
            <div
              className="text-[18px] sm:text-[24px] leading-[24px] sm:leading-[36px] font-light font-display mt-3 text-center"
              style={{ color: textColor }}
            >
              <RichText
                linkResolver={linkResolver}
                render={primary.description.richText}
              />
            </div>
          )}
          <div
            className="c-divider mx-auto mt-4"
            style={{ backgroundColor: textColor }}
          />

          <blockquote
            className="font-display text-center text-[18px] sm:text-[24px] leading-[26px] sm:leading-[36px] italic mt-[20px] max-w-2xl mx-auto"
            style={{ color: textColor }}
            >
            {!!primary.quote && (
              <RichText
                render={primary.quote.richText}
                linkResolver={linkResolver}
              />
            )}

            {!!primary.attribution && (
              <cite
                className="not-italic text-center text-lg mt-4 text-gray-700 block"
                style={{ color: textColor }}
              >
                {RichText.asText(primary.attribution.richText)}
              </cite>
            )}
          </blockquote>

          {Array.isArray(primary.call_to_action?.richText) &&
            primary.call_to_action.richText[0]?.text?.length > 0 && (
              <div className="flex justify-center mt-[20px] sm:mt-[32px]">
                <LinkButton
                  className="text-[16px] sm:text-[18px] leading-[18px] font-semibold"
                  isWarning
                  label={primary.call_to_action}
                  link={primary.link}
                />
              </div>
            )}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment AnnualReportDataBodyQuoteWithCallToAction on PrismicAnnualReportDataBodyQuoteWithCallToAction {
    primary {
      background_color
      background_color_filling
      call_to_action {
        richText
      }
      description {
        richText
      }
      link {
        url
      }
      title {
        text
      }
      quote {
        richText
      }
    }
  }
`

export default QuoteWithCallToAction
