import * as React from 'react'
import { Link } from 'gatsby'

const FeaturedOrgMember = ({ logo, url }) => {
  const logo_element = (
    <img 
      alt={logo.alt} 
      src={logo.url} 
      className="o-media-logo__item object-fill inline" 
    />
  )

  return (
    <div>
      <Link 
        to={url} 
        className="o-media-logo p-3 flex justify-center text-center">
          {logo_element}
      </Link>  
    </div>
  )
}

export default FeaturedOrgMember
