import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import { sortBy } from 'lodash'

import ListPodcasts from '../components/ListPodcasts'
import { getImage } from 'gatsby-plugin-image'

const stateOrder = {
  'Live': 1,
  'Upcoming': 2,
  'Archived': 3,
}

const AllPodcasts = ({ slice }) => {
  const { primary } = slice

  // get all upcoming events
  const queryData = useStaticQuery(graphql`
    {
      allPrismicPodcast(sort: {first_publication_date: DESC}) {
        edges {
          node {
            ...PrismicPodcastFragment
          }
        }
      }
    }
  `)

  const { data } = useMergePrismicPreviewData(queryData)
  const doc = data.allPrismicPodcast
  const allPodcasts = sortBy(doc.edges.map(edge => (
    { 
      ...edge.node.data, 
      uid: edge.node.uid, 
      url: edge.node.url, 
      cover_image: getImage(edge.node.data.cover_image),
      sort_key: stateOrder[edge.node.data.state],
    } 
  )), 'sort_key')

  return (
    <ListPodcasts {...primary} allPodcasts={allPodcasts} />
  )
}

export const query = graphql`
  fragment PageDataBodyAllPodcasts on PrismicPageDataBodyAllPodcasts {
    primary {
      subtitle {
        richText
      }
      title {
        richText
      }
    }
  }
`

export default AllPodcasts
