import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import { linkResolver } from '/src/utils/LinkResolver.js';

const ArticleVideo = ({ slice }) => {
  const data = slice.primary;
  const videoElement = data.video_embed.html;

  if (!videoElement) return null;

  return (
    <section className="my-[40px] w-full">
      <div
        className="article-video h-200px lg:h-[500px] w-full aspect-video rounded-[8px] overflow-hidden"
        // Necessary for embed video code
        dangerouslySetInnerHTML={{ __html: videoElement }}
      />
      {Array.isArray(data.description?.richText) && data.description.richText[0]?.text?.length > 0 && (
        <div className="text-[18px] leading-[25px] text-[#777785] text-center italic mt-[12px]">
          <RichText
            linkResolver={linkResolver}
            render={data.description.richText}
          />
        </div>
      )}
    </section>
  );
};

export const query = graphql`
  fragment ArticleDataBodyArticleVideo on PrismicArticleDataBodyArticleVideo {
    primary {
      description {
        richText
      }
      video_embed {
        html
      }
    }
  }
`;

export default ArticleVideo;
