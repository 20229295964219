import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

// import { HubspotFormBuilder } from '../components/forms/HubspotFormBuilder'

// This slice is currently using a workaround, by embedding the HubSpot Form using HubSpot JS
// At the moment, HubSpot API does not allow to submit forms that have company properties embedded (beta)
// via it's Submit API. Once that changes, we can use the HubSpotFormBuilder again, to really built our
// own forms, and not rely on the HubSpot JS for loading the form.

// const HubspotForm = ({ slice }) => {
//   console.log(slice)
//   return (
//     <section className="container-small mt-12 sm:mt-20 p-8 shadow-2 bg-white">
//       {slice.primary && slice.primary.hubspot_form_id ? 
//         <HubspotFormBuilder id={slice.primary.hubspot_form_id} themeDark /> 
//         : null
//       }
//     </section>
//   )
// }

import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form'

const HubspotForm = ({ slice }) => {
  const { primary } = slice
  const { hubspot_form_id, form_title } = primary
  const divId = `hubspot-form-${hubspot_form_id}`

  const { loaded, error, formCreated } = useHubspotForm({
    portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
    formId: hubspot_form_id,
    target: `#${divId}`
  })

  return (
    <section className="container-small mt-12 sm:mt-20 p-8 shadow-2 bg-white" id="form">
      {form_title ? <h4 className="text-center mb-3">{RichText.asText(form_title.richText)}</h4> : null}
      
      <div id={divId}>
        <span>
          {loaded ? null : 'Loading Form...'}
          {error ? 'Error loading form' : null}
        </span>
      </div>
    </section>
  )
}


export const query = graphql`
  fragment PageDataBodyHubspotForm on PrismicPageDataBodyHubspotForm {
    id
    primary {
      form_title {
        richText
      }
      hubspot_form_id
    }
  }

  fragment RoadmapChallengeDataBodyHubspotForm on PrismicRoadmapChallengeDataBodyHubspotForm {
    id
    primary {
      form_title {
        richText
      }
      hubspot_form_id
    }
  }

  fragment DictionaryEntryDataBodyHubspotForm on PrismicDictionaryEntryDataBodyHubspotForm {
    id
    primary {
      form_title {
        richText
      }
      hubspot_form_id
    }
  }

  fragment EventDataBody1HubSpotForm on PrismicEventDataBody1HubspotForm {
    id
    primary {
      form_title {
        richText
      }
      hubspot_form_id
    }
  }

  fragment SteeringGroupDataBodyHubspotForm on PrismicSteeringGroupDataBodyHubspotForm {
    id
    primary {
      form_title {
        richText
      }
      hubspot_form_id
    }
  }

  fragment LandingPageDataBodyHubspotForm on PrismicLandingPageDataBodyHubspotForm {
    id
    primary {
      form_title {
        richText
      }
      hubspot_form_id
    }
  }

  fragment MembershipDataBodyHubspotForm on PrismicMembershipDataBodyHubspotForm {
    id
    primary {
      form_title {
        richText
      }
      hubspot_form_id
    }
  }

  fragment ResourceDataBodyHubspotForm on PrismicResourceDataBodyHubspotForm {
    id
    primary {
      form_title {
        richText
      }
      hubspot_form_id
    }
  }
`

export default HubspotForm
