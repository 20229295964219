import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { linkResolver } from '../utils/LinkResolver.js'
import LinkButton from '../components/LinkButton.js'

class GoalsSlideshow extends React.Component {
  state = {
    active: 0,
  }

  constructor(props) {
    super(props)
    this.handleNextClick = this.handleNextClick.bind(this)
    this.handlePrevClick = this.handlePrevClick.bind(this)
  }

  handleNextClick(max) {
    this.setState(state => ({
      active: (state.active + 1 <= max) ? state.active + 1 : state.active
    }))
  }

  handlePrevClick() {
    this.setState(state => ({
      active: (state.active - 1 >= 0) ? state.active - 1 : state.active
    }))
  }

  render() {
    const { slice } = this.props
    const { active } = this.state
    const goal = slice.items[active]
    const background = getImage(goal.background_image)
    const max = slice.items.length - 1

    return (
      <section className={`mt-16 sm:mt-32 container`}>
        <h2 className="text-center text-3xl sm:text-4xl">
          {RichText.asText(slice.primary.title.richText)}
        </h2>

        <div className="mt-6 lg:mt-12">
          <div className="c-gallery grid" key={`goal-slider-${active}`}>
            <GatsbyImage
              style={{
                gridArea: "1/1",
                maxHeight: 560,
              }}
              layout="fullWidth"
              alt=""
              image={background}
            />

            <div className="grid c-gallery__inner p-6 px-12 sm:p-12 md:px-16 lg:px-24">
              <h3 className="text-white text-xl leading-snug sm:text-3xl">
                {RichText.asText(goal.headline.richText)}
              </h3>
              <div className="c-divider c-divider--white mt-2" />
              <div className="mt-6 text-white prose">
                <RichText
                  render={goal.description.richText}
                  linkResolver={linkResolver}
                />
              </div>

              <div className="mt-4">
                {[...Array(3)].map((e, i) => {
                  const sdg_goal = goal[`sdg_icon_${i + 1}`]
                  if(!sdg_goal || !sdg_goal.document) return null

                  const sdg_icon = sdg_goal.document.data
                  const icon = getImage(sdg_icon.icon)
                  if(!sdg_icon || !icon) return null

                  return (
                    <GatsbyImage
                      key={`goal-gallery-sdg-${i}`}
                      alt={sdg_icon.icon.alt}
                      image={icon}
                      className="rounded shadow-lg mr-1 md:mr-4 sm:w-auto w-12"
                    />
                  )
                })}
              </div>

              <div className="mt-3">
                <LinkButton link={goal.link} label={goal.call_to_action} isWarning />
              </div>
            </div>

            {active === 0 ? null : (
              <div className="c-gallery__chevron c-gallery__chevron--prev"
                onClick={this.handlePrevClick}
                onKeyDown={this.handlePrevClick}
                role="button"
                aria-label="Previous Goal"
                tabIndex="0"
              />
            )}
            {active === max ? null : (
              <div className="c-gallery__chevron c-gallery__chevron--next"
                onClick={() => this.handleNextClick(max)}
                onKeyDown={() => this.handleNextClick(max)}
                role="button"
                aria-label="Next Goal"
                tabIndex="0"
               />
            )}
          </div>
        </div>
      </section>
    )
  }
}

export const query = graphql`
  fragment HomepageDataBodyGoalsSlideshow on PrismicHomepageDataBodyGoalsSlideshow {
    primary {
      title {
        richText
      }
    }
    items {
      background_image {
        gatsbyImageData(width: 1130)
      }
      call_to_action {
        richText
      }
      description {
        richText
      }
      headline {
        richText
      }
      link {
        link_type
        url
        target
      }
      sdg_icon_1 {
        document {
          ... on PrismicSdGoal {
            data {
              icon {
                alt
                gatsbyImageData(width: 100)
              }
            }
          }
        }
      }
      sdg_icon_2 {
        document {
          ... on PrismicSdGoal {
            data {
              icon {
                alt
                gatsbyImageData(width: 100)
              }
            }
          }
        }
      }
      sdg_icon_3 {
        document {
          ... on PrismicSdGoal {
            data {
              icon {
                alt
                gatsbyImageData(width: 100)
              }
            }
          }
        }
      }
      sdg_icon_4 {
        document {
          ... on PrismicSdGoal {
            data {
              icon {
                alt
                gatsbyImageData(width: 100)
              }
            }
          }
        }
      }
    }
  }

  fragment PageDataBodyGoalsSlideshow on PrismicPageDataBodyGoalsSlideshow {
    primary {
      title {
        richText
      }
    }
    items {
      background_image {
        gatsbyImageData(width: 1130)
      }
      call_to_action {
        richText
      }
      description {
        richText
      }
      headline {
        richText
      }
      link {
        link_type
        url
        target
      }
      sdg_icon_1 {
        document {
          ... on PrismicSdGoal {
            data {
              icon {
                alt
                gatsbyImageData(width: 100)
              }
            }
          }
        }
      }
      sdg_icon_2 {
        document {
          ... on PrismicSdGoal {
            data {
              icon {
                alt
                gatsbyImageData(width: 100)
              }
            }
          }
        }
      }
      sdg_icon_3 {
        document {
          ... on PrismicSdGoal {
            data {
              icon {
                alt
                gatsbyImageData(width: 100)
              }
            }
          }
        }
      }
      sdg_icon_4 {
        document {
          ... on PrismicSdGoal {
            data {
              icon {
                alt
                gatsbyImageData(width: 100)
              }
            }
          }
        }
      }
    }
  }

  fragment LandingPageDataBodyGoalsSlideshow on PrismicLandingPageDataBodyGoalsSlideshow {
    primary {
      title {
        richText
      }
    }
    items {
      background_image {
        gatsbyImageData(width: 1130)
      }
      call_to_action {
        richText
      }
      description {
        richText
      }
      headline {
        richText
      }
      link {
        link_type
        url
        target
      }
      sdg_icon_1 {
        document {
          ... on PrismicSdGoal {
            data {
              icon {
                alt
                gatsbyImageData(width: 100)
              }
            }
          }
        }
      }
      sdg_icon_2 {
        document {
          ... on PrismicSdGoal {
            data {
              icon {
                alt
                gatsbyImageData(width: 100)
              }
            }
          }
        }
      }
      sdg_icon_3 {
        document {
          ... on PrismicSdGoal {
            data {
              icon {
                alt
                gatsbyImageData(width: 100)
              }
            }
          }
        }
      }
      sdg_icon_4 {
        document {
          ... on PrismicSdGoal {
            data {
              icon {
                alt
                gatsbyImageData(width: 100)
              }
            }
          }
        }
      }
    }
  }
`

export default GoalsSlideshow
