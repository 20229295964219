import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { linkResolver } from '../utils/LinkResolver.js'
import LinkButton from '../components/LinkButton.js'

const BenefitsWithImage = ({ slice }) => {
  const image = getImage(slice.primary.main_image)

  return (
    <section className="mt-12 sm:mt-20 container">
      <div className="grid md:grid-cols-3 items-center shadow bg-primary-900 rounded text-white">
        <div className="col-span-3 lg:col-span-2 order-2 lg:order-1 flex-1 p-8 md:p-12">
          <h3 className="text-xl leading-snug sm:text-2xl sm:leading-normal">{RichText.asText(slice.primary.title.richText)}</h3>

          {slice.items.map((item, index) => {
            const icon = getImage(item.icon)

            return (
              <div className="flex flex-col sm:flex-row items-center mt-6 sm:mt-3 lg:mt-6 first:mt-0" key={`benefit-bullet-${index}`}>
                <GatsbyImage image={icon} alt="" className="flex-shrink-0 w-10" />
                <div className="mt-4 sm:mt-0 sm:ml-6 prose text-white">
                  <RichText render={item.bullet_text.richText} linkResolver={linkResolver} />
                </div>
              </div>
            )
          })}

          <div className="mt-4 mb-0 text-center lg:text-left">
            <LinkButton label={slice.primary.call_to_action} link={slice.primary.link} isWarning />
          </div>
        </div>

        <div className="order-1 min-h-full h-full hidden lg:block">
          <GatsbyImage image={image} alt="" className="rounded-r" />
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyBenefitsWithImage on PrismicHomepageDataBodyBenefitsWithImage {
    items {
      icon {
        gatsbyImageData(width: 50)
      }
      bullet_text {
        richText
      }
    }
    primary {
      call_to_action {
        richText
      }
      link {
        url
        target
        link_type
      }
      main_image {
        gatsbyImageData(width: 376)
      }
      title {
        richText
      }
    }
  }

  fragment PageDataBodyBenefitsWithImage on PrismicPageDataBodyBenefitsWithImage {
    items {
      icon {
        gatsbyImageData(width: 50)
      }
      bullet_text {
        richText
      }
    }
    primary {
      call_to_action {
        richText
      }
      link {
        url
        target
        link_type
      }
      main_image {
        gatsbyImageData(width: 376)
      }
      title {
        richText
      }
    }
  }

  fragment LandingPageDataBodyBenefitsWithImage on PrismicLandingPageDataBodyBenefitsWithImage {
    items {
      icon {
        gatsbyImageData(width: 50)
      }
      bullet_text {
        richText
      }
    }
    primary {
      call_to_action {
        richText
      }
      link {
        url
        target
        link_type
      }
      main_image {
        gatsbyImageData(width: 376)
      }
      title {
        richText
      }
    }
  }

  fragment SteeringGroupDataBodyBenefitsWithImage on PrismicSteeringGroupDataBodyBenefitsWithImage {
    items {
      icon {
        gatsbyImageData(width: 50)
      }
      bullet_text {
        richText
      }
    }
    primary {
      call_to_action {
        richText
      }
      link {
        url
        target
        link_type
      }
      main_image {
        gatsbyImageData(width: 376)
      }
      title {
        richText
      }
    }
  }
`

export default BenefitsWithImage