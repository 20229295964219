import * as React from 'react'
import { RichText } from 'prismic-reactjs'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import BackButton from './BackButton'
import { linkResolver } from '../utils/LinkResolver'

import {
  parseISO,
  format,
} from 'date-fns'

// import LocationIcon from '../images/icons/location-icon.svg'
import EventIcon from '../images/icons/streamline-icon-event.svg'
import Envelope from '../images/icons/streamline-icon-envelope.svg'
// import LinkedInIcon from '../images/icons/streamline-icon-professional-network-linkedin.svg'

const SteeringGroupDetail = ({ 
  uid,
  url,
  start_date,
  background_image,
  icon_light,
  icon_dark,
  summary,
  name,
  state,
  roadmap_activity,
  moderator,
  subject_matter_expert, 
}) => {
  const background = getImage(background_image)
  const darkIcon = getImage(icon_dark)
  // const lightIcon = getImage(icon_light) // Not being used at the moment
  const startDate = parseISO(start_date)
  // const moderatorObj = moderator.document ? moderator.document.data : null
  // const expertObj = subject_matter_expert.document ? subject_matter_expert.document.data : null

  return (
    <section className={`container mt-16 lg:mt-24`}>
      <BackButton>Back to overview</BackButton>

      <div className="bg-primary-900 rounded grid grid-cols-5 text-white relative mt-4">
        <div className="col-span-5 md:col-span-3 order-2 md:order-1 p-8 lg:p-16">
          <p className="uppercase text-primary-600 font-bold tracking-widest">
            {state}
          </p>
          <h2 className="">
            {RichText.asText(name.richText)}
          </h2>

          {start_date ? 
            <div className="inline-flex items-center mt-4">
              <EventIcon className="fill-current text-white stroke-current w-6 h-6" />
              <span className="ml-2">
                {state === 'Upcoming' || state === 'Assembling' ? 'Starts on' : 'Started on'}&nbsp;
                {format(startDate, 'do MMMM yyyy')}
              </span>
            </div> : null}

          <div className="prose text-white mt-4">
            <RichText render={summary.richText} linkResolver={linkResolver} />
          </div>
          
          <div className="mt-6">
            <a href="mailto:community@sdialliance.org?subject=Apply for steering group" className="border-2 rounded py-2 px-4 inline-flex items-center font-bold hover:bg-primary-800 transition">
              <Envelope className="fill-current stroke-current w-6 h-6 text-white" />
              <span className="ml-4">Apply to join</span>
            </a>
          </div>

          {roadmap_activity && roadmap_activity.document && roadmap_activity.document.data ? 
            <div className="mt-12 ">
              <h5 className="mb-4">Roadmap Activity:</h5>

              <span className="rounded-full bg-primary-800 py-2 px-6 font-bold mr-2">
                {RichText.asText(roadmap_activity.document.data.name.richText)}
              </span>
            </div> : null}
        </div>

        {background_image ? <GatsbyImage
          alt={RichText.asText(name.richText)}
          image={background}
          objectFit="cover"
          layout="fullWidth"
          className="
            h-full relative pb-80 col-span-5 md:col-span-2 order-1 md:order-2 
            rounded-t md:rounded-t-none md:rounded-r
            max-h-12 md:max-h-full
          "
        /> : null}

        {icon_dark ? 
          <div className="bg-white max-w-60 p-4 shadow rounded absolute top-6 right-0 mr-6 mb-6">
            <GatsbyImage alt={RichText.asText(name.richText)} image={darkIcon} />
          </div> : null}
      </div>

      {/* {moderatorObj || expertObj ?
        <h2 className="text-center mt-20">Moderation of {RichText.asText(name.richText)}</h2>
      : null}
      
      {moderatorObj ? 
        <div 
          className="bg-gray-100 rounded p-8 mt-6 flex justify-between items-center max-w-6xl mx-auto"
        >
          <div className="flex items-center">
            <GatsbyImage 
              alt={RichText.asText(moderatorObj.full_name.richText)} 
              image={getImage(moderatorObj.photo)} 
              imgClassName="rounded-full" 
              className="w-24" 
            />
            <div className="ml-8">
              <h6 className="text-lg">{RichText.asText(moderatorObj.full_name.richText)}</h6>
              <p className="text-gray-700">{RichText.asText(moderatorObj.title.richText)}</p>
            </div>
          </div>

          <a 
            href={`mailto:${moderatorObj.public_email}`} 
            className="py-3 px-6 bg-secondary-900 hover:bg-secondary-800 transition rounded text-white font-bold inline-flex items-center"
          >
            <Envelope className="w-6 h-6 stroke-current fill-current" />
            <span className="ml-4">Write an email</span>
          </a>
        </div> : null}

      {expertObj ? 
        <div 
          className="bg-gray-100 rounded p-8 mt-6 flex justify-between items-center max-w-6xl mx-auto"
        >
          <div className="flex items-center">
            <GatsbyImage 
              alt={RichText.asText(expertObj.full_name.richText)} 
              image={getImage(expertObj.photo)} 
              imgClassName="rounded-full" 
              className="w-24" 
            />
            <div className="ml-8">
              <h6 className="text-lg">{RichText.asText(expertObj.full_name.richText)}</h6>
              <p className="text-gray-700">{RichText.asText(expertObj.title.richText)}</p>
            </div>
          </div>

          <a 
            href={expertObj.linkedin_profile.url} 
            target="_blank"
            rel="noreferrer noopener"
            className="py-3 px-6 bg-secondary-900 hover:bg-secondary-800 transition rounded text-white font-bold inline-flex items-center"
          >
            <LinkedInIcon className="w-6 h-6 stroke-current fill-current" />
            <span className="ml-4">LinkedIn</span>
          </a>
        </div> : null} */}
      
    </section>
  )
}

export default SteeringGroupDetail
