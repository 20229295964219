import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

const TaggboxEmbed = ({ slice }) => {
  const { primary } = slice

  useEffect(() => {
    if (typeof window !== 'undefined') {
      try {
        window.ApplicationEmbedWidget.init();
      } catch (e) {
        console.error('Taggbox is not loaded.')
      }
    }
  });

  if(!slice || !slice.primary) return null

  return (
    <section className={`container mt-0 sm:mt-0`}>

      {primary.headline ? 
        <h2 className="text-center">
          {RichText.asText(primary.headline.richText)}
        </h2> : null}

      {primary.wall_id ? 
        <div className="taggbox-container" style={{ width: '100%', height: '100%', overflow: 'auto' }}>
          <div className="taggbox-socialwall" data-wall-id={primary.wall_id} view-url={`https://widget.taggbox.com/${primary.wall_id}`}></div>
        </div> : null}
    </section>
  )
}

export const query = graphql`
  fragment SteeringGroupDataBodyTaggboxEmbed on PrismicSteeringGroupDataBodyTaggboxEmbed {
    primary {
      headline {
        richText
      }
      wall_id
    }
  }

  fragment RoadmapChallengeDataBodyTaggboxEmbed on PrismicRoadmapChallengeDataBodyTaggboxEmbed {
    primary {
      headline {
        richText
      }
      wall_id
    }
  }
`

export default TaggboxEmbed
