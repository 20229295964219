import * as React from 'react';
import PropTypes from 'prop-types';

import ArticleCard from './ArticleCard';

const FeaturedArticlesSection = ({ className, items }) => {
  if (!Array.isArray(items) || !items.length) return null;

  // Making sure we only display 3 articles on this section
  const articles = items.slice(0, 3);

  return (
    <section className={className ? className : null}>
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-[20px]">
        {articles.map(item => (
          <ArticleCard
            key={item.slug}
            author={item.document?.data?.author}
            categories={item.document?.data?.categories}
            className="sm:last-of-type:hidden lg:last-of-type:block"
            mainImage={item.document?.data?.main_image}
            publicationTime={item.document?.data?.publication_time}
            slug={item.document?.uid}
            title={item.document?.data?.title?.text}
          />
        ))}
      </div>
    </section>
  );
};

FeaturedArticlesSection.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    document: PropTypes.shape({
      data: PropTypes.shape({
        author: PropTypes.shape({
          document: PropTypes.shape({
            data: PropTypes.shape({
              full_name: PropTypes.shape({
                text: PropTypes.string
              }),
              photo: PropTypes.shape({
                alt: PropTypes.string,
                gatsbyImageData: PropTypes.shape({
                  height: PropTypes.number,
                  layout: PropTypes.string,
                  width: PropTypes.number
                })
              })
            })
          })
        }),
        categories: PropTypes.arrayOf(PropTypes.shape({
          category: PropTypes.shape({
            document: PropTypes.shape({
              data: PropTypes.shape({
                title: PropTypes.shape({
                  text: PropTypes.string
                })
              })
            })
          })
        })),
        excerpt: PropTypes.shape({
          text: PropTypes.string
        }),
        main_image: PropTypes.shape({
          alt: PropTypes.string
        }),
        publication_time: PropTypes.string,
        title: PropTypes.shape({
          text: PropTypes.string
        })
      })
    }),
    slug: PropTypes.string
  }))
};

FeaturedArticlesSection.defaultProps = {
  className: null,
  items: null
};

export default FeaturedArticlesSection
