import * as React from 'react'
import { graphql } from 'gatsby'

// import { HubspotFormBuilder } from '../components/forms/HubspotFormBuilder'

// This slice is currently using a workaround, by embedding the HubSpot Form using HubSpot JS
// At the moment, HubSpot API does not allow to submit forms that have company properties embedded (beta)
// via it's Submit API. Once that changes, we can use the HubSpotFormBuilder again, to really built our
// own forms, and not rely on the HubSpot JS for loading the form.

// const HubspotForm = ({ slice }) => {
//   console.log(slice)
//   return (
//     <section className="container-small mt-12 sm:mt-20 p-8 shadow-2 bg-white">
//       {slice.primary && slice.primary.hubspot_form_id ? 
//         <HubspotFormBuilder id={slice.primary.hubspot_form_id} themeDark /> 
//         : null
//       }
//     </section>
//   )
// }

import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/LinkResolver'
import TeamMemberPhoto from '../components/TeamMemberPhoto'

const HubspotFormWithTeamMember = ({ slice }) => {
  const { primary } = slice
  const { 
    hubspot_form_id,
    headline,
    description,
    team_member,
    team_member_headline,
  } = primary
  const divId = `hubspot-form-${hubspot_form_id}`

  const { loaded, error } = useHubspotForm({
    portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
    formId: hubspot_form_id,
    target: `#${divId}`
  });

  return (
    <div id="form">
      <section className="bg-gray-100 py-12 mt-24" id="form">
        <h3 className="text-center">
          {RichText.asText(headline.richText)}
        </h3>

        <div className="prose-lg mt-4 text-gray-600 text-center mx-auto">
          <RichText
            linkResolver={linkResolver}
            render={description.richText}
          />
        </div>

        <div className="grid grid-cols-5 gap-8 lg:gap-0 mt-12 max-w-4xl mx-auto px-4 md:px-8">
          <div className="col-span-5 sm:col-span-2 order-2 sm:order-none">
            <h4 className="mb-4">
              {RichText.asText(team_member_headline.richText)}
            </h4>
            {!!team_member?.document?.data && (
              <TeamMemberPhoto
                {...team_member.document.data}
                className="w-40"
              />
            )}
          </div>

          {/* this is the form container that will be injected */}
          <div className="col-span-5 sm:col-span-3" id={divId}>
            <span>
              {!loaded && 'Loading Form…'}
              {error && 'Error loading form'}
            </span>
          </div>
        </div>
      </section>
    </div>
  )
}


export const query = graphql`
  fragment HomepageDataBodyHubspotFormWithTeamMember on PrismicHomepageDataBodyHubspotFormWithTeamMember {
    primary {
      description {
        richText
      }
      headline {
        richText
      }
      hubspot_form_id
      team_member {
        document {
          ... on PrismicTeamMember {
            id
            data {
              full_name {
                richText
              }
              linkedin_profile {
                url
                uid
                type
                target
                link_type
              }
              photo {
                gatsbyImageData(width: 180)
              }
              title {
                richText
              }
            }
          }
        }
      }
      team_member_headline {
        richText
      }
    }
  }

  fragment DictionaryEntryDataBodyHubspotFormWithTeamMember on PrismicDictionaryEntryDataBodyHubspotFormWithTeamMember {
    primary {
      description {
        richText
      }
      headline {
        richText
      }
      hubspot_form_id
      team_member {
        document {
          ... on PrismicTeamMember {
            id
            data {
              full_name {
                richText
              }
              linkedin_profile {
                url
                uid
                type
                target
                link_type
              }
              photo {
                gatsbyImageData(width: 180)
              }
              title {
                richText
              }
            }
          }
        }
      }
      team_member_headline {
        richText
      }
    }
  }

  fragment LandingPageDataBodyHubspotFormWithTeamMember on PrismicLandingPageDataBodyHubspotFormWithTeamMember {
    primary {
      description {
        richText
      }
      headline {
        richText
      }
      hubspot_form_id
      team_member {
        document {
          ... on PrismicTeamMember {
            id
            data {
              full_name {
                richText
              }
              linkedin_profile {
                url
                uid
                type
                target
                link_type
              }
              photo {
                gatsbyImageData(width: 180)
              }
              title {
                richText
              }
            }
          }
        }
      }
      team_member_headline {
        richText
      }
    }
  }

  fragment ResourceDataBodyHubspotFormWithTeamMember on PrismicResourceDataBodyHubspotFormWithTeamMember {
    primary {
      description {
        richText
      }
      headline {
        richText
      }
      hubspot_form_id
      team_member {
        document {
          ... on PrismicTeamMember {
            id
            data {
              full_name {
                richText
              }
              linkedin_profile {
                url
                uid
                type
                target
                link_type
              }
              photo {
                gatsbyImageData(width: 180)
              }
              title {
                richText
              }
            }
          }
        }
      }
      team_member_headline {
        richText
      }
    }
  }

  fragment SteeringGroupDataBodyHubspotFormWithTeamMember on PrismicSteeringGroupDataBodyHubspotFormWithTeamMember {
    primary {
      description {
        richText
      }
      headline {
        richText
      }
      hubspot_form_id
      team_member {
        document {
          ... on PrismicTeamMember {
            id
            data {
              full_name {
                richText
              }
              linkedin_profile {
                url
                uid
                type
                target
                link_type
              }
              photo {
                gatsbyImageData(width: 180)
              }
              title {
                richText
              }
            }
          }
        }
      }
      team_member_headline {
        richText
      }
    }
  }
`

export default HubspotFormWithTeamMember
