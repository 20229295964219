import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import LinkButton from '../components/LinkButton';

const PositionSubPosition = ({ slice }) => {
  const { description, title } = slice?.primary || {};
  const { items } = slice || {};

  if (!title?.text) return null;

  return (
    <section className="container mt-[40px] md:mt-[80px]">
      <div className="position-headline">
        <div className="text-center max-w-[676px] mx-auto">
          <div className="text-[24px] leading-[25px] md:text-[40px] md:leading-[49px] max-w-none text-[#1B202B] font-body font-semibold">
            {title.text}
          </div>
          {!!description && Array.isArray(description.richText) && description.richText[0]?.text?.length && (
            <div className="font-body text-[18px] md:text-[20px] leading-[24px] md:leading-[29px] text-[#1B202B] mt-[8px] md:mt-[20px]">
              <RichText render={description.richText} />
            </div>
          )}
          {Array.isArray(items) && !!items.length && items.map((action, index) => (
            <LinkButton
              key={`headline-action-${index}`}
              className={`mr-4 mt-[20px] md:mt-[32px] text-[14px] sm:text-[18px] leading-[18px] font-bold px-[20px] py-[10px] md:px-[32px] md:py-[16px] ${action.type === 'primary' ? ' c-button--warning-dark' : ' c-button--warning'}`}
              label={action.call_to_action_text}
              link={action.call_to_action_link}
            />
          ))}
        </div>
      </div>
      <hr className="my-[40px] md:my-[80px]" />
    </section>
  );
};

export const query = graphql`
  fragment PositionDataBodyPositionSubPosition on PrismicPositionDataBodyPositionSubPosition {
    items {
      call_to_action_text {
        richText
      }
      call_to_action_link {
        url
        type
        target
        link_type
      }
      type
    }
    primary {
      description {
        richText
      }
      title {
        text
      }
    }
  }
`;

export default PositionSubPosition;
