import * as React from 'react'
import { RichText } from 'prismic-reactjs'

// import LinkButton from './LinkButton'
import SteeringGroup from './SteeringGroup'

const ListSteeringGroups = ({ allGroups, title, subtitle }) => {
  return (
    <section className={`mt-12 sm:mt-20 lg:pb-8 container`}>
      {title ? 
        <h2 className="text-center">
          {RichText.asText(title.richText)}
        </h2> : null}

      {subtitle ? 
        <p className="text-xl font-light font-display text-gray-500 mt-3 text-center">
          {RichText.asText(subtitle.richText)}
        </p> : null}

      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mt-12">
        {allGroups.map((group, index) => (
          <SteeringGroup {...group} key={`steering-group-${index}`} />
        ))}
      </div>
{/* 
      {call_to_action && call_to_action_label ? 
        <div className="mt-12 text-center">
          <LinkButton
            link={call_to_action}
            label={call_to_action_label}
            isWarning
          />
        </div> : null} */}
    </section>
  )
}

export default ListSteeringGroups
