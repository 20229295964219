// This util is used to create a horizontal masonry layout with 2 rows of items.
const GetHorizontalMasonryRows = (items) => {
  if (!Array.isArray(items) || !items.length) return null;

  // Initialize the two rows and their current widths
  const firstRow = [];
  const secondRow = [];
  let firstRowWidth = 0;
  let secondRowWidth = 0;

  // Loop through the items
  items.forEach((item, index) => {
    const { imageWidth, imageHeight } = item || {};

    if (imageHeight && imageWidth) {
      // The aspect ratio is used here instead of the image width because the aspect ratio is a more reliable measure
      // of how much space the image will take up in the grid.
      // In a masonry layout, the height of the rows is typically fixed, and the width of each image is adjusted to maintain the image's aspect ratio.
      // This means that an image with a larger aspect ratio (i.e., a wider image) will take up more space in the row
      // than an image with a smaller aspect ratio (i.e., a taller image), regardless of the actual width of the image.
      // By using the aspect ratio to decide where to place each image, the code ensures that the rows are as evenly filled as possible,
      // regardless of the actual dimensions of the images. This helps to create a balanced layout where each row
      // takes up approximately the same amount of horizontal space.
      const imageAspectRatio = imageWidth / imageHeight;

      // Depending on the image aspect ratio and the rows width, the item will be dynamically placed in the row with the smallest width.
      if (firstRowWidth <= secondRowWidth) {
        firstRow.push(item);
        firstRowWidth += imageAspectRatio;
      } else {
        secondRow.push(item);
        secondRowWidth += imageAspectRatio;
      }
    }
  });

  return [firstRow, secondRow];
}

export default GetHorizontalMasonryRows;
