import * as React from 'react'

import { Header } from './Header'
import { Footer } from './Footer'

export const Layout = ({ children, hideHeaderBorderTop, useDarkHeader, useDarkFooter,  }) => (
  <div className="c-page">
    <Header
      hideBorderTop={hideHeaderBorderTop}
      useDarkHeader={useDarkHeader}
    />
    {children}

    <Footer
      useDarkFooter={useDarkFooter}
    />
  </div>
)

export default Layout
