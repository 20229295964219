import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const SimpleImage = ({ slice }) => {
  const data = slice?.primary;
  const image = getImage(data.image);

  if (!image) return null;

  return (
    <section className="container-medium pt-[30px]">
      <GatsbyImage
        alt={image.alt || ''}
        className="block w-full"
        image={image}
      />
    </section>
  );
};

export const query = graphql`
  fragment PageDataBodySimpleImage on PrismicPageDataBodySimpleImage {
    primary {
      image {
        alt
        gatsbyImageData
        url
      }
    }
  }

  fragment MembershipDataBodySimpleImage on PrismicMembershipDataBodySimpleImage {
    primary {
      image {
        alt
        gatsbyImageData
        url
      }
    }
  }
`;

export default SimpleImage;
