import React from 'react';
import { graphql } from 'gatsby';
import { linkResolver } from '../utils/LinkResolver.js';
import { RichText } from 'prismic-reactjs';

// Import Swiper
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

import ArticleCard from '../components/ArticleCard';

const RelevantArticles = ({ slice }) => {
  const { description, title } = slice?.primary || {};
  const { items } = slice || {};

  if (!Array.isArray(items) || !items.length) return null;

  const articles = items.map(item => item.article);

  // Make sure we only get 3 articles from the items
  articles.slice(0, 3);

  return (
    <section className="my-[60px] md:my-[120px]">
      <div className="container">
        {(!!title?.text || (Array.isArray(description?.richText) && description.richText[0]?.text)) && (
          <div className="mb-[20px] md:mb-[32px]">
            {!!title?.text && (
              <h2 className="font-display font-bold text-[20px] md:text-[32px] text-[#1B202B] leading-[20px] md:leading-[27px]">
                {title.text}
              </h2>
            )}
            {Array.isArray(description?.richText) && description.richText[0]?.text && (
              <div className="text-[18px] md:text-[20px] leading-[24px] md:leading-[29px] text-[#1B202B] mx-auto mt-[20px]">
                <RichText
                  linkResolver={linkResolver}
                  render={description.richText}
                />
              </div>
            )}
          </div>
        )}
        <div className="hidden md:grid sm:grid-cols-2 md:grid-cols-3 gap-[20px]">
          {articles.map(item => (
            <ArticleCard
              key={item.document?.uid}
              author={item.document?.data.author}
              categories={item.document?.data.categories}
              mainImage={item.document?.data.main_image}
              publicationTime={item.document?.data.publication_time}
              slug={item.document?.uid}
              title={item.document?.data.title?.text}
            />
          ))}
        </div>
        <div className='md:hidden'>
          <Swiper
            className="overflow-visible"
            breakpoints={{
              576: {
                slidesPerView: 2.1
              }
            }}
            spaceBetween={24}
            slidesPerView={1.1}
          >
            {articles.map((item, index) => (
              <SwiperSlide key={index}>
               <ArticleCard
                  key={item.document?.uid}
                  author={item.document?.data.author}
                  categories={item.document?.data.categories}
                  mainImage={item.document?.data.main_image}
                  publicationTime={item.document?.data.publication_time}
                  slug={item.document?.uid}
                  title={item.document?.data.title?.text}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment PositionDataBodyRelevantArticles on PrismicPositionDataBodyRelevantArticles {
    items {
      article {
        document {
          ... on PrismicArticle {
            ... PrismicArticleFragment
          }
        }
        slug
      }
    }
    primary {
      title {
        text
      }
      description {
        richText
      }
    }
  }
`;

export default RelevantArticles;
