import React, { useEffect, useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { RichText } from 'prismic-reactjs';
import { navigate } from '@reach/router';

// Components
import ArticlesList from '../components/ArticlesList';
import BlogCategories from '../components/BlogCategories';
import FeaturedArticlesSection from '../components/FeaturedArticlesSection';
import HeroSectionWithImage from '../components/HeroSectionWithImage';
import Layout from '../components/Layout';
import Loader from '../components/Loader';
import Pagination from '../components/Pagination';
import SearchResultsPagination from '../components/SearchResultsPagination';
import Seo from '../components/Seo';

// We need to use base url because location.pathname can be either '/blog' or '/blog/page'
const BASE_URL = '/blog';

// NOTE:
// There is a console.log error, after performing a blog search, with the following message:
// Uncaught (in promise) TypeError: t.current.render is not a function
// After looking deeply into it we suspected that it must be a Gatsby bug

const BlogTemplate = ({ location, data }) => {
  const doc = data?.prismicBlog?.data;
  const categories = data?.allPrismicCategory?.nodes;
  const { pageInfo } = data?.articles || {};

  const featuredArticlesSectionData = {
    items: Array.isArray(doc?.featured_articles_items) && !!doc.featured_articles_items.length && doc.featured_articles_items.map(item => item.featured_article)
  };

  const {
    hero_call_to_action_link,
    hero_call_to_action_text,
    hero_image,
    hero_text,
    hero_title
  } = doc || {};

  const articlesList = useRef(null);
  const searchInput = useRef(null);

  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState(null);
  const [searchHasFailed, setSearchHasFailed] = useState(false);
  const [totalResultsNumber, setTotalResultsNumber] = useState(null);

  const isSearch = !!location?.search;
  const searchInputValue = searchInput?.current?.value;

  const urlParams = new URLSearchParams(location.search);

  useEffect(() => {
    const fetchArticlesFromUrlParams = async () => {
      const paramsObject = Object.fromEntries(urlParams);

      const { terms } = paramsObject || {};

      if (isSearch && terms) {
        await fetchArticlesFromSearch(paramsObject);

        if (articlesList?.current) {
          if (searchInput?.current) searchInput.current.value = terms;

          window.scrollTo({
            behavior: 'smooth',
            top:
              // 100px is an offset value that seemed good to me
              articlesList.current.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 100,
          });
        }
      } else {
        setArticles(data?.articles?.nodes);
        setPaginationInfo(pageInfo);
      }
    }

    fetchArticlesFromUrlParams();
  }, [location.search]);

  const fetchArticlesFromSearch = (params) => {
    setLoading(true);
    setPaginationInfo(null);
    setSearchHasFailed(false);

    fetch('/api/fetch-blog-search-results', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    })
      .then((response) => {
        if (!response.ok) throw Error;

        return response.json();
      })
      .then((response) => {
        const { results } = response;

        if (!Array.isArray(response.results)) throw new Error('Results from response are invalid.');

        setLoading(false);

        const formattedArticles = results.map(article => ({
          ...article,
          data: {
            ...article?.data,
            excerpt: Array.isArray(article?.data?.excerpt) && article.data.excerpt.length ?
              article.data.excerpt[0] :
              null,
            title: Array.isArray(article?.data?.title) && article.data.title.length ?
            article.data.title[0] :
            null,
          }
        }))

        setArticles(formattedArticles);

        setPaginationInfo({
          currentPage: response.page,
          hasNextPage: !!response.next_page,
          hasPreviousPage: !!response.prev_page,
          pageCount: response.total_pages
        })

        setTotalResultsNumber(response.total_results_size);
      })
      .catch(() => {
        setSearchHasFailed(true);
        setLoading(false);
        setArticles([]);
        setPaginationInfo(null);
      })
  }

  if (!data) return null;

  const handleSearchFormSubmit = (event) => {
    event.preventDefault();

    const { value } = searchInput?.current || {};

    const searchTerms = typeof value === 'string' ? value.trim() : '';

    let newUrl = BASE_URL;

    if (searchTerms) {
      const newUrlParams = new URLSearchParams();

      newUrlParams.set('terms', searchTerms);
      newUrl = `${newUrl}?${newUrlParams}`;
    }

    navigate(newUrl, {
      state: {
        disableScrollUpdate: true,
      }
    });
  };

  const handleSearchPaginationButtonClick = (pageNumber) => {
    const { value } = searchInput?.current || {};

    const searchTerms = typeof value === 'string' ? value.trim() : '';

    if (searchTerms && pageNumber) {
      const newUrlParams = new URLSearchParams();

      newUrlParams.set('terms', searchTerms);
      newUrlParams.set('page', pageNumber);

      const newUrl = `${BASE_URL}?${newUrlParams}`;

      navigate(newUrl, {
        state: {
          disableScrollUpdate: true,
        }
      });
    }
  };

  return (
    <Layout
      hideHeaderBorderTop
      useDarkFooter
    >
      <Seo title={RichText.asText(hero_title.richText)} description={RichText.asText(hero_text.richText)} />
      <div className="w-full">
        <HeroSectionWithImage
          callToAction={{
            link: hero_call_to_action_link,
            text: hero_call_to_action_text
          }}
          image={hero_image}
          text={hero_text.richText}
          title={hero_title.richText}
        />
      </div>
      <div className="container max-w-[1248px]">
        <FeaturedArticlesSection
          className="relative mt-[-100px] max-w-[1040px] ml-auto mr-auto z-10"
          {...featuredArticlesSectionData}
        />
        <section
          ref={articlesList}
          id="latest-articles"
          className="mt-12 sm:mt-[102px]"
        >
          <div className="grid md:grid-cols-[1fr_239px] gap-[40px] md:gap-[73px] mt-[32px]">
            <main>
              <div className="flex flex-col lg:flex-row lg:items-center justify-between mb-[32px]">
                <h2 className="text-[32px] leading-[50px] text-[#1D4732]">
                  Latest Articles
                </h2>
                <form
                  className="relative inline-flex mt-[20px] lg:mt-0 max-w-[400px]"
                  onSubmit={handleSearchFormSubmit}
                >
                  <input
                    ref={searchInput}
                    className="text-[16px] leading-[18px] py-[12.5px] pl-[20px] pr-[120px] border border-slate-400 rounded-[8px] w-full"
                    placeholder="Search articles..."
                    type="text"
                  />
                  <button
                    className="absolute top-1/2 -translate-y-1/2 right-[5px] text-white text-[14px] leading-[20px] py-[8px] px-[20px] rounded-[5px] bg-[#527563] cursor-pointer"
                    type="submit"
                  >
                    Search
                  </button>
                </form>
              </div>
              {isSearch &&
              !!searchInputValue &&
              !!articles.length &&
              !!totalResultsNumber &&
              !!paginationInfo && (
                <p className="text-[#1D4732] text-sm my-5">
                  
                  Showing results {!paginationInfo.hasNextPage ? `${((paginationInfo.currentPage - 1) * 10) + 1}-${totalResultsNumber}` : `${((paginationInfo.currentPage - 1) * 10) + 1}-${paginationInfo.currentPage * 10}`} of {totalResultsNumber} for "{searchInputValue}"
                </p>
              )}
              {loading ?
                (
                  <Loader className="text-[#1D4732] h-10 w-10 mt-10 mx-auto" />
                ) :
                  <>
                    {articles.length ?
                      (
                        <ArticlesList articles={articles} />
                      ) :
                      !!searchInputValue && (
                        <p className="text-[#1D4732] text-sm my-5">
                          {searchHasFailed ? 'Something went wrong while searching. Please try again.' : `We couldn‘t find any articles with "${searchInputValue}"`}
                        </p>
                      )
                    }
                  </>
                }
            </main>
            <aside>
              <BlogCategories categories={categories} />
            </aside>
          </div>
          {typeof paginationInfo?.pageCount === 'number' && paginationInfo.pageCount > 1 && (
            <>
              {isSearch ?
              (
                <div className="flex justify-center mt-12 md:mt-[80px]">
                  <SearchResultsPagination
                    pageInfo={paginationInfo}
                    onPaginationButtonClick={handleSearchPaginationButtonClick}
                  />
                </div>
              ) :
              (
                <div className="flex justify-center mt-12 md:mt-[80px]">
                  <Pagination
                    basePath={BASE_URL}
                    pageInfo={paginationInfo}
                    pagePath={`${BASE_URL}/page`}
                  />
                </div>
              )}
            </>
          )}
        </section>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query BlogArticlesListQuery($limit: Int!, $skip: Int!) {
    articles: allPrismicArticle(
      filter: {
        data: {
          categories: {
            elemMatch: {
              category: {
                uid: {
                  ne: "featured-article"
                }
              }
            }
          }
        }
      }
      sort: {data: {date: DESC}}
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...PrismicArticleFragment
        uid
      }
      pageInfo {
        currentPage
        pageCount
        hasNextPage
        hasPreviousPage
      }
    }
    allPrismicCategory {
      nodes {
        data {
          title {
            text
          }
        }
        id
        uid
      }
    }
    prismicBlog {
      _previewable
      data {
        featured_articles_items {
          featured_article {
            document {
              ...PrismicArticleFragment
            }
            slug
          }
        }
        hero_call_to_action_link {
          url
          target
          type
          uid
          slug
          link_type
        }
        hero_call_to_action_text {
          richText
        }
        hero_image {
          alt
          gatsbyImageData
        }
        hero_title {
          text
          richText
        }
        hero_text {
          text
          richText
        }
      }
    }
  }
`

// Needed for category page
// categories: allPrismicArticle(filter: {
    //   data: {
    //     categories: {
    //       elemMatch: {
    //         category: {
    //           uid: {
    //             eq: "featured-article"
    //           }
    //         }
    //       }
    //     }
    //   }
    // }) {
    //   totalCount
    // }

export default withPrismicPreview(BlogTemplate);
