import * as React from 'react'
import { groupBy } from 'lodash'

import DictionaryEntry from './DictionaryEntry'

const ListDictionaryEntries = ({ entries }) => {
  const entriesByLetter = groupBy(entries, 'letter')

  return (
    <section className={`mt-12 sm:mt-18 lg:pb-8 container-medium`}>
      {Object.keys(entriesByLetter).map((letter) => (
        <div key={`dict-${letter}`} className="mb-4">
          <h2 className="uppercase">{letter}</h2>
          <ul>
            {entriesByLetter[letter].map((word, index) => <DictionaryEntry key={`entry-${index}`} {...word} />)}
          </ul>
        </div>
      ))}
    </section>
  )
}

export default ListDictionaryEntries
