import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

import ListResources from '../components/ListResources'
import { getImage } from 'gatsby-plugin-image'

const AllResources = ({ slice }) => {
  const { primary } = slice

  // get all upcoming events
  const queryData = useStaticQuery(graphql`
    {
      allPrismicResource(sort: {data: {published_on: DESC}}) {
        edges {
          node {
            ...PrismicResourceFragment
          }
        }
      }
    }
  `)

  const { data } = useMergePrismicPreviewData(queryData)
  const doc = data.allPrismicResource
  const allResources = doc.edges.map(edge => (
    { 
      ...edge.node.data, 
      uid: edge.node.uid, 
      url: edge.node.url, 
      preview_image: getImage(edge.node.data.preview_image) 
    } 
  ))

  return (
    <ListResources {...primary} allResources={allResources} />
  )
}



export const query = graphql`
  fragment PageDataBodyAllResources on PrismicPageDataBodyAllResources {
    primary {
      subtitle {
        richText
      }
      title {
        richText
      }
    }
  }
`

export default AllResources
