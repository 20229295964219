import * as React from 'react'

import {
  format,
} from 'date-fns'

import EventListItem from './EventListItem'

const EventsByMonth = ({ events }) => {
  const groups = {}

  events.forEach(event => {
    groups[event.month] ||= []
    groups[event.month].push(event)
  })

  return (
    <>
      {Object.keys(groups).map(month => {
        return (
          <div className="mt-12" key={`events-by-month-${month}`}>
            <h3 className="mb-8">{format(groups[month][0].start_date_obj, 'MMMM yyyy')}</h3>
            <ul>
              {groups[month].map((event, index) => (
                <EventListItem {...event} key={`events-${month}-${index}`} />
              ))}
            </ul>
          </div>
        )
      })}
    </>
  )
}

export default EventsByMonth
