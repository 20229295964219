import * as React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { linkResolver } from '../utils/LinkResolver.js';
import getCorrectTextColor from '/src/utils/SetTextColorDependingOnBackground';


const ConferenceItemsWithTextAndSideImage = ({ slice }) => {
  const data = slice.primary;
  const { items } = slice;

  if (!data?.title?.text || !Array.isArray(items) || !items.length) return null;

  const backgroundColor = data.background_color;
  const textColor = getCorrectTextColor(backgroundColor);

  return (
    <section
      className={`relative ${backgroundColor ? 'py-[80px] md:py-[120px]' : 'my-[140px] scroll-mt-[80px]'}`}
      id={data.anchor_id ?? null}
      style={{ backgroundColor: backgroundColor ? `${backgroundColor}` : '#FFFFFF'}}
    >
      <div className="container">
        <div className="text-center max-w-[800px] mx-auto mb-[80px] md:mb-[120px]">
          <h2
            className="font-body font-semibold text-[32px] md:text-[40px] text-[#1B202B] leading-[37px] md:leading-[44px]"
            style={{ color: textColor }}
          >
            {data.title.text}
          </h2>
        </div>
        <div className="flex flex-col gap-[80px] md:gap-[120px]">
          {items.map((item, index) => {
            const image = getImage(item.image);

            return (
              <div
                key={index}
                className="grid grid-cols-1 md:grid-cols-2 gap-[40px] md:gap-[80px]"
              >
                <div className={`${index % 2 !== 0 ? 'md:order-2' : 'md:order-1'}`}>
                  <h3
                    className="font-body font-semibold text-[24px] md:text-[32px] text-[#1B202B] leading-[30px] md:leading-[40px]"
                    style={{ color: textColor }}
                  >
                    {item.title.text}
                  </h3>
                  {Array.isArray(item.description?.richText) && item.description.richText[0]?.text && (
                    <div
                      className="prose text-[18px] md:text-[20px] leading-[24px] md:leading-[28px] text-[#1B202B] mt-[20px]"
                      style={{ color: textColor }}
                    >
                      <RichText
                        linkResolver={linkResolver}
                        render={item.description.richText}
                      />
                    </div>
                  )}
                </div>
                {!!image && (
                  <div className={`relative h-[300px] md:h-auto overflow-hidden ${index % 2 !== 0 ? 'md:order-1' : 'md:order-2'}`}>
                    <GatsbyImage
                      alt={item.image.alt || ''}
                      className="flex h-full w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                      image={image}
                      objectFit="cover"
                    />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ConferenceDataBodyConferenceItemsWithTextAndSideImage on PrismicDigitalSustainabilityConferenceDataBodyConferenceItemsWithTextAndSideImage {
    primary {
      title {
        text
      }
      background_color
    }
    items {
      title {
        text
      }
      description {
        richText
      }
      image {
        alt
        url
        gatsbyImageData
      }
    }
  }
`

export default ConferenceItemsWithTextAndSideImage;
