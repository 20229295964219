import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { Layout } from '../../components/Layout';
import SliceZone from '../../components/SliceZone';
import Seo from '../../components/Seo';
import { RichText } from 'prismic-reactjs';

const MembershipTemplate = ({ data }) => {
  const doc = data?.prismicMembership?.data;

  const {
    body,
    name,
    tagline,
  } = doc || {};

  let shareImage = null;

  if (Array.isArray(body) && body.length) {
    const firstImage = body.find(entry => entry.slice_type === 'simple_image');

    if (firstImage) shareImage = firstImage?.primary?.image;
  }

  if (!data) return null;

  return (
    <Layout
      useDarkFooter={true}
      useDarkHeader={true}
    >
      <Seo
        description={RichText.asText(tagline.richText)}
        image={shareImage}
        title={RichText.asText(name.richText)}
      />
      <div className="pt-12 md:pt-6">
        {doc?.body && (
          <SliceZone sliceZone={doc.body} />
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query MembershipQuery($id: String) {
    prismicMembership(id: { eq: $id }) {
      _previewable
      data {
        name {
          richText
        }
        features {
          text {
            richText
          }
        }
        description {
          richText
        }
        currency
        color
        call_to_action_label
        call_to_action {
          url
          uid
          type
          target
          link_type
        }
        price
        tagline {
          richText
        }
        terms {
          richText
        }
        body {
          ...on PrismicSliceType {
            slice_type
          }
          ...MembershipDataBodyTextHero
          ...MembershipDataBodyCallout
          ...MembershipDataBodyToggleList
          ...MembershipDataBodyFeaturedOrgMembers
          ...MembershipDataBodySimpleRichText
          ...MembershipDataBodyContactTeamMembers
          ...MembershipDataBodyHubspotForm
          ...MembershipDataBodyFeaturedIndividualMembers
          ...MembershipDataBodyCallToAction
          ...MembershipDataBodyHeroWithFeatures
          ...MembershipDataBodySimpleImage
        }
      }
    }
  }
`;

export default withPrismicPreview(MembershipTemplate);
