import * as React from 'react'
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'

const LinkBlock = ({ items, primary, className, useDarkFooter }) => {
  if(!items || items.length === 0) return null;

  return (
    <nav className={className}>
      <h5 className={`${useDarkFooter ? '' : 'text-gray-300'} uppercase text-lg`}>{RichText.asText(primary.block_title.richText)}</h5>

      <ul className="mt-2">
        {items.map((item, index) => (
          <li className="mb-2 last:mb-0" key={`link-block-item-${index}`}>
            <Link
              to={item.link.url}
              className={`${useDarkFooter ? 'text-gray-600 hover:text-gray-300' : 'text-gray-400 hover:text-gray-100'}`}
            >
              {RichText.asText(item.link_label.richText)}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default LinkBlock
