import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

import LinkButton from '../components/LinkButton.js'
import TeamMemberBiography from '../components/TeamMemberBiography.js'

const AllTeamMembers = ({ slice }) => {
  const { primary } = slice

  // get all team members
  const queryData = useStaticQuery(graphql`
    {
      allPrismicTeamMember(sort: {data: {full_name: {text: ASC}}}) {
        edges {
          node {
            ...PrismicTeamMemberFragment
          }
        }
      }
    }
  `)
  const { data } = useMergePrismicPreviewData(queryData)
  const doc = data.allPrismicTeamMember
  const team_members = doc.edges

  return (
    <section className="mt-12 sm:mt-20">
      <div className="container">
        {!!primary.title && (
          <h2 className="text-center">
            {RichText.asText(primary.title.richText)}
          </h2>
        )}

        {!!primary.subtitle && (
          <p className="text-xl font-light font-display text-gray-500 mt-3 text-center">
            {RichText.asText(primary.subtitle.richText)}
          </p>
        )}

        {Array.isArray(team_members) && team_members.length > 0 && (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-12 mb-0">
            {team_members.map((member, index) => member?.node?.data ?
              (
                <TeamMemberBiography
                  {...member.node.data}
                  key={`team-member-photo-${index}`}
                />
              ) :
              null
            )}
          </div>
        )}

        {!!primary.cta && !!primary.link.url && (
          <div className="mt-12">
            <LinkButton
              label={primary.cta}
              link={primary.link}
            />
          </div>
        )}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyAllTeamMembers on PrismicPageDataBodyAllTeamMembers {
    primary {
      link {
        url
        uid
        type
        target
        link_type
      }
      subtitle {
        richText
      }
      title {
        richText
      }
      cta: call_to_action
    }
  }

  fragment LandingPageDataBodyAllTeamMembers on PrismicLandingPageDataBodyAllTeamMembers {
    primary {
      link {
        url
        uid
        type
        target
        link_type
      }
      subtitle {
        richText
      }
      title {
        richText
      }
      cta: call_to_action
    }
  }
`

export default AllTeamMembers
