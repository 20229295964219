import * as React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import LinkButton from '/src/components/LinkButton.js';
import { linkResolver } from '../utils/LinkResolver.js';
import getCorrectTextColor from '/src/utils/SetTextColorDependingOnBackground';


const ConferenceSponsors = ({ slice }) => {
  const data = slice.primary;
  const { items } = slice;

  const {
    anchor_id,
    call_to_action_link: callToActionLink,
    call_to_action_text: callToActionText,
    description,
    post_event_call_to_action_link: postEventCallToActionLink,
    post_event_call_to_action_text: postEventCallToActionText,
    post_event_description: postEventDescription,
    post_event_state: postEventState,
    post_event_title: postEventTitle,
    title
  } = data || {};

  if ((!title && !postEventTitle) || !Array.isArray(items) || !items.length) return null;

  const finalTitle = postEventState ? postEventTitle : title;
  const finalDescription = postEventState ? postEventDescription : description;
  const finalCallToActionLink = postEventState ? postEventCallToActionLink : callToActionLink;
  const finalCallToActionText = postEventState ? postEventCallToActionText : callToActionText;

  const backgroundColor = slice.primary.background_color;
  const textColor = getCorrectTextColor(backgroundColor);

  const sponsors = {}
  items.forEach((item, index) => {
    sponsors[item.sponsor_type] ||= []
    sponsors[item.sponsor_type].push(item)
  })

  const imageElement = (item, scale) => {
    const sponsorImage = getImage(item.sponsor_image);
    const sponsorWebsiteUrl = item.website?.url;

    const imageElement = (
      <div className={`flex items-center justify-center p-3 rounded-[12px]${postEventState ? ' h-[160px] w-[160px]' : ' h-[165px] w-[165px] outline outline-1 outline-[#487662]'}`}>
        <GatsbyImage
          alt={item.sponsor_image.alt || ''}
          image={sponsorImage}
          objectFit="contain"
          objectPosition="center"
        />
      </div>
    );

    return sponsorWebsiteUrl ?
      (
        <a
          href={sponsorWebsiteUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          {imageElement}
        </a>
      ) :
      imageElement
  };

  return (
    <section
      className={`relative ${backgroundColor ? 'py-[80px]' : 'my-[80px] md:my-[140px] scroll-mt-[80px]'}`}
      id={anchor_id ?? null}
      style={{ backgroundColor: backgroundColor ? `${backgroundColor}` : '#FFFFFF'}}
    >
      <div className="container">
        <div className={`${postEventState ? 'relative md:grid md:grid-cols-2 md:gap-[120px]' : ''}`}>
          <div className={`text-center${postEventState ? ' md:sticky md:h-min md:top-[40px] md:text-left md:max-w-[480px]' : ' mx-auto max-w-[800px]'}`}>
            <h2
              className="font-body font-bold text-[32px] md:text-[40px] text-[#1B202B] leading-[37px] md:leading-[44px]"
              style={{ color: textColor }}
            >
              {finalTitle.text}
            </h2>
            {Array.isArray(finalDescription?.richText) && finalDescription.richText[0]?.text && (
              <div
                className="text-[18px] md:text-[20px] leading-[24px] md:leading-[28px] text-[#1B202B] mx-auto mt-[20px]"
                style={{ color: textColor }}
              >
                <RichText
                  linkResolver={linkResolver}
                  render={finalDescription.richText}
                />
              </div>
            )}
             {postEventState &&
              !!finalCallToActionLink &&
              Array.isArray(finalCallToActionText?.richText) &&
              finalCallToActionText.richText[0]?.text && (
                <div className="flex justify-center md:justify-start mt-[20px]">
                  <LinkButton
                    className="text-[14px] md:text-[16px] leading-[18px] font-semibold text-white bg-[#E69635] py-[12px] px-[20px] rounded-[5px] hover:bg-[#dc851b]"
                    isPrimary
                    label={finalCallToActionText}
                    link={finalCallToActionLink}
                  />
                </div>
              )}
          </div>
          <div>
            {!!Object.keys(sponsors).length && (
              <div className={`${postEventState ? 'mt-[60px] sm:mt-0' : 'mt-[60px]'}`}>
                {Object.keys(sponsors).map((category, index) => (
                  <div key={index}>
                    {Object.keys(sponsors).length > 1 ? (
                      <div className={`flex justify-center${
                        index > 0 ? ' mt-[40px]' : ''}${
                        postEventState && index === 0 ? ' mt-[40px] md:mt-0' : ''}${
                        postEventState ? ' md:justify-start' : ''}`}
                      >
                        <h3
                          className="font-body font-semibold text-[#1B202B] text-[18px] md:text-[24px] leading-[28px] md:leading-[36px]"
                          style={{ color: textColor }}
                        >
                          {category} Supporters
                        </h3>
                      </div>
                    ) : null}
                    <div className={`gap-[30px] md:gap-[40px] items-center justify-center mt-[20px]${postEventState ? ' flex flex-wrap md:grid md:grid-cols-2 xl:grid-cols-3' : ' flex flex-wrap '}`}>
                      {sponsors[category].map((item, index) => (
                        <div
                          key={index}
                          className="relative"
                        >
                          {imageElement(item, category == 'Headline')}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
            {!postEventState &&
            !!finalCallToActionLink &&
            Array.isArray(finalCallToActionText?.richText) &&
            finalCallToActionText.richText[0]?.text && (
              <div className="flex justify-center mt-[60px]">
                <LinkButton
                  className="text-[14px] md:text-[16px] leading-[18px] font-semibold text-white bg-[#E69635] py-[12px] px-[20px] rounded-[5px] hover:bg-[#dc851b]"
                  isPrimary
                  label={finalCallToActionText}
                  link={finalCallToActionLink}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ConferenceDataBodyConferenceSponsors on PrismicDigitalSustainabilityConferenceDataBodyConferenceSponsors {
    primary {
      title {
        text
      }
      description {
        richText
      }
      call_to_action_text {
        richText
      }
      call_to_action_link {
        url
        target
        type
        uid
        slug
        link_type
      }
      post_event_state
      post_event_title {
        text
      }
      post_event_description {
        richText
      }
      post_event_call_to_action_text {
        richText
      }
      post_event_call_to_action_link {
        url
        target
        type
        uid
        slug
        link_type
      }
      background_color
    }
    items {
      sponsor_image {
        alt
        gatsbyImageData
        url
      }
      website {
        url
      }
      sponsor_type
    }
  }
`

export default ConferenceSponsors;
