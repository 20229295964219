import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from '@reach/router';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Cookies from 'universal-cookie';

import Loader from '../Loader';

const renderField = ({ articleNewsletter, field, themeLight, hideLabels, large, values}) => {
  switch(field.fieldType) {
    case 'booleancheckbox':
      return (
        <div key={field.name}>
          <p
            className="text-gray-500 text-xs"
            dangerouslySetInnerHTML={{__html: field.description}}
          />
          <label className="flex flex-row my-3 lg:w-11/12">
            <Field
              type="checkbox"
              name={field.name}
            >
              {({
                field, // { name, value, onChange, onBlur }
                form: { touched, errors, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                meta,
              }) => (
                <div className="self-center flex-shrink-0 w-7">
                  <input
                    {...field}
                    className="text-primary-900 w-4 h-4 mr-2 focus:ring-primary-900 focus:ring-opacity-25 border border-gray-300 rounded" 
                    type="checkbox"
                  />
                </div>
              )}
            </Field>
            <span
              className="text-xs text-gray-700"
              dangerouslySetInnerHTML={{__html: field.label}}
            />
            {values[field.name]}
          </label>
        </div>
      )
    case "select":
      return (
        <div key={field.name}>
          <label
            className={`text-gray-700 mb-2 block text-sm${field.labelHidden || hideLabels ? ' hidden' : ''}`}
            dangerouslySetInnerHTML={{ __html: field.label }}
            htmlFor={field.name}
          />
          <Field
            as="select"
            className={`
              ${themeLight ?
                'text-white border-white bg-transparent placeholder-white' :
                'border-gray-500'}
              ${large ? 'p-4' : 'p-2'}
              mb-4 rounded w-full border pr-6
            `}
            name={field.name}
          >
            <option value=""></option>
            {field.options.map((option, index) => (
              !option.hidden && (
                <option
                  key={option.value}
                  value={option.value}
                >
                    {option.label}
                </option>
              )
            ))}
          </Field>
          <ErrorMessage
            name={field.name}
            render={msg => (
              <span className={`block pb-2 text-red-600 ${large ? 'text-base' : 'text-sm'}`}>
                {msg}
              </span>
            )}
          />
        </div>
      )
    default:
      return (
        <div
          key={field.name}
          className="relative"
        >
          <label
            className={`text-gray-700 mb-2 block text-sm${field.labelHidden || hideLabels ? ' hidden' : ''}`}
            dangerouslySetInnerHTML={{ __html: field.label }}
            htmlFor={field.name}
          />
          <Field
            className={`${
              articleNewsletter ? 'text-[16px] leading-[18px] py-[18.5px] pl-[20px] pr-[121px] border-[#D7DADF] rounded-[8px]' : 'mb-4 rounded'}${
              large ? ' p-4' : ' p-2'}${
              themeLight ? ' text-white border-white bg-transparent placeholder-white' : ' border-gray-500'} border w-full`}
            name={field.name}
            placeholder={field.placeholder}
            type={field.name === 'email' ? 'email' : field.fieldType}
          />
          <ErrorMessage
            name={field.name}
            render={msg => (
              <span className={`pb-2 text-red-600${
                articleNewsletter ? ' absolute top-[110%] left-0' : ' block'}${
                large ? ' text-base' : ' text-sm'}`}
              >
                {msg}
              </span>
            )}
          />
        </div>
      )
  }
};

export const HubspotFormBuilder = ({ articleNewsletter, id, themeLight, large, hideLabels }) => {
  const cookies = new Cookies();
  const location = useLocation();

  const [allForms, setAllForms] = useState([]);
  const [allFormFields, setAllFormFields] = useState(null);
  const [currentForm, setCurrentForm] = useState(null);
  const [fieldGroups, setFieldGroups] = useState(null);
  const [formError, setFormError] = useState('');
  const [initialValues, setInitialValues] = useState({});
  const [loadingError, setLoadingError] = useState('');
  const [loadingForm, setLoadingForm] = useState(false);
  const [metaConsent, setMetaConsent] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [validationSchema, setValidationSchema] = useState(null);

  useEffect(() => {
    setLoadingForm(true);

    fetch('/api/get-hubspot-forms', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (!response.ok) throw Error;

        return response.json();
      })
      .then((response) => {
        setLoadingForm(false);

        const { results } = response || {};

        if (Array.isArray(results) && results.length) setAllForms(results);
      })
      .catch(() => {
        setLoadingForm(false);

        setLoadingError('There was an error loading the Newsletter form. Please refresh the page or try again later.')
      })
  }, []);

  useEffect(() => {
    if (Array.isArray(allForms) && allForms.length) setCurrentForm(allForms.find(form => form.id === id))
  }, [allForms]);

  useEffect(() => {
    if (currentForm) {
      setFieldGroups(currentForm.fieldGroups);
      setMetaConsent(currentForm.legalConsentOptions)
    }
  }, [currentForm]);

  useEffect(() => {
    if (Array.isArray(fieldGroups) && fieldGroups.length) {
      setAllFormFields(fieldGroups.map(group => group.fields).flat());
    }
  }, [fieldGroups]);

  useEffect(() => {
    if (Array.isArray(allFormFields) && allFormFields.length) {
      allFormFields.map((field) => {
        switch(field.fieldType) {
          case "booleancheckbox":
            return setInitialValues(prevState => ({
              ...prevState,
              [field.name]: field.selectedOptions[0] === 'true'
            }));
          default:
            return setInitialValues(prevState => ({
              ...prevState,
              [field.name]: field.defaultValue || ""
            }));
        }
      });

      const validations = {};

      allFormFields.forEach((field) => {
        let fieldValidation = {};

        if (field.fieldType === 'string' || field.fieldType === 'text' || field.fieldType === 'email') {
          fieldValidation = Yup.string();
        }

        if (field.fieldType === 'select') {
          fieldValidation = Yup.string();
        }

        if (field.fieldType === 'booleancheckbox') {
          fieldValidation = Yup.bool();
        }

        if (field.name === 'email') {
          fieldValidation = fieldValidation.email('Please enter a valid email address.');
        }

        if (field.required) {
          fieldValidation = fieldValidation.required('This field is required.');
        }

        validations[field.name] = fieldValidation;
      });

      setValidationSchema(Yup.object().shape(validations));
    }
  }, [allFormFields])

  const submitHandler = async (values, { setSubmitting, resetForm }) => {
    const fields = Object.keys(values).map((key) => ({ name: key, value: values[key] }));

    const data = {
      submittedAt: Date.now(),
      fields,
      context: {
        hutk: cookies.get('hubspotutk'),
        pageUri: `${location?.href}`
      }
    };

    // if (metaConsent?.processingConsentType !== "IMPLICIT") {
      // Now submission must be extended with this
      // "legalConsentOptions": {
      //   "consent": { // Include this object when GDPR options are enabled
      //     "consentToProcess": true,
      //     "text": "I agree to allow Example Company to store and process my personal data.",
      //     "communications": [
      //       {
      //         "value": true,
      //         "subscriptionTypeId": 999,
      //         "text": "I agree to receive marketing communications from Example Company."
      //       }
      //     ]
      //   }
    // }

    return fetch('/api/submit-hubspot-form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        formId: currentForm?.id,
        formData: data
      })
    })
      .then((response) => {
        if (!response.ok) throw Error;

        return response.json();
      })
      .then((response) => {
        setSubmitting(false);

        const { inlineMessage } = response || {};

        if (inlineMessage) setSuccessMessage(inlineMessage);

        resetForm();
      })
      .catch((error) => {
        setFormError('The form could not be submitted. Please try again.');
        setSubmitting(false);
      })
  };

  if (formError) {
    return (
      <div className="text-red-600">
        {formError}
      </div>
    )
  }

  if (loadingError) {
    return (
      <div className="text-red-600">
        {loadingError}
      </div>
    )
  }

  if (loadingForm) {
    return (
      <Loader className="text-white mx-auto" />
    )
  }

  return (
    successMessage ?
      (
        <div
          className="text-primary-800 leading-7 rounded-md p-4 bg-white"
          // Necessary to inject message from HubSpot success response
          dangerouslySetInnerHTML={{ __html: successMessage }}
        />
      ) :
      validationSchema && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({ errors, isSubmitting, isValid, values }) => (
            <Form className="relative">
              {Array.isArray(fieldGroups) && !!fieldGroups.length && fieldGroups.map((group, index) => (
                <fieldset
                  key={`form-${id}-group-${index}`}
                  className="p-0"
                >
                  {group.fields.map((field, index) => renderField({ articleNewsletter, field, hideLabels, themeLight, large, values }))}
                </fieldset>
              ))}
              {!articleNewsletter && metaConsent?.privacyText && (
                <div
                  className={`${themeLight ? 'text-white' : ' text-gray-500'} text-xs mb-6`}
                  dangerouslySetInnerHTML={{__html: metaConsent.privacyText}}
                />
              )}
              <button
                className={`${articleNewsletter ? 'absolute top-1/2 -translate-y-1/2 right-[8px] text-white text-[14px] leading-[20px] py-[10px] px-[20px] rounded-[5px] bg-[#527563] cursor-pointer' : 'c-button c-button--primary'}`}
                disabled={isSubmitting || !isValid}
                type="submit"
              >
                {currentForm?.submitText || 'Submit'}
              </button>
            </Form>
          )}
        </Formik>
      )
  );
};
