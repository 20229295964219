import * as React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

import logo_light from '../images/logo_light.png'
import logo_dark from '../images/logo_dark.png'
import LinkBlock from './LinkBlock'

export const Footer = ({ useDarkFooter, children }) => {
  const queryData = useStaticQuery(graphql`
    {
      prismicSiteInfo {
        _previewable
        data {
          social_profiles {
            icon {
              gatsbyImageData(width: 20)
            }
            link {
              url
              target
              link_type
            }
            name
          }
          body {
            ... on PrismicSiteInfoDataBodyFooterLinkBlock {
              id
              items {
                link {
                  link_type
                  url
                  target
                }
                link_label {
                  richText
                }
              }
              primary {
                block_title {
                  richText
                }
              }
            }
          }
        }
      }
    }
  `)

  const { data } = useMergePrismicPreviewData(queryData)
  const doc = data.prismicSiteInfo
  const social_profiles = doc.data.social_profiles
  const blocks = doc.data.body

  return (
    <footer className="my-16 md:my-36 container">
      <div className="md:grid md:grid-cols-12 md:gap-2 lg:gap-4">
        <div className="md:col-span-3 md:mr-8 mb-12 lg:col-span-3">
          <Link to="/">
            <img src={useDarkFooter ? logo_dark : logo_light} alt="SDIA Logo" className="w-20 lg:ml-6" />
          </Link>
        </div>

        {blocks.map((block, index) => (
          <LinkBlock 
            {...block} 
            key={`link-block-${index}`} 
            useDarkFooter={useDarkFooter} 
            className="md:col-span-3 lg:col-span-2 mb-12" 
          />
        ))}

        <div className="md:col-start-4 md:col-span-12 lg:text-right lg:col-end-13 lg:col-start-12 lg:col-span-1 mt-12 md:mt-0 lg:pr-6 w-full">
          {social_profiles.map((profile, index) => {
            const icon = getImage(profile.icon)
            return (
              <a 
                key={`sm-icon-${index}`} 
                href={profile.link.url} 
                target={profile.link.target} 
                className="inline lg:inline-block lg:w-full lg:mb-8 mr-4"
              >
                <GatsbyImage alt={profile.name} image={icon} />
              </a>
            )
          })}
        </div>
      </div>

      <img src="https://api.thegreenwebfoundation.org/greencheckimage/sdialliance.org?nocache=true" alt="This website is hosted Green - checked by thegreenwebfoundation.org"></img>

      {children}
    </footer>
  )
}
