exports.linkResolver = (doc) => {
  if (doc.isBroken) {
    return '/404'
  }

  if (doc && doc.type === 'membership') {
    return `/memberships/${doc.uid}`
  }

  if (doc && doc.type === 'event') {
    return `/events/${doc.uid}`
  }

  if (doc && doc.type === 'organization_member') {
    return `/members/${doc.uid}`
  }

  if (doc && doc.type === 'resource') {
    return `/resources/${doc.uid}`
  }

  if (doc && doc.type === 'steering_group') {
    return `/steering-groups/${doc.uid}`
  }

  if (doc && doc.type === 'landing_page') {
    return `/landing/${doc.uid}`
  }

  if (doc && doc.type === 'page') {
    return `/${doc.uid}`
  }

  if (doc && doc.type === 'roadmap_page') {
    return `/roadmap`
  }

  if (doc && doc.type === 'dictionary_entry') {
    return `/dictionary/${doc.uid}`
  }

  if (doc && doc.type === 'roadmap_activity') {
    return `/${doc.uid}`
  }

  return '/'
}
