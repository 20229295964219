import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ArticleCard = ({
  author,
  categories,
  className,
  contentOrientation,
  excerpt,
  mainImage,
  publicationTime,
  slug,
  title
}) => {
  const authorImage = author?.data?.photo;
  const authorImageGatsby = author?.document?.data?.photo?.gatsbyImageData ? getImage(author.document.data.photo) : null;
  const authorName = author?.document?.data?.full_name?.text ||
    (
      Array.isArray(author?.data?.full_name) &&
      !!author.data.full_name.length && author.data.full_name[0].text
    );
  const category = Array.isArray(categories) &&
    !!categories.length &&
    (
      categories[0].category?.document?.data?.title?.text ||
      (
        Array.isArray(categories[0].category?.data?.title) &&
        !!categories[0].category.data.title.length && categories[0].category.data.title[0].text
      )
    )
  const date = new Date(publicationTime);
  const formattedDate = new Intl.DateTimeFormat('en-GB', { dateStyle: 'medium' }).format(date);
  const image = mainImage;
  const imageGatsby = mainImage?.gatsbyImageData ? getImage(mainImage) : null;

  return (
    <Link
      className={className ? className : null}
      to={`/blog/${slug}`}
    >
      <div className={`flex h-full flex-col items-center sm:pt-[12px] shadow-xl shadow-[#0000000D] rounded-xl bg-white p-[12px] sm:p-[20px]${
        contentOrientation === 'horizontal' ? ' md:flex-row md:items-stretch md:gap-[20px] md:pt-[0px] md:shadow-none md:p-0' : ''}`}
      >
        {(category || !!formattedDate) && (
          <div className={`flex items-center justify-between mb-[12px] w-full${contentOrientation === 'horizontal' ? ' md:hidden' : ''}`}>
            {category && (
              <div className="rounded-[30px] py-[4px] px-[8px] bg-[#38604B1F] text-[14px] text-[#1D4732] leading-[14px]">
                {category}
              </div>
            )}
            {!!formattedDate && (
              <span className="text-[14px] leading-[16px] text-[#5C6372]">
                {formattedDate}
              </span>
            )}
          </div>
        )}
        {(!!imageGatsby || !!image) && (
          <div className={`h-[200px] rounded-[8px] overflow-hidden${
            contentOrientation === 'horizontal' ? ' w-full md:min-w-[250px] md:w-[250px] md:h-[217px] lg:min-w-[411px] lg:w-[411px]' : ' w-full' }`}
          >
            {!!imageGatsby && (
              <GatsbyImage
                alt={mainImage.alt || title}
                className="rounded-[8px] h-full w-full"
                image={imageGatsby}
                objectFit="cover"
              />
            )}
            {!imageGatsby && !!image && (
              <img
                alt={mainImage.alt || title}
                className="rounded-[8px] h-full w-full"
                src={image.url}
                style={{
                  objectFit: "cover"
                }}
              />
            )}
          </div>
        )}
        <div className={`flex flex-col${contentOrientation === 'horizontal' ? ' md:max-w-[457px]' : ' grow w-full'}`}>
          {(category || !!formattedDate) && (
            <div className={`hidden items-center justify-between gap-[20px]${contentOrientation === 'horizontal' ? ' md:flex' : ''}`}>
              {category && (
                <div className="rounded-[30px] py-[4px] px-[8px] bg-[#38604B1F] text-[14px] text-[#1D4732] leading-[14px]">
                  {category}
                </div>
              )}
              {!!formattedDate && (
                <span className="text-[14px] leading-[16px] text-[#5C6372]">
                  {formattedDate}
                </span>
              )}
            </div>
          )}
          {!!title && (
            <h5 className={`text-[20px] leading-[28px] font-semibold font-body line-clamp-2${contentOrientation === 'horizontal' ? ' mt-[20px] md:mt-[12px]' : ' mt-[20px]'}`}>
              {title}
            </h5>
          )}
          {!!excerpt && (
            <p className="text-[16px] leading-[25px] mt-[8px] line-clamp-3">
              {excerpt}
            </p>
          )}
          {(!!authorImage || !!authorImageGatsby || !!authorName) && (
            <div className={contentOrientation === 'horizontal' ? 'md:flex grow' : 'mt-auto'}>
              <div className="flex items-center mt-[12px] self-end">
                {!authorImage && !!authorImageGatsby && (
                  <div className="rounded-full h-[32px] w-[32px]">
                    <GatsbyImage
                      alt={authorImageGatsby.alt || authorName || ''}
                      className="rounded-full h-full w-full"
                      image={authorImageGatsby}
                      objectFit="cover"
                      objectPosition="top"
                    />
                  </div>
                )}
                 {!authorImageGatsby && !!authorImage && (
                  <div className="rounded-full h-[32px] w-[32px]">
                    <img
                      alt={authorImage.alt || authorName || ''}
                      className="rounded-full h-full w-full"
                      src={authorImage.url}
                      style={{
                        objectFit: "cover",
                        objectPosition: "top"
                      }}
                    />
                  </div>
                )}
                {!!authorName && (
                  <div className="text-[16px] leading-[19px] text-[#1B202B] ml-[12px]">
                    {authorName}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

ArticleCard.propTypes = {
  author: PropTypes.shape({
    document: PropTypes.shape({
      data: PropTypes.shape({
        full_name: PropTypes.shape({
          text: PropTypes.string
        }),
        photo: PropTypes.shape({
          alt: PropTypes.string,
          gatsbyImageData: PropTypes.shape({
            height: PropTypes.number,
            layout: PropTypes.string,
            width: PropTypes.number
          })
        })
      })
    })
  }),
  categories: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.shape({
      document: PropTypes.shape({
        data: PropTypes.shape({
          title: PropTypes.shape({
            text: PropTypes.string
          })
        })
      })
    })
  })),
  className: PropTypes.string,
  contentOrientation: PropTypes.oneOf(['horizontal', 'vertical']),
  mainImage: PropTypes.shape({
    alt: PropTypes.string
  }),
  publicationTime: PropTypes.string,
  title: PropTypes.string
};

ArticleCard.defaultProps = {
  author: null,
  categories: null,
  className: null,
  contentOrientation: 'vertical',
  mainImage: null,
  publicationTime: null,
  title: null
};

export default ArticleCard;
