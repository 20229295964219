import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { linkResolver } from '../utils/LinkResolver.js';

const PositionListOfIndividualMembers = ({ slice }) => {
  const { description, title } = slice?.primary || {};
  const { items } = slice || {};

  if (!Array.isArray(items) || !items.length) return null;

  return (
    <section className="container mt-[60px] md:mt-[140px]">
      <div className="text-center max-w-[800px] mx-auto">
        <h2 className="font-body font-semibold text-[24px] md:text-[40px] text-[#1B202B] leading-[25px] md:leading-[49px]">
          {title.text}
        </h2>
        {Array.isArray(description?.richText) && description.richText[0]?.text && (
          <div className="text-[18px] md:text-[20px] leading-[24px] md:leading-[29px] text-[#1B202B] mx-auto mt-[20px]">
            <RichText
              linkResolver={linkResolver}
              render={description.richText}
            />
          </div>
        )}
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-[12px] md:gap-[24px] mt-[40px] md:mt-[60px]">
        {items.map((item, index) => {
          const image = getImage(item.photo);

          return (
            <div
              key={`member-${index}`}
              className="flex flex-col justify-center rounded-md bg-white p-[12px] sm:p-[20px] shadow-md"
            >
              {image ?
                (
                  <GatsbyImage
                    alt={image.alt || ''}
                    className="rounded-md h-[135px] md:h-[250px]"
                    image={image}
                    objectFit="cover"
                  />
                ) :
                (
                  <div className="rounded-md bg-gray-200 h-full w-full" />
                )
              }
              <div>
                {!!item.name?.text && (
                  <span className="block font-body font-normal text-[16px] leading-[18px] sm:text-[18px] sm:leading-[21px] mt-[12px] md:mt-[20px] mb-[4px] md:mb-[8px]">
                    {item.name.text}
                  </span>
                )}
                {!!item.position && Array.isArray(item.position.richText) && item.position.richText[0]?.text?.length > 0 && (
                  <span className="block font-body font-normal text-[#777785] text-[14px] leading-[16px]">
                    {item.position.richText[0].text}
                  </span>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </section>
  );
};

export const query = graphql`
  fragment PositionDataBodyPositionListOfIndividualMembers on PrismicPositionDataBodyPositionListOfIndividualMembers {
    items {
      position {
        richText
      }
      photo {
        gatsbyImageData
      }
      name {
        text
      }
    }
    primary {
      description {
        richText
      }
      title {
        text
      }
    }
  }
`;

export default PositionListOfIndividualMembers;
