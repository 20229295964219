import React from 'react';
import PropTypes from 'prop-types';

import ArticleCard from '../components/ArticleCard';

const ArticlesList = ({ articles, className }) => {
  if (!Array.isArray(articles) || !articles.length) return null;

  return (
    <section className={className ? className : null}>
      <div className="flex flex-col gap-[20px]">
        {articles.map(item => (
          <ArticleCard
            key={item.slug || item.uid}
            author={item.data.author}
            categories={item.data.categories}
            className="border-b last:border-0 border-[#D7DADF] pb-[20px]"
            contentOrientation="horizontal"
            excerpt={item.data.excerpt.text}
            mainImage={item.data.main_image}
            publicationTime={item.data.publication_time}
            slug={item.slug || item.uid}
            title={item.data.title.text}
          />
        ))}
      </div>
    </section>
  );
};

ArticlesList.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.shape({
      author: PropTypes.shape({
        document: PropTypes.shape({
          data: PropTypes.shape({
            full_name: PropTypes.shape({
              text: PropTypes.string
            }),
            photo: PropTypes.shape({
              alt: PropTypes.string,
              gatsbyImageData: PropTypes.shape({
                height: PropTypes.number,
                layout: PropTypes.string,
                width: PropTypes.number
              })
            })
          })
        })
      }),
      categories: PropTypes.arrayOf(PropTypes.shape({
        category: PropTypes.shape({
          document: PropTypes.shape({
            data: PropTypes.shape({
              title: PropTypes.shape({
                text: PropTypes.string
              })
            })
          })
        })
      })),
      excerpt: PropTypes.shape({
        text: PropTypes.string
      }),
      main_image: PropTypes.shape({
        alt: PropTypes.string
      }),
      publication_time: PropTypes.string,
      title: PropTypes.shape({
        text: PropTypes.string
      })
    }),
    uid: PropTypes.string
  })),
  className: PropTypes.string
};

ArticlesList.defaultProps = {
  articles: null,
  className: null
};

export default ArticlesList;
