import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import LinkButton from '/src/components/LinkButton.js';

import { linkResolver } from '/src/utils/LinkResolver.js';
import getCorrectTextColor from '/src/utils/SetTextColorDependingOnBackground';

const AnnualReportHero = ({ slice }) => {
  const data = slice.primary;
  const background = getImage(data.background_image);

  if (!data.title || !data.year || !data.description) return null;

  const textColor = getCorrectTextColor(data?.background_color);

  return (
    <section
      className="relative pb-[80px] sm:pb-[120px] pt-[150px] sm:pt-[199px]"
      style={{ backgroundColor: data.background_color || '#ffffff' }}
    >
      <GatsbyImage
        alt={data.background_image.alt || ''}
        className="absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 h-[calc(100%-78px)] w-full hidden z-0 mt-[40px] sm:flex"
        objectFit="contain"
        image={background}
        layout="fullWidth"
      />
      <div className="relative container-small text-center max-w-[530px] z-10">
        <h2
          className="font-body font-bold text-[32px] sm:text-[44px] md:text-[70px] leading-[37px] sm:leading-[57px] md:leading-[82px]"
          style={{ color: textColor }}
          >
          {RichText.asText(data.title.richText)}
        </h2>
        <div
          className="text-[145px] md:text-[185px] leading-[110px] md:leading-[140px] font-bold font-display my-[32px]"
          style={{ color: textColor }}
        >
          {data.year}
        </div>
        <div
          className="leading-relaxed"
          style={{ color: textColor }}
          >
          <RichText
            linkResolver={linkResolver}
            render={data.description.richText}
          />
        </div>
        {!!data.link && Array.isArray(data?.call_to_action?.richText) && data.call_to_action.richText[0]?.text && (
          <LinkButton
            className="text-[16px] sm:text-[18px] leading-[18px] font-semibold mt-5"
            isWarning
            label={data.call_to_action}
            link={data.link}
          />
        )}
      </div>
    </section>
  );
};

export const query = graphql`
  fragment AnnualReportDataBodyAnnualReportHero on PrismicAnnualReportDataBodyAnnualReportHero {
    primary {
      background_color
      background_image {
        alt
        url
        gatsbyImageData
      }
      call_to_action {
        richText
      }
      description {
        richText
      }
      link {
        url
        target
        type
        uid
        slug
        link_type
      }
      title {
        richText
      }
      year
    }
  }
`;

export default AnnualReportHero;
