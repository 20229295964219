import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { linkResolver } from '../utils/LinkResolver.js';
import getCorrectTextColor from '/src/utils/SetTextColorDependingOnBackground';

import ModalBootstrap from '../components/ModalBootstrap.js';


const ConferenceSpeakers = ({ slice }) => {
  const { anchor_id, title, tagline } = slice.primary;
  const { items } = slice;

  const [selectedSpeakerBio, setSelectedSpeakerBio] = useState(null);
  const [selectedSpeakerName, setSelectedSpeakerName] = useState(null);
  const [showSpeakerBioModal, setShowSpeakerBioModal] = useState(false);

  if (!title || !Array.isArray(items) || !items.length) return null;

  const handleSpeakerBioModalClose = () => {
    setShowSpeakerBioModal(false);
  }

  const handleSpeakerImageClick = (speakerInfo) => {
    const { bio, name } = speakerInfo || {};

    if (bio) setSelectedSpeakerBio(bio);
    if (name) setSelectedSpeakerName(name);

    setShowSpeakerBioModal(true);
  }

  const backgroundColor = slice.primary.background_color;
  const textColor = getCorrectTextColor(backgroundColor);

  return (
    <section
      className={`relative ${backgroundColor ? 'py-[80px]' : 'my-[80px] md:my-[140px] scroll-mt-[80px]'}`}
      id={anchor_id ?? null}
      style={{ backgroundColor: backgroundColor ? `${backgroundColor}` : '#FFFFFF'}}
    >
      <div className="container">
        <div className="max-w-[800px] mx-auto">
          {Array.isArray(tagline?.richText) && tagline.richText[0]?.text && (
            <div
              className="container uppercase text-center text-[16px] md:text-[20px] leading-[20px] md:leading-[36px] text-[#777785] mb-[20px]"
              style={{ color: textColor }}
            >
              <RichText
                linkResolver={linkResolver}
                render={tagline.richText}
              />
            </div>
          )}
          <h2
            className="font-body font-semibold text-center text-[32px] md:text-[40px] text-[#1B202B] leading-[37px] md:leading-[44px]"
            style={{ color: textColor }}
          >
            {title.text}
          </h2>
        </div>
        <div className="mt-[60px]">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-x-[32px] gap-y-[60px] justify-center md:px-[53px]">
            {items.map((item, index) => {
              const organisationImage = getImage(item.organisation_image);
              const speakerImage = getImage(item.speaker_image);
              const speakerInfo = {
                bio: Array.isArray(item?.speaker_bio?.richText) && item.speaker_bio.richText[0]?.text ? item.speaker_bio.richText : null,
                name: item.speaker_name?.text ? item.speaker_name.text : null
              };

              return (
                <div
                  key={index}
                  className="relative"
                >
                  {!!speakerImage && (
                    <GatsbyImage
                      alt={item.speaker_image.alt || ''}
                      className="block h-[136px] w-[136px] rounded-full mx-auto"
                      image={speakerImage}
                    />
                  )}
                  {!!item.speaker_name?.text && (
                    <div
                      role="button"
                      onClick={() => handleSpeakerImageClick(speakerInfo)}
                    >
                      <h3
                        className={`font-body font-semibold text-center text-[#1B202B] text-[18px] md:text-[24px] leading-[28px] md:leading-[36px] mt-[20px]${
                          !!speakerInfo?.bio ? ' hover:cursor-pointer hover:text-[#487662] transition-all' : ' hover:cursor-default'}`}
                        style={{ color: textColor }}
                      >
                        {item.speaker_name.text}
                      </h3>
                    </div>
                  )}
                  {Array.isArray(item?.speaker_role?.richText) && item.speaker_role.richText[0]?.text && (
                    <div
                      className="font-normal text-center text-[#777785] text-[16px] md:text-[18px] leading-[24px] md:leading-[27px]"
                      style={{ color: textColor }}
                    >
                      <RichText
                        linkResolver={linkResolver}
                        render={item.speaker_role.richText}
                      />
                    </div>
                  )}
                  {!!organisationImage && (
                    <GatsbyImage
                      alt={item.speaker_image.alt || ''}
                      className="block h-auto w-[80px] mt-[20px] mx-auto"
                      image={organisationImage}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {!!selectedSpeakerBio && (
        <ModalBootstrap
          showModal={showSpeakerBioModal}
          title={selectedSpeakerName}
          onClose={handleSpeakerBioModalClose}
        >
          <div className="prose leading-6">
            <RichText
              linkResolver={linkResolver}
              render={selectedSpeakerBio}
            />
          </div>
        </ModalBootstrap>
      )}
    </section>
  );
};

export const query = graphql`
  fragment ConferenceDataBodyConferenceSpeakers on PrismicDigitalSustainabilityConferenceDataBodyConferenceSpeakers {
    primary {
      title {
        text
      }
      tagline {
        richText
      }
      background_color
    }
    items {
      speaker_image {
        alt
        gatsbyImageData
        url
      }
      speaker_bio {
        richText
      }
      speaker_name {
        text
      }
      speaker_role {
        richText
      }
      organisation_image {
        gatsbyImageData
        alt
        url
      }
    }
  }
`

export default ConferenceSpeakers;
