import React, { useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import { linkResolver } from '/src/utils/LinkResolver.js';
import getCorrectTextColor from '/src/utils/SetTextColorDependingOnBackground';

import CalendarModal from '../components/CalendarModal';

const ConferenceCalendar = ({ slice, timezone }) => {
  const data = slice.primary || {};
  const { items } = slice || [];

  const [entryModalContent, setEntryModalContent] = useState(null);
  const [showModal, setShowModal] = useState(null);

  const backgroundColor = data?.background_color;
  const textColor = getCorrectTextColor(backgroundColor);

  const days = items.map(item => {
    const day = item?.days?.document?.data;
    const { body, title, date } = day || [];

    let entries = [];

    if (Array.isArray(body)) entries = body.flatMap(entry => Array.isArray(entry?.items) && entry.items);

    const entriesWithModalContent = entries.map(entry => {
      const content = entry?.calendar_modal_document?.document?.data;

      const modalBody = content?.body;
      const modalTitle = content?.title;

      if (entry.interactive && modalTitle && Array.isArray(modalBody)) {
        return {
          ...entry,
          modalContent: {
            items: modalBody,
            title: modalTitle
          }
        }
      }

      return entry;
    })

    return {
      title: title?.text,
      date,
      entries: entriesWithModalContent
    };
  });

  const handleCalendarEntryClick = (content) => {
    setEntryModalContent(null);

    if (content) {
      setEntryModalContent(content);
      setShowModal(true);
    }
  };

  const handleCalendarEntryKeyDown = (event, content) => {
    // Only on enter press
    if (event.keyCode = 13) {
      setEntryModalContent(null);

      if (content) {
        setEntryModalContent(content);
        setShowModal(true);
      }
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const timezoneValue = useMemo(() => {
    if (timezone) {
      // Map the API value to a valid IANA timezone
      const timezoneMap = {
        'GMT': 'Europe/London',
        'GMT+1': 'Europe/Amsterdam',
        'GMT+2': 'Europe/Athens'
      };

      return timezoneMap[timezone];
    }
  }, [timezone]);

  if (!data.title || !Array.isArray(items) || !items.length) return null;

  return (
    <section
      id={data.anchor_id ?? null}
      className={`relative ${backgroundColor ? 'py-[80px]' : 'my-[80px] md:my-[140px] scroll-mt-[80px]'}`}
      style={{ backgroundColor: backgroundColor ? `${backgroundColor}` : '#FFFFFF'}}
    >
      <div className="container">
        <div className="text-center max-w-[800px] mx-auto">
          <h2
            className="font-body font-semibold text-[32px] md:text-[40px] text-[#1B202B] leading-[37px] md:leading-[44px]"
            style={{ color: textColor }}
          >
            {data.title.text}
          </h2>
          {Array.isArray(data.description?.richText) && data.description.richText[0]?.text && (
            <div
              className="text-[18px] md:text-[20px] leading-[24px] md:leading-[28px] text-[#1B202B] mx-auto mt-[20px]"
              style={{ color: textColor }}
            >
              <RichText
                linkResolver={linkResolver}
                render={data.description.richText}
              />
            </div>
          )}
        </div>
        {Array.isArray(days) && !!days.length && (
          <div className={`grid grid-cols-1 gap-[32px] mt-[60px] max-w-2xl ml-auto mr-auto md:grid-cols-${
            days.length > 1 ? 2 : days.length} lg:grid-cols-${
            days.length > 2 ? 3 : days.length}`}>
            {days.map((item, index) => {
              const dateOptions = { day: 'numeric', month: 'long', timeZone: timezoneValue };
              const date = new Intl.DateTimeFormat([], dateOptions);
              const formattedDate = date.format(new Date(item.date));

              return (
                <div key={index}>
                  <div className="bg-[#385F4B] rounded-[12px] p-[12px] mb-[12px]">
                    <div className="text-center text-[20px] leading-[36px] text-[#F2F2F5]">
                      {formattedDate}
                    </div>
                    <div className="text-center text-[20px] leading-[36px] text-[#F2F2F5]">
                      {item.title}
                    </div>
                  </div>
                  {Array.isArray(item.entries) && !!item.entries.length && (
                    <div className="flex flex-col gap-[12px]">
                      {item.entries.map((entry, index) => {
                        let endDateString = null;
                        let startDateString = null;

                        const dateTimeOptions = { hour12: false, hour: '2-digit', minute: '2-digit', timeZone: timezoneValue };
                        const dateTime = new Intl.DateTimeFormat([], dateTimeOptions);

                        if (entry.end_date) endDateString = dateTime.format(new Date(entry.end_date));
                        startDateString = dateTime.format(new Date(entry.start_date));

                        return (
                          <div
                            key={index}
                            className={`flex items-start gap-[12px] rounded-[12px] p-[8px] bg-white drop-shadow-md${entry.interactive ? ' border border-[#527563] outline-none focus-visible:outline-[#527563]' : ''}`}
                            role={entry.interactive && entry.modalContent ? 'button' : null}
                            tabIndex={entry.interactive && entry.modalContent ? 0 : -1}
                            onClick={entry.interactive && entry.modalContent ? () => handleCalendarEntryClick(entry.modalContent) : null}
                            onKeyDown={entry.interactive && entry.modalContent ? (event) => handleCalendarEntryKeyDown(event, entry.modalContent) : null}
                          >
                            <div className="flex items-center justify-center rounded-[8px] w-[120px] p-[11.5px] bg-[#F2F2F5]">
                              <div className="text-[16px] leading-[20px] text-[#385F4B]">
                                {startDateString}
                              </div>
                              &nbsp;
                              {!!endDateString && (
                                <>
                                  <div className="text-[16px] leading-[20px] text-[#385F4B]">
                                    -
                                  </div>
                                  &nbsp;
                                  <div className="text-[16px] leading-[20px] text-[#385F4B]">
                                    {endDateString}
                                  </div>
                                </>
                              )}
                            </div>
                            {Array.isArray(entry.text?.richText) && entry.text.richText[0]?.text && (
                              <div className="text-[16px] prose self-center leading-[20px] break-words text-[#1B202B]">
                                <RichText
                                  linkResolver={linkResolver}
                                  render={entry.text.richText}
                                />
                              </div>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        )}
      </div>
      <CalendarModal
        content={entryModalContent}
        showModal={showModal}
        onClose={handleModalClose}
      />
    </section>
  );
};

export const query = graphql`
  fragment ConferenceDataBodyConferenceCalendar on PrismicDigitalSustainabilityConferenceDataBodyConferenceCalendar {
    primary {
      title {
        text
      }
      description {
        richText
      }
      background_color
    }
    items {
      days {
        document {
          ... on PrismicDigitalSustainabilityConferenceDays {
            data {
              title {
                text
              }
              date
              body {
                ... on PrismicDigitalSustainabilityConferenceDaysDataBodyConferenceDayEntry {
                  items {
                    end_date
                    start_date
                    text {
                      richText
                    }
                    interactive
                    calendar_modal_document {
                      document {
                        ... on PrismicDigitalSustainabilityConferenceCalendarModal {
                          data {
                            body {
                              ... on PrismicDigitalSustainabilityConferenceCalendarModalDataBodyCalendarStartAndEndTime {
                                items {
                                  end_date
                                  start_date
                                  add_break_after
                                }
                                slice_type
                              }
                              ... on PrismicDigitalSustainabilityConferenceCalendarModalDataBodyCalendarTopic {
                                items {
                                  item_text {
                                    richText
                                  }
                                }
                                primary {
                                  topic_title {
                                    text
                                  }
                                }
                                slice_type
                              }
                            }
                            title {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ConferenceCalendar;
