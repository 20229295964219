const getCorrectTextColor = (hex) => {
  if (hex) {
    const threshold = 180; /* Lower threshold equals more dark text on dark background */

  const cutHex = (h) => (h.charAt(0) === "#") ? h.substring(1,7):h;
  const hexToR = (h) => parseInt((cutHex(h)).substring(0,2),16);
  const hexToG = (h) => parseInt((cutHex(h)).substring(2,4),16);
  const hexToB = (h) => parseInt((cutHex(h)).substring(4,6),16);
  
  const hRed = hexToR(hex);
  const hGreen = hexToG(hex);
  const hBlue = hexToB(hex);

    const cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;

    if (cBrightness > threshold) return '#1A202C';
    return '#ffffff';
  }

  return '#ffffff';
};

export default getCorrectTextColor;
