import * as React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import LinkButton from '/src/components/LinkButton.js';
import { linkResolver } from '../utils/LinkResolver.js';
import getCorrectTextColor from '/src/utils/SetTextColorDependingOnBackground';


const ConferenceCallToActionsAndImage = ({ slice }) => {
  const data = slice.primary;

  if (!data?.title?.text) return null;

  const backgroundColor = data.background_color;
  const image = getImage(data.image);
  const textColor = getCorrectTextColor(backgroundColor);

  return (
    <section
      className={`relative ${backgroundColor ? 'py-[80px]' : 'my-[80px] md:my-[140px] scroll-mt-[80px]'}`}
      id={data.anchor_id ?? null}
      style={{ backgroundColor: backgroundColor ? `${backgroundColor}` : '#FFFFFF'}}
    >
      <div className="container">
        <div className="text-center mx-auto max-w-[835px]">
          <h2
            className="font-body font-semibold text-[32px] md:text-[40px] text-[#1B202B] leading-[37px] md:leading-[44px]"
            style={{ color: textColor }}
          >
            {data.title.text}
          </h2>
          {Array.isArray(data.description?.richText) && data.description.richText[0]?.text && (
            <div
              className="text-[18px] md:text-[20px] leading-[24px] md:leading-[28px] text-[#1B202B] mx-auto mt-[20px]"
              style={{ color: textColor }}
            >
              <RichText
                linkResolver={linkResolver}
                render={data.description.richText}
              />
            </div>
          )}
          <div className="flex justify-center gap-[20px] mt-[32px]">
            {!!data?.secondary_call_to_action_link &&
            Array.isArray(data.secondary_call_to_action_text?.richText) &&
            data.secondary_call_to_action_text.richText[0]?.text && (
              <LinkButton
                className="text-[14px] md:text-[16px] leading-[18px] font-semibold py-[12px] px-[20px] rounded-[5px]"
                isWarning
                label={data.secondary_call_to_action_text}
                link={data.secondary_call_to_action_link}
              />
            )}
            {!!data?.primary_call_to_action_link &&
            Array.isArray(data.primary_call_to_action_text?.richText) &&
            data.primary_call_to_action_text.richText[0]?.text && (
              <LinkButton
                className="text-[14px] md:text-[16px] leading-[18px] font-semibold text-white bg-[#E69635] py-[12px] px-[20px] rounded-[5px] hover:bg-[#dc851b]"
                isPrimary
                label={data.primary_call_to_action_text}
                link={data.primary_call_to_action_link}
              />
            )}
          </div>
        </div>
        {!!image && (
          <GatsbyImage
            alt={data.image.alt || ''}
            className="block h-[200px] md:h-[448px] w-full mx-auto mt-[60px]"
            image={image}
          />
        )}
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ConferenceDataBodyConferenceCallToActionsAndImage on PrismicDigitalSustainabilityConferenceDataBodyConferenceCallToActionsAndImage {
    primary {
      title {
        text
      }
      description {
        richText
      }
      secondary_call_to_action_text {
        richText
      }
      secondary_call_to_action_link {
        url
        target
        type
        uid
        slug
        link_type
      }
      primary_call_to_action_text {
        richText
      }
      primary_call_to_action_link {
        url
        target
        type
        uid
        slug
        link_type
      }
      image {
        alt
        url
        gatsbyImageData
      }
      background_color
    }
  }
`

export default ConferenceCallToActionsAndImage;
