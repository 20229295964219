import React, { useEffect, useState } from 'react';
import { RichText } from 'prismic-reactjs';

import getCorrectTextColor from '/src/utils/SetTextColorDependingOnBackground';

import ModalSlider from '/src/components/ModalSlider';
import SteeringGroupWhiteWithColoredBorder from '/src/components/SteeringGroupWhiteWithColoredBorder';

const SteeringGroupsGrid = ({ allGroups, background_color, subtitle, title }) => {
  const cardsData = Array.isArray(allGroups) ?
    allGroups.map(group => {
      const topics = Array.isArray(group?.body) ?
        group.body.find(slice => Array.isArray(slice?.items) && slice.items.some(item => item?.topic_title))?.items :
        null;

      return {
        ...group,
        call_to_action: group?.call_to_action?.richText,
        color: group?.color,
        icon: group?.icon_dark,
        description: group?.roadmap_activity?.document?.data?.name?.richText,
        link: group?.subject?.document?.data?.link,
        title: group?.subject?.document?.data?.name?.richText,
        topics: Array.isArray(topics) ? topics : []
      };
    }) :
    [];

  const [activeSlideIndex, setActiveSlideIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleItemClick = (index) => {
    setActiveSlideIndex(index);
  };

  const handleSlideChange = (index) => {
    setActiveSlideIndex(index);
  };

  useEffect(() => {
    if (typeof activeSlideIndex === 'number') setShowModal(true);
  }, [activeSlideIndex]);

  useEffect(() => {
    if (!showModal) {
      // We need a timeout to prevent the modal content to disappear (because we are setting the index to null) before the modal is close
      setTimeout(() => {
        setActiveSlideIndex(null);
      }, 10)
    };
  }, [showModal]);

  const textColor = getCorrectTextColor(background_color);

  if (!Array.isArray(allGroups) || !allGroups.length) return null;

  return (
    <section
      className="py-[60px] md:py-[120px]"
      style={{ backgroundColor: background_color || '#ffffff' }}
    >
      <div className="container">
        <div className="container-medium">
          {Array.isArray(title?.richText) && title.richText[0]?.text?.length > 0 && (
            <h2
              className="font-bold text-center text-[32px] sm:text-[40px] leading-[37px] sm:leading-[49px]"
              style={{ color: textColor }}
            >
              {RichText.asText(title.richText)}
            </h2>
          )}
          {Array.isArray(subtitle?.richText) && subtitle.richText[0]?.text?.length > 0 && (
            <p
              className="text-xl font-light font-display mt-3 text-center"
              style={{ color: textColor }}
            >
              {RichText.asText(subtitle.richText)}
            </p>
          )}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-[60px]">
          {allGroups.map((group, index) => (
            <SteeringGroupWhiteWithColoredBorder
              key={`steering-group-${index}`}
              onClick={() => handleItemClick(index)}
              {...group}
            />
          ))}
        </div>
      </div>
      <ModalSlider
        activeSlideIndex={activeSlideIndex}
        showModal={showModal}
        slides={cardsData}
        onClose={handleModalClose}
        onSlideChange={handleSlideChange}
      />
    </section>
  );
};

export default SteeringGroupsGrid;
