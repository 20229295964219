import * as React from 'react'
import { getImage } from "gatsby-plugin-image"
import Resource from './Resource'

const FeaturedResource = ({ resource, resource_type, link, preview_image, title }) => {
  let resourceDoc = null

  if (resource && resource.document)
    resourceDoc = { ...resource.document.data, url: resource.document.url }

  // either take the one specified in the slice or the one from the resource as backup
  let previewImage
  if (preview_image && preview_image.gatsbyImageData) {
    previewImage = getImage(preview_image)
  } else {
    previewImage = getImage(resourceDoc.preview_image)
  }

  const actualResource = (resource_type === 'Resource' && resourceDoc) ? 
    resourceDoc.resource : 
    link

  return (
    <Resource 
      {...resourceDoc}
      resource_type={resource_type}
      preview_image={previewImage}
      resource={actualResource}
      url={actualResource.url}
      name={(title && title.richText.length > 0) ? title : resourceDoc.name}
    />
  )
}

export default FeaturedResource
