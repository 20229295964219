// gatsby-ssr.js and gatsby-browser.js files

import * as React from 'react'
import { PrismicPreviewProvider, componentResolverFromMap } from 'gatsby-plugin-prismic-previews'
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form'
import { linkResolver } from './src/utils/LinkResolver'

import HomeTemplate from './src/pages/index'
import PageTemplate from './src/pages/{PrismicPage.uid}'
import EventTemplate from './src/pages/events/{PrismicEvent.uid}'
import MembershipTemplate from './src/pages/memberships/{PrismicMembership.uid}'
import OrganizationMemberTemplate from './src/pages/members/{PrismicOrganizationMember.uid}'
import SteerinGroupTemplate from './src/pages/steering-groups/{PrismicSteeringGroup.uid}'
import LandingPageTemplate from './src/pages/landing/{PrismicLandingPage.uid}'
import BlogArticlesListTemplate from './src/templates/blog-articles-list';
import ConferencePageTemplate from './src/pages/{PrismicDigitalSustainabilityConference.uid}/{PrismicDigitalSustainabilityConference.data__year}';
import PositionPageTemplate from './src/pages/position/{PrismicPosition.uid}';

import './src/styles/main.scss'

export const shouldUpdateScroll = ({ routerProps }) => {
  const { disableScrollUpdate } = routerProps?.location?.state || {};

  return !disableScrollUpdate;
};

export const wrapRootElement = ({ element }) => (
  <HubspotProvider>
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
            repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
            linkResolver,
            componentResolver: componentResolverFromMap({
              homepage: HomeTemplate,
              page: PageTemplate,
              event: EventTemplate,
              membership: MembershipTemplate,
              organization_member: OrganizationMemberTemplate,
              steering_group: SteerinGroupTemplate,
              landing_page: LandingPageTemplate,
              blog: BlogArticlesListTemplate,
              digital_sustainability_conference: ConferencePageTemplate,
              position: PositionPageTemplate
            })
        },
    ]}
    >
      {element}
    </PrismicPreviewProvider>
  </HubspotProvider>
);
