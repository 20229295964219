import * as React from 'react'
import { graphql } from 'gatsby'

import ListSteeringGroups from '../components/ListSteeringGroups'

const FeaturedSteeringGroups = ({ slice }) => {
  if (!slice || !slice.items || slice.items.length === 0) return null;

  const { primary, items } = slice;

  const itemsAreValid = items.every(edge => !!edge.steering_group.document);

  const allGroups = itemsAreValid ? items.map(edge => {
    if(edge.steering_group && edge.steering_group.document && edge.steering_group.document.data) {
      return {
        ...edge.steering_group.document.data,
        uid: edge.steering_group.document.uid,
        url: edge.steering_group.document.url
      }
    }
  }) : null;

  if (!Array.isArray(allGroups) || !allGroups.length) return null;

  return (
    <ListSteeringGroups {...primary} allGroups={allGroups} />
  )
}

export const query = graphql`
  fragment HomepageDataBodyFeaturedSteeringGroups on PrismicHomepageDataBodyFeaturedSteeringGroups {
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
    items {
      steering_group {
        document {
          ...PrismicSteeringGroupFragment
        }
      }
    }
  }

  fragment RoadmapChallengeDataBodyFeaturedSteeringGroups on PrismicRoadmapChallengeDataBodyFeaturedSteeringGroups {
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
    items {
      steering_group {
        document {
          ...PrismicSteeringGroupFragment
        }
      }
    }
  }

  fragment DictionaryEntryDataBodyFeaturedSteeringGroups on PrismicDictionaryEntryDataBodyFeaturedSteeringGroups {
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
    items {
      steering_group {
        document {
          ...PrismicSteeringGroupFragment
        }
      }
    }
  }

  fragment PageDataBodyFeaturedSteeringGroups on PrismicPageDataBodyFeaturedSteeringGroups {
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
    items {
      steering_group {
        document {
          ...PrismicSteeringGroupFragment
        }
      }
    }
  }

  fragment LandingPageDataBodyFeaturedSteeringGroups on PrismicLandingPageDataBodyFeaturedSteeringGroups {
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
    items {
      steering_group {
        document {
          ...PrismicSteeringGroupFragment
        }
      }
    }
  }

  fragment SteeringGroupDataBodyFeaturedSteeringGroups on PrismicSteeringGroupDataBodyFeaturedSteeringGroups {
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
    items {
      steering_group {
        document {
          ...PrismicSteeringGroupFragment
        }
      }
    }
  }

  fragment ResourceDataBodyFeaturedSteeringGroups on PrismicResourceDataBodyFeaturedSteeringGroups {
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
    items {
      steering_group {
        document {
          ...PrismicSteeringGroupFragment
        }
      }
    }
  }
`

export default FeaturedSteeringGroups
