import * as React from 'react'
import { lowercase } from 'lodash'

import DesktopMonitorSmiley from '../images/icons/streamline-icon-desktop-monitor-smiley.svg'
import Event from '../images/icons/streamline-icon-event.svg'
import MovieCinemaWatch from '../images/icons/streamline-icon-movie-cinema-watch.svg'

const EventPresence = ({ presence, className }) => {
  let icon
  const iconClassName = "fill-current w-5 h-5"

  switch(lowercase(presence)) {
    case 'virtual':
      icon = (
        <DesktopMonitorSmiley className={iconClassName} />
      )
      break
    case 'physical':
      icon = (
        <Event className={iconClassName} />
      )
      break
    case 'hybrid':
      icon = (
        <MovieCinemaWatch className={iconClassName} />
      )
      break
    default: 
      icon = null 
  }

  return (
    <div className={`${className} inline-flex items-center`}>
      {icon}
      <span className="ml-3">{presence}</span>
    </div>
  )
}

export default EventPresence