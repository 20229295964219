import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import { sortBy } from 'lodash'

import ListSteeringGroups from '../components/ListSteeringGroups';


const stateOrder = {
  'Running': 1,
  'Upcoming': 2,
  'Assembling': 3,
  'Finished': 4,
}

const AllSteeringGroups = ({ slice }) => {
  const { primary } = slice;

  // get all upcoming events
  const queryData = useStaticQuery(graphql`
    {
      allPrismicSteeringGroup(sort: {data: {state: ASC}}) {
        edges {
          node {
            ...PrismicSteeringGroupFragment
            ...PrismicSteeringGroupGridFragment
          }
        }
      }
    }
  `)

  const { data } = useMergePrismicPreviewData(queryData)
  const doc = data.allPrismicSteeringGroup
  const allGroups = sortBy(doc.edges.map(edge => (
    { ...edge.node.data, uid: edge.node.uid, url: edge.node.url, sort_key: stateOrder[edge.node.data.state] } 
  )), 'sort_key')

  return (
    <ListSteeringGroups {...primary} allGroups={allGroups} />
  )
}



export const query = graphql`
  fragment PageDataBodyAllSteeringGroups on PrismicPageDataBodyAllSteeringGroups {
    primary {
      subtitle {
        richText
      }
      title {
        richText
      }
    }
  }
`

export default AllSteeringGroups
