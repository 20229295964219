import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { linkResolver } from '/src/utils/LinkResolver.js';
import getCorrectTextColor from '/src/utils/SetTextColorDependingOnBackground';

const VerticallyCenteredImage = ({ slice }) => {
  const data = slice.primary;
  const image = getImage(data.image);

  if (!image) return null;

  const textColor = getCorrectTextColor(data?.background_color);

  return (
    <section
      className="py-[60px] md:py-[120px]"
      style={{ backgroundColor: `${data.background_color}` }}
    >
      {(!!data.title?.text || (Array.isArray(data.description?.richText) && data.description.richText[0]?.text?.length > 0)) && (
        <div className="container-medium mb-[40px] md:mb-28">
          {!!data.title?.text && (
            <h2
              className="font-bold text-center text-[32px] sm:text-[40px] leading-[37px] sm:leading-[49px]"
              style={{ color: textColor }}
              >
              {data.title.text}
            </h2>
          )}
          {Array.isArray(data.description?.richText) && data.description.richText[0]?.text?.length > 0 && (
            <div
              className="text-xl font-light font-display mt-3 text-center"
              style={{ color: textColor }}
              >
              <RichText
                linkResolver={linkResolver}
                render={data.description.richText}
              />
            </div>
          )}
        </div>
      )}
      <div className="container">
        <GatsbyImage
          alt={image.alt || ''}
          className="block max-w-[976px] mx-auto"
          image={image}
        />
      </div>
    </section>
  );
};

export const query = graphql`
  fragment AnnualReportDataBodyVerticallyCenteredImage on PrismicAnnualReportDataBodyVerticallyCenteredImage {
    primary {
      background_color
      title {
        text
      }
      description {
        richText
      }
      image {
        alt
        gatsbyImageData
      }
    }
  }
`;

export default VerticallyCenteredImage;
