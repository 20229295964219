import { useStaticQuery, graphql } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

const useSiteMetadata = () => {
  const queryData = useStaticQuery(
    graphql`
      query Seo {
        site {
          siteMetadata {
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            siteUrl: url
            defaultImage: image
            twitterUsername
          }
        }
      }
    `
  )
  const { data } = useMergePrismicPreviewData(queryData)
  const { site } = data

  return site.siteMetadata
};

export default useSiteMetadata