import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import useSiteMetadata from "../hooks/use-site-metadata"

const Seo = ({ title, description, image, article }) => {
  const { pathname } = useLocation()
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = useSiteMetadata()

  const seoData = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: (image ? image.url : defaultImage),
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={seoData.title} titleTemplate={titleTemplate}>

      <meta name="description" content={seoData.description} />
      <meta name="image" content={seoData.image} />

      {seoData.url && <meta property="og:url" content={seoData.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {seoData.title && <meta property="og:title" content={seoData.title} />}

      {seoData.description && (
        <meta property="og:description" content={seoData.description} />
      )}

      {seoData.image && <meta property="og:image" content={seoData.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}

      {seoData.title && <meta name="twitter:title" content={seoData.title} />}

      {seoData.description && (
        <meta name="twitter:description" content={seoData.description} />
      )}

      {seoData.image && <meta name="twitter:image" content={seoData.image} />}
    </Helmet>
  )
}

export default Seo

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  article: PropTypes.bool,
}
Seo.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}
