import React, { useEffect, useMemo, useState } from 'react';
import { graphql } from 'gatsby';

import getCorrectTextColor from '/src/utils/SetTextColorDependingOnBackground';

import { Link as ScrollLink } from 'react-scroll';


const ConferenceCountdown = ({ slice }) => {
  const data = slice.primary;

  const [conferenceIsLive, setConferenceIsLive] = useState(false);
  const [daysToConferenceStart, setDaysToConferenceStart] = useState('-');
  const [hoursToConferenceStart, setHoursToConferenceStart] = useState('-');
  const [minutesToConferenceStart, setMinutesToConferenceStart] = useState('-');
  const [secondsToConferenceStart, setSecondsToConferenceStart] = useState('-');
  const [weeksToConferenceStart, setWeeksToConferenceStart] = useState('-');

  const backgroundColor = slice.primary?.background_color;
  const textColor = getCorrectTextColor(backgroundColor);

  const conferenceStartDate = useMemo(() => new Date(data.conference_start_date), [data.conference_start_date]);
  const currentDate = new Date().getTime();
  const differenceBetweenDates = conferenceStartDate - currentDate;

  useEffect(() => {
    let countdown = null;

    if (conferenceStartDate && currentDate) {
      if (currentDate < conferenceStartDate) {
        countdown = setInterval(() => {
            setWeeksToConferenceStart(Math.floor(differenceBetweenDates / (1000 * 60 * 60 * 24 * 7)));
            setDaysToConferenceStart(Math.floor((differenceBetweenDates / (1000 * 60 * 60 * 24)) % 7));
            setHoursToConferenceStart(Math.floor((differenceBetweenDates % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
            setMinutesToConferenceStart(Math.floor((differenceBetweenDates % (1000 * 60 * 60)) / (1000 * 60)));
            setSecondsToConferenceStart(Math.floor((differenceBetweenDates % (1000 * 60)) / 1000));
        }, 1000);
      } else {
        setConferenceIsLive(true);
      }
    }

    return () => clearInterval(countdown);
  }, [differenceBetweenDates, conferenceStartDate, currentDate]);

  if (!data?.title || !data.conference_start_date) return null;

  const countdownElements = [
    {
      label: 'Weeks',
      value: weeksToConferenceStart
    },
    {
      label: 'Days',
      value: daysToConferenceStart
    },
    {
      label: 'Hours',
      value: hoursToConferenceStart
    },
    {
      label: 'Minutes',
      value: minutesToConferenceStart
    },
    {
      label: 'Seconds',
      value: secondsToConferenceStart
    },
  ];

  return (
    <section
      className={`relative ${backgroundColor ? 'py-[80px]' : 'my-[80px] md:my-[140px] scroll-mt-[80px]'}`}
      id={data.anchor_id ?? null}
      style={{ backgroundColor: backgroundColor ? `${backgroundColor}` : '#FFFFFF'}}
    >
      <div className="container max-w-[835px]">
        <h2
          className="font-body font-semibold text-center text-[32px] md:text-[40px] leading-[37px] md:leading-[44px] mb-[60px]"
          style={{ color: textColor }}
        >
          {data.title.text}
        </h2>
        {conferenceIsLive ?
          (
            <div className="flex justify-center">
              <ScrollLink
                to="conference-calendar"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className="hover:cursor-pointer"
              >
                <div className="relative flex">
                  <div className="animate-pulse absolute top-[-15%] left-[-10%] inline-flex h-[130%] w-[120%] rounded-[20px] bg-[#1D473229]" />
                  <div className="relative inline-flex items-center justify-center bg-gradient-to-r from-[#527563] to-[#3F5B4D] rounded-[12px] px-[20px] py-[17px]">
                    <span className="font-display font-semibold text-white uppercase text-[38px] leading-[45px]">
                      Live
                    </span>
                    <div className="h-[14px] w-[14px] rounded-full bg-[#E69635] ml-[15px]" />
                  </div>
                </div>
              </ScrollLink>
            </div>
          ) :
          !!countdownElements.length && (
            <div className="flex gap-[8px] justify-center">
              {countdownElements.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center h-[120px] w-[120px] bg-[#F2F2F5] first-of-type:rounded-l-[20px] last-of-type:rounded-r-[20px]"
                >
                  <div className="font-bold text-[24px] sm:text-[40px] leading-[32px] sm:leading-[49px] text-[#527563]">
                    {item.value}
                  </div>
                  <div className="text-[14px] sm:text-[18px] leading-[18px] sm:leading-[26px] text-[#777785] mt-[8px]">
                    {item.label}
                  </div>
                </div>
              ))}
            </div>
          )
        }
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ConferenceDataBodyConferenceCountdown on PrismicDigitalSustainabilityConferenceDataBodyConferenceCountdown {
    primary {
      title {
        text
      }
      conference_start_date
      background_color
    }
  }
`

export default ConferenceCountdown;
