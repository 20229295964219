import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import FeaturedOrgMember from './FeaturedOrgMember'
import { linkResolver } from '../utils/LinkResolver'

const ResearchProject = ({ 
    id,
    project_name,
    project_type,
    start_year,
    end_year,
    country,
    description,
    project_image,
    website,
    other_partners,
    alliance_members,
 }) => (
  <div className="c-accordion__item__content pt-4">
    {start_year ? 
      <span className="block font-bold py-4 mb-4 border-b-2 border-t-2 border-primary-500 text-primary-600 text-center uppercase">
        Project Period: {start_year}{end_year ? `- ${end_year}` : null}
      </span> : null}

    <div className="prose">
      <RichText render={description.richText} linkResolver={linkResolver} />
    </div>
      
    <a 
      href={website.url} 
      title={RichText.asText(project_name.richText)} 
      target={website.target} 
      className="c-link mt-4 inline-block"
    >
      Project Website
    </a>

    {alliance_members && alliance_members.length > 0 ? 
      <div className="mt-8">
        <h6>Participating from the Alliance:</h6>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mt-4">
          {alliance_members.map(({ organization_member }, index) => {
            if (!organization_member.document) return null;

            return (
              <FeaturedOrgMember
                key={`research-proj-${id}-alliance-members-${index}`}
                url={organization_member.document.url}
                {...organization_member.document.data}
              />
            )
          })}
        </div>
      </div>
    : null }

    {other_partners && other_partners.length > 0 ? 
      <div className="mt-8">
        <h6>Other Parties:</h6>
        <ul className="grid grid-cols-2 sm:grid-cols-3 mt-2">
          {other_partners.map((partner, index) => (
            <li key={`research-proj-${id}-opartners-${index}`} className="my-2">
              <a href={partner.partner_website.url} target={partner.partner_website.target} className="c-link">
                {RichText.asText(partner.partner_name.richText)}
              </a>
            </li>
          ))}
        </ul>
      </div>
    : null }
    
  </div>
)

export default ResearchProject

export const researchProjectFragment = graphql`
  fragment PrismicResearchProjectFragment on PrismicResearchProject {
    _previewable
    uid
    id
    data {
      country
      website {
        url
        type
        target
        link_type
      }
      start_year
      project_type
      project_name {
        richText
      }
      project_image {
        gatsbyImageData(width: 180)
      }
      other_partners {
        partner_logo {
          url
          alt
        }
        partner_name {
          richText
        }
        partner_website {
          url
          type
          target
          link_type
        }
      }
      description {
        richText
      }
      end_year
      alliance_members {
        organization_member {
          document {
            ... on PrismicOrganizationMember {
              uid
              url

              data {
                name {
                  richText
                }
                logo {
                  url
                }
                link {
                  url 
                  link_type
                  target
                }
              }
            }
          }
        }
      }
    }
  }
`
