import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { linkResolver } from '/src/utils/LinkResolver.js';

const StatisticsCards = ({ slice }) => {
  const data = slice.primary;
  const { items } = slice;

  const {
    anchor_id,
    content_alignment: contentAlignment,
    description,
    tagline,
    title,
    section_margin
  } = data || {};

  if (!Array.isArray(items) || !items.length) return null;

  return (
    <section
      className={`scroll-mt-[80px]${section_margin === 'small' ? ' my-[40px] md:my-[60px]' : ' my-[80px] md:my-[140px]'}`}
      id={anchor_id ?? null}
    >
      <div className="container">
        <div className="flex flex-col lg:flex-row lg:gap-[56px] lg:items-center">
          <div className={`lg:min-w-[480px] mx-auto${contentAlignment === 'left' ? ' text-center lg:text-left' : ' text-center'}`}>
            {!!tagline?.text && (
              <div className="font-semibold uppercase text-[18px] md:text-[24px] leading-[22px] md:leading-[32px] tracking-[2.4px] text-[#777785] mb-[20px]">
                {tagline.text}
              </div>
            )}
            {!!title?.text && (
              <h2 className="font-bold font-body text-[32px] md:text-[40px] leading-[37px] md:leading-[44px]">
                {title.text}
              </h2>
            )}
            {Array.isArray(description?.richText) && description.richText[0]?.text?.length > 0 && (
              <div className="font-body text-[18px] md:text-[20px] leading-[24px] md:leading-[28px] mt-[20px]">
                <RichText
                  linkResolver={linkResolver}
                  render={description.richText}
                />
              </div>
            )}
          </div>
          <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 mt-[40px] lg:mt-0">
            {items.map((item, index) => {
              const icon = getImage(item.card_icon);

              return (
                <div
                  key={index}
                  className={`rounded-xl px-[32px] py-[24px]${
                    item.highlight_card ? ' bg-[#527A66] text-white' : ' border border-[#DADAE7] text-[#111827]'}`}
                >
                  <div className={`rounded-lg p-[12px] h-[40px] w-[40px]${
                    item.highlight_card ? ' bg-white' : ' bg-[#527a66]/5 border border-[#527A66]'
                  }`}
                  >
                    <GatsbyImage
                      alt={icon.alt || ''}
                      image={icon}
                      objectFit="contain"
                    />
                  </div>
                  {Array.isArray(item.card_value?.richText) && item.card_value.richText[0]?.text?.length > 0 && (
                    <h6 className="font-body font-semibold text-[40px] leading-[48px] mt-[16px]">
                      {RichText.asText(item.card_value.richText)}
                    </h6>
                  )}
                  {Array.isArray(item.card_text?.richText) && item.card_text.richText[0]?.text?.length > 0 && (
                    <p className="text-[16px] leading-[22px] mt-[8px]">
                      {RichText.asText(item.card_text.richText)}
                    </p>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ConferenceDataBodyStatisticsCards on PrismicDigitalSustainabilityConferenceDataBodyStatisticsCards {
    primary {
      tagline {
        text
      }
      title {
        text
      }
      description {
        richText
      }
      content_alignment
      section_margin
    }
    items {
      card_icon {
        alt
        gatsbyImageData
      }
      card_value {
        richText
      }
      card_text {
        richText
      }
      highlight_card
    }
  }
`;

export default StatisticsCards;
