import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import { linkResolver } from '../utils/LinkResolver.js'

const Callout = ({ slice }) => {
  const { text } = slice.primary

  return (
    <section className={`container-medium mt-12 sm:mt-20`}>
      <div className="prose mt-5 prose-xl max-w-none font-display font-light leading-relaxed rounded bg-primary-100 p-6 text-center">
        <RichText
          render={text.richText}
          linkResolver={linkResolver}
        />
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyCallout on PrismicHomepageDataBodyCallout {
    primary {
      text {
        richText
      }
    }
  }
  fragment DictionaryEntryDataBodyCallout on PrismicDictionaryEntryDataBodyCallout {
    primary {
      text {
        richText
      }
    }
  }
  fragment PageDataBodyCallout on PrismicPageDataBodyCallout {
    primary {
      text {
        richText
      }
    }
  }
  fragment SteeringGroupDataBodyCallout on PrismicSteeringGroupDataBodyCallout {
    primary {
      text {
        richText
      }
    }
  }
  fragment LandingPageDataBodyCallout on PrismicLandingPageDataBodyCallout {
    primary {
      text {
        richText
      }
    }
  }
  fragment MembershipDataBodyCallout on PrismicMembershipDataBodyCallout {
    primary {
      text {
        richText
      }
    }
  }
  fragment ResourceDataBodyCallout on PrismicResourceDataBodyCallout {
    primary {
      text {
        richText
      }
    }
  }
`

export default Callout
