import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

import ListEvents from '../components/ListEvents'
import { enrichEventsDates } from '../utils/EnrichEventDates'

const AllUpcomingEvents = ({ slice }) => {
  const { primary } = slice

  // get all upcoming events
  const queryData = useStaticQuery(graphql`
    {
      allPrismicEvent(
        sort: {data: {start_date: ASC}},
        filter: {data: {upcoming_event: {ne: true}}}
      ) {
        edges {
          node {
            ...PrismicEventFragment
          }
        }
      }
    }
  `)

  const { data } = useMergePrismicPreviewData(queryData)
  const doc = data.allPrismicEvent
  const allEvents = enrichEventsDates(
    doc.edges.map(edge => { 
      return { ...edge.node.data, uid: edge.node.uid, url: edge.node.url } 
    })
  )

  return (
    <ListEvents {...primary} allEvents={allEvents} showUpcoming />
  )
}



export const query = graphql`
  fragment PageDataBodyAllUpcomingEvents on PrismicPageDataBodyAllUpcomingEvents {
    primary {
      call_to_action_label
      call_to_action {
        url
        uid
        type
        target
        link_type
      }
      headline {
        richText
      }
      description {
        richText
      }
    }
  }

  fragment LandingPageDataBodyAllUpcomingEvents on PrismicLandingPageDataBodyAllUpcomingEvents {
    primary {
      call_to_action_label
      call_to_action {
        url
        uid
        type
        target
        link_type
      }
      headline {
        richText
      }
      description {
        richText
      }
    }
  }

  fragment HomepageDataBodyAllUpcomingEvents on PrismicHomepageDataBodyAllUpcomingEvents {
    primary {
      call_to_action_label
      call_to_action {
        url
        uid
        type
        target
        link_type
      }
      headline {
        richText
      }
      description {
        richText
      }
    }
  }
`

export default AllUpcomingEvents
