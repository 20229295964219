import * as React from 'react'
import { RichText } from 'prismic-reactjs'

// import LinkButton from './LinkButton'
import Resource from './Resource'

const ListResources = ({ allResources, title, subtitle }) => {
  return (
    <section className={`mt-12 sm:mt-20 lg:pb-8 container`}>
      {title ? 
        <h2 className="text-center">
          {RichText.asText(title.richText)}
        </h2> : null}

      {subtitle ? 
        <p className="text-xl font-light font-display text-gray-500 mt-3 text-center">
          {RichText.asText(subtitle.richText)}
        </p> : null}

      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-12">
        {allResources.map((resource, index) => (
          <Resource {...resource} key={`resource-${index}`} />
        ))}
      </div>
    </section>
  )
}

export default ListResources
