import * as React from 'react'
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { Link as ScrollLink } from 'react-scroll'

const LinkButton = ({ link, label, isWarning, isPrimary, className, children, id }) => {
  let labelText = null
  
  if (label) {
    labelText = label && label.richText ? RichText.asText(label.richText) : label
  }

  const cssClass = `
    c-button
    ${className || ''}
    ${isWarning ? 'c-button--warning' : ''}
    ${isPrimary ? 'c-button--primary' : ''}
  `

  if (link.link_type === 'Document') {
    return (
      <Link
        id={id}
        to={link.url}
        target={link.target}
        className={cssClass}
      >
        {labelText || children}
      </Link>
    )
  }

  if (link.link_type === 'Web') {
    if (link.url.startsWith('https://#')) {
     const anchorLink = link.url.slice(link.url.indexOf('#') + 1, link.url.length);

      return (
        <ScrollLink to={anchorLink} spy={true} smooth={true} offset={0} duration={500} className={cssClass}> 
          {labelText || children}
        </ScrollLink>
      )
    }

    if (link.url === 'https://#form') {
      return (
        <ScrollLink to="form" spy={true} smooth={true} offset={0} duration={500} className={cssClass}> 
          {labelText || children}
        </ScrollLink>
      )
    }
  }

  return (
    <a
      id={id}
      href={link.url}
      target={link.target}
      className={cssClass}
    >
      {labelText || children}
    </a>
  )
}

export default LinkButton
