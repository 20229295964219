import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { RichText } from 'prismic-reactjs'

import { Layout } from '../../components/Layout'
import SliceZone from '../../components/SliceZone'
import Seo from '../../components/Seo'

const LandingPageTemplate = ({ data }) => {
  if (!data) return null
  const doc = data.prismicLandingPage.data
  const {
    page_title,
    page_description,
    share_image,
    use_dark_header,
    use_dark_footer,
  } = doc

  return (
    <Layout useDarkHeader={use_dark_header} useDarkFooter={use_dark_footer}>
      <Seo 
        title={RichText.asText(page_title.richText)} 
        description={RichText.asText(page_description.richText)} 
        image={share_image}
      />
    
      <div className="">
        {doc.body ? <SliceZone sliceZone={doc.body} /> : null}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query LandingPageQuery($id: String) {
    prismicLandingPage(id: { eq: $id }) {
      _previewable
      data {
        page_description {
          richText
        }
        page_title {
          richText
        }
        share_image {
          url
        }
        use_dark_header
        use_dark_footer
        body {
          ...on PrismicSliceType {
            slice_type
          }
          ...LandingPageDataBodyAllIndividualMembers
          ...LandingPageDataBodyAllTeamMembers
          ...LandingPageDataBodyAllAdvisors
          ...LandingPageDataBodyTextHero
          ...LandingPageDataBodyHeroWithTeamMember
          ...LandingPageDataBodyCallout
          ...LandingPageDataBodyToggleList
          ...LandingPageDataBodyFeaturedMemberships
          ...LandingPageDataBodyHeroWithFeatures
          ...LandingPageDataBodyFeaturedContent
          ...LandingPageDataBodyGoalsSlideshow
          ...LandingPageDataBodyFeaturedOrgMembers
          ...LandingPageDataBodyBenefitsWithImage
          ...LandingPageDataBodyFeaturedTeamMembers
          ...LandingPageDataBodyLargeNewsletterForm
          ...LandingPageDataBodySimpleRichText
          ...LandingPageDataBodyContactTeamMembers
          ...LandingPageDataBodyAllUpcomingEvents
          ...LandingPageDataBodyAllPastEvents
          ...LandingPageDataBodyFeaturedEvents
          ...LandingPageDataBodyHubspotForm
          ...LandingPageDataBodyResourceHero
          ...LandingPageDataBodyTwoColumnImageChecklist
          ...LandingPageDataBodyFeaturesWithIconsGrid
          ...LandingPageDataBodyHubspotFormWithTeamMember
          ...LandingPageDataBodyAboutSummaryBlock
          ...LandingPageDataBodyFullWidthVideo
          ...LandingPageDataBodyFeaturedIndividualMembers
          ...LandingPageDataBodyCallToAction
          ...LandingPageDataBodyFeaturedArticles
        }
      }
    }
  }
`

export default withPrismicPreview(LandingPageTemplate)
