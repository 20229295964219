import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import LinkButton from '../components/LinkButton.js'
import FeaturedOrgMember from '../components/FeaturedOrgMember.js'

const FeaturedOrgMembers = ({ slice }) => {
  if(slice.items.length === 0) return null

  const { items, primary } = slice
  const { container_medium, title, subtitle, link, call_to_action } = primary
  const container = container_medium ? 'container-medium' : 'container'
  const gridConfig = container_medium ? 
    'grid-cols-3 md:grid-cols-4 lg:grid-cols-5' :
    'grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6'

  return (
    <section className={`mt-12 sm:mt-20 lg:pb-8 ${container}`}>
      <h2 className="text-center">
        {RichText.asText(title.richText)}
      </h2>

      <p className="text-xl font-light font-display text-gray-500 mt-3 text-center">
        {RichText.asText(subtitle.richText)}
      </p>

      <div className={`grid ${gridConfig} mt-12 gap-4`}>
        {items.map((item, index) => {
          if(!item.featured_member || !item.featured_member.document) return null

          const member = item.featured_member.document

          return (
            <FeaturedOrgMember 
              key={`feat-org-member-${index}`} 
              url={member.url}
              {...member.data} 
            />
          )
        })}
      </div>

      {link.url && call_to_action.richText ? 
        <div className="mt-12 text-center">
          <LinkButton
            link={link}
            label={call_to_action}
            isWarning
          />
        </div> : null}
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyFeaturedOrgMembers on PrismicHomepageDataBodyFeaturedOrgMembers {
    items {
      featured_member {
        document {
          ... on PrismicOrganizationMember {
            url
            uid
            _previewable
            data {
              link {
                url
                target
                link_type
              }
              logo {
                alt
                url(
                  imgixParams: {fill: "max", w: 168, h: 168, height: 168, width: 168}
                )
                gatsbyImageData(
                  layout: FIXED
                  height: 168
                )
              }
            }
          }
        }
      }
    }
    primary {
      container_medium
      call_to_action {
        richText
      }
      link {
        link_type
        url
        target
      }
      subtitle {
        richText
      }
      title {
        richText
      }
    }
  }

  fragment DictionaryEntryDataBodyFeaturedOrgMembers on PrismicDictionaryEntryDataBodyFeaturedOrgMembers {
    items {
      featured_member {
        document {
          ... on PrismicOrganizationMember {
            url
            uid
            _previewable
            data {
              link {
                url
                target
                link_type
              }
              logo {
                alt
                url(
                  imgixParams: {fill: "max", w: 168, h: 168, height: 168, width: 168}
                )
                gatsbyImageData(
                  layout: FIXED
                  height: 168
                )
              }
            }
          }
        }
      }
    }
    primary {
      container_medium
      call_to_action {
        richText
      }
      link {
        link_type
        url
        target
      }
      subtitle {
        richText
      }
      title {
        richText
      }
    }
  }

  fragment PageDataBodyFeaturedOrgMembers on PrismicPageDataBodyFeaturedOrgMembers {
    items {
      featured_member {
        document {
          ... on PrismicOrganizationMember {
            _previewable
            url
            uid 
            
            data {
              link {
                url
                target
                link_type
              }
              logo {
                alt
                url(
                  imgixParams: {fill: "max", w: 140, h: 110}
                )
                gatsbyImageData(
                  imgixParams: {fill: "max", w: 168, h: 168, height: 168, width: 168}
                  width: 168
                  height: 168
                )
              }
            }
          }
        }
      }
    }
    primary {
      container_medium
      call_to_action {
        richText
      }
      link {
        link_type
        url
        target
      }
      subtitle {
        richText
      }
      title {
        richText
      }
    }
  }

  fragment MembershipDataBodyFeaturedOrgMembers on PrismicMembershipDataBodyFeaturedOrgMembers {
    items {
      featured_member {
        document {
          ... on PrismicOrganizationMember {
            url
            uid 
            
            _previewable
            data {
              link {
                url
                target
                link_type
              }
              logo {
                alt
                url
                gatsbyImageData(
                  layout: FIXED
                  height: 168
                )
              }
            }
          }
        }
      }
    }
    primary {
      call_to_action {
        richText
      }
      link {
        link_type
        url
        target
      }
      subtitle {
        richText
      }
      title {
        richText
      }
    }
  }

  fragment OrganizationMemberDataBodyFeaturedOrgMembers on PrismicOrganizationMemberDataBodyFeaturedOrgMembers {
    items {
      featured_member {
        document {
          ... on PrismicOrganizationMember {
            _previewable
            url
            uid 
            
            data {
              link {
                url
                target
                link_type
              }
              logo {
                alt
                url
                gatsbyImageData(
                  layout: FIXED
                  height: 168
                )
              }
            }
          }
        }
      }
    }
    primary {
      container_medium
      call_to_action {
        richText
      }
      link {
        link_type
        url
        target
      }
      subtitle {
        richText
      }
      title {
        richText
      }
    }
  }
  
  fragment LandingPageDataBodyFeaturedOrgMembers on PrismicLandingPageDataBodyFeaturedOrgMembers {
    items {
      featured_member {
        document {
          ... on PrismicOrganizationMember {
            _previewable
            url
            uid 
            
            data {
              link {
                url
                target
                link_type
              }
              logo {
                alt
                url
                gatsbyImageData(
                  layout: FIXED
                  height: 168
                )
              }
            }
          }
        }
      }
    }
    primary {
      container_medium
      call_to_action {
        richText
      }
      link {
        link_type
        url
        target
      }
      subtitle {
        richText
      }
      title {
        richText
      }
    }
  }

  fragment SteeringGroupDataBodyFeaturedOrgMembers on PrismicSteeringGroupDataBodyFeaturedOrgMembers {
    items {
      featured_member {
        document {
          ... on PrismicOrganizationMember {
            _previewable
            url
            uid 
            
            data {
              link {
                url
                target
                link_type
              }
              logo {
                alt
                url
                gatsbyImageData(
                  layout: FIXED
                  height: 168
                )
              }
            }
          }
        }
      }
    }
    primary {
      container_medium
      call_to_action {
        richText
      }
      link {
        link_type
        url
        target
      }
      subtitle {
        richText
      }
      title {
        richText
      }
    }
  }

  fragment ResourceDataBodyFeaturedOrgMembers on PrismicResourceDataBodyFeaturedOrgMembers {
    items {
      featured_member {
        document {
          ... on PrismicOrganizationMember {
            _previewable
            url
            uid 
            
            data {
              link {
                url
                target
                link_type
              }
              logo {
                alt
                url
                gatsbyImageData(
                  layout: FIXED
                  height: 168
                )
              }
            }
          }
        }
      }
    }
    primary {
      container_medium
      call_to_action {
        richText
      }
      link {
        link_type
        url
        target
      }
      subtitle {
        richText
      }
      title {
        richText
      }
    }
  }
`

export default FeaturedOrgMembers
