import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'

const DictionaryEntry = ({ 
  uid,
  url,
  word,
  explanation,
  image,
  letter,
}) => (
  <li className="border-b-2 border-dotted border-gray-300 p-4 last:border-b-0">
    <Link 
      to={url} 
      title={`Definition of ${RichText.asText(word.richText)}`}
      className="text-orange-400 underline text-lg hover:no-underline underline-offset-1"
    >
      {RichText.asText(word.richText)}
    </Link>
  </li>
)

export default DictionaryEntry

export const dictionaryEntryFragment = graphql`
  fragment PrismicDictionaryEntryFragment on PrismicDictionaryEntry {
    _previewable
    url
    uid
    last_publication_date(fromNow: true)
    data {
      explanation {
        richText
      }
      image {
        gatsbyImageData
      }
      word {
        richText
        text
      }
    }
  }
`