import {
  parseISO,
  getMonth,
  getYear,
} from 'date-fns'

export const enrichEventsDates = (events) => {
  return events.map(event => {
    // an upcoming event has no date yet
    if(!event.upcoming_event) {
      const start_date = parseISO(event.start_date)
      const end_date = parseISO(event.end_date)

      event.start_date_obj = start_date
      event.end_date_obj = end_date
      event.month = getMonth(start_date)
      event.year = getYear(start_date)
    }
    return event
  })
}
