import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { NavItem } from './NavItem'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

const Navigation = ({ useDarkHeader, className }) => {

  const queryData = useStaticQuery(graphql`
    {
      prismicNavigation {
        _previewable
        data {
          website_title {
            richText
          }
          body {
            ... on PrismicNavigationDataBodyComplexNavigation {
              id
              slice_type
              primary {
                top_link {
                  url
                  type
                  link_type
                  slug

                  document {
                    ... on PrismicRoadmapPage {
                      uid
                    }
                    ... on PrismicPage {
                      uid
                    }
                    ... on PrismicHomepage {
                      uid
                    }
                  }
                }
                top_link_label {
                  richText
                }
              }
              items {
                sub_link {
                  url
                  type
                  link_type
                  uid
                }
                sub_link_label {
                  richText
                }
              }
            }
          }
        }
      }
    }
  `)

  const { data } = useMergePrismicPreviewData(queryData)
  const doc = data.prismicNavigation
  const navigation = doc.data
  // array of complex navigation blocks with each primary object and items array
  const items = navigation.body

  return (
    <nav 
      className={`
        ${className}
        c-navigation 
        ${useDarkHeader ? '' : 'c-navigation--light'}
        w-full self-end flex-col items-center h-full py-1 pb-4 pt-4
        md:self-center md:flex md:w-auto md:flex-row md:py-0 md:pb-0
      `}
    >    
      {items.map((item, index) => {
        if (Array.isArray(item.items) && item.items.length) {
          const annualReportLink = item.items.find(entry => entry?.sub_link?.type === 'annual_report');
          const blogLink = item.items.find(entry => entry?.sub_link?.type === 'blog');
          if (annualReportLink && annualReportLink.sub_link) annualReportLink.sub_link.url = `/annual-report/${annualReportLink?.sub_link?.uid}`
          if (blogLink && blogLink.sub_link) blogLink.sub_link.url = '/blog';
        };

        return (
          <NavItem 
            {...item} 
            key={`nav-item-${index}`} 
            useDarkHeader={useDarkHeader} 
          />
        );
      })}
    </nav>
  )
}

export default Navigation
