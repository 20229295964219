import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { linkResolver } from '../utils/LinkResolver.js'
import LinkButton from '../components/LinkButton.js'

const ResourceHero = ({ slice }) => {
  const { 
    hero_title, 
    hero_text, 
    hero_subtitle, 
    hero_cta, 
    hero_cta_label,
    hero_image,
    shadow_on_hero_image,
    wide_hero_image,
    sticker_text,
  } = slice.primary

  const image = getImage(hero_image)

  return (
    <section class="bg-primary-100 py-24 md:py-24 lg:pt-48">
      <div class="mx-auto max-w-4xl px-4 md:px-8">
        <div class="grid md:grid-cols-5 gap-4 md:gap-12 relative">
          {sticker_text ? 
            <span class="ml-4 mt-4 md:-ml-4 md:-mt-4 lg:-ml-12 lg:-mt-12 absolute top-0 left-0 w-24 h-24 bg-secondary-900 rounded-full text-center text-white font-bold flex items-center justify-center z-10">
              {sticker_text}
            </span> : null}

          <GatsbyImage 
            className={`${shadow_on_hero_image ? 'shadow-lg rounded' : ''} ${wide_hero_image ? 'w-auto' : 'w-1/2 md:w-auto'} mx-auto md:col-span-2`}
            image={image}
            alt={RichText.asText(hero_title.richText)}
            aspectRatio={wide_hero_image ? (16/9) : (4/3)}
          />
        
          <div class="mt-12 md:mt-0 md:col-span-3">
            <p class="uppercase tracking-wide text-secondary-900">
              {RichText.asText(hero_subtitle.richText)}
            </p>
            <h2 class="text-4xl font-display mt-1 font-bold">
              {RichText.asText(hero_title.richText)}
            </h2>
            
            <span class="h-1 w-12 mt-4 bg-green-900 inline-block"></span>

            <div class="prose text-lg font-display mt-4 text-gray-900 leading-relaxed">
              <RichText render={hero_text.richText} linkResolver={linkResolver} />
            </div>

            <LinkButton 
              className="mt-6 inline-block antialiased c-button--primary-dark"
              label={hero_cta_label}
              link={hero_cta}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment LandingPageDataBodyResourceHero on PrismicLandingPageDataBodyResourceHero {
    primary {
      hero_text {
        richText
      }
      hero_title {
        richText
      }
      hero_subtitle {
        richText
      }
      hero_cta {
        url
        uid
        type
        target
        link_type
      }
      hero_cta_label
      hero_image {
        gatsbyImageData
      }
      shadow_on_hero_image
      wide_hero_image
      sticker_text
    }
  }
`

export default ResourceHero
