import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import { Layout } from '../components/Layout'
import SliceZone from '../components/SliceZone'
import Seo from '../components/Seo'

const HomeTemplate = ({ data }) => {
  if (!data) return null
  const doc = data.prismicHomepage.data
  
  return (
    <Layout useDarkHeader={false} useDarkFooter={true}>
      <Seo title="Home" />
      <SliceZone sliceZone={doc.body} />
    </Layout>
  )
}

export const query = graphql`
  query Homepage {
    prismicHomepage {
      _previewable

      data {
        body {
          ...on PrismicSliceType {
            slice_type
          }
          ...HomepageDataBodyHeroWithFeatures
          ...HomepageDataBodyFeaturedContent
          ...HomepageDataBodyGoalsSlideshow
          ...HomepageDataBodyFeaturedOrgMembers
          ...HomepageDataBodyBenefitsWithImage
          ...HomepageDataBodyFeaturedTeamMembers
          ...HomepageDataBodyLargeNewsletterForm
          ...HomepageDataBodyAllUpcomingEvents
          ...HomepageDataBodyFeaturedEvents
          ...HomepageDataBodyFeaturedResources
          ...HomepageDataBodyFeaturedSteeringGroups
          ...HomepageDataBodyCallout
          ...HomepageDataBodyFeaturesWithIconsGrid
          ...HomepageDataBodyFullQuote
          ...HomepageDataBodyFullWidthVideo
          ...HomepageDataBodyHubspotFormWithTeamMember
          ...HomepageDataBodySimpleRichText
          ...HomepageDataBodyToggleList
          ...HomepageDataBodyTwoColumnImageChecklist
          ...HomepageDataBodyFeaturedSteeringGroups
          ...HomepageDataBodyCallToAction
          ...HomepageDataBodyFeaturedArticles
        }
      }
    }
  }
`

export default withPrismicPreview(HomeTemplate)
