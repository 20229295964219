module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'SDIA - Sustainable Digital Infrastructure Alliance e.V.', // Navigation and Site Title
  titleAlt: 'Sustainable Digital Infrastructure Alliance e.V.', // Title for JSONLD
  logoTitle: 'Sustainable Digital Infrastructure Alliance',
  titleTemplate: "%s · SDIA",
  description: 'SDIA is the first alliance created as an authoritative source of industry leaders and information forging the way for sustainable digital infrastructure.',
  headline: 'SDIA is the first alliance created as an authoritative source of industry leaders and information forging the way for sustainable digital infrastructure.',
  url: 'https://sdialliance.org', // Domain of your site. No trailing slash!
  logo: '/logo-1024.png', // Used for SEO
  sharingImage: 'https://images.prismic.io/sdiav2/c6275695-592e-42f1-ab55-77b6eda289cb_sharing.jpg?auto=compress,format&w=1200',
  ogLanguage: 'en_US', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'SDIA', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Sustainable Digital Infrastructure Alliance e.V.', // Author for schemaORGJSONLD
  themeColor: '#3D63AE',
  backgroundColor: '#EBEDF2',

  twitterUsername: '@sdialliance', // Twitter Username

  skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}
