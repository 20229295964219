import * as React from 'react'
import { RichText } from 'prismic-reactjs'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import BackButton from './BackButton'
import { linkResolver } from '../utils/LinkResolver'
import { compact } from 'lodash'

import LocationIcon from '../images/icons/location-icon.svg'
import ShareExternalLinkIcon from '../images/icons/streamline-icon-share-external-link-1.svg'
import Envelope from '../images/icons/streamline-icon-envelope.svg'

const OrganizationMemberDetail = ({ 
  id,
  description,
  logo,
  name,
  link,
  type,
  joined_on,
  contact_people,
  roadmap_activities,
  profile_image,
  tags,
  headquarter,
}) => {
  const tagList = compact(tags.map(obj => obj.tag))
  const activities = compact(
    roadmap_activities.map(obj => obj.activity.document ? obj.activity.document.data : null)
  )
  const image = getImage(profile_image)
  const logoImage = getImage(logo)

  const activityBlock = (
    <div className="mt-12 ">
      <h5 className="mb-4">Active in roadmap activities:</h5>

      <div className="inline-flex items-start">
        {activities.map((activity, index) => (
          <span className="rounded-full bg-primary-800 py-2 px-6 font-bold mr-2">
            {RichText.asText(activity.name.richText)}
          </span>
        ))}
      </div>
      
    </div>
  )

  return (
    <section className={`container mt-16 lg:mt-24`}>
      <BackButton>Back to overview</BackButton>

      <div className="bg-primary-900 rounded grid grid-cols-5 text-white relative mt-4">

        <div className="col-span-5 md:col-span-3 order-2 md:order-1 p-8 lg:p-16">
          <p className="uppercase text-primary-600 font-bold tracking-widest">
            {type}
          </p>
          <h2 className="">
            {RichText.asText(name.richText)}
          </h2>

          {headquarter ? 
            <div className="inline-flex items-center mt-4">
              <LocationIcon className="fill-current w-6 h-6" />
              <span className="ml-2">{headquarter}</span>
            </div> : null}

          <div className="prose text-white mt-4">
            <RichText render={description.richText} linkResolver={linkResolver} />
          </div>
          
          <div className="mt-6">
            <Link to={link.url} target={link.target} className="border-2 rounded py-2 px-4 inline-flex items-center font-bold hover:bg-primary-800 transition">
              <ShareExternalLinkIcon className="fill-current stroke-current w-6 h-6 text-white" />
              <span className="ml-4">Go to website</span>
            </Link>
          </div>

          {activities && activities.length > 0 ? activityBlock : null}
        </div>

        {image ? <GatsbyImage
          alt={RichText.asText(name.richText)}
          image={image}
          objectFit="cover"
          layout="fullWidth"
          className="
            h-full relative pb-80 col-span-5 md:col-span-2 order-1 md:order-2 
            rounded-t md:rounded-t-none md:rounded-r
            max-h-12 md:max-h-full
          "
        /> : null}

        <div className="bg-white max-w-60 p-4 shadow rounded absolute top-6 md:bottom-0 md:top-auto right-0 mr-6 mb-6">
          <GatsbyImage alt={RichText.asText(name.richText)} image={logoImage} />
        </div>
      </div>

      {contact_people && contact_people.length > 0 ? (
        <h2 className="text-center mt-20">Contact {RichText.asText(name.richText)}</h2>
      ) : null}
      
      {contact_people.map((person, index) => {
        const photo = getImage(person.photo)
        const key = `member-${id}-contact-${index}`

        const { full_name, job_title, contact_email } = person

        if (!full_name || !contact_email) {
          return null
        }
        
        return (
          <div 
            className="bg-gray-100 rounded p-8 mt-6 flex justify-between items-center max-w-6xl mx-auto"
            key={key}
          >
            <div className="flex items-center">
              <GatsbyImage 
                alt={RichText.asText(full_name.richText)} 
                image={photo} 
                imgClassName="rounded-full" 
                className="w-24" 
              />
              <div className="ml-8">
                <h6 className="text-lg">{RichText.asText(full_name.richText)}</h6>
                <p className="text-gray-700">{RichText.asText(job_title.richText)}</p>
              </div>
            </div>

            <a 
              href={`mailto:${contact_email}`} 
              className="py-3 px-6 bg-secondary-900 hover:bg-secondary-800 transition rounded text-white font-bold inline-flex items-center"
            >
              <Envelope className="w-6 h-6 stroke-current fill-current" />
              <span className="ml-4">Write an email</span>
            </a>
          </div>
        )
      })}
      
      {tagList && tagList.length > 0 ? 
        <div className="mt-20 flex justify-center items-center -ml-6">
          {tagList.map((tag, index) => (
            <span 
              key={`org-member-${id}-tags-${index}`} 
              className="rounded-full border-2 py-2 px-6 ml-6"
            >
              {tag}
            </span>
          ))}
        </div> : null}
    </section>
  )
}

export default OrganizationMemberDetail