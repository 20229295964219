import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import LinkButton from '../components/LinkButton.js'
import TeamMemberPhoto from '../components/TeamMemberPhoto.js'

const FeaturedTeamMembers = ({ slice }) => (
  <section className="mt-16 sm:mt-32 bg-primary-100 py-16">
    <div className="container-medium text-center">
      <h2 className="">
        {RichText.asText(slice.primary.title.richText)}
      </h2>

      <p className="text-xl font-light font-display text-gray-500 mt-3 text-center">
        {RichText.asText(slice.primary.subtitle.richText)}
      </p>

      <div className="grid grid-cols-3 sm:grid-cols-4 gap-4 mt-12 mb-0">
        {slice.items.map((item, index) => item?.person?.document?.data ?
          (
            <TeamMemberPhoto
              {...item.person.document.data}
              key={`team-member-photo-${index}`}
            />
          ) :
          null
        )}
      </div>
      
      {!!slice.primary.call_to_action && !!slice.primary.link && (
        <div className="mt-12">
          <LinkButton
            label={slice.primary.call_to_action}
            link={slice.primary.link}
          />
        </div>
      )}
    </div>
  </section>
)

export const query = graphql`
  fragment HomepageDataBodyFeaturedTeamMembers on PrismicHomepageDataBodyFeaturedTeamMembers {
    items {
      person {
        document {
          ... on PrismicTeamMember {
            id
            _previewable
            data {
              full_name {
                richText
              }
              linkedin_profile {
                url
                target
                link_type
              }
              photo {
                gatsbyImageData(width: 180, layout: FULL_WIDTH)
              }
              title {
                richText
              }
            }
          }
        }
      }
    }
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
  }

  fragment PageDataBodyFeaturedTeamMembers on PrismicPageDataBodyFeaturedTeamMembers {
    items {
      person {
        document {
          ... on PrismicTeamMember {
            id
            _previewable
            data {
              full_name {
                richText
              }
              linkedin_profile {
                url
                target
                link_type
              }
              photo {
                gatsbyImageData(width: 180, layout: FULL_WIDTH)
              }
              title {
                richText
              }
            }
          }
        }
      }
    }
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
  }

  fragment LandingPageDataBodyFeaturedTeamMembers on PrismicLandingPageDataBodyFeaturedTeamMembers {
    items {
      person {
        document {
          ... on PrismicTeamMember {
            id
            _previewable
            data {
              full_name {
                richText
              }
              linkedin_profile {
                url
                target
                link_type
              }
              photo {
                gatsbyImageData(width: 180, layout: FULL_WIDTH)
              }
              title {
                richText
              }
            }
          }
        }
      }
    }
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
  }

  fragment EventDataBodyFeaturedTeamMembers on PrismicEventDataBodyFeaturedTeamMembers {
    items {
      person {
        document {
          ... on PrismicTeamMember {
            id
            _previewable
            data {
              full_name {
                richText
              }
              linkedin_profile {
                url
                target
                link_type
              }
              photo {
                gatsbyImageData(width: 180, layout: FULL_WIDTH)
              }
              title {
                richText
              }
            }
          }
        }
      }
    }
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
  }

  fragment SteeringGroupDataBodyFeaturedTeamMembers on PrismicSteeringGroupDataBodyFeaturedTeamMembers {
    items {
      person {
        document {
          ... on PrismicTeamMember {
            id
            _previewable
            data {
              full_name {
                richText
              }
              linkedin_profile {
                url
                target
                link_type
              }
              photo {
                gatsbyImageData(width: 180, layout: FULL_WIDTH)
              }
              title {
                richText
              }
            }
          }
        }
      }
    }
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
  }

  fragment DictionaryEntryDataBodyFeaturedTeamMembers on PrismicDictionaryEntryDataBodyFeaturedTeamMembers {
    items {
      person {
        document {
          ... on PrismicTeamMember {
            id
            _previewable
            data {
              full_name {
                richText
              }
              linkedin_profile {
                url
                target
                link_type
              }
              photo {
                gatsbyImageData(width: 180, layout: FULL_WIDTH)
              }
              title {
                richText
              }
            }
          }
        }
      }
    }
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
  }

  fragment ResourceDataBodyFeaturedTeamMembers on PrismicResourceDataBodyFeaturedTeamMembers {
    items {
      person {
        document {
          ... on PrismicTeamMember {
            id
            _previewable
            data {
              full_name {
                richText
              }
              linkedin_profile {
                url
                target
                link_type
              }
              photo {
                gatsbyImageData(width: 180, layout: FULL_WIDTH)
              }
              title {
                richText
              }
            }
          }
        }
      }
    }
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
  }
`

export default FeaturedTeamMembers
