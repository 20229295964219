import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// Utils
import detectClickOutsideElement from '../utils/DetectClickOutsideElement';

// Assets
import ListIcon from '../images/icons/list.svg';


const PageMenu = ({ className, links }) => {
  const menuContent = useRef(null);

  const windowHash = typeof window !== 'undefined' ? window.location.hash : null;

  const [activeSection, setActiveSection] = useState(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    if (windowHash) {
      setActiveSection(windowHash);
    }
  }, [windowHash]);

  useEffect(() => {
    if (menuIsOpen) window.addEventListener('click', handleClickOutside);
    else window.removeEventListener('click', handleClickOutside);
  }, [menuIsOpen]);

  // Add intersection observer to each anchor to detect when the user is scrolling on it and update the active anchor
  useEffect(() => {
    if (!Array.isArray(links) || !links.length) return;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setActiveSection(`#${entry.target.id}`);

          // Update the URL hash
          window.history.replaceState(null, null, `#${entry.target.id}`);
        }
      });
    }, {
      rootMargin: '0px 0px -50% 0px',
      threshold: 0
    });

    const sections = document.querySelectorAll('section[id]');

    sections.forEach(section => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, [links]);

  if (!Array.isArray(links) || !links.length) return null;

  const handleClickOutside = (event) => {
    const clickWasOutside = detectClickOutsideElement(event, menuContent.current);

    if (clickWasOutside) setMenuIsOpen(false);
  }

  const handleHamburgerMenuButtonClick = (event) => {
    event.stopPropagation();

    setMenuIsOpen(prevState => !prevState);
  }

  return (
    <div className={`fixed bg-white rounded-xl top-[90px] left-[10px] md:top-[20px] md:left-[20px] z-50 shadow-md${className ? ` ${className}` : ''}`}>
      <div className="flex items-center justify-between gap-[20px]">
        <button
          className={`flex items-center justify-center p-4`}
          type="button"
          onClick={handleHamburgerMenuButtonClick}
        >
          <ListIcon className="h-[20px] w-[20px]" />
        </button>
      </div>
      <div
        ref={menuContent}
        className={`absolute top-[calc(100%_+_12px)] text-[16px] w-[300px] md:w-[400px] left-0 gap-[12px] bg-white rounded-xl shadow-md max-h-[70vh] px-5 py-3 pt-[20px] overflow-auto${
        menuIsOpen ? ' grid' : ' hidden'}`}
      >
        {links.map((link, index) => {
          const { title, url } = link;

          return (
            <a
              className={`inline hover:text-[#E69635] transition-colors${activeSection === url ? ' text-[#E69635]' : ''}`}
              key={index}
              href={url}
              onClick={() => setMenuIsOpen(false)}
            >
              {title}
            </a>
          );
        })}
      </div>
    </div>
  );
};

PageMenu.propTypes = {
  className: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  )
};

PageMenu.defaultProps = {
  className: null,
  links: null
};

export default PageMenu;
