import * as React from 'react'
import { graphql } from 'gatsby'
// import { RichText } from 'prismic-reactjs'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import { Layout } from '../components/Layout'
import SliceZone from '../components/SliceZone'
import Seo from '../components/Seo'
import { RichText } from 'prismic-reactjs'

const PageTemplate = ({ data }) => {
  if (!data) return null
  
  const doc = data.prismicPage.data
  const {
    page_title,
    page_description,
    header_color,
    footer_color
  } = doc

  return (
    <Layout useDarkHeader={header_color} useDarkFooter={footer_color}>
      <Seo title={RichText.asText(page_title.richText)} description={RichText.asText(page_description.richText)} />
      {doc.body ? <SliceZone sliceZone={doc.body} /> : null}
    </Layout>
  )
}

export const query = graphql`
  query PageQuery($id: String) {
    prismicPage(id: { eq: $id }) {
      _previewable
      data {
        header_color
        footer_color
        page_description {
          richText
        }
        page_title {
          richText
        }
        body {
          ...on PrismicSliceType {
            slice_type
          }
          ...PageDataBodyAllIndividualMembers
          ...PageDataBodyAllTeamMembers
          ...PageDataBodyAllAdvisors
          ...PageDataBodyTextHero
          ...PageDataBodyHeroWithTeamMember
          ...PageDataBodyCallout
          ...PageDataBodyToggleList
          ...PageDataBodyFeaturedMemberships
          ...PageDataBodyHeroWithFeatures
          ...PageDataBodyFeaturedContent
          ...PageDataBodyGoalsSlideshow
          ...PageDataBodyFeaturedOrgMembers
          ...PageDataBodyBenefitsWithImage
          ...PageDataBodyFeaturedTeamMembers
          ...PageDataBodyLargeNewsletterForm
          ...PageDataBodySimpleRichText
          ...PageDataBodyContactTeamMembers
          ...PageDataBodyAllUpcomingEvents
          ...PageDataBodyAllPastEvents
          ...PageDataBodyFeaturedEvents
          ...PageDataBodyHubspotForm
          ...PageDataBodyAllResearchProjects
          ...PageDataBodyAllSteeringGroups
          ...PageDataBodyAllResources
          ...PageDataBodyFeaturedIndividualMembers
          ...PageDataBodyCallToAction
          ...PageDataBodyDictionaryList
          ...PageDataBodyAllRoadmapActivities
          ...PageDataBodyAllPodcasts
          ...PageDataBodyFullSizeEmbed
          ...PageDataBodyFeaturedArticles,
          ...PageDataBodySimpleImage
        }
      }
    }
  }
`

export default withPrismicPreview(PageTemplate)
