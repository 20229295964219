import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { linkResolver } from '/src/utils/LinkResolver.js';
import getCorrectTextColor from '/src/utils/SetTextColorDependingOnBackground';

import LinkButton from '/src/components/LinkButton.js';

const TextWithCallToActionsAndBackgroundImage = ({ slice }) => {
  const data = slice.primary;

  const background = getImage(data.background_image);
  const backgroundColorFilling = data.background_color_filling || '#E69635';
  const nextSectionBackgroundColor = data.next_section_background_color || '#ffffff';
  const previousSectionBackgroundColor = data.previous_section_background_color || '#ffffff';
  
  const textColor = getCorrectTextColor(backgroundColorFilling);

  return (
    <section
      style={{ background: `linear-gradient(${previousSectionBackgroundColor} 49.9%, ${nextSectionBackgroundColor} 50%)` }}
    >
      <div className="container">
        <div className="rounded-[20px] p-[20px] sm:p-[40px] bg-white">
          <div className="relative flex items-center justify-center rounded-xl py-[120px] w-full overflow-hidden">
            {!!background && (
              <GatsbyImage
                alt={background.alt || ''}
                className="absolute top-1/2 transform -translate-y-1/2 h-full w-full"
                image={background}
                layout="fixed"
                objectFit="cover"
              />
            )}
            <div
              className="absolute h-full w-full inset rounded-[12px] opacity-[0.85]"
              style={{
                backgroundColor: backgroundColorFilling
              }}
            />
            <div
              className="relative container-medium text-center px-7 py-7"
              style={{ color: textColor }}
            >
              {!!data.title.text && (
                <>
                  <h4
                    className="text-[24px] sm:text-[32px] leading-[28px] sm:leading-[37px]"
                    style={{ color: textColor }}
                  >
                    {data.title.text}
                  </h4>
                  <div
                    className="c-divider mx-auto mt-4"
                    style={{ backgroundColor: textColor }}
                  />
                </>
              )}
              {Array.isArray(data.description?.richText) && data.description.richText[0]?.text?.length > 0 && (
                <div
                  className="font-display text-[18px] sm:text-[24px] leading-[24px] sm:leading-[36px] mt-4"
                  style={{ color: textColor }}
                >
                  <RichText
                    linkResolver={linkResolver}
                    render={data.description.richText}
                  />
                </div>
              )}
              {((Array.isArray(data.call_to_action_1?.richText) && data.call_to_action_1.richText[0]?.text?.length > 0) ||
              (Array.isArray(data.call_to_action_2?.richText) && data.call_to_action_2.richText[0]?.text?.length > 0)) && (
                <div className="flex mt-5 justify-center">
                  {Array.isArray(data.call_to_action_1?.richText) && data.call_to_action_1.richText[0]?.text?.length > 0 && (
                    <LinkButton
                      className="text-[16px] sm:text-[18px] leading-[18px] font-semibold"
                      isWarning
                      label={data.call_to_action_1}
                      link={data.link_1}
                    />
                  )}
                  {Array.isArray(data.call_to_action_2?.richText) && data.call_to_action_2.richText[0]?.text?.length > 0 && (
                    <LinkButton
                      className="text-[16px] sm:text-[18px] leading-[18px] font-semibold ml-3"
                      isWarning
                      label={data.call_to_action_2}
                      link={data.link_2}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment AnnualReportDataBodyTextWithCallToActionsAndBackgroundImage on PrismicAnnualReportDataBodyTextWithCallToActionsAndBackgroundImage {
    primary {
      previous_section_background_color
      next_section_background_color
      background_color_filling
      background_image {
        alt
        gatsbyImageData
      }
      call_to_action_1 {
        richText
      }
      call_to_action_2 {
        richText
      }
      description {
        richText
      }
      link_1 {
        link_type
        slug
        target
        type
        uid
        url
      }
      link_2 {
        link_type
        slug
        target
        type
        uid
        url
      }
      title {
        text
      }
    }
  }
`;

export default TextWithCallToActionsAndBackgroundImage;
