import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { linkResolver } from '/src/utils/LinkResolver.js';
import getCorrectTextColor from '/src/utils/SetTextColorDependingOnBackground';

const TextWithImage = ({ slice }) => {
  const data = slice.primary;
  const image = getImage(data.image);

  if (!data.content_title.text || (!Array.isArray(data.content_description.richText) && !data.content_description.richText.length) || !image) return null;

  const textColor = getCorrectTextColor(data?.background_color);

  return (
    <section
      className="py-[60px] md:py-[120px]"
      style={{ backgroundColor: `${data.background_color}` }}
    >
      <div className="container">
        {!!data.title?.text && (
          <h2
            className="font-bold text-center text-[32px] sm:text-[40px] leading-[37px] sm:leading-[49px]"
            style={{ color: textColor }}
          >
            {data.title.text}
          </h2>
        )}
        {Array.isArray(data.description?.richText) && data.description.richText[0]?.text?.length > 0 && (
          <div
            className="text-center leading-relaxed mt-5"
            style={{ color: textColor }}
          >
            <RichText
              linkResolver={linkResolver}
              render={data.description.richText}
            />
          </div>
        )}
        <div className="md:columns-2 gap-24 mt-[40px] md:mt-28">
          <div
            className="break-inside-avoid-column"
            style={{ color: textColor }}
          >
            {!!data.content_title?.text && (
              <h4 className="text-[24px] sm:text-[32px] leading-[28px] sm:leading-[37px]">
                {data.content_title?.text}
              </h4>
            )}
            <div
              className="c-divider c-divider--white mt-4"
              style={{ backgroundColor: textColor }}
            />
            {Array.isArray(data.content_description?.richText) && data.content_description.richText[0]?.text?.length > 0 && (
              <div
                className="text-[18px] sm:text-[24px] leading-[24px] sm:leading-[36px] mt-6"
                style={{ color: textColor }}
              >
                <RichText
                  linkResolver={linkResolver}
                  render={data.content_description.richText}
                />
              </div>
            )}
          </div>
          {!!image && (
            <div className="mt-7 md:mt-0">
              <GatsbyImage
                alt={image.alt || ''}
                className="block max-w-lg mx-auto"
                image={image}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment AnnualReportDataBodyTextWithImage on PrismicAnnualReportDataBodyTextWithImage {
    primary {
      background_color
      title {
        text
      }
      description {
        richText
      }
      content_title {
        text
      }
      content_description {
        richText
      }
      image {
        alt
        gatsbyImageData
      }
    }
  }
`;

export default TextWithImage;
