import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { linkResolver } from '/src/utils/LinkResolver.js';
import getCorrectTextColor from '/src/utils/SetTextColorDependingOnBackground';

import LinkButton from '/src/components/LinkButton.js';

import ChevronLeft from '/src/images/icons/chevron-left.svg';
import ChevronRight from '/src/images/icons/chevron-right.svg';


const SlideshowWithBullets = ({ slice }) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [showNavigationButtons, setShowNavigationButtons] = useState(false);
  const data = slice.primary;
  const { items } = slice;
  const lastSlideIndex = items.length - 1;

  if (!Array.isArray(items) || !items.length) return null;

  const handleBulletClick = (index) => {
    setActiveSlideIndex(index);
  };

  const handleNextSlideButtonClick = () => {
    if (activeSlideIndex < lastSlideIndex) setActiveSlideIndex(activeSlideIndex + 1);
  }

  const handlePreviousSlideButtonClick = () => {
    if (activeSlideIndex > 0) setActiveSlideIndex(activeSlideIndex - 1);
  }

  const handleSlideMouseEnter = () => {
    setShowNavigationButtons(true);
  }

  const handleSlideMouseLeave = () => {
    setShowNavigationButtons(false);
  }

  const currentSlide = items[activeSlideIndex];
  const slides = items;
  const image = getImage(currentSlide.image);
  const backgroundColorFilling = currentSlide.background_color_filling || '#E69635';

  const sectionBackgroundColor = data.background_color || '#ffffff';
  const textColor = getCorrectTextColor(backgroundColorFilling);
  const sectionTextColor = getCorrectTextColor(sectionBackgroundColor);

  return (
    <section
      className="py-[60px] md:py-[120px]"
      style={{ backgroundColor: sectionBackgroundColor }}
    >
      <div className="container">
        {!!data.title?.text && (
          <h2
            className="font-bold text-center text-[32px] sm:text-[40px] leading-[37px] sm:leading-[49px]"
            style={{ color: sectionTextColor }}
          >
            {data.title.text}
          </h2>
        )}
        {Array.isArray(data.description?.richText) && data.description.richText[0]?.text?.length > 0 && (
          <div
            className="text-center leading-relaxed mt-5"
            style={{ color: sectionTextColor }}
          >
            <RichText
              linkResolver={linkResolver}
              render={data.description.richText}
            />
          </div>
        )}
        <div className="mt-[40px] lg:mt-[60px]">
          <div
            key={`slide-${activeSlideIndex}`}
            className="relative flex items-center rounded-xl w-full overflow-hidden select-none"
            onMouseEnter={handleSlideMouseEnter}
            onMouseLeave={handleSlideMouseLeave}
          >
            <div
              className="absolute h-full w-full inset rounded-xl"
              style={{
                backgroundColor: backgroundColorFilling
              }}
            />
            <div
              className={`relative px-16 py-10 lg:px-28 lg:py-16${!!image ? ' grid items-center lg:grid-cols-[1.6fr_1fr] gap-5 lg:gap-10' : ''}`}
              style={{ color: textColor }}
            >
              <div className="text-center lg:text-left">
                {!!currentSlide.title.text && (
                  <>
                    <h4 className="text-[24px] sm:text-[32px] leading-[28px] sm:leading-[37px]">
                      {currentSlide.title.text}
                    </h4>
                    <div
                      className="c-divider mx-auto lg:mx-0 mt-4"
                      style={{ backgroundColor: textColor }}
                    />
                  </>
                )}
                {Array.isArray(currentSlide.description?.richText) && currentSlide.description.richText[0]?.text?.length > 0 && (
                  <div className="text-[18px] leading-[24px] mt-4">
                    <RichText
                      linkResolver={linkResolver}
                      render={currentSlide.description.richText}
                    />
                  </div>
                )}
                {((Array.isArray(currentSlide.call_to_action_1?.richText) && currentSlide.call_to_action_1.richText[0]?.text?.length > 0) ||
                (Array.isArray(currentSlide.call_to_action_2?.richText) && currentSlide.call_to_action_2.richText[0]?.text?.length > 0)) && (
                  <div className="flex mt-5 justify-center lg:justify-start">
                    {Array.isArray(currentSlide.call_to_action_1?.richText) && currentSlide.call_to_action_1.richText[0]?.text?.length > 0 && (
                      <LinkButton
                        className="text-[16px] sm:text-[18px] leading-[18px] font-semibold"
                        isWarning
                        label={currentSlide.call_to_action_1}
                        link={currentSlide.link_1}
                      />
                    )}
                    {Array.isArray(currentSlide.call_to_action_2?.richText) && currentSlide.call_to_action_2.richText[0]?.text?.length > 0 && (
                      <LinkButton
                        className="text-[16px] sm:text-[18px] leading-[18px] font-semibold ml-3"
                        isWarning
                        label={currentSlide.call_to_action_2}
                        link={currentSlide.link_2}
                      />
                    )}
                  </div>
                )}
              </div>
              {!!image && (
                <div className="relative flex h-56 lg:h-full w-full mt-7 lg:mt-0">
                  <GatsbyImage
                    alt={image.alt || "Slideshow Image"}
                    className="mx-auto lg:ml-auto h-full w-full"
                    objectFit="contain"
                    image={image}
                  />
                </div>
              )}
            </div>
            {showNavigationButtons && activeSlideIndex > 0 && (
              <ChevronLeft
                aria-label="Previous slide"
                className="absolute h-7 w-7 top-1/2 left-[8px] sm:left-[17px] transform -translate-y-1/2 outline-none"
                role="button"
                tabIndex="0"
                onClick={handlePreviousSlideButtonClick}
                onKeyDown={handlePreviousSlideButtonClick}
                style={{ color: textColor }}
              />
            )}
            {showNavigationButtons && activeSlideIndex < lastSlideIndex && (
              <ChevronRight
                aria-label="Next slide"
                className="absolute h-7 w-7 top-1/2 right-[8px] sm:right-[17px] transform -translate-y-1/2 outline-none"
                role="button"
                tabIndex="0"
                onClick={handleNextSlideButtonClick}
                onKeyDown={handleNextSlideButtonClick}
                style={{ color: textColor }}
              />
            )}
          </div>
          <div className="flex justify-center mt-7">
            {slides.map((item, index) => (
              <div
                key={index}
                aria-label="Select slide"
                className={`rounded-full h-2 w-2 mr-2 bg-white opacity-${activeSlideIndex === index ? '100' : '50' }`}
                role="button"
                tabIndex="0"
                onClick={() => handleBulletClick(index)}
                onKeyDown={() => handleBulletClick(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment AnnualReportDataBodySlideshowWithBullets on PrismicAnnualReportDataBodySlideshowWithBullets {
    primary {
      background_color
      description {
        richText
      }
      title {
        text
      }
    }
    items {
      background_color_filling
      image {
        alt
        gatsbyImageData
      }
      call_to_action_1 {
        richText
      }
      call_to_action_2 {
        richText
      }
      description {
        richText
      }
      link_1 {
        link_type
        slug
        target
        type
        uid
        url
      }
      link_2 {
        link_type
        slug
        target
        type
        uid
        url
      }
      title {
        text
      }
    }
  }
`;

export default SlideshowWithBullets;
