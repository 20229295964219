import * as React from 'react'

import {
  format,
} from 'date-fns'

import { RichText } from 'prismic-reactjs'
import { Link } from 'gatsby'

const EventListItem = ({ url, start_date_obj, upcoming_event, title, presence }) => {
  return (
    <li className="mb-4">
      <Link 
        to={url} 
        className="relative transform hover:scale-99 transition bg-primary-100 rounded-xl p-4 lg:p-6 flex items-center"
      >
        {!upcoming_event ? <p className="text-secondary-900 text-2xl md:text-3xl font-bold">{format(start_date_obj, 'dd')}</p> : null}
        <div className="ml-8">
          <h3 className="md:w-full w-9/12 font-display font-bold text-lg md:text-xl leading-snug md:leading-normal">
            {RichText.asText(title.richText)}
          </h3>
          <p className="text-gray-500 text-sm md:text-lg mt-1 md:mt-0">
            {presence}
          </p>
        </div>

        <svg className="fill-current absolute top-1/2 -mt-3 mr-6 right-0 text-primary-900" width="24" height="25" viewBox="0 0 24 25" fill="none">
          <path d="M19.5 12.5C19.5004 12.8271 19.431 13.1505 19.2965 13.4487C19.162 13.7468 18.9655 14.0129 18.72 14.229L7.568 24.04C7.19846 24.3501 6.72225 24.5033 6.24122 24.4669C5.76018 24.4304 5.3125 24.2072 4.9939 23.845C4.67529 23.4827 4.51105 23.0102 4.5363 22.5285C4.56155 22.0467 4.77428 21.594 5.129 21.267L14.881 12.688C14.9078 12.6645 14.9292 12.6356 14.9439 12.6032C14.9586 12.5708 14.9662 12.5356 14.9662 12.5C14.9662 12.4644 14.9586 12.4292 14.9439 12.3968C14.9292 12.3644 14.9078 12.3355 14.881 12.312L5.129 3.73301C4.94043 3.57478 4.78539 3.38044 4.67299 3.16143C4.5606 2.94242 4.49312 2.70315 4.47452 2.45769C4.45592 2.21222 4.48657 1.96552 4.56469 1.73207C4.6428 1.49862 4.76678 1.28315 4.92936 1.0983C5.09194 0.91346 5.28983 0.762983 5.5114 0.655714C5.73297 0.548445 5.97374 0.486549 6.21957 0.473665C6.4654 0.460781 6.71133 0.497169 6.94289 0.58069C7.17446 0.664211 7.38699 0.793177 7.568 0.960012L18.716 10.768C18.9621 10.9846 19.1593 11.251 19.2944 11.5496C19.4296 11.8482 19.4997 12.1722 19.5 12.5Z" />
        </svg>
      </Link>
    </li>
  )
}

export default EventListItem
