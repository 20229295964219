import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import useEventbriteWidget from '../hooks/use-eventbrite-widget';

import { linkResolver } from '/src/utils/LinkResolver.js';
import LinkButton from '/src/components/LinkButton.js';
import getCorrectTextColor from '/src/utils/SetTextColorDependingOnBackground';

const ConferenceHero = ({ slice }) => {
  const { items, primary } = slice;
  const data = primary || {};

  const { call_to_action_link } = data || {};
  const { url } = call_to_action_link || {};

  const [isEventbriteLink, setIsEventbriteLink] = useState(false);

  useEventbriteWidget('eventbrite-hero-button', url);

  useEffect(() => {
    setIsEventbriteLink(url?.includes('eventbrite'))
  }, [url]);

  if (!data?.title) return null;

  const backgroundColor = data.background_color;
  const backgroundImage = data.background_image;
  const bgColor = backgroundColor ? `${data.background_color}` : 'rgb(56,96,75)';
  const textColor = getCorrectTextColor(data?.background_color);

  return (
    <section id={data.anchor_id ?? null}>
      <div
        className="relative pb-[80px] md:pb-[195px] pt-[158px] md:pt-[219px] -mt-[158px] md:-mt-[219px]"
        style={{ background: backgroundImage ? `url(${data.background_image.url})` : {}}}
      >
        <div
          className="absolute top-0 left-0 h-full w-full"
          style={{
            backgroundColor: bgColor,
            opacity: !backgroundImage ? 1 : 0.8
          }}
        />
        <div className="relative">
          <div className="container-small max-w-[600px] text-center mx-auto">
            <h1
              className="font-body font-semibold text-[32px] md:text-[40px] leading-[37px] md:leading-[44px]"
              style={{ color: textColor }}
            >
              {data.title.text}
            </h1>
            {!!data.subtitle?.text && (
              <h2
                className="font-body font-normal text-[18px] md:text-[24px] leading-[24px] md:leading-[32px] mt-[12px]"
                style={{ color: textColor }}
              >
                {data.subtitle.text}
              </h2>
            )}

            <div className="flex justify-center gap-[20px] mt-[20px]">
              {Array.isArray(data.call_to_action_text?.richText) && data.call_to_action_text.richText[0]?.text && (
                isEventbriteLink ?
                  (
                    <button
                      id="eventbrite-hero-button"
                      className="text-[14px] md:text-[16px] leading-[18px] font-semibold text-white bg-[#E69635] py-[12px] px-[20px] rounded-[5px] hover:bg-[#dc851b]"
                      type="button"
                    >
                      {data.call_to_action_text.richText[0].text}
                    </button>
                  ) :
                  (
                    <LinkButton
                      className="text-[14px] md:text-[16px] leading-[18px] font-semibold text-white bg-[#E69635] py-[12px] px-[20px] rounded-[5px] hover:bg-[#dc851b]"
                      label={data.call_to_action_text}
                      link={data.call_to_action_link}
                    />
                  )
              )}
              {Array.isArray(data.secondary_action_text?.richText) && data.secondary_action_text.richText[0]?.text && (
                <LinkButton
                  className="text-[14px] md:text-[16px] leading-[18px] font-semibold py-[12px] px-[20px] rounded-[5px]"
                  isWarning
                  label={data.secondary_action_text}
                  link={data.secondary_action_link}
                />
              )}
            </div>
          </div>
          {Array.isArray(data.description?.richText) && data.description.richText[0]?.text && (
            <div
              className="container text-center text-[18px] md:text-[20px] leading-[24px] md:leading-[28px] mt-[62px] max-w-[800px] mx-auto"
              style={{ color: textColor }}
            >
              <RichText
                linkResolver={linkResolver}
                render={data.description.richText}
              />
            </div>
          )}
        </div>
      </div>
      {Array.isArray(items) && !!items.length && !!items[0].card_title.text && (
        <div className="container mb-[70px] md:mb-[140px]">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-[20px] justify-center mt-[-50px] md:mt-[-100px] md:px-[67px]">
            {items.map((item, index) => {
              const image = getImage(item.card_image);

              return (
                <div
                  key={index}
                  className="relative h-fit bg-white rounded-[8px] p-[32px] drop-shadow-md z-10"
                >
                  {!!image && (
                    <GatsbyImage
                      alt={item.card_image.alt || ''}
                      className="block h-[80px] w-[80px] mx-auto"
                      image={image}
                    />
                  )}
                  {!!item.card_title?.text && (
                    <h3
                      className="font-body font-semibold text-center text-[#1B202B] text-[18px] md:text-[24px] leading-[28px] md:leading-[36px] mt-[20px]"
                      style={{ color: textColor }}
                    >
                      {item.card_title.text}
                    </h3>
                  )}
                  {Array.isArray(item?.card_description?.richText) && item.card_description.richText[0]?.text && (
                    <div
                      className="font-normal text-center text-[#1B202B] text-[16px] md:text-[18px] leading-[24px] md:leading-[27px] mt-[12px]"
                      style={{ color: textColor }}
                    >
                      <RichText
                        linkResolver={linkResolver}
                        render={item.card_description.richText}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </section>
  );
};

export const query = graphql`
  fragment ConferenceDataBodyConferenceHero on PrismicDigitalSustainabilityConferenceDataBodyConferenceHero {
    primary {
      title {
        text
      }
      subtitle {
        text
      }
      description {
        richText
      }
      call_to_action_text {
        richText
      }
      call_to_action_link {
        url
        target
        type
        uid
        slug
        link_type
      }
      secondary_action_text {
        richText
      }
      secondary_action_link {
        url
        target
        type
        uid
        slug
        link_type
      }
      background_image {
        alt
        url
        gatsbyImageData
      }
      background_color
    }
    items {
      card_description {
        richText
      }
      card_image {
        alt
        url
        gatsbyImageData
      }
      card_title {
        text
      }
    }
  }
`;

export default ConferenceHero;
