import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import LinkButton from '../components/LinkButton.js'
import TeamMemberBiography from '../components/TeamMemberBiography.js'

const FeaturedIndividualMembers = ({ slice }) => {
  const { primary, items } = slice
  
  const individual_members = items.map(item => item.featured_individual_member.document)

  return (
    <section className="mt-16 sm:mt-20">
      <div className="container">
        {!!primary.title && (
          <h2 className="text-center">
            {RichText.asText(primary.title.richText)}
          </h2>
        )}

        {!!primary.subtitle && (
          <p className="text-xl font-light font-display text-gray-500 mt-3 text-center">
            {RichText.asText(primary.subtitle.richText)}
          </p>
        )}

        {Array.isArray(individual_members) && individual_members.length > 0 && (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-12 mb-0">
            {individual_members.map((member, index) => member?.data ?
              (
                <TeamMemberBiography
                  {...member.data}
                  key={`team-member-photo-${index}`}
                />
              ) :
              null
            )}
          </div>
        )}

        {!!primary.cta && !!primary.link.url && (
          <div className="mt-12 text-center">
            <LinkButton
              isWarning link={primary.link}
              label={primary.cta}
            />
          </div>
        )}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyFeaturedIndividualMembers on PrismicPageDataBodyFeaturedIndividualMembers {
    items {
      featured_individual_member {
        document {
          ...PrismicIndividualMemberFragment
        }
      }
    }

    primary {
      link {
        url
        uid
        type
        target
        link_type
      }
      subtitle {
        richText
      }
      title {
        richText
      }
      cta: call_to_action
    }
  }

  fragment HomepageDataBodyFeaturedIndividualMembers on PrismicHomepageDataBodyFeaturedIndividualMembers {
    items {
      featured_individual_member {
        document {
          ...PrismicIndividualMemberFragment
        }
      }
    }

    primary {
      link {
        url
        uid
        type
        target
        link_type
      }
      subtitle {
        richText
      }
      title {
        richText
      }
      cta: call_to_action
    }
  }

  fragment SteeringGroupDataBodyFeaturedIndividualMembers on PrismicSteeringGroupDataBodyFeaturedIndividualMembers {
    items {
      featured_individual_member {
        document {
          ...PrismicIndividualMemberFragment
        }
      }
    }

    primary {
      link {
        url
        uid
        type
        target
        link_type
      }
      subtitle {
        richText
      }
      title {
        richText
      }
      cta: call_to_action
    }
  }

  fragment ResourceDataBodyFeaturedIndividualMembers on PrismicResourceDataBodyFeaturedIndividualMembers {
    items {
      featured_individual_member {
        document {
          ...PrismicIndividualMemberFragment
        }
      }
    }

    primary {
      link {
        url
        uid
        type
        target
        link_type
      }
      subtitle {
        richText
      }
      title {
        richText
      }
      cta: call_to_action
    }
  }

  fragment EventDataBody1FeaturedIndividualMembers on PrismicEventDataBody1FeaturedIndividualMembers {
    items {
      featured_individual_member {
        document {
          ...PrismicIndividualMemberFragment
        }
      }
    }

    primary {
      link {
        url
        uid
        type
        target
        link_type
      }
      subtitle {
        richText
      }
      title {
        richText
      }
      cta: call_to_action
    }
  }

  fragment MembershipDataBodyFeaturedIndividualMembers on PrismicMembershipDataBodyFeaturedIndividualMembers {
    items {
      featured_individual_member {
        document {
          ...PrismicIndividualMemberFragment
        }
      }
    }

    primary {
      link {
        url
        uid
        type
        target
        link_type
      }
      subtitle {
        richText
      }
      title {
        richText
      }
      cta: call_to_action
    }
  }

  fragment OrganizationMemberDataBodyFeaturedIndividualMembers on PrismicOrganizationMemberDataBodyFeaturedIndividualMembers {
    items {
      featured_individual_member {
        document {
          ...PrismicIndividualMemberFragment
        }
      }
    }

    primary {
      link {
        url
        uid
        type
        target
        link_type
      }
      subtitle {
        richText
      }
      title {
        richText
      }
      cta: call_to_action
    }
  }

  fragment LandingPageDataBodyFeaturedIndividualMembers on PrismicLandingPageDataBodyFeaturedIndividualMembers {
    items {
      featured_individual_member {
        document {
          ...PrismicIndividualMemberFragment
        }
      }
    }

    primary {
      link {
        url
        uid
        type
        target
        link_type
      }
      subtitle {
        richText
      }
      title {
        richText
      }
      cta: call_to_action
    }
  }
`

export default FeaturedIndividualMembers
