import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { RichText } from 'prismic-reactjs'

const SteeringGroup = ({ 
  uid,
  url,
  start_date,
  background_image,
  icon_light,
  icon_dark,
  summary,
  name,
  state,
  roadmap_activity,
  moderator,
  subject_matter_expert, 
}) => {
  const fadeOut = (state === 'Upcoming' || state === 'Assembling' || state === 'Finished')
  const darkIcon = getImage(icon_dark)
  const lightIcon = getImage(icon_light)
  
  if (!name) return null

  return (
    <Link 
      to={url}
      className={`
        ${fadeOut ? 
          'bg-primary-100 hover:bg-primary-200' : 
          'bg-primary-900 hover:bg-primary-800 text-white '
        }
        transform 
        hover:scale-99 transition p-4 
        rounded-xl md:flex-col md:justify-between
      `}
    >
      <div className="md:mb-6 w-4/12 md:w-full">
        <p className={`text-sm uppercase mb-4 tracking-widest md:text-base ${fadeOut ? 'text-primary-600' : 'text-primary-400'}`}>
          {state}
        </p>

        {fadeOut ? 
          <GatsbyImage image={darkIcon} alt={RichText.asText(name.richText)} /> : 
          <GatsbyImage image={lightIcon} alt={RichText.asText(name.richText)} />}
      </div>
      
      <div className="flex-1 mt-2 md:mt-0 pb-2 md:ml-0">
        {roadmap_activity && roadmap_activity.document && roadmap_activity.document.data ? 
          <p className="text-sm md:text-base leading-snug md:leading-normal">{RichText.asText(roadmap_activity.document.data.name.richText)}</p> : null}
        <h5 className="mt-2 text-lg md:text-xl md:leading-tight leading-snug">{RichText.asText(name.richText)}</h5>
      </div>
    </Link>
  )
}

export default SteeringGroup

export const steeringGroupFragment = graphql`
  fragment PrismicSteeringGroupFragment on PrismicSteeringGroup {
    _previewable
    uid
    url

    data {
      start_date
      
      background_image {
        gatsbyImageData(width: 800)
      }
      icon_light {
        url
        gatsbyImageData(width: 80)
      }
      icon_dark {
        url
        gatsbyImageData(width: 80)
      }
      summary {
        richText
      }
      name {
        richText
      }

      # Running
      # Finished
      # Upcoming
      # Assembling
      state 

      roadmap_activity {
        document {
          ... on PrismicRoadmapActivity {
            id
            data {
              name {
                richText
              }
            }
          }
        }
      }

      moderator {
        document {
          ... on PrismicTeamMember {
            id
            url

            data {
              full_name {
                richText
              }
              public_email
              photo {
                gatsbyImageData(
                  width: 130, height: 130, imgixParams: {
                    fit: "facearea",
                    facepad: 3.5
                    maxH: 130
                  }
                )
              }
              title {
                richText
              }
            }
          }
        }
      }

      subject_matter_expert {
        document {
          ... on PrismicIndividualMember {
            id
            url

            data {
              title {
                richText
              }
              full_name {
                richText
              }
              linkedin_profile {
                url
                uid
                type
                target
                link_type
              }
              biography {
                richText
              }
              photo {
                gatsbyImageData(
                  width: 130, height: 130, imgixParams: {
                    fit: "facearea",
                    facepad: 3.5
                    maxH: 130
                  }
                )
              }
            }
          }
        }
      }
    }
  }
`
