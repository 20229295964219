import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { linkResolver } from '../utils/LinkResolver.js';
import LinkButton from '/src/components/LinkButton.js';
import getCorrectTextColor from '/src/utils/SetTextColorDependingOnBackground';

import useEventbriteWidget from '../hooks/use-eventbrite-widget.js';

const ConferenceTextWithCallToActionAndSideImage = ({ slice }) => {
  const { primary } = slice;
  const data = primary;

  const { anchor_id, call_to_action_link } = data || {};
  const { url } = call_to_action_link || {};

  const [isEventbriteLink, setIsEventbriteLink] = useState(false);

  useEventbriteWidget('eventbrite-text-and-image-button', url);

  useEffect(() => {
    setIsEventbriteLink(url?.includes('eventbrite'))
  }, [url]);

  if (!data?.title?.text) return null;

  const backgroundColor = data.background_color;
  const image = getImage(data.image);
  const textColor = getCorrectTextColor(backgroundColor);

  return (
    <section
      className={`relative ${backgroundColor ? 'py-[80px]' : 'my-[80px] md:my-[140px] scroll-mt-[80px]'}`}
      id={anchor_id ?? null}
      style={{ backgroundColor: backgroundColor ? `${backgroundColor}` : '#FFFFFF'}}
    >
      <div className="container md:px-[106px]">
        <div className="flex flex-col md:flex-row gap-[80px]">
          <div className="md:max-w-[350px] lg:max-w-[520px]">
            <h2
              className="font-body font-semibold text-[32px] md:text-[40px] text-white leading-[37px] md:leading-[44px]"
              style={{ color: textColor }}
            >
              {data.title.text}
            </h2>
            {Array.isArray(data.description?.richText) && data.description.richText[0]?.text && (
              <div
                className="text-[18px] prose md:text-[20px] leading-[24px] md:leading-[28px] text-white mx-auto mt-[20px]"
                style={{ color: textColor }}
              >
                <RichText
                  linkResolver={linkResolver}
                  render={data.description.richText}
                />
              </div>
            )}
            <div className="mt-[32px]">
              {Array.isArray(data.call_to_action_text?.richText) && data.call_to_action_text.richText[0]?.text &&
                isEventbriteLink ?
                  (
                    <button
                      id="eventbrite-text-and-image-button"
                      className="text-[14px] md:text-[16px] leading-[18px] font-semibold text-white bg-[#E69635] py-[12px] px-[20px] rounded-[5px] hover:bg-[#dc851b]"
                      type="button"
                    >
                      {data.call_to_action_text.richText[0].text}
                    </button>
                  ) :
                  (
                    <LinkButton
                      className="text-[14px] md:text-[16px] leading-[18px] font-semibold text-white bg-[#E69635] py-[12px] px-[20px] rounded-[5px] hover:bg-[#dc851b]"
                      label={data.call_to_action_text}
                      link={data.call_to_action_link}
                    />
                  )
              }
            </div>
          </div>
          {!!image && (
            <GatsbyImage
              alt={data.image.alt || ''}
              className="flex h-[300px] md:h-auto md:max-h-[346px] w-full"
              image={image}
              objectFit="contain"
            />
          )}
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ConferenceDataBodyConferenceTextWithCallToActionAndSideImage on PrismicDigitalSustainabilityConferenceDataBodyConferenceTextWithCallToActionAndSideImage {
    primary {
      title {
        text
      }
      description {
        richText
      }
      call_to_action_text {
        richText
      }
      call_to_action_link {
        url
        target
        type
        uid
        slug
        link_type
      }
      image {
        alt
        url
        gatsbyImageData
      }
      background_color
    }
  }
`

export default ConferenceTextWithCallToActionAndSideImage;
