import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const BlogCategories = ({ categories, currentCategory }) => {
  if (!Array.isArray(categories) || !categories.length) return null;

  return (
    <section>
      <h5 className="text-[18px] leading-[21px] text-[#1D4732] font-medium">
        Categories
      </h5>
      <ul className="list-none flex flex-col gap-[12px] mt-[20px]">
        {categories.map(item => (
            <li key={item.uid}>
              <Link
                rel="button"
                className={`${currentCategory === item.data.title.text ? 'pointer-events-none text-white bg-[#385F4B]' : 'text-[#1D4732] bg-[#38604B1F] hover:bg-[#385F4B52]'} inline-flex items-center rounded-[30px] px-[12px] py-[4px] text-[14px] leading-[16px] last-of-type:mb-0`}
                to={`/blog/category/${item.uid}`}
              >
                {item.data.title.text}
                {/* TODO: Find way to retrieve number of articles per category from Prismic */}
                {/* <div className="flex items-center justify-center h-[20px] w-[20px] rounded-full ml-[8px] bg-white">
                  <span className="text-[14px] leading-[14px] text-[#385F4B]">
                    2
                  </span>
                </div> */}
              </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};

BlogCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.shape({
      title: PropTypes.shape({
        text: PropTypes.string
      })
    }),
    uid: PropTypes.string
  })),
  currentCategory: PropTypes.string
};

BlogCategories.defaultProps = {
  categories: null,
  currentCategory: null
};

export default BlogCategories;
