import * as React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/LinkResolver'
import LinkButton from './LinkButton'

import Ticket from '../images/icons/streamline-icon-ticket.svg'

const EventTicketInfo = ({ 
  link_label,
  link,
  information,
  costs, 
  className,
}) => {
  return (
    <div className={`${className}`}>
      <h4>
        Ticket Information
        {costs ? 
          <span className="text-gray-500 text-sm ml-2 font-normal">
            {RichText.asText(costs.richText)}
          </span> : null}
      </h4>
      
      {information ? 
        <div className="prose mt-3">
          <RichText render={information.richText} linkResolver={linkResolver} />
        </div> : null}

      {link && link_label ? 
        <LinkButton link={link} className="mt-4" isWarning>
          {link_label}
          <Ticket className="stroke-current fill-current w-4 h-4 ml-2" />
        </LinkButton> : null}
    </div>
  )
}

export default EventTicketInfo