import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { RichText } from 'prismic-reactjs';

import { Layout } from '/src/components/Layout';
import PageMenu from '/src/components/PageMenu';
import SliceZone from '/src/components/SliceZone';
import Seo from '/src/components/Seo';

const DigitalSustainabilityConferenceTemplate = ({ data }) => {
  const [pageAnchors, setPageAnchors] = useState(null);
  const [showPageMenu, setShowPageMenu] = useState(false);

  const {
    body,
    event_timezone,
    share_description,
    share_image,
    share_title,
  } = data.prismicDigitalSustainabilityConference?.data || {};

  let pageDescription = null;
  let pageTitle = null;
  let shareImage = share_image;

  if (share_description && Array.isArray(share_description.richText) && share_description.richText[0]?.text?.length > 0) {
    pageDescription = RichText.asText(share_description.richText);
  }

  if (share_title && Array.isArray(share_title.richText) && share_title.richText[0]?.text?.length > 0) {
    pageTitle = RichText.asText(share_title.richText);
  }

  if (share_image) shareImage = share_image;

  useEffect(() => {
    if (body) {
      const pageAnchors = [];

      for (const slice of body) {
        const { text } = slice.primary?.title || {};

        if (text) {
          const titleInKebabCase = text?.toLowerCase().replace(/\s/g, '-');

          const pageAnchor = {
            title: text,
            url: `#${titleInKebabCase}`
          };

          pageAnchors.push(pageAnchor)

          slice.primary.anchor_id = titleInKebabCase;
        }
      }

      setPageAnchors(pageAnchors);
    }
  }, [body]);

  useEffect(() => {
    // Show PageMenu only after the user scrolls more than 100px
    const handleScroll = () => {
      setShowPageMenu(window.scrollY > 200);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Layout
      useDarkFooter={true}
      useDarkHeader={false}
    >
      <Seo
        description={pageDescription}
        image={shareImage}
        title={pageTitle || 'Digital Sustainability Conference'}
      />
      <div className="bg-white">
        {pageAnchors && (
          <PageMenu
            className={`transition-all duration-300${showPageMenu ? ' opacity-100' : ' opacity-0'}`}
            links={pageAnchors}
          />
        )}
        {body && (
          <SliceZone
            className="[&_section:first-of-type]:mt-[158px] [&_section:first-of-type]:md:mt-[219px]"
            sliceZone={body}
            timezone={event_timezone}
          />
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query DigitalSustainabilityConference($id: String) {
    prismicDigitalSustainabilityConference(id: { eq: $id }) {
      _previewable
      data {
        share_description {
          richText
        }
        share_title {
          richText
        }
        share_image {
          url
        }
        event_timezone
        body {
          ...on PrismicSliceType {
            slice_type
          }
          ...ConferenceDataBodyConferenceHero
          ...ConferenceDataBodyConferenceTextBlock
          ...ConferenceDataBodyConferenceCountdown
          ...ConferenceDataBodyConferenceSpeakers
          ...ConferenceDataBodyConferenceQuoteWithImage
          ...ConferenceDataBodyConferenceCallToActionsAndImage
          ...ConferenceDataBodyConferenceSponsors
          ...ConferenceDataBodyConferenceTextWithCallToActionAndSideImage
          ...ConferenceDataBodyConferenceItemsWithTextAndSideImage
          ...ConferenceDataBodyToggleList
          ...ConferenceDataBodyConferenceCalendar
          ...ConferenceDataBodyKeyOutcomes
          ...ConferenceDataBodySpeakerHighlights
          ...ConferenceDataBodyStatisticsTextItems
          ...ConferenceDataBodySectionHeader
          ...ConferenceDataBodyImageSlider
          ...ConferenceDataBodyStatisticsCards
          ...ConferenceDataBodyAccordionWithBackground
          ...ConferenceDataBodySpeakerPresentationSlides
          ...ConferenceDataBodyFullWidthVideoBlock
        }
      }
    }
  }
`;

export default withPrismicPreview(DigitalSustainabilityConferenceTemplate);
