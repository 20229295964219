import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Pagination = ({ basePath, pageInfo, pagePath }) => {
  const { currentPage, hasNextPage, hasPreviousPage, pageCount } = pageInfo;

  const getPageNumberPath = (pageNumber) => {
    if (pageNumber === 1) {
      return basePath;
    }

    return `${pagePath}/${pageNumber}`;
  }

  const prevPagePath = getPageNumberPath(currentPage - 1);
  const nextPagePath = getPageNumberPath(currentPage + 1);

  return (
    <div className="flex items-center">
      <Link
        className={`inline-flex justify-center items-center px-[16px] py-[7px] mr-[23px] border rounded-[8px] border-[#CBD4E1] text-[14px] leading-[22px] text-[#1D4732] font-semibold hover:bg-[#1D4732] hover:text-white hover:border-[#1D4732]${!hasPreviousPage ? ' text-[#CBD4E1] pointer-events-none' : ''}`}
        rel="prev"
        to={prevPagePath}
      >
        Previous
      </Link>
     <div className="flex gap-[4px]">
      {Array.from({ length: pageCount }, (item, index) => (
            <Link
              key={index + 1}
              className={`inline-flex justify-center items-center h-[32px] w-[32px] rounded-[8px] text-[14px] leading-[22px] hover:bg-[#1D4732] hover:text-white text-xs ${currentPage === index + 1 ? 'bg-[#1D4732] text-white pointer-events-none' : ' text-[#94A3B8]'}`}
              to={getPageNumberPath(index + 1)}
            >
              {index + 1}
            </Link>
          )
        )}
     </div>
      <Link
        className={`inline-flex justify-center items-center px-[16px] py-[7px] ml-[23px] border rounded-[8px] border-[#CBD4E1] text-[14px] leading-[22px] text-[#1D4732] font-semibold hover:bg-[#1D4732] hover:text-white hover:border-[#1D4732]${!hasNextPage ? ' text-[#CBD4E1] pointer-events-none' : ''}`}
        rel="next"
        to={nextPagePath}
      >
        Next
      </Link>
    </div>
  );
};

Pagination.propTypes = {
  basePath: PropTypes.string,
  pageInfo: PropTypes.shape({
    currentPage: PropTypes.number,
    hasNextPage: PropTypes.bool,
    hasPreviousPage: PropTypes.bool,
    pageCount: PropTypes.number
  }),
  pagePath: PropTypes.string
};

Pagination.defaultProps = {
  basePath: null,
  pageInfo: null,
  pagePath: null
};

export default Pagination;
