import * as React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import { linkResolver } from '../utils/LinkResolver.js';


const ConferenceQuoteWithImage = ({ slice }) => {
  const { anchor_id, author_name, author_role, image, quote } = slice.primary;

  if (!quote || !author_name) return null;

  return (
    <section
      className="relative py-[80px] bg-center bg-no-repeat bg-cover"
      id={anchor_id ?? null}
      style={{ background: image ? `url(${image.url})` : '#FCFCFC'}}
    >
      <div className="container">
        <div className="flex gap-[20px] rounded-[12px] bg-[#F2F2F5] p-[32px] ml-auto mr-auto md:mr-0 md:ml-auto max-w-[497px]">
          <div className="text-[60px] md:text-[130px] leading-[72px] md:leading-[140px] text-[#527563]">
            “
          </div>
          <div>
            {Array.isArray(quote?.richText) && quote.richText[0]?.text && (
              <div className="text-[16px] md:text-[20px] leading-[20px] md:leading-[28px] text-[#1B202B]">
                <RichText
                  linkResolver={linkResolver}
                  render={quote.richText}
                />
              </div>
            )}
            {Array.isArray(author_name?.richText) && author_name.richText[0]?.text && (
              <div className="font-sans text-[18px] leading-[24px] text-[#101019] mt-[20px]">
                <RichText
                  linkResolver={linkResolver}
                  render={author_name.richText}
                />
              </div>
            )}
            {Array.isArray(author_name?.richText) && author_name.richText[0]?.text &&
            Array.isArray(author_role?.richText) && author_role.richText[0]?.text && (
              <div className="font-sans text-[14px] leading-[20px] text-[#777785]">
                <RichText
                  linkResolver={linkResolver}
                  render={author_role.richText}
                />
              </div>
            )}
          </div>
          <div className="self-end text-[60px] md:text-[130px] leading-[72px] md:leading-[80px] text-[#527563]">
            ”
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ConferenceDataBodyConferenceQuoteWithImage on PrismicDigitalSustainabilityConferenceDataBodyConferenceQuoteWithImage {
    primary {
      quote {
        richText
      }
      author_name {
        richText
      }
      author_role {
        richText
      }
      image {
        alt
        url
        gatsbyImageData
      }
    }
  }
`

export default ConferenceQuoteWithImage;
