import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { RichText } from 'prismic-reactjs'
import LinkButton from './LinkButton'
import { linkResolver } from '../utils/LinkResolver'

import PlaceholderImage from '../images/icons/file.svg'


const Resource = ({ 
  url,
  preview_image,
  link_to_member_content,
  description,
  members_only,
  published_on,
  resource,
  resource_type,
  type,
  name,
}) => {
  return (
    <div
      className="bg-primary-100 rounded p-4 lg:py-8"
    >
      <div className="w-full">
        <Link to={url} target={resource.target}>
          <div className="aspect-[12/17]">
            {preview_image ?
             (
              <GatsbyImage
                image={preview_image}
                className="shadow-md h-full w-full rounded bg-white"
                alt={RichText.asText(name.richText)}
              />
             ) :
             (
              <PlaceholderImage className="w-full" />
             )}
          </div>
        </Link>
      </div>

      <div className="mt-6">
        <p className="text-sm uppercase tracking-wider font-display font-bold text-primary-900">
          {type || resource_type}
        </p>
        
        <h5 className="mt-1 leading-1">
          <Link to={url} target={resource.target}>
            {RichText.asText(name.richText)}
          </Link>
        </h5>
        
        {description ? 
          <div className="prose mt-3">
            <div className="line-clamp-6">
              <RichText
                render={description.richText}
                linkResolver={linkResolver}
              />
            </div>

            <LinkButton link={{ url: url }} isWarning className="mt-3">
              {resource_type !== 'Resource' ? 'View' : 'Download'}
            </LinkButton>
          </div> : null}
      </div>
    </div>)
  }

export default Resource

export const resourceFragment = graphql`
  fragment PrismicResourceFragment on PrismicResource {
    _previewable
    uid
    url

    data {
      description {
        richText
      }
      link_to_member_content {
        url
        type
        target
        link_type
      }
      members_only
      name {
        richText
        text
      }
      preview_image {
        gatsbyImageData
      }
      published_on
      resource {
        link_type
        url
        type
        target
      }
      type
      detail_cta {
        url
        uid
        type
        target
        link_type
      }
      detail_cta_label
    }
  }
`
