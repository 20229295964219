import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';

import { linkResolver } from '/src/utils/LinkResolver.js';

import Modal from 'react-bootstrap/Modal';

import CloseIcon from '/src/images/icons/close.svg';

const CalendarModal = ({ content, onClose, showModal }) => {
  const items = content?.items;

  if (!content || !Array.isArray(items) || !items.length) return null;

  const timeEntries = items.find(entry => entry.slice_type === 'calendar_start_and_end_time');
  const topics = items.filter(entry => entry.slice_type === 'calendar_topic');

  if (!Array.isArray(timeEntries?.items) || !timeEntries.items.length || !Array.isArray(topics) || !topics.length ) return null;

  return (
    <Modal
      centered
      contentClassName="relative bg-white rounded-[12px] border-0 p-[40px]"
      dialogClassName="container max-w-[90%]"
      scrollable
      show={showModal}
      onHide={onClose}
    >
      <Modal.Header className="flex justify-between border-0 p-0 mb-[40px]">
        <h2 className="font-semibold text-[20px] md:text-[32px] text-[#1B202B] leading-[28px] md:leading-[40px]">
          {content.title?.text}
        </h2>
        <div
          className="bg-white rounded-md p-[8px]"
          role="button"
          tabIndex={0}
          onKeyDown={onClose}
          onClick={onClose}
        >
          <CloseIcon className="flex" />
        </div>
      </Modal.Header>
      <Modal.Body className="p-0">
        <table
          className="table-fixed border-separate"
          style={{ borderSpacing: '12px' }}
        >
          <thead>
            <tr>
              <th width="120px"></th>
              {topics.map((topic, index) => (
                <th
                  key={index}
                  width="210px"
                  style={{ minWidth: '210px' }}
                >
                  {topic?.primary?.topic_title?.text && (
                    <div className="bg-[#385F4B] rounded-[12px] px-[12px] py-[22px] mb-[8px]">
                      <div className="font-display text-center text-[16px] leading-[18px] text-[#F2F2F5]">
                        {topic.primary.topic_title.text}
                      </div>
                    </div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {timeEntries.items.map((timeEntry, index) => {
               let endDateString = null;
               let startDateString = null;

               const dateTimeOptions = { hour12: false, hour: '2-digit', minute: '2-digit', timeZone: 'UTC' };
               const dateTime = new Intl.DateTimeFormat([], dateTimeOptions);

               if (timeEntry.end_date) endDateString = dateTime.format(new Date(timeEntry.end_date));
               startDateString = dateTime.format(new Date(timeEntry.start_date));

              const row = (
                <tr>
                  <td valign="top">
                    <div className="flex items-center justify-center rounded-[8px] w-[120px] p-[11.5px] bg-[#F2F2F5]">
                      <div className="font-bold text-[16px] leading-[20px] text-[#385F4B]">
                        {startDateString}
                      </div>
                      &nbsp;
                      <div className="font-bold text-[16px] leading-[20px] text-[#385F4B]">
                        -
                      </div>
                      &nbsp;
                      <div className="font-bold text-[16px] leading-[20px] text-[#385F4B]">
                        {endDateString}
                      </div>
                    </div>
                  </td>
                  {topics.map((topic, topicIndex) => (
                    <td
                      key={topicIndex}
                      width="210px"
                      style={{ minWidth: '210px' }}
                    >
                      {!!topic.items[index]?.item_text?.richText[0]?.text && (
                        <div className="rounded-[12px] p-[12px] pb-[27px] bg-white drop-shadow-md">
                          <div className="font-body font-normal text-left text-[14px] leading-[21px] text-[#1B202B]">
                            <RichText
                              linkResolver={linkResolver}
                              render={topic.items[index].item_text.richText}
                            />
                          </div>
                        </div>
                      )}
                    </td>
                  ))}
                </tr>
              )
            
              return (
                <React.Fragment key={index}>
                  {timeEntry.add_break_after ?
                    (
                      <React.Fragment key={index}>
                        {row}
                        <tr>
                          <td width="120px"></td>
                          <td colSpan={5}>
                            <div className="flex items-center justify-center rounded-[8px] w-full p-[8px] text-[16px] leading-[26px] text-[#527563] bg-[#52756333]">
                              Break
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    ) : row}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  )
};

CalendarModal.propTypes = {
  content: PropTypes.shape({}),
  onClose: PropTypes.func,
  showModal: PropTypes.bool
};

CalendarModal.defaultProps = {
  content: null,
  onClose: () => {},
  showModal: false
}

export default CalendarModal;
