import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { RichText } from 'prismic-reactjs'

import Layout from '../../components/Layout'
import SteeringGroupDetail from '../../components/SteeringGroupDetail'
import SliceZone from '../../components/SliceZone'
import Seo from '../../components/Seo'

const SteeringGroupTemplate = ({ data }) => {
  if (!data) return null
  const doc = data.prismicSteeringGroup.data

  const {
    name,
    summary,
  } = doc

  return (
    <Layout useDarkHeader={true} useDarkFooter={true}>
      <Seo title={RichText.asText(name.richText)} description={RichText.asText(summary.richText)} />
      
      <div className="pt-12 md:pt-6">
        <SteeringGroupDetail {...doc} id={data.prismicSteeringGroup.id} />
        
        {doc.body ? <SliceZone sliceZone={doc.body} /> : null}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query SteerinGroupQuery($id: String) {
    prismicSteeringGroup(id: { eq: $id }) {
      ...PrismicSteeringGroupFragment

      data {
        body {
          ...on PrismicSliceType {
            slice_type
          }
          ...SteeringGroupDataBodyFeaturedOrgMembers
          ...SteeringGroupDataBodyFeaturedTeamMembers
          ...SteeringGroupDataBodyFeaturedResources
          ...SteeringGroupDataBodyFeaturedSteeringGroups
          ...SteeringGroupDataBodyHubspotForm
          ...SteeringGroupDataBodyHubspotFormWithTeamMember
          ...SteeringGroupDataBodyToggleList
          ...SteeringGroupDataBodyCallout
          ...SteeringGroupDataBodyFullQuote
          ...SteeringGroupDataBodyFeaturedContent
          ...SteeringGroupDataBodyFeaturedEvents
          ...SteeringGroupDataBodySimpleRichText
          ...SteeringGroupDataBodyLargeNewsletterForm
          ...SteeringGroupDataBodyBenefitsWithImage
          ...SteeringGroupDataBodyFeaturedIndividualMembers
          ...SteeringGroupDataBodyCallToAction
          ...SteeringGroupDataBodyTaggboxEmbed
          ...SteeringGroupDataBodyFullSizeEmbed
          ...SteeringGroupDataBodyFeaturedArticles
        }
      }
    }
  }
`

export default withPrismicPreview(SteeringGroupTemplate)
