import * as React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import { linkResolver } from '../utils/LinkResolver.js';

const ArticleQuote = ({ slice }) => {
  if(!slice || !slice.primary) return null;

  const { primary } = slice;

  return (
    <section className="flex flex-col my-[40px] w-full p-[24px] bg-[#F2F2F5] rounded-[8px]">
      <blockquote className="text-[20px] leading-[32px] text-[#1D4732] italic">
        {primary.quote && (
          <RichText
            render={primary.quote.richText}
            linkResolver={linkResolver}
          />
        )}
        {primary.attribution && (
          <cite className="flex justify-end text-[#414152] text-[18px] leading-[25px] mt-[20px] not-italic">
            {RichText.asText(primary.attribution.richText)}
          </cite>
        )}
      </blockquote>
    </section>
  );
};

export const query = graphql`
  fragment ArticleDataBodyArticleQuote on PrismicArticleDataBodyArticleQuote {
    primary {
      quote {
        richText
      }
      attribution {
        richText
      }
    }
  }
`;

export default ArticleQuote;
