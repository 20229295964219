import * as React from 'react'
import BackButtonArrow from '../images/icons/back-arrow.svg'
import { navigate } from "gatsby"

const BackButton = ({ children, className }) => {
  return (
    <button
      onClick={() => navigate(-1)}
      title="Back" 
      className={`${className} -ml-4 cursor-pointer place-self-center inline-flex items-center px-3 py-2 transition hover:bg-secondary-100 rounded`}
    >
      <BackButtonArrow className="stroke-current text-secondary-900 w-7 h-7" />
      <span className="ml-2 font-bold">{children}</span>
    </button>
  )
}

export default BackButton
