import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { linkResolver } from '/src/utils/LinkResolver.js';
import getCorrectTextColor from '/src/utils/SetTextColorDependingOnBackground';

import ModalSlider from '/src/components/ModalSlider.js';

import PlusIcon from '/src/images/icons/plus.svg';

const AnnualReportSubjects = ({ slice }) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const { items, primary: data } = slice || {};

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleItemClick = (index) => {
    setActiveSlideIndex(index);
  };

  const handleSlideChange = (index) => {
    setActiveSlideIndex(index);
  };

  useEffect(() => {
    if (typeof activeSlideIndex === 'number') setShowModal(true);
  }, [activeSlideIndex]);

  useEffect(() => {
    if (!showModal) {
      // We need a timeout to prevent the modal content to disappear (because we are setting the index to null) before the modal is close
      setTimeout(() => {
        setActiveSlideIndex(null);
      }, 10)
    };
  }, [showModal]);

  if (!Array.isArray(items) || !items.length) return null;

  const slides = items.map(item => {
    const subject = item?.subject?.document?.data;
    const { body, call_to_action, color, description, link, title } = subject || {};

    const topics = Array.isArray(body) ?
      body.find(slice => Array.isArray(slice?.items) && slice.items.some(sliceItem => sliceItem?.topic_title))?.items :
      null;

    return {
      ...item,
      call_to_action: call_to_action?.richText,
      color,
      description: description?.richText,
      link,
      title: title?.text,
      topics: Array.isArray(topics) ? topics : []
    };
  });

  const textColor = getCorrectTextColor(data?.background_color);

  return (
    <section
      className="py-[60px] md:py-[120px]"
      style={{ backgroundColor: data.background_color || '#ffffff' }}
    >
      <div className="container-medium">
        {!!data.title?.text && (
          <h2
            className="font-bold text-center text-[32px] sm:text-[40px] leading-[37px] sm:leading-[49px]"
            style={{ color: textColor }}
          >
            {data.title.text}
          </h2>
        )}
        {Array.isArray(data.description?.richText) && data.description.richText[0]?.text?.length > 0 && (
          <div
            className="text-center leading-relaxed mt-5"
            style={{ color: textColor }}
          >
            <RichText
              linkResolver={linkResolver}
              render={data.description.richText}
            />
          </div>
        )}
        <div className="mt-[40px] md:mt-[60px]">
          {items.map((item, index) => {
            const title = item?.subject?.document?.data?.title?.text;
            const image = getImage(item?.subject?.document?.data?.project_logo);

            if (!title) return null;

            return (
              <div
                key={index}
                className="flex justify-between rounded-xl bg-white p-4 px-6 mt-[20px]"
                role="button"
                tabIndex={0}
                onClick={() => handleItemClick(index)}
                onKeyDown={() => handleItemClick(index)}
              >
                <div className="flex items-center">
                  {!!image && (
                    <GatsbyImage
                      alt={image.alt || "Project Logo"}
                      className="h-5 w-5 mr-3"
                      objectFit="contain"
                      image={image}
                    />
                  )}
                  <h6 className="font-display font-bold text-[20px] md:text-[24px]">
                    {title}
                  </h6>
                </div>
                <PlusIcon className="outline-0" />
              </div>
            )
          })}
        </div>
      </div>
      <ModalSlider
        activeSlideIndex={activeSlideIndex}
        showModal={showModal}
        slides={slides}
        onClose={handleModalClose}
        onSlideChange={handleSlideChange}
      />
    </section>
  );
};

export const query = graphql`
  fragment AnnualReportDataBodyAnnualReportSubjects on PrismicAnnualReportDataBodyAnnualReportSubjects {
    primary {
      background_color
      description {
        richText
      }
      title {
        text
      }
    }
    items {
      subject {
        document {
          ... on PrismicAnnualReportSubject {
            data {
              title {
                text
              }
              description {
                richText
              }
              project_logo {
                alt
                gatsbyImageData
              }
              color
              call_to_action {
                richText
              }
              link {
                url
              }
              body {
                ... on PrismicAnnualReportSubjectDataBodyAnnualReportTopics {
                  items {
                    topic_title {
                      text
                    }
                    topic_description {
                      richText
                    }
                    image_1 {
                      alt
                      gatsbyImageData
                    }
                    image_2 {
                      alt
                      gatsbyImageData
                    }
                    image_3 {
                      alt
                      gatsbyImageData
                    }
                    image_4 {
                      alt
                      gatsbyImageData
                    }
                    image_5 {
                      alt
                      gatsbyImageData
                    }
                    image_6 {
                      alt
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default AnnualReportSubjects;
