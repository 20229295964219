import * as React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { linkResolver } from '../utils/LinkResolver.js';
import LinkButton from '../components/LinkButton.js'


const HeroSectionWithImage = ({ callToAction, image, text, title }) => {
  if (!text && !title && !image) return null;

  const heroImage = getImage(image);

  return (
    <section className="w-full pt-[213px] pb-[239px]">
      {!!image && (
        <GatsbyImage
          image={heroImage}
          className="h-[664px] flex w-full absolute top-0 left-0 z-0"
          alt={heroImage.alt || RichText.asText(title)}
        />
      )}
      {(!!title || !!text) && (
        <div className="container-small max-w-[665px]">
          <div className="relative">
            {!!title && (
              <>
                <h2 className="text-white text-2xl md:text-4xl">
                  {RichText.asText(title)}
                </h2>
                <div className="c-divider c-divider--heavy c-divider--wider c-divider--white mt-[12px] mb-[12px]" />
              </>
            )}
            {!!text && (
              <div className="prose md:prose-xl max-w-none font-display font-light leading-relaxed text-white">
                <RichText
                  render={text}
                  linkResolver={linkResolver}
                />
              </div>
            )}
            {!!callToAction && (
              <LinkButton
                link={callToAction.link}
                isWarning
                label={callToAction.text}
                className="c-button--lighter c-button--smaller mt-[12px]"
              />
            )}
          </div>
        </div>
      )}
    </section>
  );
};

HeroSectionWithImage.propTypes = {
  callToAction: PropTypes.shape({
    link: PropTypes.shape({
      link_type: PropTypes.string,
      url: PropTypes.string
    }),
    text: PropTypes.shape({
      richText: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string
      }))
    })
  }),
  text: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    type: PropTypes.string
  })),
  title: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    type: PropTypes.string
  })),
};

HeroSectionWithImage.defaultProps = {
  callToAction: null,
  text: null,
  title: null
};

export default HeroSectionWithImage;
