import * as React from 'react'

import { RichText } from 'prismic-reactjs'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { linkResolver } from '../utils/LinkResolver'

const TeamMemberBiography = ({ photo, full_name, linkedin_profile, twitter_link, title, biography }) => {
  const image = getImage(photo)

  return (
    <div className="bg-white shadow-2 rounded p-6 pt-6">
      <GatsbyImage
        alt={RichText.asText(full_name.richText)}
        className="rounded-full w-3/12 sm:w-4/12"
        image={image}
      />

      <h5 className="mt-4">
        {RichText.asText(full_name.richText)}
      </h5>

      <h6 className="text-gray-500 mt-2 font-normal">
        {RichText.asText(title.richText)}
      </h6>

      <div className="prose mt-4 leading-relaxed">
        <RichText
          render={biography.richText}
          linkResolver={linkResolver}
        />
      </div>

      {!!linkedin_profile && !!linkedin_profile.url && (
        <a
          className="text-blue-700 mt-4 mr-4 inline-block font-bold" 
          href={linkedin_profile.url}
          rel="noreferrer noopener"
          target="_blank"
        >
          LinkedIn
        </a>
      )}

      {!!twitter_link && !!twitter_link.url && (
        <a
          className="text-blue-700 mt-4 inline-block font-bold" 
          href={linkedin_profile.url}
          rel="noreferrer noopener"
          target="_blank"
        >
          Twitter
        </a>
      )}
    </div>
  )
}

export default TeamMemberBiography
