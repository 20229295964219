import * as React from 'react';
import { graphql } from 'gatsby';

import LinkButton from '../components/LinkButton.js';

const ArticleCallToAction = ({ slice }) => {
  if(!slice || !slice.primary) return null;

  const { primary } = slice;

  return (
    <section className={`container-medium mt-4 sm:mt-8 mb-12 sm:mb-24 text-center`}>
      {primary.call_to_action_label && primary.call_to_action.url ? 
        <LinkButton isWarning link={primary.call_to_action} label={primary.call_to_action_label} /> : 
        null
      }
    </section>
  );
};

export const query = graphql`
  fragment ArticleDataBodyArticleCallToAction on PrismicArticleDataBodyArticleCallToAction {
    primary {
      call_to_action_label
      call_to_action {
        url
        uid
        type
        target
        link_type
      }
    }
    slice_type
    slice_label
  }
`;

export default ArticleCallToAction;
