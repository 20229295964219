import * as React from 'react';
import { graphql } from 'gatsby';

import SteeringGroupsGrid from '../components/SteeringGroupsGrid';


const SteeringGroups = ({ slice }) => {
  const { primary, items } = slice;

  const itemsAreValid = items.every(item => !!item.steering_groups.document);

  const allGroups = itemsAreValid ? items.map(item => ({
        ...item.steering_groups.document.data,
        uid: item.steering_groups.document.data?.uid,
        url: item.steering_groups.document.data?.url
      })
  ) : [];

  if (!Array.isArray(allGroups) || !allGroups.length) return null;

  return (
    <SteeringGroupsGrid {...primary} allGroups={allGroups} />
  );
};

export const query = graphql`
  fragment AnnualReportDataBodySteeringGroups on PrismicAnnualReportDataBodySteeringGroups {
    primary {
      background_color
      subtitle {
        richText
      }
      title {
        richText
      }
    }
    items {
      steering_groups {
        document {
          ... on PrismicSteeringGroup {
            data {
              body {
                ... on PrismicSteeringGroupDataBodyAnnualReportTopics {
                  items {
                    image_1 {
                      alt
                      gatsbyImageData
                    }
                    image_2 {
                      alt
                      gatsbyImageData
                    }
                    image_3 {
                      alt
                      gatsbyImageData
                    }
                    topic_description {
                      richText
                    }
                    topic_title {
                      text
                    }
                  }
                }
              }
              color
              name {
                richText
              }
              icon_light {
                alt
                gatsbyImageData(width: 80)
              }
              icon_dark {
                alt
                gatsbyImageData(width: 80)
              }
              roadmap_activity {
                document {
                  ... on PrismicRoadmapActivity {
                    data {
                      name {
                        richText
                      }
                    }
                  }
                }
              }
            }
            url
          }
        }
      }
    }
  }
`

export default SteeringGroups;
