import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import getCorrectTextColor from '../utils/SetTextColorDependingOnBackground';

import { linkResolver } from '/src/utils/LinkResolver.js';

import Modal from 'react-bootstrap/Modal';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';

import LinkButton from '/src/components/LinkButton.js';

import CloseIcon from '/src/images/icons/close.svg';
import ChevronLeft from '/src/images/icons/chevron-left.svg';
import ChevronRight from '/src/images/icons/chevron-right.svg';

const ModalSlider = ({ activeSlideIndex, onClose, onSlideChange, showModal, slides }) => {
  if (!Array.isArray(slides) || !slides.length) return null;

  const currentSlide = slides[activeSlideIndex];
  const headerBackgroundColor = currentSlide?.color || '#1D4732';
  const lastSlideIndex = slides.length - 1;
  const topics = currentSlide?.topics;

  const textColor = getCorrectTextColor(headerBackgroundColor);
  
  const cardIcon = getImage(currentSlide?.icon_dark);

  const handleNextSlideButtonClick = () => {
    if (activeSlideIndex < lastSlideIndex) onSlideChange(activeSlideIndex + 1);
  }

  const handlePreviousSlideButtonClick = () => {
    if (activeSlideIndex > 0) onSlideChange(activeSlideIndex - 1);
  }

  return (
    <Modal
      centered
      contentClassName="relative bg-transparent rounded-[12px] border-0"
      dialogClassName="container max-w-[1080px]"
      scrollable
      show={showModal}
      onHide={onClose}
    >
      <Modal.Header
        className="flex justify-end border-0 p-[10px]"
        style={{ backgroundColor: headerBackgroundColor }}
      >
        <div
          className="bg-white rounded-md p-[8px]"
          role="button"
          tabIndex={0}
          onKeyDown={onClose}
          onClick={onClose}
        >
          <CloseIcon
            className="flex"
            style={{
              color: headerBackgroundColor
            }}
          />
        </div>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div
          key={`slide-${activeSlideIndex}`}
        >
          <div
            className="relative flex items-center justify-center min-h-[264px] select-none"
            style={{ backgroundColor: headerBackgroundColor}}
          >
            {activeSlideIndex > 0 && (
              <ChevronLeft
                aria-label="Previous slide"
                className="absolute h-10 w-10 top-1/2 left-[8px] sm:left-[17px] transform -translate-y-1/2 outline-none"
                role="button"
                tabIndex="0"
                onClick={handlePreviousSlideButtonClick}
                onKeyDown={handlePreviousSlideButtonClick}
                style={{
                  color: textColor
                }}
              />
            )}
            <div
              className="max-w-[645px] px-[35px] pb-10 sm:px-[30px] text-center"
              style={{
                color: textColor
              }}
            >
              {!!currentSlide?.state && (
                <p className="uppercase text-[12px] leading-[14px] sm:text-[18px] sm:leading-[21px] tracking-wide">
                  {currentSlide.state}
                </p>
              )}
              {!!cardIcon && (
                <div className="h-[80px] w-[80px] bg-white rounded-md p-[10px] my-[20px] mx-auto">
                  <GatsbyImage
                    alt={cardIcon.alt || ''}
                    image={cardIcon}
                    layout="fixed"
                    objectFit="contain"
                  />
                </div>
              )}
              {!!currentSlide?.title && (
                <h3 className="text-[24px] sm:text-[40px] leading-[30px] sm:leading-[49px] font-display font-bold">
                  {currentSlide.title}
                </h3>
              )}
              {Array.isArray(currentSlide?.description) && currentSlide.description.length > 0 && (
                <div className="text-[16px] sm:text-[18px] leading-[18px] sm:leading-[27px]">
                  <RichText
                    linkResolver={linkResolver}
                    render={currentSlide.description}
                  />
                </div>
              )}
              {Array.isArray(currentSlide?.roadmap_activity?.document?.data?.name?.richText) && currentSlide.roadmap_activity.document.data.name.richText[0]?.text?.length > 0 && (
                <li className="text-[16px] leading-[20px] sm:text-[18px] mt-[12px] mb-[11px]">
                  {RichText.asText(currentSlide.roadmap_activity.document.data.name.richText)}
                </li>
              )}
            </div>
            {activeSlideIndex < lastSlideIndex && (
              <ChevronRight
                aria-label="Next slide"
                className="absolute h-10 w-10 top-1/2 right-[8px] sm:right-[17px] transform -translate-y-1/2 outline-none"
                role="button"
                tabIndex="0"
                onClick={handleNextSlideButtonClick}
                onKeyDown={handleNextSlideButtonClick}
                style={{
                  color: textColor
                }}
              />
            )}
          </div>
          {Array.isArray(topics) && topics.length > 0 && (
            <div className="bg-[#f2f2f5] py-[20px] sm:py-[40px]">
              <Accordion
                allowMultipleExpanded
                allowZeroExpanded
                className="container-medium"
                preExpanded={['toggle-topic-0', 'toggle-topic-1', 'toggle-topic-2', 'toggle-topic-3', 'toggle-topic-4', 'toggle-topic-5']}
              >
                {topics.map((item, index) => {
                  const image1 = getImage(item.image_1);
                  const image2 = getImage(item.image_2);
                  const image3 = getImage(item.image_3);
                  const image4 = getImage(item.image_4);
                  const image5 = getImage(item.image_5);
                  const image6 = getImage(item.image_6);

                  return (
                    <AccordionItem
                      key={`toggle-topic-${index}`}
                      className="rounded bg-white p-[12px] mt-4 sm:p-4 sm:px-6 first-of-type:mt-0"
                      uuid={`toggle-topic-${index}`}
                    >
                      <AccordionItemHeading>
                        <AccordionItemButton className="flex flex-row items-center accordion__button">
                          <svg className="flex-shrink-0 w-2 h-5 sm:h-8 accordion__trigger" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" /></svg>
                          <h3 className="text-[20px] sm:text-[24px] leading-[28px] sm:leading-[36px] sm:text-xl ml-3">
                            {item.topic_title.text}
                          </h3>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        {Array.isArray(item.topic_description?.richText) && item.topic_description.richText.length > 0 && (
                          <div className="prose break-words text-[16px] sm:text-[18px] leading-[26px] px-5 mt-4">
                              <RichText
                                render={item.topic_description.richText}
                                linkResolver={linkResolver}
                              />
                          </div>
                        )}
                        {(!!image1 || !!image2 || !!image3 || !!image4 || !!image5 || !!image6) && (
                          <div className="grid grid-cols-2 md:grid-cols-3 md:gap-[20px] mt-[28px]">
                            {!!image1 && (
                              <GatsbyImage
                                alt={image1.alt || ''}
                                className="max-h-[90px] md:max-h-[130px]"
                                image={image1}
                                objectPosition="left"
                                objectFit="contain"
                              />
                            )}
                            {!!image2 && (
                              <GatsbyImage
                                alt={image2.alt || ''}
                                className="max-h-[90px] md:max-h-[130px]"
                                image={image2}
                                objectPosition="left"
                                objectFit="contain"
                              />
                            )}
                            {!!image3 && (
                              <GatsbyImage
                                alt={image3.alt || ''}
                                className="max-h-[90px] md:max-h-[130px]"
                                image={image3}
                                objectPosition="left"
                                objectFit="contain"
                              />
                            )}
                            {!!image4 && (
                              <GatsbyImage
                                alt={image4.alt || ''}
                                className="max-h-[90px] md:max-h-[130px]"
                                image={image4}
                                objectPosition="left"
                                objectFit="contain"
                              />
                            )}
                            {!!image5 && (
                              <GatsbyImage
                                alt={image5.alt || ''}
                                className="max-h-[90px] md:max-h-[130px]"
                                image={image5}
                                objectPosition="left"
                                objectFit="contain"
                              />
                            )}
                            {!!image6 && (
                              <GatsbyImage
                                alt={image6.alt || ''}
                                className="max-h-[90px] md:max-h-[130px]"
                                image={image6}
                                objectPosition="left"
                                objectFit="contain"
                              />
                            )}
                          </div>
                        )}
                      </AccordionItemPanel>
                    </AccordionItem>
                  )
                })}
              </Accordion>
              {Array.isArray(currentSlide?.call_to_action?.richText) &&
              currentSlide.call_to_action.richText[0]?.text?.length > 0 && (
                <div className="flex justify-center mt-[20px] sm:mt-[32px]">
                  <LinkButton
                    className="text-[16px] sm:text-[18px] leading-[18px] font-semibold"
                    isWarning
                    label={currentSlide.call_to_action}
                    link={currentSlide.link}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
};

ModalSlider.propTypes = {
  onClose: PropTypes.func,
  onSlideChange: PropTypes.func,
  showModal: PropTypes.bool,
  slides: PropTypes.arrayOf(PropTypes.shape({}))
};

ModalSlider.defaultProps = {
  onClose: () => {},
  onSlideChange: () => {},
  showModal: false,
  slides: null
}

export default ModalSlider;
