import * as React from 'react'
import { uniq } from 'lodash'
import { RichText } from 'prismic-reactjs'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'

import ResearchProject from './ResearchProject'

class ListResearchProjects extends React.Component {

  constructor(props) {
    super(props)
    this.setFilter = this.setFilter.bind(this)

    this.state = {
      activeCategory: null
    }
  }

  setFilter = value => () => {
    const newState = { activeCategory: value }
    this.setState(newState)
  };

  isVisible(project) {
    if (this.state.activeCategory === null) 
      return true
    
    if (project.project_type && project.project_type === this.state.activeCategory) 
      return true
    
    return false
  }

  render() {
    const { 
      allResearchProjects,
    } = this.props
    
    const allCategories = uniq(allResearchProjects.map(proj => proj.project_type))
    allCategories.unshift(null)

    return (
      <section className={`mt-12 sm:mt-20 lg:pb-8 container-medium`}>
        <div className="mt-8 sm:mt-12 c-tabs">
          <span className="font-bold">Filter:</span>
          {allCategories.map((category, index) => {
            return (
              <button 
                key={`research-filter-${index}`}
                className={"c-tabs__item" + (this.state.activeCategory === category ? ' is-active' : '')} 
                onClick={this.setFilter(category)}>
                {category === null ? 'All' : category}
              </button>
            )
          })}
        </div>

        <Accordion className="mt-6">
          {allResearchProjects.map((project, index) => {
            if(this.isVisible(project))
              return (
                <AccordionItem key={`research-project-${index}`} className="rounded bg-gray-100 mt-4 p-4 px-6" expanded="true">
                  <AccordionItemHeading>
                    <AccordionItemButton className="flex flex-row accordion__button">
                      <svg className="flex-shrink-0 w-2 h-8 accordion__trigger" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" /></svg>
                      <h3 className="text-xl ml-3">{RichText.asText(project.project_name.richText)}</h3>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <ResearchProject {...project} />
                  </AccordionItemPanel>
                </AccordionItem>
              )
            
            return null
          })}
        </Accordion>
      </section>
    )
  }
}

export default ListResearchProjects
