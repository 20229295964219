import * as React from 'react'
import { graphql } from 'gatsby'

import LinkButton from '../components/LinkButton.js'

const CallToAction = ({ slice }) => {
  if(!slice || !slice.primary) return null

  const { primary } = slice

  return (
    <section className={`container-medium mt-12 sm:mt-24 text-center`}>
      {primary.call_to_action_label && primary.call_to_action.url ? 
        <LinkButton isWarning link={primary.call_to_action} label={primary.call_to_action_label} /> : 
        null
      }
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyCallToAction on PrismicHomepageDataBodyCallToAction {
    primary {
      call_to_action_label
      call_to_action {
        url
        uid
        type
        target
        link_type
      }
    }
  }

  fragment ResourceDataBodyCallToAction on PrismicResourceDataBodyCallToAction {
    primary {
      call_to_action_label
      call_to_action {
        url
        uid
        type
        target
        link_type
      }
    }
  }

  fragment DictionaryEntryDataBodyCallToAction on PrismicDictionaryEntryDataBodyCallToAction {
    primary {
      call_to_action_label
      call_to_action {
        url
        uid
        type
        target
        link_type
      }
    }
  }

  fragment PageDataBodyCallToAction on PrismicPageDataBodyCallToAction {
    primary {
      call_to_action_label
      call_to_action {
        url
        uid
        type
        target
        link_type
      }
    }
  }

  fragment LandingPageDataBodyCallToAction on PrismicLandingPageDataBodyCallToAction {
    primary {
      call_to_action_label
      call_to_action {
        url
        uid
        type
        target
        link_type
      }
    }
  }

  fragment MembershipDataBodyCallToAction on PrismicMembershipDataBodyCallToAction {
    primary {
      call_to_action_label
      call_to_action {
        url
        uid
        type
        target
        link_type
      }
    }
  }

  fragment OrganizationMemberDataBodyCallToAction on PrismicOrganizationMemberDataBodyCallToAction {
    primary {
      call_to_action_label
      call_to_action {
        url
        uid
        type
        target
        link_type
      }
    }
  }

  fragment SteeringGroupDataBodyCallToAction on PrismicSteeringGroupDataBodyCallToAction {
    primary {
      call_to_action_label
      call_to_action {
        url
        uid
        type
        target
        link_type
      }
    }
  }

  fragment EventDataBody1CallToAction on PrismicEventDataBody1CallToAction {
    primary {
      call_to_action_label
      call_to_action {
        url
        uid
        type
        target
        link_type
      }
    }
  }
`

export default CallToAction
