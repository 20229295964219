import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import { linkResolver } from '/src/utils/LinkResolver.js';

const StatisticsTextItems = ({ slice }) => {
  const data = slice.primary;
  const { items } = slice;

  const {
    anchor_id,
    content_alignment: contentAlignment,
    description,
    tagline,
    title,
    section_margin
  } = data || {};

  if (!Array.isArray(items) || !items.length) return null;

  return (
    <section
      className={`scroll-mt-[80px]${section_margin === 'small' ? ' my-[40px] md:my-[60px]' : ' my-[80px] md:my-[140px]'}`}
      id={anchor_id ?? null}
    >
      <div className="container">
        <div className={`max-w-[640px] mx-auto${contentAlignment === 'left' ? ' text-left' : ' text-center'}`}>
          {!!tagline?.text && (
            <div className="font-semibold uppercase text-[18px] md:text-[24px] leading-[22px] md:leading-[32px] tracking-[2.4px] text-[#777785] mb-[20px]">
              {tagline.text}
            </div>
          )}
          {!!title?.text && (
            <h2 className="font-bold font-body text-[32px] md:text-[40px] leading-[37px] md:leading-[44px]">
              {title.text}
            </h2>
          )}
          {Array.isArray(description?.richText) && description.richText[0]?.text?.length > 0 && (
            <div className="font-body text-[18px] md:text-[20px] leading-[24px] md:leading-[28px] mt-[20px]">
              <RichText
                linkResolver={linkResolver}
                render={description.richText}
              />
            </div>
          )}
        </div>
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mt-[40px] md:mt-[60px]">
          {items.map((item, index) => (
            <div key={index}>
              {Array.isArray(item.value?.richText) && item.value.richText[0]?.text?.length > 0 && (
                <h6 className="font-body font-semibold text-[40px] leading-[48px]">
                  {RichText.asText(item.value.richText)}
                </h6>
              )}
              {Array.isArray(item.text?.richText) && item.text.richText[0]?.text?.length > 0 && (
                <p className="text-[16px] leading-[22px] mt-[8px]">
                  {RichText.asText(item.text.richText)}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ConferenceDataBodyStatisticsTextItems on PrismicDigitalSustainabilityConferenceDataBodyStatisticsTextItems {
    primary {
      tagline {
        text
      }
      title {
        text
      }
      description {
        richText
      }
      content_alignment
      section_margin
    }
    items {
      value {
        richText
      }
      text {
        richText
      }
    }
  }
`;

export default StatisticsTextItems;
