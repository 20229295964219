import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { linkResolver } from '/src/utils/LinkResolver.js';

const KeyOutcomes = ({ slice }) => {
  const data = slice.primary;
  const { items } = slice;

  const {
    anchor_id,
    content_alignment: contentAlignment,
    description,
    tagline,
    title,
    section_margin
  } = data || {};

  if (!Array.isArray(items) || !items.length) return null;

  return (
    <section
      className={`scroll-mt-[80px]${section_margin === 'small' ? ' my-[40px] md:my-[60px]' : ' my-[80px] md:my-[140px]'}`}
      id={anchor_id ?? null}
    >
      <div className="container">
        <div className={`max-w-[640px] mx-auto${contentAlignment === 'left' ? ' text-left' : ' text-center'}`}>
          {!!tagline?.text && (
            <div className="font-semibold uppercase text-[18px] md:text-[24px] leading-[22px] md:leading-[32px] tracking-[2.4px] text-[#777785] mb-[20px]">
              {tagline.text}
            </div>
          )}
          {!!title?.text && (
            <h2 className="font-bold font-body text-[32px] md:text-[40px] leading-[37px] md:leading-[44px]">
              {title.text}
            </h2>
          )}
          {Array.isArray(description?.richText) && description.richText[0]?.text?.length > 0 && (
            <div className="font-body text-[18px] md:text-[20px] leading-[24px] md:leading-[28px] mt-[20px]">
              <RichText
                linkResolver={linkResolver}
                render={description.richText}
              />
            </div>
          )}
        </div>
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mt-[40px] md:mt-[60px]">
          {items.map((item, index) => {
            const image = getImage(item.image);
            const ctaLink = item.cta_link;
            const ctaText = item.cta_text;

            return (
              <div
                key={index}
                className="rounded-xl bg-white border border-[#DADAE7] p-[20px]"
              >
                {!!image && (
                  <GatsbyImage
                    alt={image.alt || ''}
                    className="rounded-xl w-full"
                    image={image}
                    objectFit="contain"
                  />
                )}
                {!!item.title?.text && (
                  <h6 className="font-body font-semibold text-[18px] leading-[24px] md:text-[20px] mt-[24px]">
                    {item.title.text}
                  </h6>
                )}
                {Array.isArray(item.description?.richText) && item.description.richText[0]?.text?.length > 0 && (
                  <p className="text-[#45455F] text-[16px] leading-[20px] md:text-[18px] md:leading-[24px] mt-[12px]">
                    {RichText.asText(item.description.richText)}
                  </p>
                )}
                {!!ctaLink &&
                  Array.isArray(ctaText?.richText) &&
                  ctaText.richText[0]?.text && (
                    <div className="mt-[8px]">
                      <a
                        href={ctaLink.url}
                        className="text-[14px] md:text-[16px] leading-[18px] font-semibold text-[#E69635] hover:text-[#dc851b]"
                      >
                        {RichText.asText(ctaText.richText)}
                      </a>
                    </div>
                  )}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ConferenceDataBodyKeyOutcomes on PrismicDigitalSustainabilityConferenceDataBodyKeyOutcomes {
    primary {
      tagline {
        text
      }
      title {
        text
      }
      description {
        richText
      }
      content_alignment
      section_margin
    }
    items {
      image {
        alt
        gatsbyImageData
      }
      title {
        text
      }
      description {
        richText
      }
      cta_text {
        richText
      }
      cta_link {
        url
        target
        type
        uid
        slug
        link_type
      }
    }
  }
`;

export default KeyOutcomes;
