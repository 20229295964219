import * as React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import { linkResolver } from '../utils/LinkResolver.js';

const ArticleTextBlock = ({ slice }) => {
  const { text_content } = slice.primary;

  return (
    <section>
      <div className="article-text-block mt-[40px] max-w-full">
        <RichText
          render={text_content.richText}
          linkResolver={linkResolver}
        />
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ArticleDataBodyArticleTextBlock on PrismicArticleDataBodyArticleTextBlock {
    primary {
      text_content {
        richText
      }
    }
  }
`

export default ArticleTextBlock;
