import * as React from 'react'
import { lowercase } from 'lodash'
import CheckBadgeAlternate from '../images/icons/streamline-icon-check-badge-alternate.svg'
import PresentationDeskNotes from '../images/icons/streamline-icon-presentation-desk-notes.svg'
import MessagesPeopleManBubbleSquare from '../images/icons/streamline-icon-messages-people-man-bubble-square.svg'
import ProfessionalNetworkLinkedin from '../images/icons/streamline-icon-professional-network-linkedin.svg'
import CheckCircle1Alternate from '../images/icons/streamline-icon-check-circle-1-alternate.svg'

const EventType = ({ type, free_for_members, className }) => {
  let icon, label, typeLabel, freeLabel
  const iconClassName = "fill-current w-5 h-5"

  switch(lowercase(type)) {
    case 'members event':
      icon = (
        <CheckBadgeAlternate className={iconClassName} />
      )
      label = "We are hosting"
      break
    case 'co-hosting':
      icon = (
        <CheckBadgeAlternate className={iconClassName} />
      )
      label = "We are hosting"
      break
    case 'speaking engagement':
      icon = (
        <PresentationDeskNotes className={iconClassName} />
      )
      label = "We are speaking"
      break
    case 'keynote':
      icon = (
        <PresentationDeskNotes className={iconClassName} />
      )
      label = "We are speaking"
      break
    case 'panel':
      icon = (
        <MessagesPeopleManBubbleSquare className={iconClassName} />
      )
      label = "Panel discussion"
      break
    case 'around the alliance':
      icon = (
        <ProfessionalNetworkLinkedin className={iconClassName} />
      )
      label = "Live on LinkedIn"
      break
    
    default: 
      label = null 
      icon = null 
  }

  if (icon && label) {
    typeLabel = (
      <p className={`inline-flex items-center mr-8`}>
        {icon}
        <span className="ml-3">{label}</span>
      </p>
    )
  }

  if (free_for_members) {
    freeLabel = (
      <p className={`inline-flex items-center`}>
        <CheckCircle1Alternate className={iconClassName} />
        <span className="ml-3">Free for members</span>
      </p>
    )
  }

  if (freeLabel || typeLabel) {
    return (
      <div className={`${className} flex flex-row text-primary-900 text-lg`}>
        {typeLabel}
        {freeLabel}
      </div>
    )
  }

  return null
}

export default EventType