import * as React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/LinkResolver'
import { lowercase } from 'lodash'
import DesktopMonitorSmiley from '../images/icons/streamline-icon-desktop-monitor-smiley.svg'
import Event from '../images/icons/streamline-icon-event.svg'
import MovieCinemaWatch from '../images/icons/streamline-icon-movie-cinema-watch.svg'

const EventLocationInfo = ({ 
  presence,
  location,
  className,
}) => {
  let icon
  const iconClassName = "fill-current w-8 h-8 mr-5"

  switch(lowercase(presence)) {
    case 'virtual':
      icon = (
        <DesktopMonitorSmiley className={iconClassName} />
      )
      break
    case 'physical':
      icon = (
        <Event className={iconClassName} />
      )
      break
    case 'hybrid':
      icon = (
        <MovieCinemaWatch className={iconClassName} />
      )
      break
    default: 
      icon = null 
  }

  return (
    <div className={`${className}`}>
      <h4>Location</h4>
      
      <div className="bg-gray-100 rounded flex items-center p-5 mt-3">
        {icon}
        <div className="prose text-xl font-bold text-gray-900 font-display">
          <RichText render={location.richText} linkResolver={linkResolver} />
        </div>
      </div>
    </div>
  )
}

export default EventLocationInfo