import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import { lowercase } from 'lodash'

import ListDictionaryEntries from '../components/ListDictionaryEntries'

const DictionaryList = () => {
  const queryData = useStaticQuery(graphql`
    {
      allPrismicDictionaryEntry(sort: {data: {word: {text: ASC}}}) {
        edges {
          node {
            ...PrismicDictionaryEntryFragment
          }
        }
      }
    }
  `)

  const { data } = useMergePrismicPreviewData(queryData)
  const doc = data.allPrismicDictionaryEntry
  const allEntries = doc.edges.map(edge => { 
    return { 
      ...edge.node.data, 
      uid: edge.node.uid, 
      url: edge.node.url, 
      letter: lowercase(edge.node.data.word.text.charAt(0)) 
    } 
  })

  return (
    <ListDictionaryEntries entries={allEntries} />
  )
}

export const query = graphql`
  fragment PageDataBodyDictionaryList on PrismicPageDataBodyDictionaryList {
    id
  }
`

export default DictionaryList
