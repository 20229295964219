import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import LinkButton from '../components/LinkButton';

const PositionHeadline = ({ slice }) => {
  const { background_image, description, title } = slice?.primary || {};
  const { items } = slice || {};

  const background = getImage(background_image);

  if (!title?.text) return null;

  return (
    <section className="relative pt-[120px] md:pt-[183px] pb-[54px] md:pb-[137px] position-headline">
      <GatsbyImage
        alt={background_image.alt || ''}
        className="absolute top-0 left-0 h-full w-full"
        objectFit="cover"
        image={background}
        layout="fullWidth"
      />
      <div className="absolute top-0 left-0 h-full w-full bg-black opacity-50" />
      <div className="container-medium">
        <div className="relative max-w-[676px]">
          <div className="text-[30px] leading-[39px] md:text-[40px] md:leading-[50px] text-white max-w-none font-display font-bold">
            {title.text}
          </div>
          {!!description && Array.isArray(description.richText) && description.richText[0]?.text?.length && (
            <div className="font-display text-white text-[18px] leading-[30px] mt-[8px] md:mt-[20px]">
              <RichText render={description.richText} />
            </div>
          )}
          {Array.isArray(items) && !!items.length && items.map((action, index) => (
            <LinkButton
              key={`headline-action-${index}`}
              className={`mr-4 mt-[20px] md:mt-[32px] text-[14px] sm:text-[18px] leading-[18px] font-bold px-[20px] py-[10px] md:px-[32px] md:py-[16px] ${action.type === 'primary' ? ' c-button--warning-dark' : ' c-button--warning'}`}
              label={action.call_to_action_text}
              link={action.call_to_action_link}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment PositionDataBodyPositionHeadline on PrismicPositionDataBodyPositionHeadline {
    items {
      call_to_action_text {
        richText
      }
      call_to_action_link {
        url
        type
        target
        link_type
      }
      type
    }
    primary {
      background_image {
        alt
        url
        gatsbyImageData
      }
      description {
        richText
      }
      title {
        text
      }
    }
  }
`;

export default PositionHeadline;
