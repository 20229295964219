import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

import ListEvents from '../components/ListEvents'
import { enrichEventsDates } from '../utils/EnrichEventDates'

const AllPastEvents = ({ slice }) => {
  const { primary } = slice

  // get all Past events
  const queryData = useStaticQuery(graphql`
    {
      allPrismicEvent(
        sort: {data: {start_date: DESC}},
        filter: {data: {upcoming_event: {ne: true}}}
      ) {
        edges {
          node {
            ...PrismicEventFragment
          }
        }
      }
    }
  `)

  const { data } = useMergePrismicPreviewData(queryData)
  const doc = data.allPrismicEvent
  const allEvents = enrichEventsDates(
    doc.edges.map(edge => { 
      return { ...edge.node.data, uid: edge.node.uid, url: edge.node.url } 
    })
  )

  return (
    <ListEvents {...primary} allEvents={allEvents} showPast />
  )
}

// fragment HomepageDataBodyAllPastEvents on PrismicHomepageDataAllPastEvents {
//   primary {
//     call_to_action_label
//     call_to_action {
//       url
//       uid
//       type
//       target
//       link_type
//     }
//     headline {
//       richText
//     }
//     description {
//       richText
//     }
//   }
// }

export const query = graphql`
  fragment PageDataBodyAllPastEvents on PrismicPageDataBodyAllPastEvents {
    primary {
      call_to_action_label
      call_to_action {
        url
        uid
        type
        target
        link_type
      }
      headline {
        richText
      }
      description {
        richText
      }
    }
  }

  fragment LandingPageDataBodyAllPastEvents on PrismicLandingPageDataBodyAllPastEvents {
    primary {
      call_to_action_label
      call_to_action {
        url
        uid
        type
        target
        link_type
      }
      headline {
        richText
      }
      description {
        richText
      }
    }
  }
`

export default AllPastEvents
