import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { linkResolver } from '/src/utils/LinkResolver.js';
import getCorrectTextColor from '/src/utils/SetTextColorDependingOnBackground';

const CardsWithImages = ({ slice }) => {
  const data = slice.primary;
  const { items } = slice;

  if (!Array.isArray(items) || !items.length) return null;

  const textColor = getCorrectTextColor(data?.background_color);

  return (
    <section
      className="py-[60px] md:py-[120px]"
      style={{ backgroundColor: `${data.background_color}` }}
    >
      <div className="container">
        {!!data.title?.text && (
          <h2
            className="font-bold text-center text-[32px] sm:text-[40px] leading-[37px] sm:leading-[49px]"
            style={{ color: textColor }}
          >
            {data.title.text}
          </h2>
        )}
        {Array.isArray(data.description?.richText) && data.description.richText[0]?.text?.length > 0 && (
          <div
            className="text-center leading-relaxed mt-5"
            style={{ color: textColor }}
          >
            <RichText
              linkResolver={linkResolver}
              render={data.description.richText}
            />
          </div>
        )}
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-[40px] md:mt-[60px]">
          {items.map((item, index) => {
            const image = getImage(item.image);

            return (
              <div
                key={index}
                className="rounded-xl bg-white p-[12px] sm:p-[20px]"
              >
                {!!image && (
                  <GatsbyImage
                    alt={image.alt || ''}
                    className="rounded-xl w-full"
                    image={image}
                    objectFit="contain"
                  />
                )}
                {!!item.title?.text && (
                  <h6 className="font-body font-semibold text-[18px] md:text-[20px] mt-5 mb-2">
                    {item.title.text}
                  </h6>
                )}
                {Array.isArray(item.description?.richText) && item.description.richText[0]?.text?.length > 0 && (
                  <p className="text-[16px] md:text-[18px]">
                    {RichText.asText(item.description.richText)}
                  </p>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment AnnualReportDataBodyCardsWithImages on PrismicAnnualReportDataBodyCardsWithImages {
    primary {
      background_color
      title {
        text
      }
      description {
        richText
      }
    }
    items {
      image {
        alt
        gatsbyImageData
      }
      description {
        richText
      }
      title {
        text
      }
    }
  }
`;

export default CardsWithImages;
