import * as React from 'react'

import { RichText } from 'prismic-reactjs'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import linkedin_icon from '../images/linkedin-icon.png'
import Envelope from '../images/icons/streamline-icon-envelope.svg'

const TeamMemberPhoto = ({ photo, full_name, linkedin_profile, title, public_email, className }) => {
  const image = getImage(photo)

  return (
    <div className={`relative ${className}`}>
      <GatsbyImage
        alt={RichText.asText(full_name.richText)}
        className="rounded shadow"
        image={image}
      />

      {!!linkedin_profile && !!linkedin_profile.url && (
        <a
          className="absolute top-2 right-2"
          href={linkedin_profile.url}
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            alt="LinkedIn Profile"
            className="w-6"
            src={linkedin_icon}
          />
        </a>
      )}

      {!!public_email && (
        <a
          className="absolute top-2 right-2"
          href={`mailto:${public_email}`}
          rel="noreferrer noopener"
          target="_blank"
        >
          <Envelope className="w-6 text-white stroke-current fill-current bg-secondary-900 p-1 shadow-md rounded" />
        </a>
      )}

      <div className="bg-gradient-to-b from-transparent to-gray-900 absolute bottom-0 left-0 text-center w-full px-2 pb-3 rounded-br rounded-bl">
        <h3 className="text-white text-base md:text-lg">
          {RichText.asText(full_name.richText)}
        </h3>
        {!!title?.richText && (
          <h4 className="text-white text-xs leading-snug md:text-base mt-1 font-normal">
            {RichText.asText(title.richText)}
          </h4>
        )}
      </div>
    </div>
  )
}

export default TeamMemberPhoto
