import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { linkResolver } from '../utils/LinkResolver.js';
import { RichText } from 'prismic-reactjs';
import { getImage } from 'gatsby-plugin-image';

// Components
import HorizontalMasonry from '../components/HorizontalMasonry.js';


const ImageSlider = ({ slice }) => {
  const data = slice.primary;
  const { items } = slice;

  const {
    anchor_id,
    content_alignment: contentAlignment,
    description,
    tagline,
    title,
    section_margin
  } = data || {};

  if (!Array.isArray(items) || !items.length) return null;

  // Loop through the items and add the image height and width values and the src
  const itemsWithImageDimensions = items.map((item) => {
    const image = getImage(item.image);

    return {
      ...item,
      imageHeight: image.height,
      imageWidth: image.width,
      imageSrc: image.images.fallback.src
    };
  });

  return (
    <section
      className={`scroll-mt-[80px]${section_margin === 'small' ? ' my-[40px] md:my-[60px]' : ' my-[80px] md:my-[140px]'}`}
      id={anchor_id ?? null}
    >
      <div className="container">
        <div className={`max-w-[640px] mx-auto${contentAlignment === 'left' ? ' text-left' : ' text-center'}`}>
          {!!tagline?.text && (
            <div className="font-semibold uppercase text-[18px] md:text-[24px] leading-[22px] md:leading-[32px] tracking-[2.4px] text-[#777785] mb-[20px]">
              {tagline.text}
            </div>
          )}
          {!!title?.text && (
            <h2 className="font-bold font-body text-[32px] md:text-[40px] leading-[37px] md:leading-[44px]">
              {title.text}
            </h2>
          )}
          {Array.isArray(description?.richText) && description.richText[0]?.text?.length > 0 && (
            <div className="font-body text-[18px] md:text-[20px] leading-[24px] md:leading-[28px] mt-[20px]">
              <RichText
                linkResolver={linkResolver}
                render={description.richText}
              />
            </div>
          )}
        </div>
      </div>
      <div className="mt-[40px] md:mt-[60px]">
        <HorizontalMasonry items={itemsWithImageDimensions} />
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ConferenceDataBodyImageSlider on PrismicDigitalSustainabilityConferenceDataBodyImageSlider {
    primary {
      tagline {
        text
      }
      title {
        text
      }
      description {
        richText
      }
      content_alignment
      section_margin
    }
    items {
      image {
        alt
        gatsbyImageData
      }
    }
  }
`;

export default ImageSlider;
