import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { linkResolver } from '/src/utils/LinkResolver.js';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';

import PlusIcon from '/src/images/icons/plus.svg';

const AccordionWithBackground = ({ slice }) => {
  const data = slice.primary;
  const { items } = slice;

  const {
    anchor_id,
    content_alignment: contentAlignment,
    description,
    tagline,
    title,
    section_margin
  } = data || {};

  if (!Array.isArray(items) || !items.length) return null;

  return (
    <section
      className={`${section_margin === 'small' ? 'my-[40px] md:my-[60px]' : 'my-[80px] md:my-[140px]'}`}
      id={anchor_id ?? null}
    >
      <div className="container">
        <div className="bg-[#E5F0EB] rounded-3xl px-[20px] py-[60px] md:py-[120px] md:p-[120px]">
          <div className={`max-w-[640px] mx-auto${contentAlignment === 'left' ? ' text-left' : ' text-center'}`}>
            <div className="mb-[40px] md:mb-[80px]">
              {!!tagline?.text && (
                <div className="font-semibold uppercase text-[18px] md:text-[24px] leading-[22px] md:leading-[32px] tracking-[2.4px] text-[#777785] mb-[20px]">
                  {tagline.text}
                </div>
              )}
              {!!title?.text && (
                <h2 className="font-bold font-body text-[32px] md:text-[40px] leading-[37px] md:leading-[44px]">
                  {title.text}
                </h2>
              )}
              {Array.isArray(description?.richText) && description.richText[0]?.text?.length > 0 && (
                <div className="font-body text-[18px] md:text-[20px] leading-[24px] md:leading-[28px] mt-[20px]">
                  <RichText
                    linkResolver={linkResolver}
                    render={description.richText}
                  />
                </div>
              )}
            </div>
          </div>
          <Accordion
            allowZeroExpanded
            preExpanded={['toggle-topic-0']}
          >
            {items.map((item, index) => {
              const icon = getImage(item.icon);
              const ctaLink = item.cta_link;
              const ctaText = item.cta_text;

              return (
                <AccordionItem
                  key={`toggle-topic-${index}`}
                  className="rounded-lg bg-white mt-4 p-[12px] first-of-type:mt-0"
                  uuid={`toggle-topic-${index}`}
                >
                  <AccordionItemHeading>
                    <AccordionItemButton className="flex items-center justify-between accordion__button">
                      <div className="flex items-center">
                        <div className="flex rounded-lg bg-[#E5F0EB] p-[12px] min-h-[40px] min-w-[40px] mr-[12px]">
                          <GatsbyImage
                            alt={icon.alt || ''}
                            image={icon}
                            objectFit="contain"
                          />
                        </div>
                        {Array.isArray(item.title?.richText) && item.title.richText[0]?.text?.length > 0 && (
                          <h6 className="font-body font-semibold text-[16px] leading-[20px] md:text-[18px] md:leading-[24px]">
                            {RichText.asText(item.title.richText)}
                          </h6>
                        )}
                      </div>
                      <PlusIcon className="flex-shrink-0 w-6 h-6 accordion__trigger accordion__trigger--half-rotation" />
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    {Array.isArray(item.description?.richText) && item.description.richText.length > 0 && (
                      <div className="font-body break-words text-[#45455F] font-light text-[16px] leading-[22px] px-[52px] mt-[8px]">
                        <RichText
                          render={item.description.richText}
                          linkResolver={linkResolver}
                        />
                      </div>
                    )}
                    {!!ctaLink &&
                      Array.isArray(ctaText?.richText) &&
                      ctaText.richText[0]?.text && (
                        <div className="px-[52px] mt-[8px]">
                          <a
                            href={ctaLink.url}
                            className="text-[14px] md:text-[16px] leading-[18px] font-semibold text-[#E69635] hover:text-[#dc851b]"
                          >
                            {RichText.asText(ctaText.richText)}
                          </a>
                        </div>
                      )}
                  </AccordionItemPanel>
                </AccordionItem>
              )
            })}
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ConferenceDataBodyAccordionWithBackground on PrismicDigitalSustainabilityConferenceDataBodyAccordionWithBackground {
    primary {
      tagline {
        text
      }
      title {
        text
      }
      description {
        richText
      }
      content_alignment
      section_margin
    }
    items {
      icon {
        alt
        gatsbyImageData
      }
      title {
        richText
      }
      description {
        richText
      }
      cta_text {
        richText
      }
      cta_link {
        url
        target
        type
        uid
        slug
        link_type
      }
    }
  }
`;

export default AccordionWithBackground;
