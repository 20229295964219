import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { linkResolver } from '../utils/LinkResolver.js';

const PositionListOfCompanies = ({ slice }) => {
  const { description, title } = slice?.primary || {};
  const { items } = slice || {};

  if (!Array.isArray(items) || !items.length) return null;

  return (
    <section className="container mt-[66px] md:mt-[140px]">
      <div className="text-center max-w-[800px] mx-auto">
        <h2 className="font-body font-semibold text-[24px] md:text-[40px] text-[#1B202B] leading-[25px] md:leading-[49px]">
          {title.text}
        </h2>
        {Array.isArray(description?.richText) && description.richText[0]?.text && (
          <div className="text-[18px] md:text-[20px] leading-[24px] md:leading-[29px] text-[#1B202B] mx-auto mt-[20px]">
            <RichText
              linkResolver={linkResolver}
              render={description.richText}
            />
          </div>
        )}
      </div>
      <div className="container-medium md:max-w-full md:p-0 grid grid-cols-2 justify-items-center sm:grid-cols-3 md:grid-cols-6 gap-[24px] md:gap-[60px] mt-[40px]">
        {items.map((item, index) => {
          const image = getImage(item.logo);
          return (
            <div
              key={`company-${index}`}
              className="flex justify-center max-w-[100px] md:max-w-full"
            >
              <GatsbyImage
                alt={RichText.asText(item.logo.alt) || ""}
                className="inline"
                image={image}
              />
            </div>
          )
        })}
      </div>
    </section>
  );
};

export const query = graphql`
  fragment PositionDataBodyPositionListOfCompanies on PrismicPositionDataBodyPositionListOfCompanies {
    items {
      link {
        url
        type
        target
        link_type
      }
      logo {
        gatsbyImageData
      }
      name {
        richText
      }
    }
    primary {
      description {
        richText
      }
      title {
        text
      }
    }
  }
`;

export default PositionListOfCompanies;
