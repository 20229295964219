import * as React from 'react';
import PropTypes from 'prop-types';

const SearchResultsPagination = ({ onPaginationButtonClick, pageInfo }) => {
  const { currentPage, hasNextPage, hasPreviousPage, pageCount } = pageInfo;

  return (
    <div className="flex items-center">
      <button
        className={`inline-flex justify-center items-center px-[16px] py-[7px] mr-[23px] border rounded-[8px] border-[#CBD4E1] text-[14px] leading-[22px] text-[#1D4732] font-semibold hover:bg-[#1D4732] hover:text-white hover:border-[#1D4732]${!hasPreviousPage ? ' text-[#CBD4E1] pointer-events-none' : ''}`}
        onClick={() => onPaginationButtonClick(currentPage - 1)}
      >
        Previous
      </button>
     <div className="flex gap-[4px]">
      {Array.from({ length: pageCount }, (item, index) => (
            <button
              key={index + 1}
              className={`inline-flex justify-center items-center h-[32px] w-[32px] rounded-[8px] text-[14px] leading-[22px] hover:bg-[#1D4732] hover:text-white text-xs ${currentPage === index + 1 ? 'bg-[#1D4732] text-white pointer-events-none' : ' text-[#94A3B8]'}`}
              onClick={() => onPaginationButtonClick(index + 1)}
            >
              {index + 1}
            </button>
          )
        )}
     </div>
      <button
        className={`inline-flex justify-center items-center px-[16px] py-[7px] ml-[23px] border rounded-[8px] border-[#CBD4E1] text-[14px] leading-[22px] text-[#1D4732] font-semibold hover:bg-[#1D4732] hover:text-white hover:border-[#1D4732]${!hasNextPage ? ' text-[#CBD4E1] pointer-events-none' : ''}`}
        onClick={() => onPaginationButtonClick(currentPage + 1)}
      >
        Next
      </button>
    </div>
  );
};

SearchResultsPagination.propTypes = {
  onPaginationButtonClick: PropTypes.func,
  pageInfo: PropTypes.shape({
    currentPage: PropTypes.number,
    hasNextPage: PropTypes.bool,
    hasPreviousPage: PropTypes.bool,
    pageCount: PropTypes.number
  }),
};

SearchResultsPagination.defaultProps = {
  onPaginationButtonClick: () => {},
  pageInfo: null
};

export default SearchResultsPagination;
