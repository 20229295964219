import * as React from 'react'
import TeamMemberBiography from './TeamMemberBiography'
import { remove } from 'lodash'

const EventSpeakers = ({
  speakers,
  className,
}) => {
  remove(speakers, s => {
    return !s.full_name || s.full_name.richText.length === 0
  })

  if(!Array.isArray(speakers) || speakers.length === 0) return null

  return (
    <div className={`${className}`}>
      <h4>
        Speakers
      </h4>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 mt-12 gap-4 mb-0">
        {speakers.map((speaker, index) => (
          <TeamMemberBiography
            {...speaker}
            key={`event-speaker-${index}`}
          />
        ))}
      </div>
    </div>
  )
}

export default EventSpeakers
