import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { linkResolver } from '../utils/LinkResolver.js';
import { RichText } from 'prismic-reactjs';
import { getImage } from 'gatsby-plugin-image';

// Import Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import * as RadixTabs from '@radix-ui/react-tabs';

// Components
import LinkButton from '/src/components/LinkButton.js';
import PresentationSlideCard from '/src/components/PresentationSlideCard.js';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import ArrowSquareOut from '/src/images/icons/arrow-square-out.svg';
import ChevronLeft from '/src/images/icons/chevron-left.svg';
import ChevronRight from '/src/images/icons/chevron-right.svg';

const SpeakerPresentationSlides = ({ slice }) => {
  const data = slice.primary;
  const { items } = slice;

  const {
    anchor_id,
    content_alignment: contentAlignment,
    download_all_slides_link: downloadAllSlidesLink,
    description,
    tagline,
    title,
    section_margin
  } = data || {};

  const [activeTab, setActiveTab] = useState(null);
  const [formattedSlides, setFormattedSlides] = useState([]);
  const [slidesByDate, setSlidesByDate] = useState([]);

  const handleActiveTabChange = (value) => {
    setActiveTab(value);
  };

  useEffect(() => {
    if (Array.isArray(items) && !!items.length) {
      const formattedSlides = Array.isArray(items) && items.map((item) => {
        const image = getImage(item.slides_card_image);
        const secondSpeakerPhoto = getImage(item.second_speaker_photo);
        const speakerPhoto = getImage(item.speaker_photo);
    
        return {
          image,
          numberOfSlides: item.number_of_slides,
          presentationDate: item.presentation_date,
          secondSpeakerCompany: item.second_speaker_company,
          secondSpeakerName: item.second_speaker_name,
          secondSpeakerPhoto,
          slidesLink: item.slides_link,
          slidesTitle: item.slides_title,
          speakerCompany: item.speaker_company,
          speakerName: item.speaker_name,
          speakerPhoto
        }
      });

      setFormattedSlides(formattedSlides);
    }
  }, [items]);

  useEffect(() => {
    if (Array.isArray(formattedSlides) && !!formattedSlides.length) {
      const slidesByDate = formattedSlides.reduce((accumulator, item) => {
        const { presentationDate } = item;

        if (!accumulator[presentationDate]) {
          accumulator[presentationDate] = [];
        }

        accumulator[presentationDate].push(item);

        return accumulator;
      }, {});

      // Sort items by date and add label to each date. Ex: Day 1, Day 2, etc.
      Object.keys(slidesByDate).forEach((date, index) => {
        slidesByDate[date].sort((a, b) => {
          const dateA = new Date(a.presentationDate);
          const dateB = new Date(b.presentationDate);

          return dateA - dateB;
        });

        slidesByDate[date].label = `Day ${index + 1}`;
      });

      setActiveTab(Object.keys(slidesByDate)[0]);
      setSlidesByDate(slidesByDate);
    }
  }, [formattedSlides]);

  return (
    <section
      className={`scroll-mt-[80px]${section_margin === 'small' ? ' my-[40px] md:my-[60px]' : ' my-[80px] md:my-[140px]'}`}
      id={anchor_id ?? null}
    >
      <RadixTabs.Root
        value={activeTab}
        onValueChange={handleActiveTabChange}
      >
        <div className="container">
          <div className={`max-w-[640px] text-center${contentAlignment === 'left' ? ' md:text-left' : ''}`}>
            {!!tagline?.text && (
              <div className="font-semibold uppercase text-[18px] md:text-[24px] leading-[22px] md:leading-[32px] tracking-[2.4px] text-[#777785] mb-[20px]">
                {tagline.text}
              </div>
            )}
            {!!title?.text && (
              <h2 className="font-bold font-body text-[32px] md:text-[40px] leading-[37px] md:leading-[44px]">
                {title.text}
              </h2>
            )}
            {Array.isArray(description?.richText) && description.richText[0]?.text?.length > 0 && (
              <div className="font-body text-[18px] md:text-[20px] leading-[24px] md:leading-[28px] mt-[20px]">
                <RichText
                  linkResolver={linkResolver}
                  render={description.richText}
                />
              </div>
            )}
          </div>
          <div className="inline-grid gap-[8px] md:flex md:flex-row md:items-center md:justify-between mt-[60px]">
            <RadixTabs.List
              aria-label="Choose event day"
              className="rounded-lg bg-[#F2F2F7] inline-flex gap-[20px] p-[4px] w-fit"
            >
              {Object.entries(slidesByDate).map(([date, item], index) => (
                <RadixTabs.Trigger
                  key={index}
                  className={`text-[16px] leading-[24px] font-semibold py-[8px] px-[20px] rounded-[6px] transition-all${
                    activeTab === date ? ' text-white bg-[#3E6552] pointer-events-none' : ' bg-transparent hover:bg-[#DADAE7]'}`}
                  value={date}
                >
                  {item.label}
                </RadixTabs.Trigger>
              ))}
            </RadixTabs.List>
            {!!downloadAllSlidesLink?.url && (
              <LinkButton
                className="text-[16px] leading-[24px] font-semibold text-white bg-[#E79523] py-[12px] px-[20px] rounded-[6px] hover:bg-[#DC851B]"
                link={downloadAllSlidesLink}
              >
                <div className="flex items-center gap-[10px]">
                  <ArrowSquareOut className="h-[20px] w-[20px]" />
                  <span>Download All Slides</span>
                </div>
              </LinkButton>
            )}
          </div>
        </div>
        <div className="mt-[24px]">
          <div className='relative group overflow-hidden'>
            <div className="container">
              <Swiper
                breakpoints={{
                  768: {
                    slidesPerView: 3
                  },
                }}
                className="overflow-visible"
                grabCursor={true}
                modules={[Navigation]}
                navigation={{
                  nextEl: '.navigation-button-next',
                  prevEl: '.navigation-button-previous'
                }}
                spaceBetween={24}
                slidesPerView={1.2}
              >
                <RadixTabs.Content value={activeTab}>
                  {Array.isArray(slidesByDate[activeTab]) && slidesByDate[activeTab].map((item, index) => {
                    const authors = [
                      {
                        company: item.speakerCompany,
                        name: item.speakerName,
                        photo: item.speakerPhoto
                      },
                      {
                        company: item.secondSpeakerCompany,
                        name: item.secondSpeakerName,
                        photo: item.secondSpeakerPhoto
                      }
                    ];

                    return (
                      <SwiperSlide
                        key={`${activeTab}-${index}`}
                        className="h-auto"
                      >
                        <PresentationSlideCard
                          authors={authors}
                          image={item.image}
                          numberOfSlides={item.numberOfSlides}
                          slidesLink={item.slidesLink}
                          title={item.slidesTitle?.text}
                        />
                      </SwiperSlide>
                    );
                  })}
                </RadixTabs.Content>
              </Swiper>
            </div>
            <button className='navigation-button navigation-button-previous'>
              <ChevronLeft className="h-[24px] w-[24px] mr-[1px]" />
            </button>
            <button className='navigation-button navigation-button-next'>
              <ChevronRight className="h-[24px] w-[24px] ml-[1px]" />
            </button>
          </div>
        </div>
      </RadixTabs.Root>
    </section>
  );
};

export const query = graphql`
  fragment ConferenceDataBodySpeakerPresentationSlides on PrismicDigitalSustainabilityConferenceDataBodySpeakerPresentationSlides {
    primary {
      tagline {
        text
      }
      title {
        text
      }
      description {
        richText
      }
      content_alignment
      section_margin
      download_all_slides_link {
        url
        target
        type
        uid
        slug
        link_type
      }
    }
    items {
      slides_card_image {
        alt
        gatsbyImageData
      }
      speaker_photo {
        alt
        gatsbyImageData
      }
      second_speaker_photo {
        alt
        gatsbyImageData
      }
      presentation_date
      slides_title {
        text
      }
      number_of_slides
      speaker_name {
        richText
      }
      speaker_company {
        richText
      }
      second_speaker_name {
        richText
      }
      second_speaker_company {
        richText
      }
      slides_link {
        url
        target
        type
        uid
        slug
        link_type
      }
    }
  }
`;

export default SpeakerPresentationSlides;
