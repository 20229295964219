import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { RichText } from 'prismic-reactjs';
import { useDebouncedCallback } from 'use-debounce';

import { linkResolver } from '/src/utils/LinkResolver.js';
import getCorrectTextColor from '/src/utils/SetTextColorDependingOnBackground';

import VideoModal from '/src/components/VideoModal.js';

import ChevronDown from '/src/images/icons/chevron-down.svg';
import ChevronUp from '/src/images/icons/chevron-up.svg';
import PlayCircleIcon from '/src/images/icons/play-circle.svg';

const VideoTestimonials = ({ slice }) => {
  const data = slice.primary;
  const { items } = slice;

  const [currentVideo, setCurrentVideo] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [showRevealableItems, setShowRevealableItems] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [visibleItems, setVisibleItems] = useState(items);

  const firstThreeElements = Array.isArray(items) ?
    items.slice(0, 3) :
    [];

  const handleResize = useDebouncedCallback(() => {
    // Mobile breakpoint defined across the website
    setIsMobile(window.innerWidth < 640);
  }, 300);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      handleResize();
  
      window.addEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setVisibleItems(isMobile && !showRevealableItems ? firstThreeElements : items);
  }, [isMobile, showRevealableItems]);

  useEffect(() => {
    setShowRevealableItems(!isMobile);
  }, [isMobile]);

  if (!Array.isArray(items) || !items.length) return null;

  const handlePlayVideoButtonClick = (item) => {
    setCurrentVideo(item?.video?.url);
    setShowVideoModal(true);
  };

  const handleSeeMoreButtonClick = () => {
    setShowRevealableItems(previousState => !previousState);
  };

  const handleVideoModalClose = () => {
    setShowVideoModal(false);
  };

  const textColor = getCorrectTextColor(data?.background_color);

  return (
    <section
      className="py-[60px] md:py-[120px]"
      style={{ backgroundColor: `${data.background_color}` }}
    >
      <div className="container">
        <div className="container-medium">
          {!!data.title?.text && (
            <h2
              className="font-bold text-center text-[32px] sm:text-[40px] leading-[37px] sm:leading-[49px]"
              style={{ color: textColor }}
            >
              {data.title.text}
            </h2>
          )}
          {Array.isArray(data.description?.richText) && data.description.richText[0]?.text?.length > 0 && (
            <div
              className="text-center leading-relaxed mt-5"
              style={{ color: textColor }}
            >
              <RichText
                linkResolver={linkResolver}
                render={data.description.richText}
              />
            </div>
          )}
        </div>
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-[40px] md:mt-[60px]">
          {visibleItems.map((item, index) => {
            const image = getImage(item.image);
            const video = item.video.url;

            return (
              <div
                key={index}
                className={`rounded-md bg-white p-[12px] sm:p-[20px]${!video ? ' col-span-full' : ''}`}
              >
                <VideoModal
                  videoUrl={currentVideo}
                  showModal={showVideoModal}
                  onClose={handleVideoModalClose}
                />
                <div className={`${!video ? 'flex flex-row sm:grid sm:gap-4 sm:grid-cols-2 lg:grid-cols-3 items-center' : 'flex flex-row sm:flex-col'}`}>
                  <div className="relative">
                    {image ?
                      (
                        <GatsbyImage
                          alt={image.alt || ''}
                          className={`rounded-md h-full w-[150px] hidden sm:h-[318px] sm:flex${!video ? ' sm:w-auto mr-3 sm:mr-[24px]' : ' sm:w-full mr-3 sm:mr-0'}`}
                          image={image}
                          layout="fixed"
                          objectFit="cover"
                        />
                      ) :
                      (
                        <div className="rounded-md bg-gray-200 h-full w-full hidden sm:h-[318px] sm:flex" />
                      )
                    }
                    {!!video && (
                      <div
                        className="absolute bottom-[12px] left-[12px] hidden sm:inline-flex items-center rounded-md bg-[#1D4732] px-[20px] py-[12px]"
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => handlePlayVideoButtonClick(item)}
                        onClick={() => handlePlayVideoButtonClick(item)}
                      >
                        <PlayCircleIcon className="mr-[8px]" />
                        <span className="text-white text-[14px] leading-[20px]">
                          Play video
                        </span>
                      </div>
                    )}
                  </div>
                  <div className={`flex flex-col${!video ? ' lg:col-span-2' : ''}`}>
                    {Array.isArray(item.card_description?.richText) && item.card_description.richText[0]?.text?.length > 0 && (
                      <p className="font-semibold text-[16px] sm:text-[20px] leading-[21px] sm:leading-[28px] mb-[10px] sm:my-[20px]">
                        {RichText.asText(item.card_description.richText)}
                      </p>
                    )}
                    <div className="flex items-center">
                      {image ?
                        (
                          <GatsbyImage
                            alt={image.alt || ''}
                            className={`rounded-md h-[80px] min-w-[80px] w-[80px] sm:hidden${!video ? ' sm:w-auto mr-3 sm:mr-[24px]' : ' sm:w-full mr-3 sm:mr-0'}`}
                            image={image}
                            objectFit="cover"
                          />
                        ) :
                        (
                          <div className="rounded-md bg-gray-200 h-full w-full sm:hidden" />
                        )
                      }
                      <div>
                        {!!item.full_name?.text && (
                          <span className="block font-body font-normal text-[16px] leading-[18px] sm:text-[18px] sm:leading-[21px] mb-[8px]">
                            {item.full_name.text}
                          </span>
                        )}
                        {!!item.job_title && Array.isArray(item.job_title.richText) && item.job_title.richText[0]?.text?.length > 0 && (
                          <span className="block font-body font-normal text-[#777785] text-[14px] leading-[16px]">
                            {item.job_title.richText[0].text}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
               {!!video && (
                  <div
                    className="flex justify-center items-center sm:hidden rounded-md bg-[#1D4732] mt-[12px] py-[12px]"
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => handlePlayVideoButtonClick(item)}
                    onClick={() => handlePlayVideoButtonClick(item)}
                  >
                    <PlayCircleIcon className="mr-[8px]" />
                    <span className="text-white text-[14px] leading-[20px]">
                      Play video
                    </span>
                  </div>
               )}
              </div>
            );
          })}
        </div>
        {isMobile && (
          <div className="mt-[21px] text-center">
            <button
              aria-expanded={showRevealableItems}
              className="text-primary-900"
              type="button"
              onClick={handleSeeMoreButtonClick}
            >
              <div className="flex flex-row items-center">
                <span className="font-semibold">
                  See
                  {' '}
                  {showRevealableItems ?
                  (
                    <span>
                      less
                    </span>
                  ) :
                  (
                    <span>
                      more
                    </span>
                  )}
                </span>
                {showRevealableItems ?
                (
                  <ChevronUp className="ml-1" />
                ) :
                (
                  <ChevronDown className="ml-1" />
                )}
              </div>
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export const query = graphql`
  fragment AnnualReportDataBodyVideoTestimonials on PrismicAnnualReportDataBodyVideoTestimonials {
    primary {
      background_color
      description {
        richText
      }
      title {
        text
      }
    }
    items {
      card_description {
        richText
      }
      job_title {
        richText
      },
      full_name {
        text
      }
      image {
        alt
        gatsbyImageData
      }
      video {
        url
      }
    }
  }
`;

export default VideoTestimonials;
