import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { RichText } from 'prismic-reactjs';
import { useDebouncedCallback } from 'use-debounce';

import { linkResolver } from '/src/utils/LinkResolver.js';

import VideoModal from '/src/components/VideoModal.js';

import ChevronDown from '/src/images/icons/chevron-down.svg';
import ChevronUp from '/src/images/icons/chevron-up.svg';
import LeftQuoteIcon from '/src/images/icons/left-quote.svg';
import PlayCircleIcon from '/src/images/icons/play-circle.svg';
import RightQuoteIcon from '/src/images/icons/right-quote.svg';


const SpeakerHighlights = ({ slice }) => {
  const data = slice.primary;
  const { items } = slice;

  const {
    anchor_id,
    content_alignment: contentAlignment,
    description,
    tagline,
    title,
    section_margin
  } = data || {};

  const [currentVideo, setCurrentVideo] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [showRevealableItems, setShowRevealableItems] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [visibleItems, setVisibleItems] = useState(items);
  const [viewportWidth, setViewportWidth] = useState(0);

  const firstThreeElements = Array.isArray(items) ?
    items.slice(0, 3) :
    [];

  const handleResize = useDebouncedCallback(() => {
    // Mobile breakpoint defined across the website
    setIsMobile(window.innerWidth < 640);
    setViewportWidth(window.innerWidth);
  }, 300);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      handleResize();
  
      window.addEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setVisibleItems(isMobile && !showRevealableItems ? firstThreeElements : items);
  }, [isMobile, showRevealableItems]);

  useEffect(() => {
    setShowRevealableItems(!isMobile);
  }, [isMobile]);

  if (!Array.isArray(items) || !items.length) return null;

  const handlePlayVideoButtonClick = (item) => {
    setCurrentVideo(item?.video?.url);
    setShowVideoModal(true);
  };

  const handleSeeMoreButtonClick = () => {
    setShowRevealableItems(previousState => !previousState);
  };

  const handleVideoModalClose = () => {
    setShowVideoModal(false);
  };

  // Initialize a counter for the current row and a variable for the size of the current row
  let rowCounter = 0;
  let rowSize = viewportWidth < 992 ? 2 : 3; // If the viewport width is less than 992px, each row has 2 columns. Otherwise, start with 3 columns.

  // Reduce the visibleItems array into an array of arrays, where each sub-array represents a row in the grid
  const gridRows = visibleItems.reduce((accumulator, currentValue) => {
    const isOnlyQuote = !currentValue?.speaker_image?.gatsbyImageData && !currentValue?.video?.url;

    // Quotes cannot be displayed in the same row as images or videos
    // But they can be displayed in the same row as other quotes
    if (isOnlyQuote) {
      // Check if the previous item is not a quote
      const previousItem = accumulator[rowCounter]?.[accumulator[rowCounter].length - 1];
      const isPreviousImageOrVideo = previousItem?.speaker_image?.gatsbyImageData || previousItem?.video?.url;

      // If the previous item is not a quote, start a new row
      if (isPreviousImageOrVideo) {
        rowCounter++;
      }
    }

    // If the current row exists, add the current item to it
    if (accumulator[rowCounter]) {
      accumulator[rowCounter].push(currentValue);
    } else {
      // If the current row does not exist, create it and add the current item to it
      accumulator.push([currentValue]);
    }

    // If the current row has reached its maximum size
    if (accumulator[rowCounter].length === rowSize) {
      // Increment the rowCounter to start a new row
      rowCounter++;

      // If the viewport width is less than 992px, each row has 2 columns. Otherwise, alternate the size of the row between 3 and 2
      rowSize = viewportWidth < 992 ? 2 : (rowSize === 3 ? 2 : 3);
    }

    // Return the accumulator for the next iteration
    return accumulator;
  }, []);

  const gridCanBeRendered = viewportWidth && Array.isArray(gridRows) && !!gridRows.length;

  return (
    <section
      className={`bg-[#F2F2F7] py-[60px] md:py-[120px] ${section_margin === 'small' ? 'my-[40px] md:my-[60px]' : 'my-[80px] md:my-[140px]'}`}
      id={anchor_id ?? null}
    >
      <div className="container">
        <div className={`max-w-[640px] mx-auto${contentAlignment === 'left' ? ' text-left' : ' text-center'}`}>
          {!!tagline?.text && (
            <div className="font-semibold uppercase text-[18px] md:text-[24px] leading-[22px] md:leading-[32px] tracking-[2.4px] text-[#777785] mb-[20px]">
              {tagline.text}
            </div>
          )}
          {!!title?.text && (
            <h2 className="font-bold font-body text-[32px] md:text-[40px] leading-[37px] md:leading-[44px]">
              {title.text}
            </h2>
          )}
          {Array.isArray(description?.richText) && description.richText[0]?.text?.length > 0 && (
            <div className="font-body text-[18px] md:text-[20px] leading-[24px] md:leading-[28px] mt-[20px]">
              <RichText
                linkResolver={linkResolver}
                render={description.richText}
              />
            </div>
          )}
        </div>
        <div className="mt-[40px] md:mt-[60px]">
          {!!gridCanBeRendered && gridRows.map((row, rowIndex) => {
            const isSingleColumn = row.length === 1;

            return (
              <div
                key={rowIndex}
                className="grid gap-4 auto-cols-fr sm:grid-flow-col mb-4 last-of-type:mb-0"
              >
                {row.map((item, index) => {
                  const image = getImage(item.speaker_image);
                  const video = item.video.url;
                  const isOnlyQuote = !image && !video;
                  const quoteAlternativeLayout = isOnlyQuote && isSingleColumn;
                  const videoAndImageAlternativeLayout = isSingleColumn && !isOnlyQuote;
  
                  return (
                    <div
                      key={index}
                      className={`flex rounded-xl bg-white border border-[#E7E7EF] border-opacity-90 shadow-[0_0_5px_5px_rgba(0,0,0,0.01)] p-[12px] sm:p-[20px]${
                        videoAndImageAlternativeLayout || quoteAlternativeLayout ? ' sm:flex-row sm:gap-[20px] items-center' : ' flex-col'}`}
                    >
                      {!!image && (
                        <div className="relative hidden sm:block">
                          <GatsbyImage
                            alt={image.alt || ''}
                            className={`rounded-lg h-full w-[150px] hidden sm:h-[318px] sm:flex${
                              videoAndImageAlternativeLayout ? ' w-[480px] mr-3 sm:mr-0' : ' sm:w-full mr-3 sm:mr-[24px]'}`}
                            image={image}
                            layout="fixed"
                            objectFit="cover"
                          />
                          {!!video && (
                            <div
                              className="absolute bottom-[12px] left-[12px] hidden sm:inline-flex items-center rounded-lg bg-[#1D4732] px-[20px] py-[12px]"
                              role="button"
                              tabIndex={0}
                              onKeyDown={() => handlePlayVideoButtonClick(item)}
                              onClick={() => handlePlayVideoButtonClick(item)}
                            >
                              <PlayCircleIcon className="mr-[8px]" />
                              <span className="text-white text-[14px] leading-[20px]">
                                Play video
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                      {isOnlyQuote && (
                        <LeftQuoteIcon className={`mb-[20px]${
                          quoteAlternativeLayout ? ' min-h-[30px] min-w-[40px] mx-[20px] self-start mt-[12px]' : ' h-[24px] w-[32px]'}`}
                        />
                      )}
                      <div className={`flex flex-col grow${
                        quoteAlternativeLayout ? ' max-w-[640px] mx-auto my-[25px]' : ''}${
                        videoAndImageAlternativeLayout ? ' sm:px-[80px]' : ''}`}
                      >
                        {Array.isArray(item.text?.richText) && item.text.richText[0]?.text?.length > 0 && (
                          <p className={`font-semibold text-[16px] leading-[22px] md:text-[20px] md:leading-[24px] mb-0${
                            quoteAlternativeLayout ? ' sm:text-[24px] sm:leading-[28px]' : ''}${
                            image && !videoAndImageAlternativeLayout ? ' sm:mt-[20px]' : ' mt-0'}`}
                          >
                            {!quoteAlternativeLayout && (
                              '“'
                            )}
                            {RichText.asText(item.text.richText)}
                            {!quoteAlternativeLayout && (
                              '”'
                            )}
                          </p>
                        )}
                        <div className={`flex items-center sm:mt-auto pt-[20px]${
                          quoteAlternativeLayout ? '' : ' sm:pt-[40px]'}`}
                        >
                          {image && (
                            <GatsbyImage
                              alt={image.alt || ''}
                              className={`rounded-lg h-[80px] min-w-[80px] w-[80px] sm:hidden${
                                videoAndImageAlternativeLayout ? ' sm:w-full mr-3 sm:mr-0' : ' sm:w-auto mr-3 sm:mr-[24px]'}`}
                              image={image}
                              objectFit="cover"
                            />
                          )}
                          <div>
                            {!!item.speaker_name?.text && (
                              <span className="block font-body font-normal text-[16px] leading-[20px] md:text-[18px] md:leading-[24px]">
                                {item.speaker_name.text}
                              </span>
                            )}
                            {!!item.job_title && Array.isArray(item.job_title.richText) && item.job_title.richText[0]?.text?.length > 0 && (
                              <span className="block font-body font-normal text-[#717184] text-[14px] leading-[20px] mt-[2px]">
                                {item.job_title.richText[0].text}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      {quoteAlternativeLayout && (
                        <RightQuoteIcon className="mb-[12px] min-h-[30px] min-w-[40px] mx-[20px] self-end" />
                      )}
                      {!!image && !!video && (
                        <div
                          className="flex justify-center items-center sm:hidden rounded-lg bg-[#1D4732] mt-[12px] py-[12px]"
                          role="button"
                          tabIndex={0}
                          onKeyDown={() => handlePlayVideoButtonClick(item)}
                          onClick={() => handlePlayVideoButtonClick(item)}
                        >
                          <PlayCircleIcon className="mr-[8px]" />
                          <span className="text-white text-[14px] leading-[20px]">
                            Play video
                          </span>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
        {isMobile && (
          <div className="mt-[21px] text-center">
            <button
              aria-expanded={showRevealableItems}
              className="text-primary-900"
              type="button"
              onClick={handleSeeMoreButtonClick}
            >
              <div className="flex flex-row items-center">
                <span className="font-semibold">
                  See
                  {' '}
                  {showRevealableItems ?
                  (
                    <span>
                      less
                    </span>
                  ) :
                  (
                    <span>
                      more
                    </span>
                  )}
                </span>
                {showRevealableItems ?
                (
                  <ChevronUp className="ml-1" />
                ) :
                (
                  <ChevronDown className="ml-1" />
                )}
              </div>
            </button>
          </div>
        )}
      </div>
      <VideoModal
        videoUrl={currentVideo}
        showModal={showVideoModal}
        onClose={handleVideoModalClose}
      />
    </section>
  );
};

export const query = graphql`
  fragment ConferenceDataBodySpeakerHighlights on PrismicDigitalSustainabilityConferenceDataBodySpeakerHighlights {
    primary {
      tagline {
        text
      }
      title {
        text
      }
      description {
        richText
      }
      content_alignment
      section_margin
    }
    items {
      speaker_image {
        alt
        gatsbyImageData
      }
      text {
        richText
      }
      speaker_name {
        text
      }
      job_title {
        richText
      }
      video {
        url
      }
    }
  }
`;

export default SpeakerHighlights;
