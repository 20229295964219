import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { linkResolver } from '../utils/LinkResolver.js'
import LinkButton from '../components/LinkButton.js'

const FeaturedContent = ({ slice }) => {
  const cols = slice.items.length

  return (
    <section className={`mt-12 sm:mt-20 container grid md:grid-cols-${cols} gap-6 lg:gap-12`}>
      {slice.items.map((item, index) => {
        const preview = getImage(item.preview)

        return (
          <div
            className="bg-primary-100 rounded p-8 lg:pt-12"
            key={`featured-content-${index}`}
          >
            <div className="w-40">
              <GatsbyImage
                image={preview}
                className="shadow-md max-w-xl rounded"
                alt={item.preview.alt || RichText.asText(item.headline.richText)}
              />
            </div>
            <div className="mt-6">
              <p className="uppercase tracking-wider font-display font-bold text-primary-900">
                {RichText.asText(item.tagline.richText)}
              </p>
              <h2 className="mt-1 text-2xl lg:text-3xl leading-snug">
                {RichText.asText(item.headline.richText)}
              </h2>
              <div className="prose mt-3">
                <RichText
                  render={item.description.richText}
                  linkResolver={linkResolver}
                />
              </div>

              <LinkButton link={item.link} label={item.call_to_action} isWarning className="mt-3" />
            </div>
          </div>
        )
      })}
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyFeaturedContent on PrismicHomepageDataBodyFeaturedContent {
    items {
      tagline {
        richText
      }
      preview {
        alt
        gatsbyImageData(width: 180)
      }
      link {
        target
        url
        link_type
      }
      headline {
        richText
      }
      description {
        richText
      }
      call_to_action {
        richText
      }
    }
  }

  fragment RoadmapChallengeDataBodyFeaturedContent on PrismicRoadmapChallengeDataBodyFeaturedContent {
    items {
      tagline {
        richText
      }
      preview {
        alt
        gatsbyImageData(width: 180)
      }
      link {
        target
        url
        link_type
      }
      headline {
        richText
      }
      description {
        richText
      }
      call_to_action {
        richText
      }
    }
  }

  fragment DictionaryEntryDataBodyFeaturedContent on PrismicDictionaryEntryDataBodyFeaturedContent {
    items {
      tagline {
        richText
      }
      preview {
        alt
        gatsbyImageData(width: 180)
      }
      link {
        target
        url
        link_type
      }
      headline {
        richText
      }
      description {
        richText
      }
      call_to_action {
        richText
      }
    }
  }

  fragment SteeringGroupDataBodyFeaturedContent on PrismicSteeringGroupDataBodyFeaturedContent {
    items {
      tagline {
        richText
      }
      preview {
        alt
        gatsbyImageData(width: 180)
      }
      link {
        target
        url
        link_type
      }
      headline {
        richText
      }
      description {
        richText
      }
      call_to_action {
        richText
      }
    }
  }

  fragment PageDataBodyFeaturedContent on PrismicPageDataBodyFeaturedContent {
    items {
      tagline {
        richText
      }
      preview {
        alt
        gatsbyImageData(width: 180)
      }
      link {
        target
        url
        link_type
      }
      headline {
        richText
      }
      description {
        richText
      }
      call_to_action {
        richText
      }
    }
  }

  fragment LandingPageDataBodyFeaturedContent on PrismicLandingPageDataBodyFeaturedContent {
    items {
      tagline {
        richText
      }
      preview {
        alt
        gatsbyImageData(width: 180)
      }
      link {
        target
        url
        link_type
      }
      headline {
        richText
      }
      description {
        richText
      }
      call_to_action {
        richText
      }
    }
  }
`

export default FeaturedContent
