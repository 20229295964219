import * as React from 'react'

import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'

export class NavItem extends React.Component {
  constructor(props) {
    super(props)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.getUrl = this.getUrl.bind(this)
    this.state = {
      menuOpen: false,
    }
  }

  toggleMenu() {
    const currentState = this.state.menuOpen;
    this.setState({ menuOpen: !currentState });
  }

  getUrl(link) {
    if(!link.url) return '/'
    
    let url = link.url

    if(url === '/homepage') {
      url = '/'
    }

    return url
  }

  render() {
    const { useDarkHeader, primary, items } = this.props
    const { top_link, top_link_label } = primary
    const { menuOpen } = this.state
    const hasDropdown = items.length > 0
    const label = RichText.asText(top_link_label.richText)

    if(hasDropdown) {
      return (
        <button 
          className={`
            accordion__button w-full md:w-auto py-1 my-1 
            md:relative 
            lg:mr-8
            last:mr-0
            font-display 
          `}
          aria-expanded={menuOpen}
          onClick={this.toggleMenu}
          id={`nav-dropdown-${label}`}
          aria-haspopup="true"
        >
          <div className="flex flex-row items-start">
            <svg 
              className={`
                stroke-current fill-current text-gray-900
                ${useDarkHeader ? '' : 'md:text-white'}
                flex-shrink-0 w-2 accordion__trigger mr-3 md:mr-2
                self-center
              `}
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 320 512"
            >
              <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
            </svg>

            <span 
              className="
                text-xl border-transparent border-b-2 hover:border-current
                md:text-base 
                lg:text-lg 
                flex-shrink-0 
              "
            >
              {label}
            </span>
          </div>

          <ul 
            className={`
              w-full flex flex-col 
              ${menuOpen ? '' : 'hidden'} 
              md:origin-top-left md:absolute md:-left-8 md:mt-2 md:w-32 md:rounded-md md:shadow-lg md:bg-white md:ring-1 md:ring-black md:ring-opacity-5 focus:outline-none
            `}
            role="menu" 
            aria-orientation="vertical"
            aria-labelledby={`nav-dropdown-${label}`}
          >
            {items.map((item, index) => (
              <li 
                key={`nav-${top_link.slug}-dropdown-${index}`} 
                className="
                  ml-9 w-full block py-1 my-1 
                  md:w-auto md:ml-0 md:py-0
                  justify-self-start
                "
              >
                <Link
                  to={this.getUrl(item.sub_link)}
                  target={item.sub_link.target}
                  activeClassName='font-bold'
                  className='
                    font-display text-lg border-transparent border-b-2 hover:border-current
                    md:text-sm md:border-b-0 md:text-gray-700 md:block md:px-3 md:py-1
                    md:hover:bg-gray-100 md:hover:text-gray-900 text-left
                  '
                  role="menuitem" 
                  tabIndex="-1"
                >
                  {RichText.asText(item.sub_link_label.richText)}
                </Link>
              </li>
            ))}
          </ul>
        </button>
      )
    } else {
      return (
        <Link
          to={this.getUrl(top_link)}
          target={top_link.target}
          activeClassName='font-bold'
          className='
            font-display 
            py-1 my-1 w-full block text-xl 
            md:w-auto md:text-base md:mr-4
            lg:text-lg lg:mr-8
            last:mr-0
          '
        >
          <span className="border-transparent border-b-2 hover:border-current">
            {RichText.asText(top_link_label.richText)}
          </span>
        </Link>
      )
    }
    
  }
}