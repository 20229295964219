import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { Layout } from '/src/components/Layout';
import SliceZone from '/src/components/SliceZone';
import Seo from '/src/components/Seo';
import { RichText } from 'prismic-reactjs';

import ArticleNewsletter from '../../components/ArticleNewsletter';

const PositionPage = ({ data }) => {
  if (!data) return null;

  const { body } = data.prismicPosition?.data || {};
  const doc = data.prismicPosition?.data;
  const {
    share_title,
    share_description,
    share_image
  } = doc;

  let shareDescription = null;
  let shareImage = share_image;
  let shareTitle = null;

  if (share_description && Array.isArray(share_description.richText) && share_description.richText[0]?.text?.length > 0) {
    shareDescription = RichText.asText(share_description.richText);
  }

  if (share_title && Array.isArray(share_title.richText) && share_title.richText[0]?.text?.length > 0) {
    shareTitle = RichText.asText(share_title.richText);
  }

  if (share_image) shareImage = share_image;

  return (
    <Layout
      useDarkFooter={true}
      useDarkHeader={false}
    >
      <Seo
        description={shareDescription}
        image={shareImage}
        title={shareTitle || 'Position Page'}
      />
      <div className="bg-white">
        {body && <SliceZone sliceZone={body} />}
        <ArticleNewsletter />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Position {
    prismicPosition {
      _previewable
      data {
        share_description {
          richText
        }
        share_title {
          richText
        }
        share_image {
          url
        }
        body {
          ...on PrismicSliceType {
            slice_type
          }
          ...PositionDataBodyPositionHeadline
          ...PositionDataBodyPositionListOfCompanies
          ...PositionDataBodyRelevantArticles
          ...PositionDataBodyPositionListOfIndividualMembers
          ...PositionDataBodyPositionFeaturedResources,
          ...PositionDataBodyPositionSubPosition
        }
      }
    }
  }
`;

export default withPrismicPreview(PositionPage);
