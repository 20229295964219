import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import { linkResolver } from '../utils/LinkResolver.js'

const SimpleRichText = ({ slice }) => {
  const { title, text_block } = slice.primary

  return (
    <section className={`container-medium mt-12 sm:mt-24 first-of-type:mt-12`}>
      {Array.isArray(title.richText) && title.richText.length > 0 ?
        <>
          <h2 className="">{RichText.asText(title.richText)}</h2>
          <div className="c-divider c-divider--black mt-3" />
        </>
        : null
      }
      <div className="prose mt-12 md:mt-16 max-w-none">
        <RichText
          render={text_block.richText}
          linkResolver={linkResolver}
        />
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodySimpleRichText on PrismicPageDataBodySimpleRichText {
    primary {
      title {
        richText
      }
      text_block {
        richText
      }
    }
  }

  fragment DictionaryEntryDataBodySimpleRichText on PrismicDictionaryEntryDataBodySimpleRichText {
    primary {
      title {
        richText
      }
      text_block {
        richText
      }
    }
  }

  fragment HomepageDataBodySimpleRichText on PrismicHomepageDataBodySimpleRichText {
    primary {
      title {
        richText
      }
      text_block {
        richText
      }
    }
  }

  fragment LandingPageDataBodySimpleRichText on PrismicLandingPageDataBodySimpleRichText {
    primary {
      title {
        richText
      }
      text_block {
        richText
      }
    }
  }

  fragment MembershipDataBodySimpleRichText on PrismicMembershipDataBodySimpleRichText {
    primary {
      title {
        richText
      }
      text_block {
        richText
      }
    }
  }

  fragment OrganizationMemberDataBodySimpleRichText on PrismicOrganizationMemberDataBodySimpleRichText {
    primary {
      title {
        richText
      }
      text_block {
        richText
      }
    }
  }

  fragment SteeringGroupDataBodySimpleRichText on PrismicSteeringGroupDataBodySimpleRichText {
    primary {
      title {
        richText
      }
      text_block {
        richText
      }
    }
  }

  fragment ResourceDataBodySimpleRichText on PrismicResourceDataBodySimpleRichText {
    primary {
      title {
        richText
      }
      text_block {
        richText
      }
    }
  }
`

export default SimpleRichText
