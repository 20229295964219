import React from 'react';
import { HubspotFormBuilder } from '../components/forms/HubspotFormBuilder';

import NewsletterImage from '../images/icons/article-newsletter.svg';

const ArticleNewsletter = () => {
  return (
    <section className="my-[120px] py-[60px] bg-[#F2F2F5]">
      <div className="container grid grid-cols-1 lg:grid-cols-[292px_1fr] gap-[40px] lg:gap-[80px] items-center justify-between max-w-[915px] ml-auto mr-auto">
        <NewsletterImage className="flex justify-center lg:justify-start w-full" />
        <div>
          <h2 className="text-[24px] leading-[28px] font-body font-semibold">
            Subscribe to our Newsletter
          </h2>
          <p className="text-[16px] leading-[24px] mt-[12px] mb-[20px]">
            SDIA needs the contact information you provide to us to contact you about our products and services. You may unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, please review our
            <a
              className="text-[#527563]"
              href="/privacy-policy"
              target="_blank"
            >
              {' '}
              Privacy Policy
            </a>.
          </p>
          <HubspotFormBuilder
            articleNewsletter
            id="a7e578da-571c-4e69-a2e0-4c4587624beb"
            hideLabels
          />
        </div>
      </div>
    </section>
  )
}

export default ArticleNewsletter
