import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import { linkResolver } from '../utils/LinkResolver.js'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

const TwoColumnImageChecklist = ({ slice }) => {
  const { primary } = slice

  return (

    <section className={`mx-auto mt-24 max-w-5xl px-4 md:px-8`}>
      {primary.headline ? 
        <h3 className="text-center">
          {RichText.asText(primary.headline.richText)}
        </h3> : null}

      {primary.description ?
        <div className="text-lg mt-4 prose text-center text-gray-600 mx-auto">
          <RichText render={primary.description.richText} linkResolver={linkResolver} />
        </div> : null}

      <div className="grid grid-cols-2 gap-8 mt-8">
        {slice.items.map((item, index) => {
          const image = getImage(item.featured_image)

          return (
            <GatsbyImage 
              alt={`feature-${index}`} 
              key={`two-col-feature-${index}-img`} 
              className=""
              aspectRatio={16/9}
              image={image}
            />
          )
        })}
      </div>
      <div className="mt-8 flex flex-col sm:flex-row mx-auto justify-center">
        {slice.items.map((item, index) => (
          <div 
            className={`block prose md:prose-lg ${index > 0 ? 'mt-4 sm:mt-0 sm:ml-16' : ''}`} 
            key={`two-col-feature-${index}-txt`} 
          >
            <RichText render={item.checklist.richText} linkResolver={linkResolver} />
          </div>
        ))}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment LandingPageDataBodyTwoColumnImageChecklist on PrismicLandingPageDataBodyTwoColumnImageChecklist {
    primary {
      description {
        richText
      }
      headline {
        richText
      }
    }
    items {
      featured_image {
        gatsbyImageData(width: 600)
      }
      checklist {
        richText
      }
    }
  }

  fragment HomepageDataBodyTwoColumnImageChecklist on PrismicHomepageDataBodyTwoColumnChecklist {
    primary {
      description {
        richText
      }
      headline {
        richText
      }
    }
    items {
      featured_image {
        gatsbyImageData(width: 600)
      }
      checklist {
        richText
      }
    }
  }

  fragment ResourceDataBodyTwoColumnImageChecklist on PrismicResourceDataBodyTwoColumnChecklist {
    primary {
      description {
        richText
      }
      headline {
        richText
      }
    }
    items {
      featured_image {
        gatsbyImageData(width: 600)
      }
      checklist {
        richText
      }
    }
  }
`

export default TwoColumnImageChecklist
