import * as React from 'react'

import {
  format,
} from 'date-fns'

import { RichText } from 'prismic-reactjs'
import { Link } from 'gatsby'
import AddToCalendar from './AddToCalendar'

const FeaturedEvent = ({ 
  url, start_date_obj, end_date_obj, description, main_link, main_link_label, title, presence, location, upcoming_event, stream_link
}) => {
  return (
    <div 
      className="
        bg-primary-900 hover:bg-primary-800 transform 
        hover:scale-99 transition p-4 text-white 
        rounded-xl flex md:flex-col md:justify-between z-1"
    >
      <div className="md:mb-6 w-1/12 md:w-full">
        {!upcoming_event ? <p className="text-4xl md:text-5xl">{format(start_date_obj, 'dd')}</p> : null}
        {upcoming_event ? 
          <p className="md:text-base text-sm uppercase tracking-widest text-primary-400">Upcoming event</p> :
          <p className="md:text-base text-sm uppercase tracking-widest text-primary-400">{format(start_date_obj, 'MMMM yyyy')}</p>}
      </div>

      <div className="flex-1 ml-20 md:ml-0">
        <p>{presence}</p>
        <h5 className="mt-2 leading-snug">
          <Link to={url}>{RichText.asText(title.richText)}</Link>
        </h5>

        {!upcoming_event ? 
          <div className="mt-4">
            <AddToCalendar 
              start_date={start_date_obj} 
              end_date={end_date_obj} 
              title={RichText.asText(title.richText)}
              description={description ? RichText.asText(description.richText) : null}
              location={location ? RichText.asText(location.richText) : null}
              main_link={main_link ? main_link : null}
              main_link_label={main_link_label}
              stream_link={stream_link}
            />
          </div> : null}
        
      </div>
    </div>
  )
}

export default FeaturedEvent
