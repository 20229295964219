import * as React from 'react'
import { RichText } from 'prismic-reactjs'
import { graphql } from 'gatsby'

class FullWidthVideo extends React.Component {

  constructor(props) {
    super(props)
    this.showVideo = this.showVideo.bind(this)

    this.state = {
      active: false
    }
  }

  showVideo(e) {
    e.preventDefault()
    this.setState({ active: true })
  }

  render() {
    const { 
      slice,
    } = this.props
    
    const {
      headline,
      video_embed,
    } = slice.primary

    const {
      active
    } = this.state

    return (
      <section className={`mx-auto mt-12 px-4 sm:px-8 sm:pb-12 max-w-3xl`}>
        <h2 class="text-center">{RichText.asText(headline.richText)}</h2>
        <div class="mt-4 text-center">

          {active ? 
            <div 
              dangerouslySetInnerHTML={{ __html: video_embed.html }} 
              className="flex flex-row place-content-center"
            />
            : 
            <a href onClick={this.showVideo}>
              <img 
                src={video_embed.thumbnail_url_with_play_button || video_embed.thumbnail_url} 
                alt={video_embed.title}
                className="inline"
              />
            </a>}
        </div>
      </section>
    )
  }
}

export const query = graphql`
  fragment LandingPageDataBodyFullWidthVideo on PrismicLandingPageDataBodyFullWidthVideo {
    primary {
      headline {
        richText
      }
      video_embed {
        html
        thumbnail_url_with_play_button
        thumbnail_url
        title
      }
    }
  }

  fragment HomepageDataBodyFullWidthVideo on PrismicHomepageDataBodyFullWidthVideo {
    primary {
      headline {
        richText
      }
      video_embed {
        html
        thumbnail_url_with_play_button
        thumbnail_url
        title
      }
    }
  }

  fragment ResourceDataBodyFullWidthVideo on PrismicResourceDataBodyFullWidthVideo {
    primary {
      headline {
        richText
      }
      video_embed {
        html
        thumbnail_url_with_play_button
        thumbnail_url
        title
      }
    }
  }
`

export default FullWidthVideo
