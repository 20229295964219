import * as React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import { linkResolver } from '../utils/LinkResolver.js';
import getCorrectTextColor from '/src/utils/SetTextColorDependingOnBackground';


const ConferenceTextBlock = ({ slice }) => {
  const { anchor_id, title, text } = slice.primary;

  if (!text) return null;

  const backgroundColor = slice.primary.background_color;
  const textColor = getCorrectTextColor(backgroundColor);

  return (
    <section
      className={`relative ${backgroundColor ? 'py-[80px]' : 'my-[80px] md:my-[140px] scroll-mt-[80px]'}`}
      id={anchor_id ?? null}
      style={{ backgroundColor: backgroundColor ? `${backgroundColor}` : '#FFFFFF'}}
    >
      <div className="container max-w-[835px]">
        {!!title?.text && (
          <h2
            className="font-body font-semibold text-center text-[32px] md:text-[40px] text-[#1B202B] leading-[37px] md:leading-[44px] mb-[60px]"
            style={{ color: textColor }}
          >
            {title.text}
          </h2>
        )}
        {Array.isArray(text?.richText) && text.richText[0]?.text && (
          <div
            className="container prose text-[18px] md:text-[20px] leading-[24px] md:leading-[28px] text-[#1B202B] mx-auto"
            style={{ color: textColor }}
          >
            <RichText
              linkResolver={linkResolver}
              render={text.richText}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ConferenceDataBodyConferenceTextBlock on PrismicDigitalSustainabilityConferenceDataBodyConferenceTextBlock {
    primary {
      title {
        text
      }
      text {
        richText
      }
      background_color
    }
  }
`

export default ConferenceTextBlock;
