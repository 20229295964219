import * as React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import FeaturedResource from '../components/FeaturedResource'

const FeaturedResources = ({ slice }) => {
  if(!slice || !slice.items || slice.items.length === 0) return null
  if(!slice || !slice.primary) return null
  
  const { items, primary } = slice
  const { title, subtitle } = primary

  return (
    <section className={`mt-12 sm:mt-24 lg:pb-8 container`}>
      <h2 className="text-center">
        {RichText.asText(title.richText)}
      </h2>

      <p className="text-xl font-light font-display text-gray-500 mt-3 text-center">
        {RichText.asText(subtitle.richText)}
      </p>

      <div className={`grid grid-cols-1 sm:grid-cols-2 mt-12 gap-4`}>
        {items.map((item, index) => (
          <FeaturedResource
            key={`resource-${index}`} 
            {...item} 
          />
        ))}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyFeaturedResources on PrismicHomepageDataBodyFeaturedResources {
    items {
      resource_type
      resource {
        document {
          ...PrismicResourceFragment
        }
      }
      preview_image {
        gatsbyImageData(width: 180)
      }
      link {
        url
        uid
        type
        target
        link_type
      }
      title {
        richText
      }
    }
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
  }

  fragment RoadmapChallengeDataBodyFeaturedResources on PrismicRoadmapChallengeDataBodyFeaturedResources {
    items {
      resource_type
      resource {
        document {
          ...PrismicResourceFragment
        }
      }
      preview_image {
        gatsbyImageData(width: 180)
      }
      link {
        url
        uid
        type
        target
        link_type
      }
      title {
        richText
      }
    }
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
  }

  fragment DictionaryEntryDataBodyFeaturedResources on PrismicDictionaryEntryDataBodyFeaturedResources {
    items {
      resource_type
      resource {
        document {
          ...PrismicResourceFragment
        }
      }
      preview_image {
        gatsbyImageData(width: 180)
      }
      link {
        url
        uid
        type
        target
        link_type
      }
      title {
        richText
      }
    }
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
  }

  fragment SteeringGroupDataBodyFeaturedResources on PrismicSteeringGroupDataBodyFeaturedResources {
    items {
      resource_type
      resource {
        document {
          ...PrismicResourceFragment
        }
      }
      preview_image {
        gatsbyImageData(width: 180)
      }
      link {
        url
        uid
        type
        target
        link_type
      }
      title {
        richText
      }
    }
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
  }

  fragment PageDataBodyFeaturedResources on PrismicPageDataBodyFeaturedResources {
    items {
      resource_type
      resource {
        document {
          ...PrismicResourceFragment
        }
      }
      preview_image {
        gatsbyImageData(width: 180)
      }
      link {
        url
        uid
        type
        target
        link_type
      }
      title {
        richText
      }
    }
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
  }

  fragment MembershipDataBodyFeaturedResources on PrismicMembershipDataBodyFeaturedResources {
    items {
      resource_type
      resource {
        document {
          ...PrismicResourceFragment
        }
      }
      preview_image {
        gatsbyImageData(width: 180)
      }
      link {
        url
        uid
        type
        target
        link_type
      }
      title {
        richText
      }
    }
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
  }

  fragment OrganizationMemberDataBodyFeaturedResources on PrismicOrganizationMemberDataBodyFeaturedResources {
    items {
      resource_type
      resource {
        document {
          ...PrismicResourceFragment
        }
      }
      preview_image {
        gatsbyImageData(width: 180)
      }
      link {
        url
        uid
        type
        target
        link_type
      }
      title {
        richText
      }
    }
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
  }
  
  fragment LandingPageDataBodyFeaturedResources on PrismicLandingPageDataBodyFeaturedResources {
    items {
      resource_type
      resource {
        document {
          ...PrismicResourceFragment
        }
      }
      preview_image {
        gatsbyImageData(width: 180)
      }
      link {
        url
        uid
        type
        target
        link_type
      }
      title {
        richText
      }
    }
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
  }
  
  fragment ResourceDataBodyFeaturedResources on PrismicResourceDataBodyFeaturedResources {
    items {
      resource_type
      resource {
        document {
          ...PrismicResourceFragment
        }
      }
      preview_image {
        gatsbyImageData(width: 180)
      }
      link {
        url
        uid
        type
        target
        link_type
      }
      title {
        richText
      }
    }
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
  }

  fragment ArticleDataBodyFeaturedResources on PrismicArticleDataBodyFeaturedResources {
    items {
      resource_type
      resource {
        document {
          ...PrismicResourceFragment
        }
      }
      preview_image {
        gatsbyImageData(width: 180)
      }
      link {
        url
        uid
        type
        target
        link_type
      }
      title {
        richText
      }
    }
    primary {
      title {
        richText
      }
      subtitle {
        richText
      }
    }
  }
`

export default FeaturedResources
