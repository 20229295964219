import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

import ListResearchProjects from '../components/ListResearchProjects'

const AllResearchProjects = () => {
  const queryData = useStaticQuery(graphql`
    {
      allPrismicResearchProject(sort: {data: {start_year: DESC}}) {
        edges {
          node {
            ...PrismicResearchProjectFragment
          }
        }
      }
    }
  `)
  const { data } = useMergePrismicPreviewData(queryData)
  const doc = data.allPrismicResearchProject
  const allProjects = doc.edges.map(edge => { 
    return { ...edge.node.data, uid: edge.node.uid } 
  })

  return (
    <ListResearchProjects allResearchProjects={allProjects} />
  )
}

export const query = graphql`
  fragment PageDataBodyAllResearchProjects on PrismicPageDataBodyAllResearchProjects {
    id
  }
`

export default AllResearchProjects
