import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { RichText } from 'prismic-reactjs'
import { parseISO } from 'date-fns'

import Layout from '../../components/Layout'
import EventDetail from '../../components/EventDetail'
import SliceZone from '../../components/SliceZone'
import Seo from '../../components/Seo'

const EventTemplate = ({ location, data }) => {
  if (!data) return null
  const doc = data.prismicEvent.data
  
  const {
    title,
    description,
    start_date,
    end_date,
  } = doc

  doc.start_date_obj = parseISO(start_date)
  doc.end_date_obj = parseISO(end_date)

  return (
    <Layout useDarkHeader={true} useDarkFooter={true}>
      <Seo title={RichText.asText(title.richText)} description={RichText.asText(description.richText)} image={doc.image} />
      
      <div className="pt-12 md:pt-6">
        <EventDetail {...doc} />
        
        {doc.body ? <SliceZone sliceZone={doc.body} location={location} /> : null}
        {doc.body1 ? <SliceZone sliceZone={doc.body1} location={location} /> : null}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query EventQuery($id: String) {
    prismicEvent(id: { eq: $id }) {
      ...PrismicEventFragment

      data {
        body {
          ...on PrismicSliceType {
            slice_type
          }
          ...EventDataBodyFeaturedTeamMembers
        }
        body1 {
          ...on PrismicSliceType {
            slice_type
          }
          ...EventDataBody1FeaturedEvents
          ...EventDataBody1FeaturedIndividualMembers
          ...EventDataBody1CallToAction
          ...EventDataBody1HubSpotForm
          ...EventDataBody1FeaturedArticles
        }
      }
    }
  }
`

export default withPrismicPreview(EventTemplate)
