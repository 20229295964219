import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'

const Activity = ({ 
  url,
  name,
  metric
}) => (
  <li className="border-b-2 border-dotted border-gray-300 p-4 last:border-b-0">
    <Link 
      to={`/roadmap/${metric.document.uid}${url}`} 
      className="text-orange-400 underline text-lg hover:no-underline underline-offset-1"
    >
      {RichText.asText(name.richText)}
    </Link>
  </li>
)

export default Activity

export const roadmapActivityFragment = graphql`
  fragment PrismicRoadmapActivityFragment on PrismicRoadmapActivity {
    _previewable
    url
    uid
    last_publication_date(fromNow: true)

    data {
      end_date
      start_date
      target {
        richText
      }
      name {
        richText
      }
      impact_principle {
        richText
      }
      metric {
        document {
          ... on PrismicRoadmapMetric {
            id
            uid
            data {
              current_measure
              name
              explanation {
                richText
              }
            }
          }
        }
      }
      members {
        member {
          document {
            ... on PrismicOrganizationMember {
              id
              data {
                joined_on
                link {
                  url
                  type
                  target
                  link_type
                }
                logo {
                  gatsbyImageData(width: 150)
                }
                name {
                  richText
                }
              }
            }
          }
        }
      }
      sd_goals {
        sd_goal {
          document {
            ... on PrismicSdGoal {
              id
              data {
                goal_number
                icon {
                  gatsbyImageData(width: 100)
                }
                icon_inverted {
                  gatsbyImageData(width: 100)
                }
                name {
                  richText
                }
              }
            }
          }
        }
      }
    }
  }
`