import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { RichText } from 'prismic-reactjs';

const SteeringGroupWhiteWithColoredBorder = ({
  color,
  icon_dark,
  name,
  onClick,
  roadmap_activity,
  state
}) => {
  const darkIcon = getImage(icon_dark);
  
  return (
    <div
      className="relative hover:cursor-pointer bg-white hover:bg-primary-100 transform hover:scale-99 transition drop-shadow rounded-xl md:flex-col md:justify-between overflow-hidden"
      tabIndex={0}
      role="button"
      onClick={onClick}
      onKeyPress={onClick}
    >
      <div className="p-[12px] sm:p-[20px] pb-[20px] sm:pb-[32px]">
        {!!state && (
          <p className="uppercase text-[12px] leading-[14px] sm:text-[18px] sm:leading-[21px] sm:mb-[30px] tracking-wide">
            {state}
          </p>
        )}
        <div className="flex items-center sm:items-start text-left flex-row sm:flex-col my-[12px] sm:my-0">
          {!!darkIcon && (
            <GatsbyImage
              alt={RichText.asText(name.richText) || ''}
              className="h-[40px] min-w-[40px] w-[40px] sm:h-[65px] sm:w-[65px] sm:mb-[30px] mr-[12px] sm:mr-0"
              layout="fixed"
              objectFit="contain"
              objectPosition="left"
              image={darkIcon}
            />
          )}
          {Array.isArray(name?.richText) && name.richText[0]?.text?.length > 0 && (
            <h5 className="text-[16px] leading-[20px] sm:text-[24px] sm:leading-[28px]">
              {RichText.asText(name.richText)}
            </h5>
          )}
        </div>
        {Array.isArray(roadmap_activity?.document?.data?.name?.richText) && roadmap_activity.document.data.name.richText[0]?.text?.length > 0 && (
          <li className="text-[16px] leading-[20px] sm:text-[18px] mt-[12px]">
            {RichText.asText(roadmap_activity.document.data.name.richText)}
          </li>
        )}
      </div>
      {!!color && (
        <div
          className="absolute bottom-0 left-0 h-[8px] sm:h-[12px] w-full"
          style={{ backgroundColor: color }}
        />
      )}
    </div>
  )
}

export default SteeringGroupWhiteWithColoredBorder;

export const steeringGroupFragment = graphql`
  fragment PrismicSteeringGroupGridFragment on PrismicSteeringGroup {
    _previewable
    uid
    url

    data {
      body {
        ... on PrismicSteeringGroupDataBodyAnnualReportTopics {
          items {
            image_1 {
              alt
              gatsbyImageData
            }
            image_2 {
              alt
              gatsbyImageData
            }
            image_3 {
              alt
              gatsbyImageData
            }
            topic_description {
              richText
            }
            topic_title {
              text
            }
          }
        }
      }
      color
      start_date
      background_image {
        gatsbyImageData(width: 800)
      }
      icon_light {
        url
        gatsbyImageData(width: 80)
      }
      icon_dark {
        url
        gatsbyImageData(width: 80)
      }
      summary {
        richText
      }
      name {
        richText
      }

      # Running
      # Finished
      # Upcoming
      # Assembling
      state 

      roadmap_activity {
        document {
          ... on PrismicRoadmapActivity {
            id
            data {
              name {
                richText
              }
            }
          }
        }
      }

      moderator {
        document {
          ... on PrismicTeamMember {
            id
            url

            data {
              full_name {
                richText
              }
              public_email
              photo {
                gatsbyImageData(
                  width: 130, height: 130, imgixParams: {
                    fit: "facearea",
                    facepad: 3.5
                    maxH: 130
                  }
                )
              }
              title {
                richText
              }
            }
          }
        }
      }

      subject_matter_expert {
        document {
          ... on PrismicIndividualMember {
            id
            url

            data {
              title {
                richText
              }
              full_name {
                richText
              }
              linkedin_profile {
                url
                uid
                type
                target
                link_type
              }
              biography {
                richText
              }
              photo {
                gatsbyImageData(
                  width: 130, height: 130, imgixParams: {
                    fit: "facearea",
                    facepad: 3.5
                    maxH: 130
                  }
                )
              }
            }
          }
        }
      }
    }
  }
`
