// Packages
import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

// Utils
import { linkResolver } from '/src/utils/LinkResolver.js';

// Components
import LinkButton from '/src/components/LinkButton.js';


const SectionHeader = ({ slice }) => {
  if (!slice?.primary || !slice.primary.title) return null;

  const data = slice.primary;

  const {
    anchor_id,
    tagline,
    title,
    description,
    primary_button_text,
    primary_button_link,
    secondary_button_text,
    secondary_button_link,
    content_alignment: contentAlignment,
    section_margin
  } = data || {};

  return (
    <section
      className={`mb-[60px] scroll-mt-[80px]${section_margin === 'small' ? ' mt-[40px] md:mt-[60px]' : ' mt-[80px] md:mt-[140px]'}`}
      id={anchor_id ?? null}
    >
      <div className="container">
        <div className={`max-w-[640px] mx-auto ${contentAlignment === 'left' ? ' text-left' : ' text-center'}`}>
          {!!tagline?.text && (
            <div className="font-semibold uppercase text-[18px] md:text-[24px] leading-[22px] md:leading-[32px] tracking-[2.4px] text-[#777785] mb-[20px]">
              {tagline.text}
            </div>
          )}
          {!!title?.text && (
            <h2 className="font-bold font-body text-[32px] md:text-[40px] leading-[37px] md:leading-[44px]">
              {title.text}
            </h2>
          )}
          {Array.isArray(description?.richText) && description.richText[0]?.text?.length > 0 && (
            <div className="font-body text-[18px] md:text-[20px] leading-[24px] md:leading-[28px] mt-[20px]">
              <RichText
                linkResolver={linkResolver}
                render={description.richText}
              />
            </div>
          )}
          {
            ((Array.isArray(secondary_button_text?.richText) && secondary_button_text.richText[0]?.text) ||
            (Array.isArray(primary_button_text?.richText) && primary_button_text.richText[0]?.text))
          && (
            <div className="flex justify-center gap-[20px] mt-[20px]">
              {Array.isArray(secondary_button_text?.richText) && secondary_button_text.richText[0]?.text && (
                <LinkButton
                  className="text-[#E79523] bg-[#FEF5EB] hover:bg-[#f6eee4] text-[16px] leading-[24px] font-semibold py-[12px] px-[20px] rounded-[6px]"
                  label={secondary_button_text}
                  link={secondary_button_link}
                />
              )}
              {Array.isArray(primary_button_text?.richText) && primary_button_text.richText[0]?.text && (
                <LinkButton
                  className="text-[16px] leading-[24px] font-semibold text-white bg-[#E79523] py-[12px] px-[20px] rounded-[6px] hover:bg-[#DC851B]"
                  label={primary_button_text}
                  link={primary_button_link}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ConferenceDataBodySectionHeader on PrismicDigitalSustainabilityConferenceDataBodySectionHeader {
    primary {
      tagline {
        text
      }
      title {
        text
      }
      description {
        richText
      }
      primary_button_text {
        richText
      }
      primary_button_link {
        url
        target
        type
        uid
        slug
        link_type
      }
      secondary_button_text {
        richText
      }
      secondary_button_link {
        url
        target
        type
        uid
        slug
        link_type
      }
      content_alignment
      section_margin
    }
  }
`;

export default SectionHeader;
